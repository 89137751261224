.choose-btns {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 40px;
    background: #fffbfb;
    margin-bottom: 20px;
}
