 @import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
 @import url("./icons/line-awesome/css/line-awesome.min.css");
 @import url("./icons/font-awesome/css/font-awesome.min.css");
 @import url("./icons/flaticon/flaticon.css");
 @import url("./icons/themify/themify-icons.css");

 :root {
   --blue: #007bff;
   --indigo: #6610f2;
   --purple: #6f42c1;
   --pink: #e83e8c;
   --red: #dc3545;
   --orange: #fd7e14;
   --yellow: #ffc107;
   --green: #28a745;
   --teal: #20c997;
   --cyan: #17a2b8;
   --white: #fff;
   --gray: #6c757d;
   --gray-dark: #343a40;
   --primary: #213f99;
   --secondary: #673BB7;
   --success: #10d876;
   --info: #2754e6;
   --warning: #FE9431;
   --danger: #E50202;
   --light: #faf8f2;
   --dark: #262F5A;
   --breakpoint-xs: 0;
   --breakpoint-sm: 576px;
   --breakpoint-md: 768px;
   --breakpoint-lg: 992px;
   --breakpoint-xl: 1200px;
   --breakpoint-xxl: 1440;
   --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
 }

 *,
 *::before,
 *::after {
   box-sizing: border-box;
 }

 html {
   font-family: sans-serif;
   line-height: 1.15;
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }

 article,
 aside,
 figcaption,
 figure,
 footer,
 header,
 hgroup,
 main,
 nav,
 section {
   display: block;
 }

 body {
   margin: 0;
   font-family: "Roboto", sans-serif;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #515184;
   text-align: left;
   background-color: #fff;
 }

 [tabindex="-1"]:focus:not(:focus-visible) {
   outline: 0 !important;
 }

 hr {
   box-sizing: content-box;
   height: 0;
   overflow: visible;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   margin-top: 0;
   margin-bottom: 0.5rem;
 }

 p {
   margin-top: 0;
   margin-bottom: 1rem;
 }

 abbr[title],
 abbr[data-original-title] {
   text-decoration: underline;
   text-decoration: underline dotted;
   cursor: help;
   border-bottom: 0;
   text-decoration-skip-ink: none;
 }

 address {
   margin-bottom: 1rem;
   font-style: normal;
   line-height: inherit;
 }

 ol,
 ul,
 dl {
   margin-top: 0;
   margin-bottom: 1rem;
 }

 ol ol,
 ul ul,
 ol ul,
 ul ol {
   margin-bottom: 0;
 }

 dt {
   font-weight: 700;
 }

 dd {
   margin-bottom: .5rem;
   margin-left: 0;
 }

 blockquote {
   margin: 0 0 1rem;
 }

 b,
 strong {
   font-weight: bolder;
 }

 small {
   font-size: 80%;
 }

 sub,
 sup {
   position: relative;
   font-size: 75%;
   line-height: 0;
   vertical-align: baseline;
 }

 sub {
   bottom: -.25em;
 }

 sup {
   top: -.5em;
 }

 a {
   color: #213f99;
   text-decoration: none;
   background-color: transparent;
 }

 a:hover {
   color: #653b11;
   text-decoration: underline;
 }

 a:not([href]) {
   color: inherit;
   text-decoration: none;
 }

 a:not([href]):hover {
   color: inherit;
   text-decoration: none;
 }

 pre,
 code,
 kbd,
 samp {
   font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
   font-size: 1em;
 }

 pre {
   margin-top: 0;
   margin-bottom: 1rem;
   overflow: auto;
   -ms-overflow-style: scrollbar;
 }

 figure {
   margin: 0 0 1rem;
 }

 img {
   vertical-align: middle;
   border-style: none;
 }

 svg {
   overflow: hidden;
   vertical-align: middle;
 }

 table {
   border-collapse: collapse;
 }

 caption {
   padding-top: 0.75rem;
   padding-bottom: 0.75rem;
   color: #AEAED5;
   text-align: left;
   caption-side: bottom;
 }

 th {
   text-align: inherit;
 }

 label {
   display: inline-block;
   margin-bottom: 0.5rem;
 }

 button {
   border-radius: 0;
 }

 button:focus {
   outline: 1px dotted;
   outline: 5px auto -webkit-focus-ring-color;
 }

 input,
 button,
 select,
 optgroup,
 textarea {
   margin: 0;
   font-family: inherit;
   font-size: inherit;
   line-height: inherit;
 }

 button,
 input {
   overflow: visible;
 }

 button,
 select {
   text-transform: none;
 }

 [role="button"] {
   cursor: pointer;
 }

 select {
   word-wrap: normal;
 }

 button,
 [type="button"],
 [type="reset"],
 [type="submit"] {
   -webkit-appearance: button;
 }

 button:not(:disabled),
 [type="button"]:not(:disabled),
 [type="reset"]:not(:disabled),
 [type="submit"]:not(:disabled) {
   cursor: pointer;
 }

 button::-moz-focus-inner,
 [type="button"]::-moz-focus-inner,
 [type="reset"]::-moz-focus-inner,
 [type="submit"]::-moz-focus-inner {
   padding: 0;
   border-style: none;
 }

 input[type="radio"],
 input[type="checkbox"] {
   box-sizing: border-box;
   padding: 0;
 }

 textarea {
   overflow: auto;
   resize: vertical;
 }

 fieldset {
   min-width: 0;
   padding: 0;
   margin: 0;
   border: 0;
 }

 legend {
   display: block;
   width: 100%;
   max-width: 100%;
   padding: 0;
   margin-bottom: .5rem;
   font-size: 1.5rem;
   line-height: inherit;
   color: inherit;
   white-space: normal;
 }

 progress {
   vertical-align: baseline;
 }

 [type="number"]::-webkit-inner-spin-button,
 [type="number"]::-webkit-outer-spin-button {
   height: auto;
 }

 [type="search"] {
   outline-offset: -2px;
   -webkit-appearance: none;
 }

 [type="search"]::-webkit-search-decoration {
   -webkit-appearance: none;
 }

 ::-webkit-file-upload-button {
   font: inherit;
   -webkit-appearance: button;
 }

 output {
   display: inline-block;
 }

 summary {
   display: list-item;
   cursor: pointer;
 }

 template {
   display: none;
 }

 [hidden] {
   display: none !important;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 .h1,
 .h2,
 .h3,
 .h4,
 .h5,
 .h6 {
   margin-bottom: 0.5rem;
   font-weight: 500;
   line-height: 1.2;
   color: #000;
 }

 h1,
 .h1 {
   font-size: 2.25rem;
 }

 h2,
 .h2 {
   font-size: 1.875rem;
 }

 h3,
 .h3 {
   font-size: 1.5rem;
 }

 h4,
 .h4 {
   font-size: 1.125rem;
 }

 h5,
 .h5 {
   font-size: 1rem;
 }

 h6,
 .h6 {
   font-size: 0.875rem;
 }

 .lead {
   font-size: 1.25rem;
   font-weight: 300;
 }

 .display-1 {
   font-size: 6rem;
   font-weight: 300;
   line-height: 1.2;
 }

 .display-2 {
   font-size: 5.5rem;
   font-weight: 300;
   line-height: 1.2;
 }

 .display-3 {
   font-size: 4.5rem;
   font-weight: 300;
   line-height: 1.2;
 }

 .display-4 {
   font-size: 3.5rem;
   font-weight: 300;
   line-height: 1.2;
 }

 hr {
   margin-top: 1rem;
   margin-bottom: 1rem;
   border: 0;
   border-top: 1px solid rgba(0, 0, 0, 0.1);
 }

 small,
 .small {
   font-size: 80%;
   font-weight: 400;
 }

 mark,
 .mark {
   padding: 0.2em;
   background-color: #fcf8e3;
 }

 .list-unstyled {
   padding-left: 0;
   list-style: none;
 }

 .list-inline {
   padding-left: 0;
   list-style: none;
 }

 .list-inline-item {
   display: inline-block;
 }

 .list-inline-item:not(:last-child) {
   margin-right: 0.5rem;
 }

 .initialism {
   font-size: 90%;
   text-transform: uppercase;
 }

 .blockquote {
   margin-bottom: 1rem;
   font-size: 1.25rem;
 }

 .blockquote-footer {
   display: block;
   font-size: 80%;
   color: #6c757d;
 }

 .blockquote-footer::before {
   content: "\2014\00A0";
 }

 .img-fluid {
   max-width: 100%;
   height: auto;
 }

 .img-thumbnail {
   padding: 0.25rem;
   background-color: #fff;
   border: 1px solid #dee2e6;
   border-radius: 0.25rem;
   max-width: 100%;
   height: auto;
 }

 .figure {
   display: inline-block;
 }

 .figure-img {
   margin-bottom: 0.5rem;
   line-height: 1;
 }

 .figure-caption {
   font-size: 90%;
   color: #6c757d;
 }

 code {
   font-size: 87.5%;
   color: #e83e8c;
   word-wrap: break-word;
 }

 a>code {
   color: inherit;
 }

 kbd {
   padding: 0.2rem 0.4rem;
   font-size: 87.5%;
   color: #fff;
   background-color: #212529;
   border-radius: 0.2rem;
 }

 kbd kbd {
   padding: 0;
   font-size: 100%;
   font-weight: 700;
 }

 pre {
   display: block;
   font-size: 87.5%;
   color: #212529;
 }

 pre code {
   font-size: inherit;
   color: inherit;
   word-break: normal;
 }

 .pre-scrollable {
   max-height: 340px;
   overflow-y: scroll;
 }

 .container {
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   margin-right: auto;
   max-width: 1170px;
   margin-left: auto;
 }

 @media (min-width: 576px) {
   .container {
     max-width: 540px;
   }
 }

 @media (min-width: 768px) {
   .container {
     max-width: 720px;
   }
 }

 @media (min-width: 992px) {
   .container {
     max-width: 960px;
   }
 }

 @media (min-width: 1200px) {
   .container {
     max-width: 1199px;
   }
 }

 .container-fluid,
 .container-sm,
 .container-md,
 .container-lg,
 .container-xl {
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   margin-right: auto;
   margin-left: auto;
   max-width: 1340px;
 }

 @media (min-width: 576px) {

   .container,
   .container-sm {
     max-width: 540px;
   }
 }

 @media (min-width: 768px) {

   .container,
   .container-sm,
   .container-md {
     max-width: 720px;
   }
 }

 @media (min-width: 992px) {

   .container,
   .container-sm,
   .container-md,
   .container-lg {
     max-width: 960px;
   }
 }

 @media (min-width: 1200px) {

   .container,
   .container-sm,
   .container-md,
   .container-lg,
   .container-xl {
     max-width: 1199px;
   }
 }

 .row {
   display: flex;
   flex-wrap: wrap;
   margin-right: -15px;
   margin-left: -15px;
 }

 .no-gutters {
   margin-right: 0;
   margin-left: 0;
 }

 .no-gutters>.col,
 .no-gutters>[class*="col-"] {
   padding-right: 0;
   padding-left: 0;
 }

 .col-1,
 .col-2,
 .col-3,
 .col-4,
 .col-5,
 .col-6,
 .col-7,
 .col-8,
 .col-9,
 .col-10,
 .col-11,
 .col-12,
 .col,
 .col-auto,
 .col-sm-1,
 .col-sm-2,
 .col-sm-3,
 .col-sm-4,
 .col-sm-5,
 .col-sm-6,
 .col-sm-7,
 .col-sm-8,
 .col-sm-9,
 .col-sm-10,
 .col-sm-11,
 .col-sm-12,
 .col-sm,
 .col-sm-auto,
 .col-md-1,
 .col-md-2,
 .col-md-3,
 .col-md-4,
 .col-md-5,
 .col-md-6,
 .col-md-7,
 .col-md-8,
 .col-md-9,
 .col-md-10,
 .col-md-11,
 .col-md-12,
 .col-md,
 .col-md-auto,
 .col-lg-1,
 .col-lg-2,
 .col-lg-3,
 .col-lg-4,
 .col-lg-5,
 .col-lg-6,
 .col-lg-7,
 .col-lg-8,
 .col-lg-9,
 .col-lg-10,
 .col-lg-11,
 .col-lg-12,
 .col-lg,
 .col-lg-auto,
 .col-xl-1,
 .col-xl-2,
 .col-xl-3,
 .col-xl-4,
 .col-xl-5,
 .col-xl-6,
 .col-xl-7,
 .col-xl-8,
 .col-xl-9,
 .col-xl-10,
 .col-xl-11,
 .col-xl-12,
 .col-xl,
 .col-xl-auto,
 .col-xxl-1,
 .col-xxl-2,
 .col-xxl-3,
 .col-xxl-4,
 .col-xxl-5,
 .col-xxl-6,
 .col-xxl-7,
 .col-xxl-8,
 .col-xxl-9,
 .col-xxl-10,
 .col-xxl-11,
 .col-xxl-12,
 .col-xxl,
 .col-xxl-auto {
   position: relative;
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
 }

 .col {
   flex-basis: 0;
   flex-grow: 1;
   min-width: 0;
   max-width: 100%;
 }

 .row-cols-1>* {
   flex: 0 0 100%;
   max-width: 100%;
 }

 .row-cols-2>* {
   flex: 0 0 50%;
   max-width: 50%;
 }

 .row-cols-3>* {
   flex: 0 0 33.3333333333%;
   max-width: 33.3333333333%;
 }

 .row-cols-4>* {
   flex: 0 0 25%;
   max-width: 25%;
 }

 .row-cols-5>* {
   flex: 0 0 20%;
   max-width: 20%;
 }

 .row-cols-6>* {
   flex: 0 0 16.6666666667%;
   max-width: 16.6666666667%;
 }

 .col-auto {
   flex: 0 0 auto;
   width: auto;
   max-width: 100%;
 }

 .col-1 {
   flex: 0 0 8.3333333333%;
   max-width: 8.3333333333%;
 }

 .col-2 {
   flex: 0 0 16.6666666667%;
   max-width: 16.6666666667%;
 }

 .col-3 {
   flex: 0 0 25%;
   max-width: 25%;
 }

 .col-4 {
   flex: 0 0 33.3333333333%;
   max-width: 33.3333333333%;
 }

 .col-5 {
   flex: 0 0 41.6666666667%;
   max-width: 41.6666666667%;
 }

 .col-6 {
   flex: 0 0 50%;
   max-width: 50%;
 }

 .col-7 {
   flex: 0 0 58.3333333333%;
   max-width: 58.3333333333%;
 }

 .col-8 {
   flex: 0 0 66.6666666667%;
   max-width: 66.6666666667%;
 }

 .col-9 {
   flex: 0 0 75%;
   max-width: 75%;
 }

 .col-10 {
   flex: 0 0 83.3333333333%;
   max-width: 83.3333333333%;
 }

 .col-11 {
   flex: 0 0 91.6666666667%;
   max-width: 91.6666666667%;
 }

 .col-12 {
   flex: 0 0 100%;
   max-width: 100%;
 }

 .order-first {
   order: -1;
 }

 .order-last {
   order: 13;
 }

 .order-0 {
   order: 0;
 }

 .order-1 {
   order: 1;
 }

 .order-2 {
   order: 2;
 }

 .order-3 {
   order: 3;
 }

 .order-4 {
   order: 4;
 }

 .order-5 {
   order: 5;
 }

 .order-6 {
   order: 6;
 }

 .order-7 {
   order: 7;
 }

 .order-8 {
   order: 8;
 }

 .order-9 {
   order: 9;
 }

 .order-10 {
   order: 10;
 }

 .order-11 {
   order: 11;
 }

 .order-12 {
   order: 12;
 }

 .offset-1 {
   margin-left: 8.3333333333%;
 }

 .offset-2 {
   margin-left: 16.6666666667%;
 }

 .offset-3 {
   margin-left: 25%;
 }

 .offset-4 {
   margin-left: 33.3333333333%;
 }

 .offset-5 {
   margin-left: 41.6666666667%;
 }

 .offset-6 {
   margin-left: 50%;
 }

 .offset-7 {
   margin-left: 58.3333333333%;
 }

 .offset-8 {
   margin-left: 66.6666666667%;
 }

 .offset-9 {
   margin-left: 75%;
 }

 .offset-10 {
   margin-left: 83.3333333333%;
 }

 .offset-11 {
   margin-left: 91.6666666667%;
 }

 @media (min-width: 576px) {
   .col-sm {
     flex-basis: 0;
     flex-grow: 1;
     min-width: 0;
     max-width: 100%;
   }

   .row-cols-sm-1>* {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .row-cols-sm-2>* {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .row-cols-sm-3>* {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }

   .row-cols-sm-4>* {
     flex: 0 0 25%;
     max-width: 25%;
   }

   .row-cols-sm-5>* {
     flex: 0 0 20%;
     max-width: 20%;
   }

   .row-cols-sm-6>* {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }

   .col-sm-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: 100%;
   }

   .col-sm-1 {
     flex: 0 0 8.3333333333%;
     max-width: 8.3333333333%;
   }

   .col-sm-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }

   .col-sm-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }

   .col-sm-4 {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }

   .col-sm-5 {
     flex: 0 0 41.6666666667%;
     max-width: 41.6666666667%;
   }

   .col-sm-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .col-sm-7 {
     flex: 0 0 58.3333333333%;
     max-width: 58.3333333333%;
   }

   .col-sm-8 {
     flex: 0 0 66.6666666667%;
     max-width: 66.6666666667%;
   }

   .col-sm-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }

   .col-sm-10 {
     flex: 0 0 83.3333333333%;
     max-width: 83.3333333333%;
   }

   .col-sm-11 {
     flex: 0 0 91.6666666667%;
     max-width: 91.6666666667%;
   }

   .col-sm-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .order-sm-first {
     order: -1;
   }

   .order-sm-last {
     order: 13;
   }

   .order-sm-0 {
     order: 0;
   }

   .order-sm-1 {
     order: 1;
   }

   .order-sm-2 {
     order: 2;
   }

   .order-sm-3 {
     order: 3;
   }

   .order-sm-4 {
     order: 4;
   }

   .order-sm-5 {
     order: 5;
   }

   .order-sm-6 {
     order: 6;
   }

   .order-sm-7 {
     order: 7;
   }

   .order-sm-8 {
     order: 8;
   }

   .order-sm-9 {
     order: 9;
   }

   .order-sm-10 {
     order: 10;
   }

   .order-sm-11 {
     order: 11;
   }

   .order-sm-12 {
     order: 12;
   }

   .offset-sm-0 {
     margin-left: 0;
   }

   .offset-sm-1 {
     margin-left: 8.3333333333%;
   }

   .offset-sm-2 {
     margin-left: 16.6666666667%;
   }

   .offset-sm-3 {
     margin-left: 25%;
   }

   .offset-sm-4 {
     margin-left: 33.3333333333%;
   }

   .offset-sm-5 {
     margin-left: 41.6666666667%;
   }

   .offset-sm-6 {
     margin-left: 50%;
   }

   .offset-sm-7 {
     margin-left: 58.3333333333%;
   }

   .offset-sm-8 {
     margin-left: 66.6666666667%;
   }

   .offset-sm-9 {
     margin-left: 75%;
   }

   .offset-sm-10 {
     margin-left: 83.3333333333%;
   }

   .offset-sm-11 {
     margin-left: 91.6666666667%;
   }
 }

 @media (min-width: 768px) {
   .col-md {
     flex-basis: 0;
     flex-grow: 1;
     min-width: 0;
     max-width: 100%;
   }

   .row-cols-md-1>* {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .row-cols-md-2>* {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .row-cols-md-3>* {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }

   .row-cols-md-4>* {
     flex: 0 0 25%;
     max-width: 25%;
   }

   .row-cols-md-5>* {
     flex: 0 0 20%;
     max-width: 20%;
   }

   .row-cols-md-6>* {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }

   .col-md-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: 100%;
   }

   .col-md-1 {
     flex: 0 0 8.3333333333%;
     max-width: 8.3333333333%;
   }

   .col-md-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }

   .col-md-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }

   .col-md-4 {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }

   .col-md-5 {
     flex: 0 0 41.6666666667%;
     max-width: 41.6666666667%;
   }

   .col-md-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .col-md-7 {
     flex: 0 0 58.3333333333%;
     max-width: 58.3333333333%;
   }

   .col-md-8 {
     flex: 0 0 66.6666666667%;
     max-width: 66.6666666667%;
   }

   .col-md-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }

   .col-md-10 {
     flex: 0 0 83.3333333333%;
     max-width: 83.3333333333%;
   }

   .col-md-11 {
     flex: 0 0 91.6666666667%;
     max-width: 91.6666666667%;
   }

   .col-md-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .order-md-first {
     order: -1;
   }

   .order-md-last {
     order: 13;
   }

   .order-md-0 {
     order: 0;
   }

   .order-md-1 {
     order: 1;
   }

   .order-md-2 {
     order: 2;
   }

   .order-md-3 {
     order: 3;
   }

   .order-md-4 {
     order: 4;
   }

   .order-md-5 {
     order: 5;
   }

   .order-md-6 {
     order: 6;
   }

   .order-md-7 {
     order: 7;
   }

   .order-md-8 {
     order: 8;
   }

   .order-md-9 {
     order: 9;
   }

   .order-md-10 {
     order: 10;
   }

   .order-md-11 {
     order: 11;
   }

   .order-md-12 {
     order: 12;
   }

   .offset-md-0 {
     margin-left: 0;
   }

   .offset-md-1 {
     margin-left: 8.3333333333%;
   }

   .offset-md-2 {
     margin-left: 16.6666666667%;
   }

   .offset-md-3 {
     margin-left: 25%;
   }

   .offset-md-4 {
     margin-left: 33.3333333333%;
   }

   .offset-md-5 {
     margin-left: 41.6666666667%;
   }

   .offset-md-6 {
     margin-left: 50%;
   }

   .offset-md-7 {
     margin-left: 58.3333333333%;
   }

   .offset-md-8 {
     margin-left: 66.6666666667%;
   }

   .offset-md-9 {
     margin-left: 75%;
   }

   .offset-md-10 {
     margin-left: 83.3333333333%;
   }

   .offset-md-11 {
     margin-left: 91.6666666667%;
   }
 }

 @media (min-width: 992px) {
   .col-lg {
     flex-basis: 0;
     flex-grow: 1;
     min-width: 0;
     max-width: 100%;
   }

   .row-cols-lg-1>* {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .row-cols-lg-2>* {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .row-cols-lg-3>* {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }

   .row-cols-lg-4>* {
     flex: 0 0 25%;
     max-width: 25%;
   }

   .row-cols-lg-5>* {
     flex: 0 0 20%;
     max-width: 20%;
   }

   .row-cols-lg-6>* {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }

   .col-lg-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: 100%;
   }

   .col-lg-1 {
     flex: 0 0 8.3333333333%;
     max-width: 8.3333333333%;
   }

   .col-lg-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }

   .col-lg-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }

   .col-lg-4 {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }

   .col-lg-5 {
     flex: 0 0 41.6666666667%;
     max-width: 41.6666666667%;
   }

   .col-lg-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .col-lg-7 {
     flex: 0 0 58.3333333333%;
     max-width: 58.3333333333%;
   }

   .col-lg-8 {
     flex: 0 0 66.6666666667%;
     max-width: 66.6666666667%;
   }

   .col-lg-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }

   .col-lg-10 {
     flex: 0 0 83.3333333333%;
     max-width: 83.3333333333%;
   }

   .col-lg-11 {
     flex: 0 0 91.6666666667%;
     max-width: 91.6666666667%;
   }

   .col-lg-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .order-lg-first {
     order: -1;
   }

   .order-lg-last {
     order: 13;
   }

   .order-lg-0 {
     order: 0;
   }

   .order-lg-1 {
     order: 1;
   }

   .order-lg-2 {
     order: 2;
   }

   .order-lg-3 {
     order: 3;
   }

   .order-lg-4 {
     order: 4;
   }

   .order-lg-5 {
     order: 5;
   }

   .order-lg-6 {
     order: 6;
   }

   .order-lg-7 {
     order: 7;
   }

   .order-lg-8 {
     order: 8;
   }

   .order-lg-9 {
     order: 9;
   }

   .order-lg-10 {
     order: 10;
   }

   .order-lg-11 {
     order: 11;
   }

   .order-lg-12 {
     order: 12;
   }

   .offset-lg-0 {
     margin-left: 0;
   }

   .offset-lg-1 {
     margin-left: 8.3333333333%;
   }

   .offset-lg-2 {
     margin-left: 16.6666666667%;
   }

   .offset-lg-3 {
     margin-left: 25%;
   }

   .offset-lg-4 {
     margin-left: 33.3333333333%;
   }

   .offset-lg-5 {
     margin-left: 41.6666666667%;
   }

   .offset-lg-6 {
     margin-left: 50%;
   }

   .offset-lg-7 {
     margin-left: 58.3333333333%;
   }

   .offset-lg-8 {
     margin-left: 66.6666666667%;
   }

   .offset-lg-9 {
     margin-left: 75%;
   }

   .offset-lg-10 {
     margin-left: 83.3333333333%;
   }

   .offset-lg-11 {
     margin-left: 91.6666666667%;
   }
 }

 @media (min-width: 1200px) {
   .col-xl {
     flex-basis: 0;
     flex-grow: 1;
     min-width: 0;
     max-width: 100%;
   }

   .row-cols-xl-1>* {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .row-cols-xl-2>* {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .row-cols-xl-3>* {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }

   .row-cols-xl-4>* {
     flex: 0 0 25%;
     max-width: 25%;
   }

   .row-cols-xl-5>* {
     flex: 0 0 20%;
     max-width: 20%;
   }

   .row-cols-xl-6>* {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }

   .col-xl-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: 100%;
   }

   .col-xl-1 {
     flex: 0 0 8.3333333333%;
     max-width: 8.3333333333%;
   }

   .col-xl-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }

   .col-xl-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }

   .col-xl-4 {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }

   .col-xl-5 {
     flex: 0 0 41.6666666667%;
     max-width: 41.6666666667%;
   }

   .col-xl-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .col-xl-7 {
     flex: 0 0 58.3333333333%;
     max-width: 58.3333333333%;
   }

   .col-xl-8 {
     flex: 0 0 66.6666666667%;
     max-width: 66.6666666667%;
   }

   .col-xl-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }

   .col-xl-10 {
     flex: 0 0 83.3333333333%;
     max-width: 83.3333333333%;
   }

   .col-xl-11 {
     flex: 0 0 91.6666666667%;
     max-width: 91.6666666667%;
   }

   .col-xl-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .order-xl-first {
     order: -1;
   }

   .order-xl-last {
     order: 13;
   }

   .order-xl-0 {
     order: 0;
   }

   .order-xl-1 {
     order: 1;
   }

   .order-xl-2 {
     order: 2;
   }

   .order-xl-3 {
     order: 3;
   }

   .order-xl-4 {
     order: 4;
   }

   .order-xl-5 {
     order: 5;
   }

   .order-xl-6 {
     order: 6;
   }

   .order-xl-7 {
     order: 7;
   }

   .order-xl-8 {
     order: 8;
   }

   .order-xl-9 {
     order: 9;
   }

   .order-xl-10 {
     order: 10;
   }

   .order-xl-11 {
     order: 11;
   }

   .order-xl-12 {
     order: 12;
   }

   .offset-xl-0 {
     margin-left: 0;
   }

   .offset-xl-1 {
     margin-left: 8.3333333333%;
   }

   .offset-xl-2 {
     margin-left: 16.6666666667%;
   }

   .offset-xl-3 {
     margin-left: 25%;
   }

   .offset-xl-4 {
     margin-left: 33.3333333333%;
   }

   .offset-xl-5 {
     margin-left: 41.6666666667%;
   }

   .offset-xl-6 {
     margin-left: 50%;
   }

   .offset-xl-7 {
     margin-left: 58.3333333333%;
   }

   .offset-xl-8 {
     margin-left: 66.6666666667%;
   }

   .offset-xl-9 {
     margin-left: 75%;
   }

   .offset-xl-10 {
     margin-left: 83.3333333333%;
   }

   .offset-xl-11 {
     margin-left: 91.6666666667%;
   }
 }

 @media (min-width: 1440) {
   .col-xxl {
     flex-basis: 0;
     flex-grow: 1;
     min-width: 0;
     max-width: 100%;
   }

   .row-cols-xxl-1>* {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .row-cols-xxl-2>* {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .row-cols-xxl-3>* {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }

   .row-cols-xxl-4>* {
     flex: 0 0 25%;
     max-width: 25%;
   }

   .row-cols-xxl-5>* {
     flex: 0 0 20%;
     max-width: 20%;
   }

   .row-cols-xxl-6>* {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }

   .col-xxl-auto {
     flex: 0 0 auto;
     width: auto;
     max-width: 100%;
   }

   .col-xxl-1 {
     flex: 0 0 8.3333333333%;
     max-width: 8.3333333333%;
   }

   .col-xxl-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }

   .col-xxl-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }

   .col-xxl-4 {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }

   .col-xxl-5 {
     flex: 0 0 41.6666666667%;
     max-width: 41.6666666667%;
   }

   .col-xxl-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .col-xxl-7 {
     flex: 0 0 58.3333333333%;
     max-width: 58.3333333333%;
   }

   .col-xxl-8 {
     flex: 0 0 66.6666666667%;
     max-width: 66.6666666667%;
   }

   .col-xxl-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }

   .col-xxl-10 {
     flex: 0 0 83.3333333333%;
     max-width: 83.3333333333%;
   }

   .col-xxl-11 {
     flex: 0 0 91.6666666667%;
     max-width: 91.6666666667%;
   }

   .col-xxl-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .order-xxl-first {
     order: -1;
   }

   .order-xxl-last {
     order: 13;
   }

   .order-xxl-0 {
     order: 0;
   }

   .order-xxl-1 {
     order: 1;
   }

   .order-xxl-2 {
     order: 2;
   }

   .order-xxl-3 {
     order: 3;
   }

   .order-xxl-4 {
     order: 4;
   }

   .order-xxl-5 {
     order: 5;
   }

   .order-xxl-6 {
     order: 6;
   }

   .order-xxl-7 {
     order: 7;
   }

   .order-xxl-8 {
     order: 8;
   }

   .order-xxl-9 {
     order: 9;
   }

   .order-xxl-10 {
     order: 10;
   }

   .order-xxl-11 {
     order: 11;
   }

   .order-xxl-12 {
     order: 12;
   }

   .offset-xxl-0 {
     margin-left: 0;
   }

   .offset-xxl-1 {
     margin-left: 8.3333333333%;
   }

   .offset-xxl-2 {
     margin-left: 16.6666666667%;
   }

   .offset-xxl-3 {
     margin-left: 25%;
   }

   .offset-xxl-4 {
     margin-left: 33.3333333333%;
   }

   .offset-xxl-5 {
     margin-left: 41.6666666667%;
   }

   .offset-xxl-6 {
     margin-left: 50%;
   }

   .offset-xxl-7 {
     margin-left: 58.3333333333%;
   }

   .offset-xxl-8 {
     margin-left: 66.6666666667%;
   }

   .offset-xxl-9 {
     margin-left: 75%;
   }

   .offset-xxl-10 {
     margin-left: 83.3333333333%;
   }

   .offset-xxl-11 {
     margin-left: 91.6666666667%;
   }
 }

 .table {
   width: 100%;
   margin-bottom: 1rem;
   color: #515184;
 }

 .table th,
 .table td {
   padding: 0.75rem;
   vertical-align: top;
   border-top: 1px solid #E1E1F0;
 }

 .table thead th {
   vertical-align: bottom;
   border-bottom: 2px solid #E1E1F0;
 }

 .table tbody+tbody {
   border-top: 2px solid #E1E1F0;
 }

 .table-sm th,
 .table-sm td {
   padding: 0.3rem;
 }

 .table-bordered {
   border: 1px solid #E1E1F0;
 }

 .table-bordered th,
 .table-bordered td {
   border: 1px solid #E1E1F0;
 }

 .table-bordered thead th,
 .table-bordered thead td {
   border-bottom-width: 2px;
 }

 .table-borderless th,
 .table-borderless td,
 .table-borderless thead th,
 .table-borderless tbody+tbody {
   border: 0;
 }

 .table-striped tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, 0.05);
 }

 .table-hover tbody tr:hover {
   color: #515184;
   background-color: rgba(0, 0, 0, 0.075);
 }

 .table-primary,
 .table-primary>th,
 .table-primary>td {
   background-color: #e6d3bf;
 }

 .table-primary th,
 .table-primary td,
 .table-primary thead th,
 .table-primary tbody+tbody {
   border-color: #d1ad89;
 }

 .table-hover .table-primary:hover {
   background-color: #dfc6ad;
 }

 .table-hover .table-primary:hover>td,
 .table-hover .table-primary:hover>th {
   background-color: #dfc6ad;
 }

 .table-secondary,
 .table-secondary>th,
 .table-secondary>td {
   background-color: #d4c8eb;
 }

 .table-secondary th,
 .table-secondary td,
 .table-secondary thead th,
 .table-secondary tbody+tbody {
   border-color: #b099da;
 }

 .table-hover .table-secondary:hover {
   background-color: #c5b5e4;
 }

 .table-hover .table-secondary:hover>td,
 .table-hover .table-secondary:hover>th {
   background-color: #c5b5e4;
 }

 .table-success,
 .table-success>th,
 .table-success>td {
   background-color: #bcf4d9;
 }

 .table-success th,
 .table-success td,
 .table-success thead th,
 .table-success tbody+tbody {
   border-color: #83ebb8;
 }

 .table-hover .table-success:hover {
   background-color: #a6f0cd;
 }

 .table-hover .table-success:hover>td,
 .table-hover .table-success:hover>th {
   background-color: #a6f0cd;
 }

 .table-info,
 .table-info>th,
 .table-info>td {
   background-color: #c3cff8;
 }

 .table-info th,
 .table-info td,
 .table-info thead th,
 .table-info tbody+tbody {
   border-color: #8fa6f2;
 }

 .table-hover .table-info:hover {
   background-color: #acbdf5;
 }

 .table-hover .table-info:hover>td,
 .table-hover .table-info:hover>th {
   background-color: #acbdf5;
 }

 .table-warning,
 .table-warning>th,
 .table-warning>td {
   background-color: #ffe1c5;
 }

 .table-warning th,
 .table-warning td,
 .table-warning thead th,
 .table-warning tbody+tbody {
   border-color: #fec794;
 }

 .table-hover .table-warning:hover {
   background-color: #ffd4ac;
 }

 .table-hover .table-warning:hover>td,
 .table-hover .table-warning:hover>th {
   background-color: #ffd4ac;
 }

 .table-danger,
 .table-danger>th,
 .table-danger>td {
   background-color: #f8b8b8;
 }

 .table-danger th,
 .table-danger td,
 .table-danger thead th,
 .table-danger tbody+tbody {
   border-color: #f17b7b;
 }

 .table-hover .table-danger:hover {
   background-color: #f6a1a1;
 }

 .table-hover .table-danger:hover>td,
 .table-hover .table-danger:hover>th {
   background-color: #f6a1a1;
 }

 .table-light,
 .table-light>th,
 .table-light>td {
   background-color: #fefdfb;
 }

 .table-light th,
 .table-light td,
 .table-light thead th,
 .table-light tbody+tbody {
   border-color: #fcfbf8;
 }

 .table-hover .table-light:hover {
   background-color: #f9f3e7;
 }

 .table-hover .table-light:hover>td,
 .table-hover .table-light:hover>th {
   background-color: #f9f3e7;
 }

 .table-dark,
 .table-dark>th,
 .table-dark>td {
   background-color: #c2c5d1;
 }

 .table-dark th,
 .table-dark td,
 .table-dark thead th,
 .table-dark tbody+tbody {
   border-color: #8e93a9;
 }

 .table-hover .table-dark:hover {
   background-color: #b3b7c6;
 }

 .table-hover .table-dark:hover>td,
 .table-hover .table-dark:hover>th {
   background-color: #b3b7c6;
 }

 .table-active,
 .table-active>th,
 .table-active>td {
   background-color: rgba(0, 0, 0, 0.075);
 }

 .table-hover .table-active:hover {
   background-color: rgba(0, 0, 0, 0.075);
 }

 .table-hover .table-active:hover>td,
 .table-hover .table-active:hover>th {
   background-color: rgba(0, 0, 0, 0.075);
 }

 .table .thead-dark th {
   color: #fff;
   background-color: #343a40;
   border-color: #454d55;
 }

 .table .thead-light th {
   color: #495057;
   background-color: #e9ecef;
   border-color: #E1E1F0;
 }

 .table-dark {
   color: #fff;
   background-color: #343a40;
 }

 .table-dark th,
 .table-dark td,
 .table-dark thead th {
   border-color: #454d55;
 }

 .table-dark.table-bordered {
   border: 0;
 }

 .table-dark.table-striped tbody tr:nth-of-type(odd) {
   background-color: rgba(255, 255, 255, 0.05);
 }

 .table-dark.table-hover tbody tr:hover {
   color: #fff;
   background-color: rgba(255, 255, 255, 0.075);
 }

 @media (max-width: 575.98px) {
   .table-responsive-sm {
     display: block;
     width: 100%;
     overflow-x: auto;
     -webkit-overflow-scrolling: touch;
   }

   .table-responsive-sm>.table-bordered {
     border: 0;
   }
 }

 @media (max-width: 767.98px) {
   .table-responsive-md {
     display: block;
     width: 100%;
     overflow-x: auto;
     -webkit-overflow-scrolling: touch;
   }

   .table-responsive-md>.table-bordered {
     border: 0;
   }
 }

 @media (max-width: 991.98px) {
   .table-responsive-lg {
     display: block;
     width: 100%;
     overflow-x: auto;
     -webkit-overflow-scrolling: touch;
   }

   .table-responsive-lg>.table-bordered {
     border: 0;
   }
 }

 @media (max-width: 1199.98px) {
   .table-responsive-xl {
     display: block;
     width: 100%;
     overflow-x: auto;
     -webkit-overflow-scrolling: touch;
   }

   .table-responsive-xl>.table-bordered {
     border: 0;
   }
 }

 @media (max-width: 1439.98) {
   .table-responsive-xxl {
     display: block;
     width: 100%;
     overflow-x: auto;
     -webkit-overflow-scrolling: touch;
   }

   .table-responsive-xxl>.table-bordered {
     border: 0;
   }
 }

 .table-responsive {
   display: block;
   width: 100%;
   overflow-x: auto;
   -webkit-overflow-scrolling: touch;
 }

 .table-responsive>.table-bordered {
   border: 0;
 }

 .form-control {
   display: block;
   width: 100%;
   height: calc(1.5em + 0.75rem + 2px);
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   border-radius: 0.25rem;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }

 @media (prefers-reduced-motion: reduce) {
   .form-control {
     transition: none;
   }
 }

 .form-control::-ms-expand {
   background-color: transparent;
   border: 0;
 }

 .form-control:-moz-focusring {
   color: transparent;
   text-shadow: 0 0 0 #495057;
 }

 .form-control:focus {
   color: #495057;
   background-color: #fff;
   border-color: #e4a15e;
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(166, 97, 28, 0.25);
 }

 .form-control::placeholder {
   color: #6c757d;
   opacity: 1;
 }

 .form-control:disabled,
 .form-control[readonly] {
   background-color: #e9ecef;
   opacity: 1;
 }

 input[type="date"].form-control,
 input[type="time"].form-control,
 input[type="datetime-local"].form-control,
 input[type="month"].form-control {
   appearance: none;
 }

 select.form-control:focus::-ms-value {
   color: #495057;
   background-color: #fff;
 }

 .form-control-file,
 .form-control-range {
   display: block;
   width: 100%;
 }

 .col-form-label {
   padding-top: calc(0.375rem + 1px);
   padding-bottom: calc(0.375rem + 1px);
   margin-bottom: 0;
   font-size: inherit;
   line-height: 1.5;
 }

 .col-form-label-lg {
   padding-top: calc(0.5rem + 1px);
   padding-bottom: calc(0.5rem + 1px);
   font-size: 1.25rem;
   line-height: 1.5;
 }

 .col-form-label-sm {
   padding-top: calc(0.25rem + 1px);
   padding-bottom: calc(0.25rem + 1px);
   font-size: 0.875rem;
   line-height: 1.5;
 }

 .form-control-plaintext {
   display: block;
   width: 100%;
   padding: 0.375rem 0;
   margin-bottom: 0;
   font-size: 1rem;
   line-height: 1.5;
   color: #515184;
   background-color: transparent;
   border: solid transparent;
   border-width: 1px 0;
 }

 .form-control-plaintext.form-control-sm,
 .form-control-plaintext.form-control-lg {
   padding-right: 0;
   padding-left: 0;
 }

 .form-control-sm {
   height: calc(1.5em + 0.5rem + 2px);
   padding: 0.25rem 0.5rem;
   font-size: 0.875rem;
   line-height: 1.5;
   border-radius: 0.2rem;
 }

 .form-control-lg {
   height: calc(1.5em + 1rem + 2px);
   padding: 0.5rem 1rem;
   font-size: 1.25rem;
   line-height: 1.5;
   border-radius: 0.3rem;
 }

 select.form-control[size],
 select.form-control[multiple] {
   height: auto;
 }

 textarea.form-control {
   height: auto;
 }

 .form-group {
   margin-bottom: 1rem;
 }

 .form-text {
   display: block;
   margin-top: 0.25rem;
 }

 .form-row {
   display: flex;
   flex-wrap: wrap;
   margin-right: -5px;
   margin-left: -5px;
 }

 .form-row>.col,
 .form-row>[class*="col-"] {
   padding-right: 5px;
   padding-left: 5px;
 }

 .form-check {
   position: relative;
   display: block;
   padding-left: 1.25rem;
 }

 .form-check-input {
   position: absolute;
   margin-top: 0.3rem;
   margin-left: -1.25rem;
 }

 .form-check-input[disabled]~.form-check-label,
 .form-check-input:disabled~.form-check-label {
   color: #AEAED5;
 }

 .form-check-label {
   margin-bottom: 0;
 }

 .form-check-inline {
   display: inline-flex;
   align-items: center;
   padding-left: 0;
   margin-right: 0.75rem;
 }

 .form-check-inline .form-check-input {
   position: static;
   margin-top: 0;
   margin-right: 0.3125rem;
   margin-left: 0;
 }

 .valid-feedback {
   display: none;
   width: 100%;
   margin-top: 0.25rem;
   font-size: 80%;
   color: #10d876;
 }

 .valid-tooltip {
   position: absolute;
   top: 100%;
   z-index: 5;
   display: none;
   max-width: 100%;
   padding: 0.25rem 0.5rem;
   margin-top: .1rem;
   font-size: 0.875rem;
   line-height: 1.5;
   color: #fff;
   background-color: rgba(16, 216, 118, 0.9);
   border-radius: 0.25rem;
 }

 .was-validated :valid~.valid-feedback,
 .was-validated :valid~.valid-tooltip,
 .is-valid~.valid-feedback,
 .is-valid~.valid-tooltip {
   display: block;
 }

 .was-validated .form-control:valid,
 .form-control.is-valid {
   border-color: #10d876;
   padding-right: calc(1.5em + 0.75rem);
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
   background-repeat: no-repeat;
   background-position: right calc(0.375em + 0.1875rem) center;
   background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
 }

 .was-validated .form-control:valid:focus,
 .form-control.is-valid:focus {
   border-color: #10d876;
   box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
 }

 .was-validated textarea.form-control:valid,
 textarea.form-control.is-valid {
   padding-right: calc(1.5em + 0.75rem);
   background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
 }

 .was-validated .custom-select:valid,
 .custom-select.is-valid {
   border-color: #10d876;
   padding-right: calc(0.75em + 2.3125rem);
   background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
 }

 .was-validated .custom-select:valid:focus,
 .custom-select.is-valid:focus {
   border-color: #10d876;
   box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
 }

 .was-validated .form-check-input:valid~.form-check-label,
 .form-check-input.is-valid~.form-check-label {
   color: #10d876;
 }

 .was-validated .form-check-input:valid~.valid-feedback,
 .was-validated .form-check-input:valid~.valid-tooltip,
 .form-check-input.is-valid~.valid-feedback,
 .form-check-input.is-valid~.valid-tooltip {
   display: block;
 }

 .was-validated .custom-control-input:valid~.custom-control-label,
 .custom-control-input.is-valid~.custom-control-label {
   color: #10d876;
 }

 .was-validated .custom-control-input:valid~.custom-control-label::before,
 .custom-control-input.is-valid~.custom-control-label::before {
   border-color: #10d876;
 }

 .was-validated .custom-control-input:valid:checked~.custom-control-label::before,
 .custom-control-input.is-valid:checked~.custom-control-label::before {
   border-color: #2cef8f;
   background-color: #2cef8f;
 }

 .was-validated .custom-control-input:valid:focus~.custom-control-label::before,
 .custom-control-input.is-valid:focus~.custom-control-label::before {
   box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
 }

 .was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
 .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
   border-color: #10d876;
 }

 .was-validated .custom-file-input:valid~.custom-file-label,
 .custom-file-input.is-valid~.custom-file-label {
   border-color: #10d876;
 }

 .was-validated .custom-file-input:valid:focus~.custom-file-label,
 .custom-file-input.is-valid:focus~.custom-file-label {
   border-color: #10d876;
   box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
 }

 .invalid-feedback {
   display: none;
   width: 100%;
   margin-top: 0.25rem;
   font-size: 80%;
   color: #E50202;
 }

 .invalid-tooltip {
   position: absolute;
   top: 100%;
   z-index: 5;
   display: none;
   max-width: 100%;
   padding: 0.25rem 0.5rem;
   margin-top: .1rem;
   font-size: 0.875rem;
   line-height: 1.5;
   color: #fff;
   background-color: rgba(229, 2, 2, 0.9);
   border-radius: 0.25rem;
 }

 .was-validated :invalid~.invalid-feedback,
 .was-validated :invalid~.invalid-tooltip,
 .is-invalid~.invalid-feedback,
 .is-invalid~.invalid-tooltip {
   display: block;
 }

 .was-validated .form-control:invalid,
 .form-control.is-invalid {
   border-color: #E50202;
   padding-right: calc(1.5em + 0.75rem);
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E50202' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e");
   background-repeat: no-repeat;
   background-position: right calc(0.375em + 0.1875rem) center;
   background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
 }

 .was-validated .form-control:invalid:focus,
 .form-control.is-invalid:focus {
   border-color: #E50202;
   box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
 }

 .was-validated textarea.form-control:invalid,
 textarea.form-control.is-invalid {
   padding-right: calc(1.5em + 0.75rem);
   background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
 }

 .was-validated .custom-select:invalid,
 .custom-select.is-invalid {
   border-color: #E50202;
   padding-right: calc(0.75em + 2.3125rem);
   background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E50202' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
 }

 .was-validated .custom-select:invalid:focus,
 .custom-select.is-invalid:focus {
   border-color: #E50202;
   box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
 }

 .was-validated .form-check-input:invalid~.form-check-label,
 .form-check-input.is-invalid~.form-check-label {
   color: #E50202;
 }

 .was-validated .form-check-input:invalid~.invalid-feedback,
 .was-validated .form-check-input:invalid~.invalid-tooltip,
 .form-check-input.is-invalid~.invalid-feedback,
 .form-check-input.is-invalid~.invalid-tooltip {
   display: block;
 }

 .was-validated .custom-control-input:invalid~.custom-control-label,
 .custom-control-input.is-invalid~.custom-control-label {
   color: #E50202;
 }

 .was-validated .custom-control-input:invalid~.custom-control-label::before,
 .custom-control-input.is-invalid~.custom-control-label::before {
   border-color: #E50202;
 }

 .was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
 .custom-control-input.is-invalid:checked~.custom-control-label::before {
   border-color: #fd1d1d;
   background-color: #fd1d1d;
 }

 .was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
 .custom-control-input.is-invalid:focus~.custom-control-label::before {
   box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
 }

 .was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
 .custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
   border-color: #E50202;
 }

 .was-validated .custom-file-input:invalid~.custom-file-label,
 .custom-file-input.is-invalid~.custom-file-label {
   border-color: #E50202;
 }

 .was-validated .custom-file-input:invalid:focus~.custom-file-label,
 .custom-file-input.is-invalid:focus~.custom-file-label {
   border-color: #E50202;
   box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
 }

 .form-inline {
   display: flex;
   flex-flow: row wrap;
   align-items: center;
 }

 .form-inline .form-check {
   width: 100%;
 }

 @media (min-width: 576px) {
   .form-inline label {
     display: flex;
     align-items: center;
     justify-content: center;
     margin-bottom: 0;
   }

   .form-inline .form-group {
     display: flex;
     flex: 0 0 auto;
     flex-flow: row wrap;
     align-items: center;
     margin-bottom: 0;
   }

   .form-inline .form-control {
     display: inline-block;
     width: auto;
     vertical-align: middle;
   }

   .form-inline .form-control-plaintext {
     display: inline-block;
   }

   .form-inline .input-group,
   .form-inline .custom-select {
     width: auto;
   }

   .form-inline .form-check {
     display: flex;
     align-items: center;
     justify-content: center;
     width: auto;
     padding-left: 0;
   }

   .form-inline .form-check-input {
     position: relative;
     flex-shrink: 0;
     margin-top: 0;
     margin-right: 0.25rem;
     margin-left: 0;
   }

   .form-inline .custom-control {
     align-items: center;
     justify-content: center;
   }

   .form-inline .custom-control-label {
     margin-bottom: 0;
   }
 }

 .btn {
   display: inline-block;
   font-weight: 400;
   color: #515184;
   text-align: center;
   vertical-align: middle;
   user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   line-height: 1.5;
   border-radius: 0.25rem;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }

 @media (prefers-reduced-motion: reduce) {
   .btn {
     transition: none;
   }
 }

 .btn:hover {
   color: #515184;
   text-decoration: none;
 }

 .btn:focus,
 .btn.focus {
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(166, 97, 28, 0.25);
 }

 .btn.disabled,
 .btn:disabled {
   opacity: 0.65;
 }

 .btn:not(:disabled):not(.disabled) {
   cursor: pointer;
 }

 a.btn.disabled,
 fieldset:disabled a.btn {
   pointer-events: none;
 }

 .btn-primary {
   color: #fff;
   background-color: #213f99;
   border-color: #213f99;
 }

 .btn-primary:hover {
   color: #fff;
   background-color: #854e16;
   border-color: #7a4815;
 }

 .btn-primary:focus,
 .btn-primary.focus {
   color: #fff;
   background-color: #854e16;
   border-color: #7a4815;
   box-shadow: 0 0 0 0rem rgba(179, 121, 62, 0.5);
 }

 .btn-primary.disabled,
 .btn-primary:disabled {
   color: #fff;
   background-color: #213f99;
   border-color: #213f99;
 }

 .btn-primary:not(:disabled):not(.disabled):active,
 .btn-primary:not(:disabled):not(.disabled).active,
 .show>.btn-primary.dropdown-toggle {
   color: #fff;
   background-color: #7a4815;
   border-color: #6f4113;
 }

 .btn-primary:not(:disabled):not(.disabled):active:focus,
 .btn-primary:not(:disabled):not(.disabled).active:focus,
 .show>.btn-primary.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(179, 121, 62, 0.5);
 }

 .btn-secondary {
   color: #fff;
   background-color: #673BB7;
   border-color: #673BB7;
 }

 .btn-secondary:hover {
   color: #fff;
   background-color: #57329a;
   border-color: #512f90;
 }

 .btn-secondary:focus,
 .btn-secondary.focus {
   color: #fff;
   background-color: #57329a;
   border-color: #512f90;
   box-shadow: 0 0 0 0rem rgba(126, 88, 194, 0.5);
 }

 .btn-secondary.disabled,
 .btn-secondary:disabled {
   color: #fff;
   background-color: #673BB7;
   border-color: #673BB7;
 }

 .btn-secondary:not(:disabled):not(.disabled):active,
 .btn-secondary:not(:disabled):not(.disabled).active,
 .show>.btn-secondary.dropdown-toggle {
   color: #fff;
   background-color: #512f90;
   border-color: #4c2b87;
 }

 .btn-secondary:not(:disabled):not(.disabled):active:focus,
 .btn-secondary:not(:disabled):not(.disabled).active:focus,
 .show>.btn-secondary.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(126, 88, 194, 0.5);
 }

 .btn-success {
   color: #fff;
   background-color: #10d876;
   border-color: #10d876;
 }

 .btn-success:hover {
   color: #fff;
   background-color: #0db463;
   border-color: #0ca95c;
 }

 .btn-success:focus,
 .btn-success.focus {
   color: #fff;
   background-color: #0db463;
   border-color: #0ca95c;
   box-shadow: 0 0 0 0rem rgba(52, 222, 139, 0.5);
 }

 .btn-success.disabled,
 .btn-success:disabled {
   color: #fff;
   background-color: #10d876;
   border-color: #10d876;
 }

 .btn-success:not(:disabled):not(.disabled):active,
 .btn-success:not(:disabled):not(.disabled).active,
 .show>.btn-success.dropdown-toggle {
   color: #fff;
   background-color: #0ca95c;
   border-color: #0c9d56;
 }

 .btn-success:not(:disabled):not(.disabled):active:focus,
 .btn-success:not(:disabled):not(.disabled).active:focus,
 .show>.btn-success.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(52, 222, 139, 0.5);
 }

 .btn-info {
   color: #fff;
   background-color: #2754e6;
   border-color: #2754e6;
 }

 .btn-info:hover {
   color: #fff;
   background-color: #1843cf;
   border-color: #173fc3;
 }

 .btn-info:focus,
 .btn-info.focus {
   color: #fff;
   background-color: #1843cf;
   border-color: #173fc3;
   box-shadow: 0 0 0 0rem rgba(71, 110, 234, 0.5);
 }

 .btn-info.disabled,
 .btn-info:disabled {
   color: #fff;
   background-color: #2754e6;
   border-color: #2754e6;
 }

 .btn-info:not(:disabled):not(.disabled):active,
 .btn-info:not(:disabled):not(.disabled).active,
 .show>.btn-info.dropdown-toggle {
   color: #fff;
   background-color: #173fc3;
   border-color: #153cb8;
 }

 .btn-info:not(:disabled):not(.disabled):active:focus,
 .btn-info:not(:disabled):not(.disabled).active:focus,
 .show>.btn-info.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(71, 110, 234, 0.5);
 }

 .btn-warning {
   color: #212529;
   background-color: #FE9431;
   border-color: #FE9431;
 }

 .btn-warning:hover {
   color: #212529;
   background-color: #fe800b;
   border-color: #fb7a01;
 }

 .btn-warning:focus,
 .btn-warning.focus {
   color: #212529;
   background-color: #fe800b;
   border-color: #fb7a01;
   box-shadow: 0 0 0 0rem rgba(221, 131, 48, 0.5);
 }

 .btn-warning.disabled,
 .btn-warning:disabled {
   color: #212529;
   background-color: #FE9431;
   border-color: #FE9431;
 }

 .btn-warning:not(:disabled):not(.disabled):active,
 .btn-warning:not(:disabled):not(.disabled).active,
 .show>.btn-warning.dropdown-toggle {
   color: #fff;
   background-color: #fb7a01;
   border-color: #ee7401;
 }

 .btn-warning:not(:disabled):not(.disabled):active:focus,
 .btn-warning:not(:disabled):not(.disabled).active:focus,
 .show>.btn-warning.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(221, 131, 48, 0.5);
 }

 .btn-danger {
   color: #fff;
   background-color: #E50202;
   border-color: #E50202;
 }

 .btn-danger:hover {
   color: #fff;
   background-color: #bf0202;
   border-color: #b20202;
 }

 .btn-danger:focus,
 .btn-danger.focus {
   color: #fff;
   background-color: #bf0202;
   border-color: #b20202;
   box-shadow: 0 0 0 0rem rgba(233, 40, 40, 0.5);
 }

 .btn-danger.disabled,
 .btn-danger:disabled {
   color: #fff;
   background-color: #E50202;
   border-color: #E50202;
 }

 .btn-danger:not(:disabled):not(.disabled):active,
 .btn-danger:not(:disabled):not(.disabled).active,
 .show>.btn-danger.dropdown-toggle {
   color: #fff;
   background-color: #b20202;
   border-color: #a60101;
 }

 .btn-danger:not(:disabled):not(.disabled):active:focus,
 .btn-danger:not(:disabled):not(.disabled).active:focus,
 .show>.btn-danger.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(233, 40, 40, 0.5);
 }

 .btn-light {
   color: #212529;
   background-color: #faf8f2;
   border-color: #faf8f2;
 }

 .btn-light:hover {
   color: #212529;
   background-color: #efe9d6;
   border-color: #ece4cd;
 }

 .btn-light:focus,
 .btn-light.focus {
   color: #212529;
   background-color: #efe9d6;
   border-color: #ece4cd;
   box-shadow: 0 0 0 0rem rgba(217, 216, 212, 0.5);
 }

 .btn-light.disabled,
 .btn-light:disabled {
   color: #212529;
   background-color: #faf8f2;
   border-color: #faf8f2;
 }

 .btn-light:not(:disabled):not(.disabled):active,
 .btn-light:not(:disabled):not(.disabled).active,
 .show>.btn-light.dropdown-toggle {
   color: #212529;
   background-color: #ece4cd;
   border-color: #e8dfc4;
 }

 .btn-light:not(:disabled):not(.disabled):active:focus,
 .btn-light:not(:disabled):not(.disabled).active:focus,
 .show>.btn-light.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(217, 216, 212, 0.5);
 }

 .btn-dark {
   color: #fff;
   background-color: #262F5A;
   border-color: #262F5A;
 }

 .btn-dark:hover {
   color: #fff;
   background-color: #1b213f;
   border-color: #171c36;
 }

 .btn-dark:focus,
 .btn-dark.focus {
   color: #fff;
   background-color: #1b213f;
   border-color: #171c36;
   box-shadow: 0 0 0 0rem rgba(71, 78, 115, 0.5);
 }

 .btn-dark.disabled,
 .btn-dark:disabled {
   color: #fff;
   background-color: #262F5A;
   border-color: #262F5A;
 }

 .btn-dark:not(:disabled):not(.disabled):active,
 .btn-dark:not(:disabled):not(.disabled).active,
 .show>.btn-dark.dropdown-toggle {
   color: #fff;
   background-color: #171c36;
   border-color: #13182d;
 }

 .btn-dark:not(:disabled):not(.disabled):active:focus,
 .btn-dark:not(:disabled):not(.disabled).active:focus,
 .show>.btn-dark.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(71, 78, 115, 0.5);
 }

 .btn-outline-primary {
   color: #213f99;
   border-color: #213f99;
 }

 .btn-outline-primary:hover {
   color: #fff;
   background-color: #213f99;
   border-color: #213f99;
 }

 .btn-outline-primary:focus,
 .btn-outline-primary.focus {
   box-shadow: 0 0 0 0rem rgba(166, 97, 28, 0.5);
 }

 .btn-outline-primary.disabled,
 .btn-outline-primary:disabled {
   color: #213f99;
   background-color: transparent;
 }

 .btn-outline-primary:not(:disabled):not(.disabled):active,
 .btn-outline-primary:not(:disabled):not(.disabled).active,
 .show>.btn-outline-primary.dropdown-toggle {
   color: #fff;
   background-color: #213f99;
   border-color: #213f99;
 }

 .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
 .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
 .show>.btn-outline-primary.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(166, 97, 28, 0.5);
 }

 .btn-outline-secondary {
   color: #673BB7;
   border-color: #673BB7;
 }

 .btn-outline-secondary:hover {
   color: #fff;
   background-color: #673BB7;
   border-color: #673BB7;
 }

 .btn-outline-secondary:focus,
 .btn-outline-secondary.focus {
   box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5);
 }

 .btn-outline-secondary.disabled,
 .btn-outline-secondary:disabled {
   color: #673BB7;
   background-color: transparent;
 }

 .btn-outline-secondary:not(:disabled):not(.disabled):active,
 .btn-outline-secondary:not(:disabled):not(.disabled).active,
 .show>.btn-outline-secondary.dropdown-toggle {
   color: #fff;
   background-color: #673BB7;
   border-color: #673BB7;
 }

 .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
 .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
 .show>.btn-outline-secondary.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5);
 }

 .btn-outline-success {
   color: #10d876;
   border-color: #10d876;
 }

 .btn-outline-success:hover {
   color: #fff;
   background-color: #10d876;
   border-color: #10d876;
 }

 .btn-outline-success:focus,
 .btn-outline-success.focus {
   box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5);
 }

 .btn-outline-success.disabled,
 .btn-outline-success:disabled {
   color: #10d876;
   background-color: transparent;
 }

 .btn-outline-success:not(:disabled):not(.disabled):active,
 .btn-outline-success:not(:disabled):not(.disabled).active,
 .show>.btn-outline-success.dropdown-toggle {
   color: #fff;
   background-color: #10d876;
   border-color: #10d876;
 }

 .btn-outline-success:not(:disabled):not(.disabled):active:focus,
 .btn-outline-success:not(:disabled):not(.disabled).active:focus,
 .show>.btn-outline-success.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5);
 }

 .btn-outline-info {
   color: #2754e6;
   border-color: #2754e6;
 }

 .btn-outline-info:hover {
   color: #fff;
   background-color: #2754e6;
   border-color: #2754e6;
 }

 .btn-outline-info:focus,
 .btn-outline-info.focus {
   box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5);
 }

 .btn-outline-info.disabled,
 .btn-outline-info:disabled {
   color: #2754e6;
   background-color: transparent;
 }

 .btn-outline-info:not(:disabled):not(.disabled):active,
 .btn-outline-info:not(:disabled):not(.disabled).active,
 .show>.btn-outline-info.dropdown-toggle {
   color: #fff;
   background-color: #2754e6;
   border-color: #2754e6;
 }

 .btn-outline-info:not(:disabled):not(.disabled):active:focus,
 .btn-outline-info:not(:disabled):not(.disabled).active:focus,
 .show>.btn-outline-info.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5);
 }

 .btn-outline-warning {
   color: #FE9431;
   border-color: #FE9431;
 }

 .btn-outline-warning:hover {
   color: #212529;
   background-color: #FE9431;
   border-color: #FE9431;
 }

 .btn-outline-warning:focus,
 .btn-outline-warning.focus {
   box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5);
 }

 .btn-outline-warning.disabled,
 .btn-outline-warning:disabled {
   color: #FE9431;
   background-color: transparent;
 }

 .btn-outline-warning:not(:disabled):not(.disabled):active,
 .btn-outline-warning:not(:disabled):not(.disabled).active,
 .show>.btn-outline-warning.dropdown-toggle {
   color: #212529;
   background-color: #FE9431;
   border-color: #FE9431;
 }

 .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
 .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
 .show>.btn-outline-warning.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5);
 }

 .btn-outline-danger {
   color: #E50202;
   border-color: #E50202;
 }

 .btn-outline-danger:hover {
   color: #fff;
   background-color: #E50202;
   border-color: #E50202;
 }

 .btn-outline-danger:focus,
 .btn-outline-danger.focus {
   box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5);
 }

 .btn-outline-danger.disabled,
 .btn-outline-danger:disabled {
   color: #E50202;
   background-color: transparent;
 }

 .btn-outline-danger:not(:disabled):not(.disabled):active,
 .btn-outline-danger:not(:disabled):not(.disabled).active,
 .show>.btn-outline-danger.dropdown-toggle {
   color: #fff;
   background-color: #E50202;
   border-color: #E50202;
 }

 .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
 .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
 .show>.btn-outline-danger.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5);
 }

 .btn-outline-light {
   color: #faf8f2;
   border-color: #faf8f2;
 }

 .btn-outline-light:hover {
   color: #212529;
   background-color: #faf8f2;
   border-color: #faf8f2;
 }

 .btn-outline-light:focus,
 .btn-outline-light.focus {
   box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5);
 }

 .btn-outline-light.disabled,
 .btn-outline-light:disabled {
   color: #faf8f2;
   background-color: transparent;
 }

 .btn-outline-light:not(:disabled):not(.disabled):active,
 .btn-outline-light:not(:disabled):not(.disabled).active,
 .show>.btn-outline-light.dropdown-toggle {
   color: #212529;
   background-color: #faf8f2;
   border-color: #faf8f2;
 }

 .btn-outline-light:not(:disabled):not(.disabled):active:focus,
 .btn-outline-light:not(:disabled):not(.disabled).active:focus,
 .show>.btn-outline-light.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5);
 }

 .btn-outline-dark {
   color: #262F5A;
   border-color: #262F5A;
 }

 .btn-outline-dark:hover {
   color: #fff;
   background-color: #262F5A;
   border-color: #262F5A;
 }

 .btn-outline-dark:focus,
 .btn-outline-dark.focus {
   box-shadow: 0 0 0 0rem rgba(38, 47, 90, 0.5);
 }

 .btn-outline-dark.disabled,
 .btn-outline-dark:disabled {
   color: #262F5A;
   background-color: transparent;
 }

 .btn-outline-dark:not(:disabled):not(.disabled):active,
 .btn-outline-dark:not(:disabled):not(.disabled).active,
 .show>.btn-outline-dark.dropdown-toggle {
   color: #fff;
   background-color: #262F5A;
   border-color: #262F5A;
 }

 .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
 .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
 .show>.btn-outline-dark.dropdown-toggle:focus {
   box-shadow: 0 0 0 0rem rgba(38, 47, 90, 0.5);
 }

 .btn-link {
   font-weight: 400;
   color: #213f99;
   text-decoration: none;
 }

 .btn-link:hover {
   color: #653b11;
   text-decoration: underline;
 }

 .btn-link:focus,
 .btn-link.focus {
   text-decoration: underline;
 }

 .btn-link:disabled,
 .btn-link.disabled {
   color: #6c757d;
   pointer-events: none;
 }

 .btn-lg,
 .btn-group-lg>.btn {
   padding: 0.5rem 1rem;
   font-size: 1.25rem;
   line-height: 1.5;
   border-radius: 0.3rem;
 }

 .btn-sm,
 .btn-group-sm>.btn {
   padding: 0.25rem 0.5rem;
   font-size: 0.875rem;
   line-height: 1.5;
   border-radius: 0.2rem;
 }

 .btn-block {
   display: block;
   width: 100%;
 }

 .btn-block+.btn-block {
   margin-top: 0.5rem;
 }

 input[type="submit"].btn-block,
 input[type="reset"].btn-block,
 input[type="button"].btn-block {
   width: 100%;
 }

 .fade {
   transition: opacity 0.15s linear;
 }

 @media (prefers-reduced-motion: reduce) {
   .fade {
     transition: none;
   }
 }

 .fade:not(.show) {
   opacity: 0;
 }

 .collapse:not(.show) {
   display: none;
 }

 .collapsing {
   position: relative;
   height: 0;
   overflow: hidden;
   transition: height 0.35s ease;
 }

 @media (prefers-reduced-motion: reduce) {
   .collapsing {
     transition: none;
   }
 }

 .dropup,
 .dropright,
 .dropdown,
 .dropleft {
   position: relative;
 }

 .dropdown-toggle {
   white-space: nowrap;
 }

 .dropdown-toggle::after {
   display: inline-block;
   margin-left: 0.255em;
   vertical-align: 0.255em;
   content: "";
   border-top: 0.3em solid;
   border-right: 0.3em solid transparent;
   border-bottom: 0;
   border-left: 0.3em solid transparent;
 }

 .dropdown-toggle:empty::after {
   margin-left: 0;
 }

 .dropdown-menu {
   position: absolute;
   top: 100%;
   left: 0;
   z-index: 1000;
   display: none;
   float: left;
   min-width: 10rem;
   padding: 0.5rem 0;
   margin: 0.125rem 0 0;
   font-size: 1rem;
   color: #515184;
   text-align: left;
   list-style: none;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: 0.25rem;
 }

 .dropdown-menu-left {
   right: auto;
   left: 0;
 }

 .dropdown-menu-right {
   right: 0;
   left: auto;
 }

 @media (min-width: 576px) {
   .dropdown-menu-sm-left {
     right: auto;
     left: 0;
   }

   .dropdown-menu-sm-right {
     right: 0;
     left: auto;
   }
 }

 @media (min-width: 768px) {
   .dropdown-menu-md-left {
     right: auto;
     left: 0;
   }

   .dropdown-menu-md-right {
     right: 0;
     left: auto;
   }
 }

 @media (min-width: 992px) {
   .dropdown-menu-lg-left {
     right: auto;
     left: 0;
   }

   .dropdown-menu-lg-right {
     right: 0;
     left: auto;
   }
 }

 @media (min-width: 1200px) {
   .dropdown-menu-xl-left {
     right: auto;
     left: 0;
   }

   .dropdown-menu-xl-right {
     right: 0;
     left: auto;
   }
 }

 @media (min-width: 1440) {
   .dropdown-menu-xxl-left {
     right: auto;
     left: 0;
   }

   .dropdown-menu-xxl-right {
     right: 0;
     left: auto;
   }
 }

 .dropup .dropdown-menu {
   top: auto;
   bottom: 100%;
   margin-top: 0;
   margin-bottom: 0.125rem;
 }

 .dropup .dropdown-toggle::after {
   display: inline-block;
   margin-left: 0.255em;
   vertical-align: 0.255em;
   content: "";
   border-top: 0;
   border-right: 0.3em solid transparent;
   border-bottom: 0.3em solid;
   border-left: 0.3em solid transparent;
 }

 .dropup .dropdown-toggle:empty::after {
   margin-left: 0;
 }

 .dropright .dropdown-menu {
   top: 0;
   right: auto;
   left: 100%;
   margin-top: 0;
   margin-left: 0.125rem;
 }

 .dropright .dropdown-toggle::after {
   display: inline-block;
   margin-left: 0.255em;
   vertical-align: 0.255em;
   content: "";
   border-top: 0.3em solid transparent;
   border-right: 0;
   border-bottom: 0.3em solid transparent;
   border-left: 0.3em solid;
 }

 .dropright .dropdown-toggle:empty::after {
   margin-left: 0;
 }

 .dropright .dropdown-toggle::after {
   vertical-align: 0;
 }

 .dropleft .dropdown-menu {
   top: 0;
   right: 100%;
   left: auto;
   margin-top: 0;
   margin-right: 0.125rem;
 }

 .dropleft .dropdown-toggle::after {
   display: inline-block;
   margin-left: 0.255em;
   vertical-align: 0.255em;
   content: "";
 }

 .dropleft .dropdown-toggle::after {
   display: none;
 }

 .dropleft .dropdown-toggle::before {
   display: inline-block;
   margin-right: 0.255em;
   vertical-align: 0.255em;
   content: "";
   border-top: 0.3em solid transparent;
   border-right: 0.3em solid;
   border-bottom: 0.3em solid transparent;
 }

 .dropleft .dropdown-toggle:empty::after {
   margin-left: 0;
 }

 .dropleft .dropdown-toggle::before {
   vertical-align: 0;
 }

 .dropdown-menu[x-placement^="top"],
 .dropdown-menu[x-placement^="right"],
 .dropdown-menu[x-placement^="bottom"],
 .dropdown-menu[x-placement^="left"] {
   right: auto;
   bottom: auto;
 }

 .dropdown-divider {
   height: 0;
   margin: 0.5rem 0;
   overflow: hidden;
   border-top: 1px solid #e9ecef;
 }

 .dropdown-item {
   display: block;
   width: 100%;
   padding: 0.25rem 1.5rem;
   clear: both;
   font-weight: 400;
   color: #212529;
   text-align: inherit;
   white-space: nowrap;
   background-color: transparent;
   border: 0;
 }

 .dropdown-item:hover,
 .dropdown-item:focus {
   color: #16181b;
   text-decoration: none;
   background-color: #f8f9fa;
 }

 .dropdown-item.active,
 .dropdown-item:active {
   color: #fff;
   text-decoration: none;
   background-color: #213f99;
 }

 .dropdown-item.disabled,
 .dropdown-item:disabled {
   color: #6c757d;
   pointer-events: none;
   background-color: transparent;
 }

 .dropdown-menu.show {
   display: block;
 }

 .dropdown-header {
   display: block;
   padding: 0.5rem 1.5rem;
   margin-bottom: 0;
   font-size: 0.875rem;
   color: #6c757d;
   white-space: nowrap;
 }

 .dropdown-item-text {
   display: block;
   padding: 0.25rem 1.5rem;
   color: #212529;
 }

 .btn-group,
 .btn-group-vertical {
   position: relative;
   display: inline-flex;
   vertical-align: middle;
 }

 .btn-group>.btn,
 .btn-group-vertical>.btn {
   position: relative;
   flex: 1 1 auto;
 }

 .btn-group>.btn:hover,
 .btn-group-vertical>.btn:hover {
   z-index: 1;
 }

 .btn-group>.btn:focus,
 .btn-group>.btn:active,
 .btn-group>.btn.active,
 .btn-group-vertical>.btn:focus,
 .btn-group-vertical>.btn:active,
 .btn-group-vertical>.btn.active {
   z-index: 1;
 }

 .btn-toolbar {
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-start;
 }

 .btn-toolbar .input-group {
   width: auto;
 }

 .btn-group>.btn:not(:first-child),
 .btn-group>.btn-group:not(:first-child) {
   margin-left: -1px;
 }

 .btn-group>.btn:not(:last-child):not(.dropdown-toggle),
 .btn-group>.btn-group:not(:last-child)>.btn {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
 }

 .btn-group>.btn:not(:first-child),
 .btn-group>.btn-group:not(:first-child)>.btn {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
 }

 .dropdown-toggle-split {
   padding-right: 0.5625rem;
   padding-left: 0.5625rem;
 }

 .dropdown-toggle-split::after,
 .dropup .dropdown-toggle-split::after,
 .dropright .dropdown-toggle-split::after {
   margin-left: 0;
 }

 .dropleft .dropdown-toggle-split::before {
   margin-right: 0;
 }

 .btn-sm+.dropdown-toggle-split,
 .btn-group-sm>.btn+.dropdown-toggle-split {
   padding-right: 0.375rem;
   padding-left: 0.375rem;
 }

 .btn-lg+.dropdown-toggle-split,
 .btn-group-lg>.btn+.dropdown-toggle-split {
   padding-right: 0.75rem;
   padding-left: 0.75rem;
 }

 .btn-group-vertical {
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
 }

 .btn-group-vertical>.btn,
 .btn-group-vertical>.btn-group {
   width: 100%;
 }

 .btn-group-vertical>.btn:not(:first-child),
 .btn-group-vertical>.btn-group:not(:first-child) {
   margin-top: -1px;
 }

 .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
 .btn-group-vertical>.btn-group:not(:last-child)>.btn {
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;
 }

 .btn-group-vertical>.btn:not(:first-child),
 .btn-group-vertical>.btn-group:not(:first-child)>.btn {
   border-top-left-radius: 0;
   border-top-right-radius: 0;
 }

 .btn-group-toggle>.btn,
 .btn-group-toggle>.btn-group>.btn {
   margin-bottom: 0;
 }

 .btn-group-toggle>.btn input[type="radio"],
 .btn-group-toggle>.btn input[type="checkbox"],
 .btn-group-toggle>.btn-group>.btn input[type="radio"],
 .btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
   position: absolute;
   clip: rect(0, 0, 0, 0);
   pointer-events: none;
 }

 .input-group {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   align-items: stretch;
   width: 100%;
 }

 .input-group>.form-control,
 .input-group>.form-control-plaintext,
 .input-group>.custom-select,
 .input-group>.custom-file {
   position: relative;
   flex: 1 1 auto;
   width: 1%;
   min-width: 0;
   margin-bottom: 0;
 }

 .input-group>.form-control+.form-control,
 .input-group>.form-control+.custom-select,
 .input-group>.form-control+.custom-file,
 .input-group>.form-control-plaintext+.form-control,
 .input-group>.form-control-plaintext+.custom-select,
 .input-group>.form-control-plaintext+.custom-file,
 .input-group>.custom-select+.form-control,
 .input-group>.custom-select+.custom-select,
 .input-group>.custom-select+.custom-file,
 .input-group>.custom-file+.form-control,
 .input-group>.custom-file+.custom-select,
 .input-group>.custom-file+.custom-file {
   margin-left: -1px;
 }

 .input-group>.form-control:focus,
 .input-group>.custom-select:focus,
 .input-group>.custom-file .custom-file-input:focus~.custom-file-label {
   z-index: 3;
 }

 .input-group>.custom-file .custom-file-input:focus {
   z-index: 4;
 }

 .input-group>.form-control:not(:last-child),
 .input-group>.custom-select:not(:last-child) {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
 }

 .input-group>.form-control:not(:first-child),
 .input-group>.custom-select:not(:first-child) {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
 }

 .input-group>.custom-file {
   display: flex;
   align-items: center;
 }

 .input-group>.custom-file:not(:last-child) .custom-file-label,
 .input-group>.custom-file:not(:last-child) .custom-file-label::after {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
 }

 .input-group>.custom-file:not(:first-child) .custom-file-label {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
 }

 .input-group-prepend,
 .input-group-append {
   display: flex;
 }

 .input-group-prepend .btn,
 .input-group-append .btn {
   position: relative;
   z-index: 2;
 }

 .input-group-prepend .btn:focus,
 .input-group-append .btn:focus {
   z-index: 3;
 }

 .input-group-prepend .btn+.btn,
 .input-group-prepend .btn+.input-group-text,
 .input-group-prepend .input-group-text+.input-group-text,
 .input-group-prepend .input-group-text+.btn,
 .input-group-append .btn+.btn,
 .input-group-append .btn+.input-group-text,
 .input-group-append .input-group-text+.input-group-text,
 .input-group-append .input-group-text+.btn {
   margin-left: -1px;
 }

 .input-group-prepend {
   margin-right: -1px;
 }

 .input-group-append {
   margin-left: -1px;
 }

 .input-group-text {
   display: flex;
   align-items: center;
   padding: 0.375rem 0.75rem;
   margin-bottom: 0;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   text-align: center;
   white-space: nowrap;
   background-color: #e9ecef;
   border: 1px solid #ced4da;
   border-radius: 0.25rem;
 }

 .input-group-text input[type="radio"],
 .input-group-text input[type="checkbox"] {
   margin-top: 0;
 }

 .input-group-lg>.form-control:not(textarea),
 .input-group-lg>.custom-select {
   height: calc(1.5em + 1rem + 2px);
 }

 .input-group-lg>.form-control,
 .input-group-lg>.custom-select,
 .input-group-lg>.input-group-prepend>.input-group-text,
 .input-group-lg>.input-group-append>.input-group-text,
 .input-group-lg>.input-group-prepend>.btn,
 .input-group-lg>.input-group-append>.btn {
   padding: 0.5rem 1rem;
   font-size: 1.25rem;
   line-height: 1.5;
   border-radius: 0.3rem;
 }

 .input-group-sm>.form-control:not(textarea),
 .input-group-sm>.custom-select {
   height: calc(1.5em + 0.5rem + 2px);
 }

 .input-group-sm>.form-control,
 .input-group-sm>.custom-select,
 .input-group-sm>.input-group-prepend>.input-group-text,
 .input-group-sm>.input-group-append>.input-group-text,
 .input-group-sm>.input-group-prepend>.btn,
 .input-group-sm>.input-group-append>.btn {
   padding: 0.25rem 0.5rem;
   font-size: 0.875rem;
   line-height: 1.5;
   border-radius: 0.2rem;
 }

 .input-group-lg>.custom-select,
 .input-group-sm>.custom-select {
   padding-right: 1.75rem;
 }

 .input-group>.input-group-prepend>.btn,
 .input-group>.input-group-prepend>.input-group-text,
 .input-group>.input-group-append:not(:last-child)>.btn,
 .input-group>.input-group-append:not(:last-child)>.input-group-text,
 .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
 .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
 }

 .input-group>.input-group-append>.btn,
 .input-group>.input-group-append>.input-group-text,
 .input-group>.input-group-prepend:not(:first-child)>.btn,
 .input-group>.input-group-prepend:not(:first-child)>.input-group-text,
 .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
 .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
 }

 .custom-control {
   position: relative;
   display: block;
   min-height: 1.5rem;
   padding-left: 1.5rem;
 }

 .custom-control-inline {
   display: inline-flex;
   margin-right: 1rem;
 }

 .custom-control-input {
   position: absolute;
   left: 0;
   z-index: -1;
   width: 1rem;
   height: 1.25rem;
   opacity: 0;
 }

 .custom-control-input:checked~.custom-control-label::before {
   color: #fff;
   border-color: #213f99;
   background-color: #213f99;
 }

 .custom-control-input:focus~.custom-control-label::before {
   box-shadow: 0 0 0 0rem rgba(166, 97, 28, 0.25);
 }

 .custom-control-input:focus:not(:checked)~.custom-control-label::before {
   border-color: #e4a15e;
 }

 .custom-control-input:not(:disabled):active~.custom-control-label::before {
   color: #fff;
   background-color: #ebba89;
   border-color: #ebba89;
 }

 .custom-control-input[disabled]~.custom-control-label,
 .custom-control-input:disabled~.custom-control-label {
   color: #6c757d;
 }

 .custom-control-input[disabled]~.custom-control-label::before,
 .custom-control-input:disabled~.custom-control-label::before {
   background-color: #e9ecef;
 }

 .custom-control-label {
   position: relative;
   margin-bottom: 0;
   vertical-align: top;
 }

 .custom-control-label::before {
   position: absolute;
   top: 0.25rem;
   left: -1.5rem;
   display: block;
   width: 1rem;
   height: 1rem;
   pointer-events: none;
   content: "";
   background-color: #fff;
   border: #adb5bd solid 1px;
 }

 .custom-control-label::after {
   position: absolute;
   top: 0.25rem;
   left: -1.5rem;
   display: block;
   width: 1rem;
   height: 1rem;
   content: "";
   background: no-repeat 50% / 50% 50%;
 }

 .custom-checkbox .custom-control-label::before {
   border-radius: 0.25rem;
 }

 .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
 }

 .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
   border-color: #213f99;
   background-color: #213f99;
 }

 .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
 }

 .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
   background-color: rgba(166, 97, 28, 0.5);
 }

 .custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
   background-color: rgba(166, 97, 28, 0.5);
 }

 .custom-radio .custom-control-label::before {
   border-radius: 50%;
 }

 .custom-radio .custom-control-input:checked~.custom-control-label::after {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
 }

 .custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
   background-color: rgba(166, 97, 28, 0.5);
 }

 .custom-switch {
   padding-left: 2.25rem;
 }

 .custom-switch .custom-control-label::before {
   left: -2.25rem;
   width: 1.75rem;
   pointer-events: all;
   border-radius: 0.5rem;
 }

 .custom-switch .custom-control-label::after {
   top: calc(0.25rem + 2px);
   left: calc(-2.25rem + 2px);
   width: calc(1rem - 4px);
   height: calc(1rem - 4px);
   background-color: #adb5bd;
   border-radius: 0.5rem;
   transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }

 @media (prefers-reduced-motion: reduce) {
   .custom-switch .custom-control-label::after {
     transition: none;
   }
 }

 .custom-switch .custom-control-input:checked~.custom-control-label::after {
   background-color: #fff;
   transform: translateX(0.75rem);
 }

 .custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
   background-color: rgba(166, 97, 28, 0.5);
 }

 .custom-select {
   display: inline-block;
   width: 100%;
   height: calc(1.5em + 0.75rem + 2px);
   padding: 0.375rem 1.75rem 0.375rem 0.75rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   vertical-align: middle;
   background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
   border: 1px solid #ced4da;
   border-radius: 0.25rem;
   appearance: none;
 }

 .custom-select:focus {
   border-color: #e4a15e;
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(166, 97, 28, 0.25);
 }

 .custom-select:focus::-ms-value {
   color: #495057;
   background-color: #fff;
 }

 .custom-select[multiple],
 .custom-select[size]:not([size="1"]) {
   height: auto;
   padding-right: 0.75rem;
   background-image: none;
 }

 .custom-select:disabled {
   color: #6c757d;
   background-color: #e9ecef;
 }

 .custom-select::-ms-expand {
   display: none;
 }

 .custom-select:-moz-focusring {
   color: transparent;
   text-shadow: 0 0 0 #495057;
 }

 .custom-select-sm {
   height: calc(1.5em + 0.5rem + 2px);
   padding-top: 0.25rem;
   padding-bottom: 0.25rem;
   padding-left: 0.5rem;
   font-size: 0.875rem;
 }

 .custom-select-lg {
   height: calc(1.5em + 1rem + 2px);
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
   padding-left: 1rem;
   font-size: 1.25rem;
 }

 .custom-file {
   position: relative;
   display: inline-block;
   width: 100%;
   height: calc(1.5em + 0.75rem + 2px);
   margin-bottom: 0;
 }

 .custom-file-input {
   position: relative;
   z-index: 2;
   width: 100%;
   height: calc(1.5em + 0.75rem + 2px);
   margin: 0;
   opacity: 0;
 }

 .custom-file-input:focus~.custom-file-label {
   border-color: #e4a15e;
   box-shadow: 0 0 0 0rem rgba(166, 97, 28, 0.25);
 }

 .custom-file-input[disabled]~.custom-file-label,
 .custom-file-input:disabled~.custom-file-label {
   background-color: #e9ecef;
 }

 .custom-file-input:lang(en)~.custom-file-label::after {
   content: "Browse";
 }

 .custom-file-input~.custom-file-label[data-browse]::after {
   content: attr(data-browse);
 }

 .custom-file-label {
   position: absolute;
   top: 0;
   right: 0;
   left: 0;
   z-index: 1;
   height: calc(1.5em + 0.75rem + 2px);
   padding: 0.375rem 0.75rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   border: 1px solid #ced4da;
   border-radius: 0.25rem;
 }

 .custom-file-label::after {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   z-index: 3;
   display: block;
   height: calc(1.5em + 0.75rem);
   padding: 0.375rem 0.75rem;
   line-height: 1.5;
   color: #495057;
   content: "Browse";
   background-color: #e9ecef;
   border-left: inherit;
   border-radius: 0 0.25rem 0.25rem 0;
 }

 .custom-range {
   width: 100%;
   height: 1rem;
   padding: 0;
   background-color: transparent;
   appearance: none;
 }

 .custom-range:focus {
   outline: none;
 }

 .custom-range:focus::-webkit-slider-thumb {
   box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(166, 97, 28, 0.25);
 }

 .custom-range:focus::-moz-range-thumb {
   box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(166, 97, 28, 0.25);
 }

 .custom-range:focus::-ms-thumb {
   box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(166, 97, 28, 0.25);
 }

 .custom-range::-moz-focus-outer {
   border: 0;
 }

 .custom-range::-webkit-slider-thumb {
   width: 1rem;
   height: 1rem;
   margin-top: -0.25rem;
   background-color: #213f99;
   border: 0;
   border-radius: 1rem;
   transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   appearance: none;
 }

 @media (prefers-reduced-motion: reduce) {
   .custom-range::-webkit-slider-thumb {
     transition: none;
   }
 }

 .custom-range::-webkit-slider-thumb:active {
   background-color: #ebba89;
 }

 .custom-range::-webkit-slider-runnable-track {
   width: 100%;
   height: 0.5rem;
   color: transparent;
   cursor: pointer;
   background-color: #dee2e6;
   border-color: transparent;
   border-radius: 1rem;
 }

 .custom-range::-moz-range-thumb {
   width: 1rem;
   height: 1rem;
   background-color: #213f99;
   border: 0;
   border-radius: 1rem;
   transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   appearance: none;
 }

 @media (prefers-reduced-motion: reduce) {
   .custom-range::-moz-range-thumb {
     transition: none;
   }
 }

 .custom-range::-moz-range-thumb:active {
   background-color: #ebba89;
 }

 .custom-range::-moz-range-track {
   width: 100%;
   height: 0.5rem;
   color: transparent;
   cursor: pointer;
   background-color: #dee2e6;
   border-color: transparent;
   border-radius: 1rem;
 }

 .custom-range::-ms-thumb {
   width: 1rem;
   height: 1rem;
   margin-top: 0;
   margin-right: 0rem;
   margin-left: 0rem;
   background-color: #213f99;
   border: 0;
   border-radius: 1rem;
   transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   appearance: none;
 }

 @media (prefers-reduced-motion: reduce) {
   .custom-range::-ms-thumb {
     transition: none;
   }
 }

 .custom-range::-ms-thumb:active {
   background-color: #ebba89;
 }

 .custom-range::-ms-track {
   width: 100%;
   height: 0.5rem;
   color: transparent;
   cursor: pointer;
   background-color: transparent;
   border-color: transparent;
   border-width: 0.5rem;
 }

 .custom-range::-ms-fill-lower {
   background-color: #dee2e6;
   border-radius: 1rem;
 }

 .custom-range::-ms-fill-upper {
   margin-right: 15px;
   background-color: #dee2e6;
   border-radius: 1rem;
 }

 .custom-range:disabled::-webkit-slider-thumb {
   background-color: #adb5bd;
 }

 .custom-range:disabled::-webkit-slider-runnable-track {
   cursor: default;
 }

 .custom-range:disabled::-moz-range-thumb {
   background-color: #adb5bd;
 }

 .custom-range:disabled::-moz-range-track {
   cursor: default;
 }

 .custom-range:disabled::-ms-thumb {
   background-color: #adb5bd;
 }

 .custom-control-label::before,
 .custom-file-label,
 .custom-select {
   transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }

 @media (prefers-reduced-motion: reduce) {

   .custom-control-label::before,
   .custom-file-label,
   .custom-select {
     transition: none;
   }
 }

 .nav {
   display: flex;
   flex-wrap: wrap;
   padding-left: 0;
   margin-bottom: 0;
   list-style: none;
 }

 .nav-link {
   display: block;
   padding: 0.5rem 1rem;
 }

 .nav-link:hover,
 .nav-link:focus {
   text-decoration: none;
 }

 .nav-link.disabled {
   color: #6c757d;
   pointer-events: none;
   cursor: default;
 }

 .nav-tabs {
   border-bottom: 1px solid #dee2e6;
 }

 .nav-tabs .nav-item {
   margin-bottom: -1px;
 }

 .nav-tabs .nav-link {
   border: 1px solid transparent;
   border-top-left-radius: 0.25rem;
   border-top-right-radius: 0.25rem;
 }

 .nav-tabs .nav-link:hover,
 .nav-tabs .nav-link:focus {
   border-color: #e9ecef #e9ecef #dee2e6;
 }

 .nav-tabs .nav-link.disabled {
   color: #6c757d;
   background-color: transparent;
   border-color: transparent;
 }

 .nav-tabs .nav-link.active,
 .nav-tabs .nav-item.show .nav-link {
   color: #495057;
   background-color: #fff;
   border-color: #dee2e6 #dee2e6 #fff;
 }

 .nav-tabs .dropdown-menu {
   margin-top: -1px;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
 }

 .nav-pills .nav-link {
   border-radius: 0.25rem;
 }

 .nav-pills .nav-link.active,
 .nav-pills .show>.nav-link {
   color: #fff;
   background-color: #213f99;
 }

 .nav-fill .nav-item {
   flex: 1 1 auto;
   text-align: center;
 }

 .nav-justified .nav-item {
   flex-basis: 0;
   flex-grow: 1;
   text-align: center;
 }

 .tab-content>.tab-pane {
   display: none;
 }

 .tab-content>.active {
   display: block;
 }

 .navbar {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
   padding: 0.5rem 1rem;
 }

 .navbar .container,
 .navbar .container-fluid,
 .navbar .container-sm,
 .navbar .container-md,
 .navbar .container-lg,
 .navbar .container-xl {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
 }

 .navbar-brand {
   display: inline-block;
   padding-top: 0.3125rem;
   padding-bottom: 0.3125rem;
   margin-right: 1rem;
   font-size: 1.25rem;
   line-height: inherit;
   white-space: nowrap;
 }

 .navbar-brand:hover,
 .navbar-brand:focus {
   text-decoration: none;
 }

 .navbar-nav {
   display: flex;
   flex-direction: column;
   padding-left: 0;
   margin-bottom: 0;
   list-style: none;
 }

 .navbar-nav .nav-link {
   padding-right: 0;
   padding-left: 0;
 }

 .navbar-nav .dropdown-menu {
   position: static;
   float: none;
 }

 .navbar-text {
   display: inline-block;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
 }

 .navbar-collapse {
   flex-basis: 100%;
   flex-grow: 1;
   align-items: center;
 }

 .navbar-toggler {
   padding: 0.25rem 0.75rem;
   font-size: 1.25rem;
   line-height: 1;
   background-color: transparent;
   border: 1px solid transparent;
   border-radius: 0.25rem;
 }

 .navbar-toggler:hover,
 .navbar-toggler:focus {
   text-decoration: none;
 }

 .navbar-toggler-icon {
   display: inline-block;
   width: 1.5em;
   height: 1.5em;
   vertical-align: middle;
   content: "";
   background: no-repeat center center;
   background-size: 100% 100%;
 }

 @media (max-width: 575.98px) {

   .navbar-expand-sm>.container,
   .navbar-expand-sm>.container-fluid,
   .navbar-expand-sm>.container-sm,
   .navbar-expand-sm>.container-md,
   .navbar-expand-sm>.container-lg,
   .navbar-expand-sm>.container-xl {
     padding-right: 0;
     padding-left: 0;
   }
 }

 @media (min-width: 576px) {
   .navbar-expand-sm {
     flex-flow: row nowrap;
     justify-content: flex-start;
   }

   .navbar-expand-sm .navbar-nav {
     flex-direction: row;
   }

   .navbar-expand-sm .navbar-nav .dropdown-menu {
     position: absolute;
   }

   .navbar-expand-sm .navbar-nav .nav-link {
     padding-right: 0.5rem;
     padding-left: 0.5rem;
   }

   .navbar-expand-sm>.container,
   .navbar-expand-sm>.container-fluid,
   .navbar-expand-sm>.container-sm,
   .navbar-expand-sm>.container-md,
   .navbar-expand-sm>.container-lg,
   .navbar-expand-sm>.container-xl {
     flex-wrap: nowrap;
   }

   .navbar-expand-sm .navbar-collapse {
     display: flex !important;
     flex-basis: auto;
   }

   .navbar-expand-sm .navbar-toggler {
     display: none;
   }
 }

 @media (max-width: 767.98px) {

   .navbar-expand-md>.container,
   .navbar-expand-md>.container-fluid,
   .navbar-expand-md>.container-sm,
   .navbar-expand-md>.container-md,
   .navbar-expand-md>.container-lg,
   .navbar-expand-md>.container-xl {
     padding-right: 0;
     padding-left: 0;
   }
 }

 @media (min-width: 768px) {
   .navbar-expand-md {
     flex-flow: row nowrap;
     justify-content: flex-start;
   }

   .navbar-expand-md .navbar-nav {
     flex-direction: row;
   }

   .navbar-expand-md .navbar-nav .dropdown-menu {
     position: absolute;
   }

   .navbar-expand-md .navbar-nav .nav-link {
     padding-right: 0.5rem;
     padding-left: 0.5rem;
   }

   .navbar-expand-md>.container,
   .navbar-expand-md>.container-fluid,
   .navbar-expand-md>.container-sm,
   .navbar-expand-md>.container-md,
   .navbar-expand-md>.container-lg,
   .navbar-expand-md>.container-xl {
     flex-wrap: nowrap;
   }

   .navbar-expand-md .navbar-collapse {
     display: flex !important;
     flex-basis: auto;
   }

   .navbar-expand-md .navbar-toggler {
     display: none;
   }
 }

 @media (max-width: 991.98px) {

   .navbar-expand-lg>.container,
   .navbar-expand-lg>.container-fluid,
   .navbar-expand-lg>.container-sm,
   .navbar-expand-lg>.container-md,
   .navbar-expand-lg>.container-lg,
   .navbar-expand-lg>.container-xl {
     padding-right: 0;
     padding-left: 0;
   }
 }

 @media (min-width: 992px) {
   .navbar-expand-lg {
     flex-flow: row nowrap;
     justify-content: flex-start;
   }

   .navbar-expand-lg .navbar-nav {
     flex-direction: row;
   }

   .navbar-expand-lg .navbar-nav .dropdown-menu {
     position: absolute;
   }

   .navbar-expand-lg .navbar-nav .nav-link {
     padding-right: 0.5rem;
     padding-left: 0.5rem;
   }

   .navbar-expand-lg>.container,
   .navbar-expand-lg>.container-fluid,
   .navbar-expand-lg>.container-sm,
   .navbar-expand-lg>.container-md,
   .navbar-expand-lg>.container-lg,
   .navbar-expand-lg>.container-xl {
     flex-wrap: nowrap;
   }

   .navbar-expand-lg .navbar-collapse {
     display: flex !important;
     flex-basis: auto;
   }

   .navbar-expand-lg .navbar-toggler {
     display: none;
   }
 }

 @media (max-width: 1199.98px) {

   .navbar-expand-xl>.container,
   .navbar-expand-xl>.container-fluid,
   .navbar-expand-xl>.container-sm,
   .navbar-expand-xl>.container-md,
   .navbar-expand-xl>.container-lg,
   .navbar-expand-xl>.container-xl {
     padding-right: 0;
     padding-left: 0;
   }
 }

 @media (min-width: 1200px) {
   .navbar-expand-xl {
     flex-flow: row nowrap;
     justify-content: flex-start;
   }

   .navbar-expand-xl .navbar-nav {
     flex-direction: row;
   }

   .navbar-expand-xl .navbar-nav .dropdown-menu {
     position: absolute;
   }

   .navbar-expand-xl .navbar-nav .nav-link {
     padding-right: 0.5rem;
     padding-left: 0.5rem;
   }

   .navbar-expand-xl>.container,
   .navbar-expand-xl>.container-fluid,
   .navbar-expand-xl>.container-sm,
   .navbar-expand-xl>.container-md,
   .navbar-expand-xl>.container-lg,
   .navbar-expand-xl>.container-xl {
     flex-wrap: nowrap;
   }

   .navbar-expand-xl .navbar-collapse {
     display: flex !important;
     flex-basis: auto;
   }

   .navbar-expand-xl .navbar-toggler {
     display: none;
   }
 }

 @media (max-width: 1439.98) {

   .navbar-expand-xxl>.container,
   .navbar-expand-xxl>.container-fluid,
   .navbar-expand-xxl>.container-sm,
   .navbar-expand-xxl>.container-md,
   .navbar-expand-xxl>.container-lg,
   .navbar-expand-xxl>.container-xl {
     padding-right: 0;
     padding-left: 0;
   }
 }

 @media (min-width: 1440) {
   .navbar-expand-xxl {
     flex-flow: row nowrap;
     justify-content: flex-start;
   }

   .navbar-expand-xxl .navbar-nav {
     flex-direction: row;
   }

   .navbar-expand-xxl .navbar-nav .dropdown-menu {
     position: absolute;
   }

   .navbar-expand-xxl .navbar-nav .nav-link {
     padding-right: 0.5rem;
     padding-left: 0.5rem;
   }

   .navbar-expand-xxl>.container,
   .navbar-expand-xxl>.container-fluid,
   .navbar-expand-xxl>.container-sm,
   .navbar-expand-xxl>.container-md,
   .navbar-expand-xxl>.container-lg,
   .navbar-expand-xxl>.container-xl {
     flex-wrap: nowrap;
   }

   .navbar-expand-xxl .navbar-collapse {
     display: flex !important;
     flex-basis: auto;
   }

   .navbar-expand-xxl .navbar-toggler {
     display: none;
   }
 }

 .navbar-expand {
   flex-flow: row nowrap;
   justify-content: flex-start;
 }

 .navbar-expand>.container,
 .navbar-expand>.container-fluid,
 .navbar-expand>.container-sm,
 .navbar-expand>.container-md,
 .navbar-expand>.container-lg,
 .navbar-expand>.container-xl {
   padding-right: 0;
   padding-left: 0;
 }

 .navbar-expand .navbar-nav {
   flex-direction: row;
 }

 .navbar-expand .navbar-nav .dropdown-menu {
   position: absolute;
 }

 .navbar-expand .navbar-nav .nav-link {
   padding-right: 0.5rem;
   padding-left: 0.5rem;
 }

 .navbar-expand>.container,
 .navbar-expand>.container-fluid,
 .navbar-expand>.container-sm,
 .navbar-expand>.container-md,
 .navbar-expand>.container-lg,
 .navbar-expand>.container-xl {
   flex-wrap: nowrap;
 }

 .navbar-expand .navbar-collapse {
   display: flex !important;
   flex-basis: auto;
 }

 .navbar-expand .navbar-toggler {
   display: none;
 }

 .navbar-light .navbar-brand {
   color: rgba(0, 0, 0, 0.9);
 }

 .navbar-light .navbar-brand:hover,
 .navbar-light .navbar-brand:focus {
   color: rgba(0, 0, 0, 0.9);
 }

 .navbar-light .navbar-nav .nav-link {
   color: rgba(0, 0, 0, 0.5);
 }

 .navbar-light .navbar-nav .nav-link:hover,
 .navbar-light .navbar-nav .nav-link:focus {
   color: rgba(0, 0, 0, 0.7);
 }

 .navbar-light .navbar-nav .nav-link.disabled {
   color: rgba(0, 0, 0, 0.3);
 }

 .navbar-light .navbar-nav .show>.nav-link,
 .navbar-light .navbar-nav .active>.nav-link,
 .navbar-light .navbar-nav .nav-link.show,
 .navbar-light .navbar-nav .nav-link.active {
   color: rgba(0, 0, 0, 0.9);
 }

 .navbar-light .navbar-toggler {
   color: rgba(0, 0, 0, 0.5);
   border-color: rgba(0, 0, 0, 0.1);
 }

 .navbar-light .navbar-toggler-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
 }

 .navbar-light .navbar-text {
   color: rgba(0, 0, 0, 0.5);
 }

 .navbar-light .navbar-text a {
   color: rgba(0, 0, 0, 0.9);
 }

 .navbar-light .navbar-text a:hover,
 .navbar-light .navbar-text a:focus {
   color: rgba(0, 0, 0, 0.9);
 }

 .navbar-dark .navbar-brand {
   color: #fff;
 }

 .navbar-dark .navbar-brand:hover,
 .navbar-dark .navbar-brand:focus {
   color: #fff;
 }

 .navbar-dark .navbar-nav .nav-link {
   color: rgba(255, 255, 255, 0.5);
 }

 .navbar-dark .navbar-nav .nav-link:hover,
 .navbar-dark .navbar-nav .nav-link:focus {
   color: rgba(255, 255, 255, 0.75);
 }

 .navbar-dark .navbar-nav .nav-link.disabled {
   color: rgba(255, 255, 255, 0.25);
 }

 .navbar-dark .navbar-nav .show>.nav-link,
 .navbar-dark .navbar-nav .active>.nav-link,
 .navbar-dark .navbar-nav .nav-link.show,
 .navbar-dark .navbar-nav .nav-link.active {
   color: #fff;
 }

 .navbar-dark .navbar-toggler {
   color: rgba(255, 255, 255, 0.5);
   border-color: rgba(255, 255, 255, 0.1);
 }

 .navbar-dark .navbar-toggler-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
 }

 .navbar-dark .navbar-text {
   color: rgba(255, 255, 255, 0.5);
 }

 .navbar-dark .navbar-text a {
   color: #fff;
 }

 .navbar-dark .navbar-text a:hover,
 .navbar-dark .navbar-text a:focus {
   color: #fff;
 }

 .card {
   position: relative;
   display: flex;
   flex-direction: column;
   min-width: 0;
   word-wrap: break-word;
   background-color: #fff;
   background-clip: border-box;
   border: 1px solid rgba(0, 0, 0, 0.125);
   border-radius: 0.25rem;
 }

 .card>hr {
   margin-right: 0;
   margin-left: 0;
 }

 .card>.list-group {
   border-top: inherit;
   border-bottom: inherit;
 }

 .card>.list-group:first-child {
   border-top-width: 0;
   border-top-left-radius: calc(0.25rem - 1px);
   border-top-right-radius: calc(0.25rem - 1px);
 }

 .card>.list-group:last-child {
   border-bottom-width: 0;
   border-bottom-right-radius: calc(0.25rem - 1px);
   border-bottom-left-radius: calc(0.25rem - 1px);
 }

 .card-body {
   flex: 1 1 auto;
   min-height: 1px;
   padding: 1.25rem;
 }

 .card-title {
   margin-bottom: 0.75rem;
 }

 .card-subtitle {
   margin-top: -0.375rem;
   margin-bottom: 0;
 }

 .card-text:last-child {
   margin-bottom: 0;
 }

 .card-link:hover {
   text-decoration: none;
 }

 .card-link+.card-link {
   margin-left: 1.25rem;
 }

 .card-header {
   padding: 0.75rem 1.25rem;
   margin-bottom: 0;
   background-color: rgba(0, 0, 0, 0.03);
   border-bottom: 1px solid rgba(0, 0, 0, 0.125);
 }

 .card-header:first-child {
   border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
 }

 .card-header+.list-group .list-group-item:first-child {
   border-top: 0;
 }

 .card-footer {
   padding: 0.75rem 1.25rem;
   background-color: rgba(0, 0, 0, 0.03);
   border-top: 1px solid rgba(0, 0, 0, 0.125);
 }

 .card-footer:last-child {
   border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
 }

 .card-header-tabs {
   margin-right: -0.625rem;
   margin-bottom: -0.75rem;
   margin-left: -0.625rem;
   border-bottom: 0;
 }

 .card-header-pills {
   margin-right: -0.625rem;
   margin-left: -0.625rem;
 }

 .card-img-overlay {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   padding: 1.25rem;
 }

 .card-img,
 .card-img-top,
 .card-img-bottom {
   flex-shrink: 0;
   width: 100%;
 }

 .card-img,
 .card-img-top {
   border-top-left-radius: calc(0.25rem - 1px);
   border-top-right-radius: calc(0.25rem - 1px);
 }

 .card-img,
 .card-img-bottom {
   border-bottom-right-radius: calc(0.25rem - 1px);
   border-bottom-left-radius: calc(0.25rem - 1px);
 }

 .card-deck .card {
   margin-bottom: 15px;
 }

 @media (min-width: 576px) {
   .card-deck {
     display: flex;
     flex-flow: row wrap;
     margin-right: -15px;
     margin-left: -15px;
   }

   .card-deck .card {
     flex: 1 0 0%;
     margin-right: 15px;
     margin-bottom: 0;
     margin-left: 15px;
   }
 }

 .card-group>.card {
   margin-bottom: 15px;
 }

 @media (min-width: 576px) {
   .card-group {
     display: flex;
     flex-flow: row wrap;
   }

   .card-group>.card {
     flex: 1 0 0%;
     margin-bottom: 0;
   }

   .card-group>.card+.card {
     margin-left: 0;
     border-left: 0;
   }

   .card-group>.card:not(:last-child) {
     border-top-right-radius: 0;
     border-bottom-right-radius: 0;
   }

   .card-group>.card:not(:last-child) .card-img-top,
   .card-group>.card:not(:last-child) .card-header {
     border-top-right-radius: 0;
   }

   .card-group>.card:not(:last-child) .card-img-bottom,
   .card-group>.card:not(:last-child) .card-footer {
     border-bottom-right-radius: 0;
   }

   .card-group>.card:not(:first-child) {
     border-top-left-radius: 0;
     border-bottom-left-radius: 0;
   }

   .card-group>.card:not(:first-child) .card-img-top,
   .card-group>.card:not(:first-child) .card-header {
     border-top-left-radius: 0;
   }

   .card-group>.card:not(:first-child) .card-img-bottom,
   .card-group>.card:not(:first-child) .card-footer {
     border-bottom-left-radius: 0;
   }
 }

 .card-columns .card {
   margin-bottom: 0.75rem;
 }

 @media (min-width: 576px) {
   .card-columns {
     column-count: 3;
     column-gap: 1.25rem;
     orphans: 1;
     widows: 1;
   }

   .card-columns .card {
     display: inline-block;
     width: 100%;
   }
 }

 .accordion>.card {
   overflow: hidden;
 }

 .accordion>.card:not(:last-of-type) {
   border-bottom: 0;
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;
 }

 .accordion>.card:not(:first-of-type) {
   border-top-left-radius: 0;
   border-top-right-radius: 0;
 }

 .accordion>.card>.card-header {
   border-radius: 0;
   margin-bottom: -1px;
 }

 .breadcrumb {
   display: flex;
   flex-wrap: wrap;
   padding: 0.75rem 1rem;
   margin-bottom: 1rem;
   list-style: none;
   background-color: #e9ecef;
   border-radius: 0.25rem;
 }

 .breadcrumb-item {
   display: flex;
 }

 .breadcrumb-item+.breadcrumb-item {
   padding-left: 0.5rem;
 }

 .breadcrumb-item+.breadcrumb-item::before {
   display: inline-block;
   padding-right: 0.5rem;
   color: #6c757d;
   content: "/";
 }

 .breadcrumb-item+.breadcrumb-item:hover::before {
   text-decoration: underline;
 }

 .breadcrumb-item+.breadcrumb-item:hover::before {
   text-decoration: none;
 }

 .breadcrumb-item.active {
   color: #6c757d;
 }

 .pagination {
   display: flex;
   padding-left: 0;
   list-style: none;
   border-radius: 0.25rem;
 }

 .page-link {
   position: relative;
   display: block;
   padding: 0.5rem 0.75rem;
   margin-left: -1px;
   line-height: 1.25;
   color: #213f99;
   background-color: #fff;
   border: 1px solid #dee2e6;
 }

 .page-link:hover {
   z-index: 2;
   color: #653b11;
   text-decoration: none;
   background-color: #e9ecef;
   border-color: #dee2e6;
 }

 .page-link:focus {
   z-index: 3;
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(166, 97, 28, 0.25);
 }

 .page-item:first-child .page-link {
   margin-left: 0;
   border-top-left-radius: 0.25rem;
   border-bottom-left-radius: 0.25rem;
 }

 .page-item:last-child .page-link {
   border-top-right-radius: 0.25rem;
   border-bottom-right-radius: 0.25rem;
 }

 .page-item.active .page-link {
   z-index: 3;
   color: #fff;
   background-color: #213f99;
   border-color: #213f99;
 }

 .page-item.disabled .page-link {
   color: #6c757d;
   pointer-events: none;
   cursor: auto;
   background-color: #fff;
   border-color: #dee2e6;
 }

 .pagination-lg .page-link {
   padding: 0.75rem 1.5rem;
   font-size: 1.25rem;
   line-height: 1.5;
 }

 .pagination-lg .page-item:first-child .page-link {
   border-top-left-radius: 0.3rem;
   border-bottom-left-radius: 0.3rem;
 }

 .pagination-lg .page-item:last-child .page-link {
   border-top-right-radius: 0.3rem;
   border-bottom-right-radius: 0.3rem;
 }

 .pagination-sm .page-link {
   padding: 0.25rem 0.5rem;
   font-size: 0.875rem;
   line-height: 1.5;
 }

 .pagination-sm .page-item:first-child .page-link {
   border-top-left-radius: 0.2rem;
   border-bottom-left-radius: 0.2rem;
 }

 .pagination-sm .page-item:last-child .page-link {
   border-top-right-radius: 0.2rem;
   border-bottom-right-radius: 0.2rem;
 }

 .badge {
   display: inline-block;
   padding: 0.25em 0.4em;
   font-size: 75%;
   font-weight: 700;
   line-height: 1;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   border-radius: 0.25rem;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }

 @media (prefers-reduced-motion: reduce) {
   .badge {
     transition: none;
   }
 }

 a.badge:hover,
 a.badge:focus {
   text-decoration: none;
 }

 .badge:empty {
   display: none;
 }

 .btn .badge {
   position: relative;
   top: -1px;
 }

 .badge-pill {
   padding-right: 0.6em;
   padding-left: 0.6em;
   border-radius: 10rem;
 }

 .badge-primary {
   color: #fff;
   background-color: #213f99;
 }

 a.badge-primary:hover,
 a.badge-primary:focus {
   color: #fff;
   background-color: #7a4815;
 }

 a.badge-primary:focus,
 a.badge-primary.focus {
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(166, 97, 28, 0.5);
 }

 .badge-secondary {
   color: #fff;
   background-color: #673BB7;
 }

 a.badge-secondary:hover,
 a.badge-secondary:focus {
   color: #fff;
   background-color: #512f90;
 }

 a.badge-secondary:focus,
 a.badge-secondary.focus {
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5);
 }

 .badge-success {
   color: #fff;
   background-color: #10d876;
 }

 a.badge-success:hover,
 a.badge-success:focus {
   color: #fff;
   background-color: #0ca95c;
 }

 a.badge-success:focus,
 a.badge-success.focus {
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5);
 }

 .badge-info {
   color: #fff;
   background-color: #2754e6;
 }

 a.badge-info:hover,
 a.badge-info:focus {
   color: #fff;
   background-color: #173fc3;
 }

 a.badge-info:focus,
 a.badge-info.focus {
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5);
 }

 .badge-warning {
   color: #212529;
   background-color: #FE9431;
 }

 a.badge-warning:hover,
 a.badge-warning:focus {
   color: #212529;
   background-color: #fb7a01;
 }

 a.badge-warning:focus,
 a.badge-warning.focus {
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5);
 }

 .badge-danger {
   color: #fff;
   background-color: #E50202;
 }

 a.badge-danger:hover,
 a.badge-danger:focus {
   color: #fff;
   background-color: #b20202;
 }

 a.badge-danger:focus,
 a.badge-danger.focus {
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5);
 }

 .badge-light {
   color: #212529;
   background-color: #faf8f2;
 }

 a.badge-light:hover,
 a.badge-light:focus {
   color: #212529;
   background-color: #ece4cd;
 }

 a.badge-light:focus,
 a.badge-light.focus {
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5);
 }

 .badge-dark {
   color: #fff;
   background-color: #262F5A;
 }

 a.badge-dark:hover,
 a.badge-dark:focus {
   color: #fff;
   background-color: #171c36;
 }

 a.badge-dark:focus,
 a.badge-dark.focus {
   outline: 0;
   box-shadow: 0 0 0 0rem rgba(38, 47, 90, 0.5);
 }

 .jumbotron {
   padding: 2rem 1rem;
   margin-bottom: 2rem;
   background-color: #e9ecef;
   border-radius: 0.3rem;
 }

 @media (min-width: 576px) {
   .jumbotron {
     padding: 4rem 2rem;
   }
 }

 .jumbotron-fluid {
   padding-right: 0;
   padding-left: 0;
   border-radius: 0;
 }

 .alert {
   position: relative;
   padding: 0.75rem 1.25rem;
   margin-bottom: 1rem;
   border: 1px solid transparent;
   border-radius: 0.25rem;
 }

 .alert-heading {
   color: inherit;
 }

 .alert-link {
   font-weight: 700;
 }

 .alert-dismissible {
   padding-right: 4rem;
 }

 .alert-dismissible .close {
   position: absolute;
   top: 0;
   right: 0;
   padding: 0.75rem 1.25rem;
   color: inherit;
 }

 .alert-primary {
   color: #56320f;
   background-color: #eddfd2;
   border-color: #e6d3bf;
 }

 .alert-primary hr {
   border-top-color: #dfc6ad;
 }

 .alert-primary .alert-link {
   color: #2b1907;
 }

 .alert-secondary {
   color: #361f5f;
   background-color: #e1d8f1;
   border-color: #d4c8eb;
 }

 .alert-secondary hr {
   border-top-color: #c5b5e4;
 }

 .alert-secondary .alert-link {
   color: #201239;
 }

 .alert-success {
   color: #08703d;
   background-color: #cff7e4;
   border-color: #bcf4d9;
 }

 .alert-success hr {
   border-top-color: #a6f0cd;
 }

 .alert-success .alert-link {
   color: #054023;
 }

 .alert-info {
   color: #142c78;
   background-color: #d4ddfa;
   border-color: #c3cff8;
 }

 .alert-info hr {
   border-top-color: #acbdf5;
 }

 .alert-info .alert-link {
   color: #0d1c4c;
 }

 .alert-warning {
   color: #844d19;
   background-color: #ffead6;
   border-color: #ffe1c5;
 }

 .alert-warning hr {
   border-top-color: #ffd4ac;
 }

 .alert-warning .alert-link {
   color: #593411;
 }

 .alert-danger {
   color: #770101;
   background-color: #facccc;
   border-color: #f8b8b8;
 }

 .alert-danger hr {
   border-top-color: #f6a1a1;
 }

 .alert-danger .alert-link {
   color: #440101;
 }

 .alert-light {
   color: #82817e;
   background-color: #fefefc;
   border-color: #fefdfb;
 }

 .alert-light hr {
   border-top-color: #f9f3e7;
 }

 .alert-light .alert-link {
   color: #686765;
 }

 .alert-dark {
   color: #14182f;
   background-color: #d4d5de;
   border-color: #c2c5d1;
 }

 .alert-dark hr {
   border-top-color: #b3b7c6;
 }

 .alert-dark .alert-link {
   color: #05060b;
 }

 @keyframes progress-bar-stripes {
   from {
     background-position: 1rem 0;
   }

   to {
     background-position: 0 0;
   }
 }

 .progress {
   display: flex;
   height: 1rem;
   overflow: hidden;
   line-height: 0;
   font-size: 0.75rem;
   background-color: #e9ecef;
   border-radius: 0.25rem;
 }

 .progress-bar {
   display: flex;
   flex-direction: column;
   justify-content: center;
   overflow: hidden;
   color: #fff;
   text-align: center;
   white-space: nowrap;
   background-color: #213f99;
   transition: width 0.6s ease;
 }

 @media (prefers-reduced-motion: reduce) {
   .progress-bar {
     transition: none;
   }
 }

 .progress-bar-striped {
   background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
   background-size: 1rem 1rem;
 }

 .progress-bar-animated {
   animation: progress-bar-stripes 1s linear infinite;
 }

 @media (prefers-reduced-motion: reduce) {
   .progress-bar-animated {
     animation: none;
   }
 }

 .media {
   display: flex;
   align-items: flex-start;
 }

 .media-body {
   flex: 1;
 }

 .list-group {
   display: flex;
   flex-direction: column;
   padding-left: 0;
   margin-bottom: 0;
   border-radius: 0.25rem;
 }

 .list-group-item-action {
   width: 100%;
   color: #495057;
   text-align: inherit;
 }

 .list-group-item-action:hover,
 .list-group-item-action:focus {
   z-index: 1;
   color: #495057;
   text-decoration: none;
   background-color: #f8f9fa;
 }

 .list-group-item-action:active {
   color: #515184;
   background-color: #e9ecef;
 }

 .list-group-item {
   position: relative;
   display: block;
   padding: 0.75rem 1.25rem;
   background-color: #fff;
   border: 1px solid rgba(0, 0, 0, 0.125);
 }

 .list-group-item:first-child {
   border-top-left-radius: inherit;
   border-top-right-radius: inherit;
 }

 .list-group-item:last-child {
   border-bottom-right-radius: inherit;
   border-bottom-left-radius: inherit;
 }

 .list-group-item.disabled,
 .list-group-item:disabled {
   color: #6c757d;
   pointer-events: none;
   background-color: #fff;
 }

 .list-group-item.active {
   z-index: 2;
   color: #fff;
   background-color: #213f99;
   border-color: #213f99;
 }

 .list-group-item+.list-group-item {
   border-top-width: 0;
 }

 .list-group-item+.list-group-item.active {
   margin-top: -1px;
   border-top-width: 1px;
 }

 .list-group-horizontal {
   flex-direction: row;
 }

 .list-group-horizontal>.list-group-item:first-child {
   border-bottom-left-radius: 0.25rem;
   border-top-right-radius: 0;
 }

 .list-group-horizontal>.list-group-item:last-child {
   border-top-right-radius: 0.25rem;
   border-bottom-left-radius: 0;
 }

 .list-group-horizontal>.list-group-item.active {
   margin-top: 0;
 }

 .list-group-horizontal>.list-group-item+.list-group-item {
   border-top-width: 1px;
   border-left-width: 0;
 }

 .list-group-horizontal>.list-group-item+.list-group-item.active {
   margin-left: -1px;
   border-left-width: 1px;
 }

 @media (min-width: 576px) {
   .list-group-horizontal-sm {
     flex-direction: row;
   }

   .list-group-horizontal-sm>.list-group-item:first-child {
     border-bottom-left-radius: 0.25rem;
     border-top-right-radius: 0;
   }

   .list-group-horizontal-sm>.list-group-item:last-child {
     border-top-right-radius: 0.25rem;
     border-bottom-left-radius: 0;
   }

   .list-group-horizontal-sm>.list-group-item.active {
     margin-top: 0;
   }

   .list-group-horizontal-sm>.list-group-item+.list-group-item {
     border-top-width: 1px;
     border-left-width: 0;
   }

   .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
     margin-left: -1px;
     border-left-width: 1px;
   }
 }

 @media (min-width: 768px) {
   .list-group-horizontal-md {
     flex-direction: row;
   }

   .list-group-horizontal-md>.list-group-item:first-child {
     border-bottom-left-radius: 0.25rem;
     border-top-right-radius: 0;
   }

   .list-group-horizontal-md>.list-group-item:last-child {
     border-top-right-radius: 0.25rem;
     border-bottom-left-radius: 0;
   }

   .list-group-horizontal-md>.list-group-item.active {
     margin-top: 0;
   }

   .list-group-horizontal-md>.list-group-item+.list-group-item {
     border-top-width: 1px;
     border-left-width: 0;
   }

   .list-group-horizontal-md>.list-group-item+.list-group-item.active {
     margin-left: -1px;
     border-left-width: 1px;
   }
 }

 @media (min-width: 992px) {
   .list-group-horizontal-lg {
     flex-direction: row;
   }

   .list-group-horizontal-lg>.list-group-item:first-child {
     border-bottom-left-radius: 0.25rem;
     border-top-right-radius: 0;
   }

   .list-group-horizontal-lg>.list-group-item:last-child {
     border-top-right-radius: 0.25rem;
     border-bottom-left-radius: 0;
   }

   .list-group-horizontal-lg>.list-group-item.active {
     margin-top: 0;
   }

   .list-group-horizontal-lg>.list-group-item+.list-group-item {
     border-top-width: 1px;
     border-left-width: 0;
   }

   .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
     margin-left: -1px;
     border-left-width: 1px;
   }
 }

 @media (min-width: 1200px) {
   .list-group-horizontal-xl {
     flex-direction: row;
   }

   .list-group-horizontal-xl>.list-group-item:first-child {
     border-bottom-left-radius: 0.25rem;
     border-top-right-radius: 0;
   }

   .list-group-horizontal-xl>.list-group-item:last-child {
     border-top-right-radius: 0.25rem;
     border-bottom-left-radius: 0;
   }

   .list-group-horizontal-xl>.list-group-item.active {
     margin-top: 0;
   }

   .list-group-horizontal-xl>.list-group-item+.list-group-item {
     border-top-width: 1px;
     border-left-width: 0;
   }

   .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
     margin-left: -1px;
     border-left-width: 1px;
   }
 }

 @media (min-width: 1440) {
   .list-group-horizontal-xxl {
     flex-direction: row;
   }

   .list-group-horizontal-xxl>.list-group-item:first-child {
     border-bottom-left-radius: 0.25rem;
     border-top-right-radius: 0;
   }

   .list-group-horizontal-xxl>.list-group-item:last-child {
     border-top-right-radius: 0.25rem;
     border-bottom-left-radius: 0;
   }

   .list-group-horizontal-xxl>.list-group-item.active {
     margin-top: 0;
   }

   .list-group-horizontal-xxl>.list-group-item+.list-group-item {
     border-top-width: 1px;
     border-left-width: 0;
   }

   .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
     margin-left: -1px;
     border-left-width: 1px;
   }
 }

 .list-group-flush {
   border-radius: 0;
 }

 .list-group-flush>.list-group-item {
   border-width: 0 0 1px;
 }

 .list-group-flush>.list-group-item:last-child {
   border-bottom-width: 0;
 }

 .list-group-item-primary {
   color: #56320f;
   background-color: #e6d3bf;
 }

 .list-group-item-primary.list-group-item-action:hover,
 .list-group-item-primary.list-group-item-action:focus {
   color: #56320f;
   background-color: #dfc6ad;
 }

 .list-group-item-primary.list-group-item-action.active {
   color: #fff;
   background-color: #56320f;
   border-color: #56320f;
 }

 .list-group-item-secondary {
   color: #361f5f;
   background-color: #d4c8eb;
 }

 .list-group-item-secondary.list-group-item-action:hover,
 .list-group-item-secondary.list-group-item-action:focus {
   color: #361f5f;
   background-color: #c5b5e4;
 }

 .list-group-item-secondary.list-group-item-action.active {
   color: #fff;
   background-color: #361f5f;
   border-color: #361f5f;
 }

 .list-group-item-success {
   color: #08703d;
   background-color: #bcf4d9;
 }

 .list-group-item-success.list-group-item-action:hover,
 .list-group-item-success.list-group-item-action:focus {
   color: #08703d;
   background-color: #a6f0cd;
 }

 .list-group-item-success.list-group-item-action.active {
   color: #fff;
   background-color: #08703d;
   border-color: #08703d;
 }

 .list-group-item-info {
   color: #142c78;
   background-color: #c3cff8;
 }

 .list-group-item-info.list-group-item-action:hover,
 .list-group-item-info.list-group-item-action:focus {
   color: #142c78;
   background-color: #acbdf5;
 }

 .list-group-item-info.list-group-item-action.active {
   color: #fff;
   background-color: #142c78;
   border-color: #142c78;
 }

 .list-group-item-warning {
   color: #844d19;
   background-color: #ffe1c5;
 }

 .list-group-item-warning.list-group-item-action:hover,
 .list-group-item-warning.list-group-item-action:focus {
   color: #844d19;
   background-color: #ffd4ac;
 }

 .list-group-item-warning.list-group-item-action.active {
   color: #fff;
   background-color: #844d19;
   border-color: #844d19;
 }

 .list-group-item-danger {
   color: #770101;
   background-color: #f8b8b8;
 }

 .list-group-item-danger.list-group-item-action:hover,
 .list-group-item-danger.list-group-item-action:focus {
   color: #770101;
   background-color: #f6a1a1;
 }

 .list-group-item-danger.list-group-item-action.active {
   color: #fff;
   background-color: #770101;
   border-color: #770101;
 }

 .list-group-item-light {
   color: #82817e;
   background-color: #fefdfb;
 }

 .list-group-item-light.list-group-item-action:hover,
 .list-group-item-light.list-group-item-action:focus {
   color: #82817e;
   background-color: #f9f3e7;
 }

 .list-group-item-light.list-group-item-action.active {
   color: #fff;
   background-color: #82817e;
   border-color: #82817e;
 }

 .list-group-item-dark {
   color: #14182f;
   background-color: #c2c5d1;
 }

 .list-group-item-dark.list-group-item-action:hover,
 .list-group-item-dark.list-group-item-action:focus {
   color: #14182f;
   background-color: #b3b7c6;
 }

 .list-group-item-dark.list-group-item-action.active {
   color: #fff;
   background-color: #14182f;
   border-color: #14182f;
 }

 .close {
   float: right;
   font-size: 1.5rem;
   font-weight: 700;
   line-height: 1;
   color: #000;
   text-shadow: 0 1px 0 #fff;
   opacity: .5;
 }

 .close:hover {
   color: #000;
   text-decoration: none;
 }

 .close:not(:disabled):not(.disabled):hover,
 .close:not(:disabled):not(.disabled):focus {
   opacity: .75;
 }

 button.close {
   padding: 0;
   background-color: transparent;
   border: 0;
 }

 a.close.disabled {
   pointer-events: none;
 }

 .toast {
   max-width: 350px;
   overflow: hidden;
   font-size: 0.875rem;
   background-color: rgba(255, 255, 255, 0.85);
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.1);
   box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(10px);
   opacity: 0;
   border-radius: 0.25rem;
 }

 .toast:not(:last-child) {
   margin-bottom: 0.75rem;
 }

 .toast.showing {
   opacity: 1;
 }

 .toast.show {
   display: block;
   opacity: 1;
 }

 .toast.hide {
   display: none;
 }

 .toast-header {
   display: flex;
   align-items: center;
   padding: 0.25rem 0.75rem;
   color: #6c757d;
   background-color: rgba(255, 255, 255, 0.85);
   background-clip: padding-box;
   border-bottom: 1px solid rgba(0, 0, 0, 0.05);
 }

 .toast-body {
   padding: 0.75rem;
 }

 .modal-open {
   overflow: hidden;
 }

 .modal-open .modal {
   overflow-x: hidden;
   overflow-y: auto;
 }

 .modal {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1050;
   display: none;
   width: 100%;
   height: 100%;
   overflow: hidden;
   outline: 0;
 }

 .modal-dialog {
   position: relative;
   width: auto;
   margin: 0.5rem;
   pointer-events: none;
 }

 .modal.fade .modal-dialog {
   transition: transform 0.3s ease-out;
   transform: translate(0, -50px);
 }

 @media (prefers-reduced-motion: reduce) {
   .modal.fade .modal-dialog {
     transition: none;
   }
 }

 .modal.show .modal-dialog {
   transform: none;
 }

 .modal.modal-static .modal-dialog {
   transform: scale(1.02);
 }

 .modal-dialog-scrollable {
   display: flex;
   max-height: calc(100% - 1rem);
 }

 .modal-dialog-scrollable .modal-content {
   max-height: calc(100vh - 1rem);
   overflow: hidden;
 }

 .modal-dialog-scrollable .modal-header,
 .modal-dialog-scrollable .modal-footer {
   flex-shrink: 0;
 }

 .modal-dialog-scrollable .modal-body {
   overflow-y: auto;
 }

 .modal-dialog-centered {
   display: flex;
   align-items: center;
   min-height: calc(100% - 1rem);
 }

 .modal-dialog-centered::before {
   display: block;
   height: calc(100vh - 1rem);
   height: min-content;
   content: "";
 }

 .modal-dialog-centered.modal-dialog-scrollable {
   flex-direction: column;
   justify-content: center;
   height: 100%;
 }

 .modal-dialog-centered.modal-dialog-scrollable .modal-content {
   max-height: none;
 }

 .modal-dialog-centered.modal-dialog-scrollable::before {
   content: none;
 }

 .modal-content {
   position: relative;
   display: flex;
   flex-direction: column;
   width: 100%;
   pointer-events: auto;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 0.3rem;
   outline: 0;
 }

 .modal-backdrop {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1040;
   width: 100vw;
   height: 100vh;
   background-color: #000;
 }

 .modal-backdrop.fade {
   opacity: 0;
 }

 .modal-backdrop.show {
   opacity: 0.5;
 }

 .modal-header {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   padding: 1rem 1rem;
   border-bottom: 1px solid #E1E1F0;
   border-top-left-radius: calc(0.3rem - 1px);
   border-top-right-radius: calc(0.3rem - 1px);
 }

 .modal-header .close {
   padding: 1rem 1rem;
   margin: -1rem -1rem -1rem auto;
 }

 .modal-title {
   margin-bottom: 0;
   line-height: 1.5;
 }

 .modal-body {
   position: relative;
   flex: 1 1 auto;
   padding: 1rem;
 }

 .modal-footer {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-end;
   padding: 0.75rem;
   border-top: 1px solid #E1E1F0;
   border-bottom-right-radius: calc(0.3rem - 1px);
   border-bottom-left-radius: calc(0.3rem - 1px);
 }

 .modal-footer>* {
   margin: 0.25rem;
 }

 .modal-scrollbar-measure {
   position: absolute;
   top: -9999px;
   width: 50px;
   height: 50px;
   overflow: scroll;
 }

 @media (min-width: 576px) {
   .modal-dialog {
     max-width: 500px;
     margin: 1.75rem auto;
   }

   .modal-dialog-scrollable {
     max-height: calc(100% - 3.5rem);
   }

   .modal-dialog-scrollable .modal-content {
     max-height: calc(100vh - 3.5rem);
   }

   .modal-dialog-centered {
     min-height: calc(100% - 3.5rem);
   }

   .modal-dialog-centered::before {
     height: calc(100vh - 3.5rem);
     height: min-content;
   }

   .modal-sm {
     max-width: 300px;
   }
 }

 @media (min-width: 992px) {

   .modal-lg,
   .modal-xl {
     max-width: 800px;
   }
 }

 @media (min-width: 1200px) {
   .modal-xl {
     max-width: 1140px;
   }
 }

 .tooltip {
   position: absolute;
   z-index: 1070;
   display: block;
   margin: 0;
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 400;
   line-height: 1.5;
   text-align: left;
   text-align: start;
   text-decoration: none;
   text-shadow: none;
   text-transform: none;
   letter-spacing: normal;
   word-break: normal;
   word-spacing: normal;
   white-space: normal;
   line-break: auto;
   font-size: 0.875rem;
   word-wrap: break-word;
   opacity: 0;
 }

 .tooltip.show {
   opacity: 0.9;
 }

 .tooltip .arrow {
   position: absolute;
   display: block;
   width: 0.8rem;
   height: 0.4rem;
 }

 .tooltip .arrow::before {
   position: absolute;
   content: "";
   border-color: transparent;
   border-style: solid;
 }

 .bs-tooltip-top,
 .bs-tooltip-auto[x-placement^="top"] {
   padding: 0.4rem 0;
 }

 .bs-tooltip-top .arrow,
 .bs-tooltip-auto[x-placement^="top"] .arrow {
   bottom: 0;
 }

 .bs-tooltip-top .arrow::before,
 .bs-tooltip-auto[x-placement^="top"] .arrow::before {
   top: 0;
   border-width: 0.4rem 0.4rem 0;
   border-top-color: #000;
 }

 .bs-tooltip-right,
 .bs-tooltip-auto[x-placement^="right"] {
   padding: 0 0.4rem;
 }

 .bs-tooltip-right .arrow,
 .bs-tooltip-auto[x-placement^="right"] .arrow {
   left: 0;
   width: 0.4rem;
   height: 0.8rem;
 }

 .bs-tooltip-right .arrow::before,
 .bs-tooltip-auto[x-placement^="right"] .arrow::before {
   right: 0;
   border-width: 0.4rem 0.4rem 0.4rem 0;
   border-right-color: #000;
 }

 .bs-tooltip-bottom,
 .bs-tooltip-auto[x-placement^="bottom"] {
   padding: 0.4rem 0;
 }

 .bs-tooltip-bottom .arrow,
 .bs-tooltip-auto[x-placement^="bottom"] .arrow {
   top: 0;
 }

 .bs-tooltip-bottom .arrow::before,
 .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
   bottom: 0;
   border-width: 0 0.4rem 0.4rem;
   border-bottom-color: #000;
 }

 .bs-tooltip-left,
 .bs-tooltip-auto[x-placement^="left"] {
   padding: 0 0.4rem;
 }

 .bs-tooltip-left .arrow,
 .bs-tooltip-auto[x-placement^="left"] .arrow {
   right: 0;
   width: 0.4rem;
   height: 0.8rem;
 }

 .bs-tooltip-left .arrow::before,
 .bs-tooltip-auto[x-placement^="left"] .arrow::before {
   left: 0;
   border-width: 0.4rem 0 0.4rem 0.4rem;
   border-left-color: #000;
 }

 .tooltip-inner {
   max-width: 200px;
   padding: 0.25rem 0.5rem;
   color: #fff;
   text-align: center;
   background-color: #000;
   border-radius: 0.25rem;
 }

 .popover {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 1060;
   display: block;
   max-width: 276px;
   font-family: "Roboto", sans-serif;
   font-style: normal;
   font-weight: 400;
   line-height: 1.5;
   text-align: left;
   text-align: start;
   text-decoration: none;
   text-shadow: none;
   text-transform: none;
   letter-spacing: normal;
   word-break: normal;
   word-spacing: normal;
   white-space: normal;
   line-break: auto;
   font-size: 0.875rem;
   word-wrap: break-word;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 0.3rem;
 }

 .popover .arrow {
   position: absolute;
   display: block;
   width: 1rem;
   height: 0.5rem;
   margin: 0 0.3rem;
 }

 .popover .arrow::before,
 .popover .arrow::after {
   position: absolute;
   display: block;
   content: "";
   border-color: transparent;
   border-style: solid;
 }

 .bs-popover-top,
 .bs-popover-auto[x-placement^="top"] {
   margin-bottom: 0.5rem;
 }

 .bs-popover-top>.arrow,
 .bs-popover-auto[x-placement^="top"]>.arrow {
   bottom: calc(-0.5rem - 1px);
 }

 .bs-popover-top>.arrow::before,
 .bs-popover-auto[x-placement^="top"]>.arrow::before {
   bottom: 0;
   border-width: 0.5rem 0.5rem 0;
   border-top-color: rgba(0, 0, 0, 0.25);
 }

 .bs-popover-top>.arrow::after,
 .bs-popover-auto[x-placement^="top"]>.arrow::after {
   bottom: 1px;
   border-width: 0.5rem 0.5rem 0;
   border-top-color: #fff;
 }

 .bs-popover-right,
 .bs-popover-auto[x-placement^="right"] {
   margin-left: 0.5rem;
 }

 .bs-popover-right>.arrow,
 .bs-popover-auto[x-placement^="right"]>.arrow {
   left: calc(-0.5rem - 1px);
   width: 0.5rem;
   height: 1rem;
   margin: 0.3rem 0;
 }

 .bs-popover-right>.arrow::before,
 .bs-popover-auto[x-placement^="right"]>.arrow::before {
   left: 0;
   border-width: 0.5rem 0.5rem 0.5rem 0;
   border-right-color: rgba(0, 0, 0, 0.25);
 }

 .bs-popover-right>.arrow::after,
 .bs-popover-auto[x-placement^="right"]>.arrow::after {
   left: 1px;
   border-width: 0.5rem 0.5rem 0.5rem 0;
   border-right-color: #fff;
 }

 .bs-popover-bottom,
 .bs-popover-auto[x-placement^="bottom"] {
   margin-top: 0.5rem;
 }

 .bs-popover-bottom>.arrow,
 .bs-popover-auto[x-placement^="bottom"]>.arrow {
   top: calc(-0.5rem - 1px);
 }

 .bs-popover-bottom>.arrow::before,
 .bs-popover-auto[x-placement^="bottom"]>.arrow::before {
   top: 0;
   border-width: 0 0.5rem 0.5rem 0.5rem;
   border-bottom-color: rgba(0, 0, 0, 0.25);
 }

 .bs-popover-bottom>.arrow::after,
 .bs-popover-auto[x-placement^="bottom"]>.arrow::after {
   top: 1px;
   border-width: 0 0.5rem 0.5rem 0.5rem;
   border-bottom-color: #fff;
 }

 .bs-popover-bottom .popover-header::before,
 .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
   position: absolute;
   top: 0;
   left: 50%;
   display: block;
   width: 1rem;
   margin-left: -0.5rem;
   content: "";
   border-bottom: 1px solid #f7f7f7;
 }

 .bs-popover-left,
 .bs-popover-auto[x-placement^="left"] {
   margin-right: 0.5rem;
 }

 .bs-popover-left>.arrow,
 .bs-popover-auto[x-placement^="left"]>.arrow {
   right: calc(-0.5rem - 1px);
   width: 0.5rem;
   height: 1rem;
   margin: 0.3rem 0;
 }

 .bs-popover-left>.arrow::before,
 .bs-popover-auto[x-placement^="left"]>.arrow::before {
   right: 0;
   border-width: 0.5rem 0 0.5rem 0.5rem;
   border-left-color: rgba(0, 0, 0, 0.25);
 }

 .bs-popover-left>.arrow::after,
 .bs-popover-auto[x-placement^="left"]>.arrow::after {
   right: 1px;
   border-width: 0.5rem 0 0.5rem 0.5rem;
   border-left-color: #fff;
 }

 .popover-header {
   padding: 0.5rem 0.75rem;
   margin-bottom: 0;
   font-size: 1rem;
   color: #000;
   background-color: #f7f7f7;
   border-bottom: 1px solid #ebebeb;
   border-top-left-radius: calc(0.3rem - 1px);
   border-top-right-radius: calc(0.3rem - 1px);
 }

 .popover-header:empty {
   display: none;
 }

 .popover-body {
   padding: 0.5rem 0.75rem;
   color: #515184;
 }

 .carousel {
   position: relative;
 }

 .carousel.pointer-event {
   touch-action: pan-y;
 }

 .carousel-inner {
   position: relative;
   width: 100%;
   overflow: hidden;
 }

 .carousel-inner::after {
   display: block;
   clear: both;
   content: "";
 }

 .carousel-item {
   position: relative;
   display: none;
   float: left;
   width: 100%;
   margin-right: -100%;
   backface-visibility: hidden;
   transition: transform 0.6s ease-in-out;
 }

 @media (prefers-reduced-motion: reduce) {
   .carousel-item {
     transition: none;
   }
 }

 .carousel-item.active,
 .carousel-item-next,
 .carousel-item-prev {
   display: block;
 }

 .carousel-item-next:not(.carousel-item-left),
 .active.carousel-item-right {
   transform: translateX(100%);
 }

 .carousel-item-prev:not(.carousel-item-right),
 .active.carousel-item-left {
   transform: translateX(-100%);
 }

 .carousel-fade .carousel-item {
   opacity: 0;
   transition-property: opacity;
   transform: none;
 }

 .carousel-fade .carousel-item.active,
 .carousel-fade .carousel-item-next.carousel-item-left,
 .carousel-fade .carousel-item-prev.carousel-item-right {
   z-index: 1;
   opacity: 1;
 }

 .carousel-fade .active.carousel-item-left,
 .carousel-fade .active.carousel-item-right {
   z-index: 0;
   opacity: 0;
   transition: opacity 0s 0.6s;
 }

 @media (prefers-reduced-motion: reduce) {

   .carousel-fade .active.carousel-item-left,
   .carousel-fade .active.carousel-item-right {
     transition: none;
   }
 }

 .carousel-control-prev,
 .carousel-control-next {
   position: absolute;
   top: 0;
   bottom: 0;
   z-index: 1;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 15%;
   color: #fff;
   text-align: center;
   opacity: 0.5;
   transition: opacity 0.15s ease;
 }

 @media (prefers-reduced-motion: reduce) {

   .carousel-control-prev,
   .carousel-control-next {
     transition: none;
   }
 }

 .carousel-control-prev:hover,
 .carousel-control-prev:focus,
 .carousel-control-next:hover,
 .carousel-control-next:focus {
   color: #fff;
   text-decoration: none;
   outline: 0;
   opacity: 0.9;
 }

 .carousel-control-prev {
   left: 0;
 }

 .carousel-control-next {
   right: 0;
 }

 .carousel-control-prev-icon,
 .carousel-control-next-icon {
   display: inline-block;
   width: 20px;
   height: 20px;
   background: no-repeat 50% / 100% 100%;
 }

 .carousel-control-prev-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
 }

 .carousel-control-next-icon {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
 }

 .carousel-indicators {
   position: absolute;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 15;
   display: flex;
   justify-content: center;
   padding-left: 0;
   margin-right: 15%;
   margin-left: 15%;
   list-style: none;
 }

 .carousel-indicators li {
   box-sizing: content-box;
   flex: 0 1 auto;
   width: 30px;
   height: 3px;
   margin-right: 3px;
   margin-left: 3px;
   text-indent: -999px;
   cursor: pointer;
   background-color: #fff;
   background-clip: padding-box;
   border-top: 10px solid transparent;
   border-bottom: 10px solid transparent;
   opacity: .5;
   transition: opacity 0.6s ease;
 }

 @media (prefers-reduced-motion: reduce) {
   .carousel-indicators li {
     transition: none;
   }
 }

 .carousel-indicators .active {
   opacity: 1;
 }

 .carousel-caption {
   position: absolute;
   right: 15%;
   bottom: 20px;
   left: 15%;
   z-index: 10;
   padding-top: 20px;
   padding-bottom: 20px;
   color: #fff;
   text-align: center;
 }

 @keyframes spinner-border {
   to {
     transform: rotate(360deg);
   }
 }

 .spinner-border {
   display: inline-block;
   width: 2rem;
   height: 2rem;
   vertical-align: text-bottom;
   border: 0.25em solid currentColor;
   border-right-color: transparent;
   border-radius: 50%;
   animation: spinner-border .75s linear infinite;
 }

 .spinner-border-sm {
   width: 1rem;
   height: 1rem;
   border-width: 0.2em;
 }

 @keyframes spinner-grow {
   0% {
     transform: scale(0);
   }

   50% {
     opacity: 1;
     transform: none;
   }
 }

 .spinner-grow {
   display: inline-block;
   width: 2rem;
   height: 2rem;
   vertical-align: text-bottom;
   background-color: currentColor;
   border-radius: 50%;
   opacity: 0;
   animation: spinner-grow .75s linear infinite;
 }

 .spinner-grow-sm {
   width: 1rem;
   height: 1rem;
 }

 .align-baseline {
   vertical-align: baseline !important;
 }

 .align-top {
   vertical-align: top !important;
 }

 .align-middle {
   vertical-align: middle !important;
 }

 .align-bottom {
   vertical-align: bottom !important;
 }

 .align-text-bottom {
   vertical-align: text-bottom !important;
 }

 .align-text-top {
   vertical-align: text-top !important;
 }

 .bg-primary {
   background-color: #213f99 !important;
 }

 a.bg-primary:hover,
 a.bg-primary:focus,
 button.bg-primary:hover,
 button.bg-primary:focus {
   background-color: #7a4815 !important;
 }

 .bg-secondary {
   background-color: #673BB7 !important;
 }

 a.bg-secondary:hover,
 a.bg-secondary:focus,
 button.bg-secondary:hover,
 button.bg-secondary:focus {
   background-color: #512f90 !important;
 }

 .bg-success {
   background-color: #10d876 !important;
 }

 a.bg-success:hover,
 a.bg-success:focus,
 button.bg-success:hover,
 button.bg-success:focus {
   background-color: #0ca95c !important;
 }

 .bg-info {
   background-color: #2754e6 !important;
 }

 a.bg-info:hover,
 a.bg-info:focus,
 button.bg-info:hover,
 button.bg-info:focus {
   background-color: #173fc3 !important;
 }

 .bg-warning {
   background-color: #FE9431 !important;
 }

 a.bg-warning:hover,
 a.bg-warning:focus,
 button.bg-warning:hover,
 button.bg-warning:focus {
   background-color: #fb7a01 !important;
 }

 .bg-danger {
   background-color: #E50202 !important;
 }

 a.bg-danger:hover,
 a.bg-danger:focus,
 button.bg-danger:hover,
 button.bg-danger:focus {
   background-color: #b20202 !important;
 }

 .bg-light {
   background-color: #faf8f2 !important;
 }

 a.bg-light:hover,
 a.bg-light:focus,
 button.bg-light:hover,
 button.bg-light:focus {
   background-color: #ece4cd !important;
 }

 .bg-dark {
   background-color: #262F5A !important;
 }

 a.bg-dark:hover,
 a.bg-dark:focus,
 button.bg-dark:hover,
 button.bg-dark:focus {
   background-color: #171c36 !important;
 }

 .bg-white {
   background-color: #fff !important;
 }

 .bg-transparent {
   background-color: transparent !important;
 }

 .border {
   border: 1px solid #E1E1F0 !important;
 }

 .border-top {
   border-top: 1px solid #E1E1F0 !important;
 }

 .border-right {
   border-right: 1px solid #E1E1F0 !important;
 }

 .border-bottom {
   border-bottom: 1px solid #E1E1F0 !important;
 }

 .border-left {
   border-left: 1px solid #E1E1F0 !important;
 }

 .border-0 {
   border: 0 !important;
 }

 .border-top-0 {
   border-top: 0 !important;
 }

 .border-right-0 {
   border-right: 0 !important;
 }

 .border-bottom-0 {
   border-bottom: 0 !important;
 }

 .border-left-0 {
   border-left: 0 !important;
 }

 .border-primary {
   border-color: #213f99 !important;
 }

 .border-secondary {
   border-color: #673BB7 !important;
 }

 .border-success {
   border-color: #10d876 !important;
 }

 .border-info {
   border-color: #2754e6 !important;
 }

 .border-warning {
   border-color: #FE9431 !important;
 }

 .border-danger {
   border-color: #E50202 !important;
 }

 .border-light {
   border-color: #faf8f2 !important;
 }

 .border-dark {
   border-color: #262F5A !important;
 }

 .border-white {
   border-color: #fff !important;
 }

 .rounded-sm {
   border-radius: 0.2rem !important;
 }

 .rounded {
   border-radius: 0.25rem !important;
 }

 .rounded-top {
   border-top-left-radius: 0.25rem !important;
   border-top-right-radius: 0.25rem !important;
 }

 .rounded-right {
   border-top-right-radius: 0.25rem !important;
   border-bottom-right-radius: 0.25rem !important;
 }

 .rounded-bottom {
   border-bottom-right-radius: 0.25rem !important;
   border-bottom-left-radius: 0.25rem !important;
 }

 .rounded-left {
   border-top-left-radius: 0.25rem !important;
   border-bottom-left-radius: 0.25rem !important;
 }

 .rounded-lg {
   border-radius: 0.3rem !important;
 }

 .rounded-circle {
   border-radius: 50% !important;
 }

 .rounded-pill {
   border-radius: 50rem !important;
 }

 .rounded-0 {
   border-radius: 0 !important;
 }

 .clearfix::after {
   display: block;
   clear: both;
   content: "";
 }

 .d-none {
   display: none !important;
 }

 .d-inline {
   display: inline !important;
 }

 .d-inline-block {
   display: inline-block !important;
 }

 .d-block {
   display: block !important;
 }

 .d-table {
   display: table !important;
 }

 .d-table-row {
   display: table-row !important;
 }

 .d-table-cell {
   display: table-cell !important;
 }

 .d-flex {
   display: flex !important;
 }

 .d-inline-flex {
   display: inline-flex !important;
 }

 @media (min-width: 576px) {
   .d-sm-none {
     display: none !important;
   }

   .d-sm-inline {
     display: inline !important;
   }

   .d-sm-inline-block {
     display: inline-block !important;
   }

   .d-sm-block {
     display: block !important;
   }

   .d-sm-table {
     display: table !important;
   }

   .d-sm-table-row {
     display: table-row !important;
   }

   .d-sm-table-cell {
     display: table-cell !important;
   }

   .d-sm-flex {
     display: flex !important;
   }

   .d-sm-inline-flex {
     display: inline-flex !important;
   }
 }

 @media (min-width: 768px) {
   .d-md-none {
     display: none !important;
   }

   .d-md-inline {
     display: inline !important;
   }

   .d-md-inline-block {
     display: inline-block !important;
   }

   .d-md-block {
     display: block !important;
   }

   .d-md-table {
     display: table !important;
   }

   .d-md-table-row {
     display: table-row !important;
   }

   .d-md-table-cell {
     display: table-cell !important;
   }

   .d-md-flex {
     display: flex !important;
   }

   .d-md-inline-flex {
     display: inline-flex !important;
   }
 }

 @media (min-width: 992px) {
   .d-lg-none {
     display: none !important;
   }

   .d-lg-inline {
     display: inline !important;
   }

   .d-lg-inline-block {
     display: inline-block !important;
   }

   .d-lg-block {
     display: block !important;
   }

   .d-lg-table {
     display: table !important;
   }

   .d-lg-table-row {
     display: table-row !important;
   }

   .d-lg-table-cell {
     display: table-cell !important;
   }

   .d-lg-flex {
     display: flex !important;
   }

   .d-lg-inline-flex {
     display: inline-flex !important;
   }
 }

 @media (min-width: 1200px) {
   .d-xl-none {
     display: none !important;
   }

   .d-xl-inline {
     display: inline !important;
   }

   .d-xl-inline-block {
     display: inline-block !important;
   }

   .d-xl-block {
     display: block !important;
   }

   .d-xl-table {
     display: table !important;
   }

   .d-xl-table-row {
     display: table-row !important;
   }

   .d-xl-table-cell {
     display: table-cell !important;
   }

   .d-xl-flex {
     display: flex !important;
   }

   .d-xl-inline-flex {
     display: inline-flex !important;
   }
 }

 @media (min-width: 1440) {
   .d-xxl-none {
     display: none !important;
   }

   .d-xxl-inline {
     display: inline !important;
   }

   .d-xxl-inline-block {
     display: inline-block !important;
   }

   .d-xxl-block {
     display: block !important;
   }

   .d-xxl-table {
     display: table !important;
   }

   .d-xxl-table-row {
     display: table-row !important;
   }

   .d-xxl-table-cell {
     display: table-cell !important;
   }

   .d-xxl-flex {
     display: flex !important;
   }

   .d-xxl-inline-flex {
     display: inline-flex !important;
   }
 }

 @media print {
   .d-print-none {
     display: none !important;
   }

   .d-print-inline {
     display: inline !important;
   }

   .d-print-inline-block {
     display: inline-block !important;
   }

   .d-print-block {
     display: block !important;
   }

   .d-print-table {
     display: table !important;
   }

   .d-print-table-row {
     display: table-row !important;
   }

   .d-print-table-cell {
     display: table-cell !important;
   }

   .d-print-flex {
     display: flex !important;
   }

   .d-print-inline-flex {
     display: inline-flex !important;
   }
 }

 .embed-responsive {
   position: relative;
   display: block;
   width: 100%;
   padding: 0;
   overflow: hidden;
 }

 .embed-responsive::before {
   display: block;
   content: "";
 }

 .embed-responsive .embed-responsive-item,
 .embed-responsive iframe,
 .embed-responsive embed,
 .embed-responsive object,
 .embed-responsive video {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border: 0;
 }

 .embed-responsive-21by9::before {
   padding-top: 42.8571428571%;
 }

 .embed-responsive-16by9::before {
   padding-top: 56.25%;
 }

 .embed-responsive-4by3::before {
   padding-top: 75%;
 }

 .embed-responsive-1by1::before {
   padding-top: 100%;
 }

 .flex-row {
   flex-direction: row !important;
 }

 .flex-column {
   flex-direction: column !important;
 }

 .flex-row-reverse {
   flex-direction: row-reverse !important;
 }

 .flex-column-reverse {
   flex-direction: column-reverse !important;
 }

 .flex-wrap {
   flex-wrap: wrap !important;
 }

 .flex-nowrap {
   flex-wrap: nowrap !important;
 }

 .flex-wrap-reverse {
   flex-wrap: wrap-reverse !important;
 }

 .flex-fill {
   flex: 1 1 auto !important;
 }

 .flex-grow-0 {
   flex-grow: 0 !important;
 }

 .flex-grow-1 {
   flex-grow: 1 !important;
 }

 .flex-shrink-0 {
   flex-shrink: 0 !important;
 }

 .flex-shrink-1 {
   flex-shrink: 1 !important;
 }

 .justify-content-start {
   justify-content: flex-start !important;
 }

 .justify-content-end {
   justify-content: flex-end !important;
 }

 .justify-content-center {
   justify-content: center !important;
 }

 .justify-content-between {
   justify-content: space-between !important;
 }

 .justify-content-around {
   justify-content: space-around !important;
 }

 .align-items-start {
   align-items: flex-start !important;
 }

 .align-items-end {
   align-items: flex-end !important;
 }

 .align-items-center {
   align-items: center !important;
 }

 .align-items-baseline {
   align-items: baseline !important;
 }

 .align-items-stretch {
   align-items: stretch !important;
 }

 .align-content-start {
   align-content: flex-start !important;
 }

 .align-content-end {
   align-content: flex-end !important;
 }

 .align-content-center {
   align-content: center !important;
 }

 .align-content-between {
   align-content: space-between !important;
 }

 .align-content-around {
   align-content: space-around !important;
 }

 .align-content-stretch {
   align-content: stretch !important;
 }

 .align-self-auto {
   align-self: auto !important;
 }

 .align-self-start {
   align-self: flex-start !important;
 }

 .align-self-end {
   align-self: flex-end !important;
 }

 .align-self-center {
   align-self: center !important;
 }

 .align-self-baseline {
   align-self: baseline !important;
 }

 .align-self-stretch {
   align-self: stretch !important;
 }

 @media (min-width: 576px) {
   .flex-sm-row {
     flex-direction: row !important;
   }

   .flex-sm-column {
     flex-direction: column !important;
   }

   .flex-sm-row-reverse {
     flex-direction: row-reverse !important;
   }

   .flex-sm-column-reverse {
     flex-direction: column-reverse !important;
   }

   .flex-sm-wrap {
     flex-wrap: wrap !important;
   }

   .flex-sm-nowrap {
     flex-wrap: nowrap !important;
   }

   .flex-sm-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }

   .flex-sm-fill {
     flex: 1 1 auto !important;
   }

   .flex-sm-grow-0 {
     flex-grow: 0 !important;
   }

   .flex-sm-grow-1 {
     flex-grow: 1 !important;
   }

   .flex-sm-shrink-0 {
     flex-shrink: 0 !important;
   }

   .flex-sm-shrink-1 {
     flex-shrink: 1 !important;
   }

   .justify-content-sm-start {
     justify-content: flex-start !important;
   }

   .justify-content-sm-end {
     justify-content: flex-end !important;
   }

   .justify-content-sm-center {
     justify-content: center !important;
   }

   .justify-content-sm-between {
     justify-content: space-between !important;
   }

   .justify-content-sm-around {
     justify-content: space-around !important;
   }

   .align-items-sm-start {
     align-items: flex-start !important;
   }

   .align-items-sm-end {
     align-items: flex-end !important;
   }

   .align-items-sm-center {
     align-items: center !important;
   }

   .align-items-sm-baseline {
     align-items: baseline !important;
   }

   .align-items-sm-stretch {
     align-items: stretch !important;
   }

   .align-content-sm-start {
     align-content: flex-start !important;
   }

   .align-content-sm-end {
     align-content: flex-end !important;
   }

   .align-content-sm-center {
     align-content: center !important;
   }

   .align-content-sm-between {
     align-content: space-between !important;
   }

   .align-content-sm-around {
     align-content: space-around !important;
   }

   .align-content-sm-stretch {
     align-content: stretch !important;
   }

   .align-self-sm-auto {
     align-self: auto !important;
   }

   .align-self-sm-start {
     align-self: flex-start !important;
   }

   .align-self-sm-end {
     align-self: flex-end !important;
   }

   .align-self-sm-center {
     align-self: center !important;
   }

   .align-self-sm-baseline {
     align-self: baseline !important;
   }

   .align-self-sm-stretch {
     align-self: stretch !important;
   }
 }

 @media (min-width: 768px) {
   .flex-md-row {
     flex-direction: row !important;
   }

   .flex-md-column {
     flex-direction: column !important;
   }

   .flex-md-row-reverse {
     flex-direction: row-reverse !important;
   }

   .flex-md-column-reverse {
     flex-direction: column-reverse !important;
   }

   .flex-md-wrap {
     flex-wrap: wrap !important;
   }

   .flex-md-nowrap {
     flex-wrap: nowrap !important;
   }

   .flex-md-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }

   .flex-md-fill {
     flex: 1 1 auto !important;
   }

   .flex-md-grow-0 {
     flex-grow: 0 !important;
   }

   .flex-md-grow-1 {
     flex-grow: 1 !important;
   }

   .flex-md-shrink-0 {
     flex-shrink: 0 !important;
   }

   .flex-md-shrink-1 {
     flex-shrink: 1 !important;
   }

   .justify-content-md-start {
     justify-content: flex-start !important;
   }

   .justify-content-md-end {
     justify-content: flex-end !important;
   }

   .justify-content-md-center {
     justify-content: center !important;
   }

   .justify-content-md-between {
     justify-content: space-between !important;
   }

   .justify-content-md-around {
     justify-content: space-around !important;
   }

   .align-items-md-start {
     align-items: flex-start !important;
   }

   .align-items-md-end {
     align-items: flex-end !important;
   }

   .align-items-md-center {
     align-items: center !important;
   }

   .align-items-md-baseline {
     align-items: baseline !important;
   }

   .align-items-md-stretch {
     align-items: stretch !important;
   }

   .align-content-md-start {
     align-content: flex-start !important;
   }

   .align-content-md-end {
     align-content: flex-end !important;
   }

   .align-content-md-center {
     align-content: center !important;
   }

   .align-content-md-between {
     align-content: space-between !important;
   }

   .align-content-md-around {
     align-content: space-around !important;
   }

   .align-content-md-stretch {
     align-content: stretch !important;
   }

   .align-self-md-auto {
     align-self: auto !important;
   }

   .align-self-md-start {
     align-self: flex-start !important;
   }

   .align-self-md-end {
     align-self: flex-end !important;
   }

   .align-self-md-center {
     align-self: center !important;
   }

   .align-self-md-baseline {
     align-self: baseline !important;
   }

   .align-self-md-stretch {
     align-self: stretch !important;
   }
 }

 @media (min-width: 992px) {
   .flex-lg-row {
     flex-direction: row !important;
   }

   .flex-lg-column {
     flex-direction: column !important;
   }

   .flex-lg-row-reverse {
     flex-direction: row-reverse !important;
   }

   .flex-lg-column-reverse {
     flex-direction: column-reverse !important;
   }

   .flex-lg-wrap {
     flex-wrap: wrap !important;
   }

   .flex-lg-nowrap {
     flex-wrap: nowrap !important;
   }

   .flex-lg-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }

   .flex-lg-fill {
     flex: 1 1 auto !important;
   }

   .flex-lg-grow-0 {
     flex-grow: 0 !important;
   }

   .flex-lg-grow-1 {
     flex-grow: 1 !important;
   }

   .flex-lg-shrink-0 {
     flex-shrink: 0 !important;
   }

   .flex-lg-shrink-1 {
     flex-shrink: 1 !important;
   }

   .justify-content-lg-start {
     justify-content: flex-start !important;
   }

   .justify-content-lg-end {
     justify-content: flex-end !important;
   }

   .justify-content-lg-center {
     justify-content: center !important;
   }

   .justify-content-lg-between {
     justify-content: space-between !important;
   }

   .justify-content-lg-around {
     justify-content: space-around !important;
   }

   .align-items-lg-start {
     align-items: flex-start !important;
   }

   .align-items-lg-end {
     align-items: flex-end !important;
   }

   .align-items-lg-center {
     align-items: center !important;
   }

   .align-items-lg-baseline {
     align-items: baseline !important;
   }

   .align-items-lg-stretch {
     align-items: stretch !important;
   }

   .align-content-lg-start {
     align-content: flex-start !important;
   }

   .align-content-lg-end {
     align-content: flex-end !important;
   }

   .align-content-lg-center {
     align-content: center !important;
   }

   .align-content-lg-between {
     align-content: space-between !important;
   }

   .align-content-lg-around {
     align-content: space-around !important;
   }

   .align-content-lg-stretch {
     align-content: stretch !important;
   }

   .align-self-lg-auto {
     align-self: auto !important;
   }

   .align-self-lg-start {
     align-self: flex-start !important;
   }

   .align-self-lg-end {
     align-self: flex-end !important;
   }

   .align-self-lg-center {
     align-self: center !important;
   }

   .align-self-lg-baseline {
     align-self: baseline !important;
   }

   .align-self-lg-stretch {
     align-self: stretch !important;
   }
 }

 @media (min-width: 1200px) {
   .flex-xl-row {
     flex-direction: row !important;
   }

   .flex-xl-column {
     flex-direction: column !important;
   }

   .flex-xl-row-reverse {
     flex-direction: row-reverse !important;
   }

   .flex-xl-column-reverse {
     flex-direction: column-reverse !important;
   }

   .flex-xl-wrap {
     flex-wrap: wrap !important;
   }

   .flex-xl-nowrap {
     flex-wrap: nowrap !important;
   }

   .flex-xl-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }

   .flex-xl-fill {
     flex: 1 1 auto !important;
   }

   .flex-xl-grow-0 {
     flex-grow: 0 !important;
   }

   .flex-xl-grow-1 {
     flex-grow: 1 !important;
   }

   .flex-xl-shrink-0 {
     flex-shrink: 0 !important;
   }

   .flex-xl-shrink-1 {
     flex-shrink: 1 !important;
   }

   .justify-content-xl-start {
     justify-content: flex-start !important;
   }

   .justify-content-xl-end {
     justify-content: flex-end !important;
   }

   .justify-content-xl-center {
     justify-content: center !important;
   }

   .justify-content-xl-between {
     justify-content: space-between !important;
   }

   .justify-content-xl-around {
     justify-content: space-around !important;
   }

   .align-items-xl-start {
     align-items: flex-start !important;
   }

   .align-items-xl-end {
     align-items: flex-end !important;
   }

   .align-items-xl-center {
     align-items: center !important;
   }

   .align-items-xl-baseline {
     align-items: baseline !important;
   }

   .align-items-xl-stretch {
     align-items: stretch !important;
   }

   .align-content-xl-start {
     align-content: flex-start !important;
   }

   .align-content-xl-end {
     align-content: flex-end !important;
   }

   .align-content-xl-center {
     align-content: center !important;
   }

   .align-content-xl-between {
     align-content: space-between !important;
   }

   .align-content-xl-around {
     align-content: space-around !important;
   }

   .align-content-xl-stretch {
     align-content: stretch !important;
   }

   .align-self-xl-auto {
     align-self: auto !important;
   }

   .align-self-xl-start {
     align-self: flex-start !important;
   }

   .align-self-xl-end {
     align-self: flex-end !important;
   }

   .align-self-xl-center {
     align-self: center !important;
   }

   .align-self-xl-baseline {
     align-self: baseline !important;
   }

   .align-self-xl-stretch {
     align-self: stretch !important;
   }
 }

 @media (min-width: 1440) {
   .flex-xxl-row {
     flex-direction: row !important;
   }

   .flex-xxl-column {
     flex-direction: column !important;
   }

   .flex-xxl-row-reverse {
     flex-direction: row-reverse !important;
   }

   .flex-xxl-column-reverse {
     flex-direction: column-reverse !important;
   }

   .flex-xxl-wrap {
     flex-wrap: wrap !important;
   }

   .flex-xxl-nowrap {
     flex-wrap: nowrap !important;
   }

   .flex-xxl-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }

   .flex-xxl-fill {
     flex: 1 1 auto !important;
   }

   .flex-xxl-grow-0 {
     flex-grow: 0 !important;
   }

   .flex-xxl-grow-1 {
     flex-grow: 1 !important;
   }

   .flex-xxl-shrink-0 {
     flex-shrink: 0 !important;
   }

   .flex-xxl-shrink-1 {
     flex-shrink: 1 !important;
   }

   .justify-content-xxl-start {
     justify-content: flex-start !important;
   }

   .justify-content-xxl-end {
     justify-content: flex-end !important;
   }

   .justify-content-xxl-center {
     justify-content: center !important;
   }

   .justify-content-xxl-between {
     justify-content: space-between !important;
   }

   .justify-content-xxl-around {
     justify-content: space-around !important;
   }

   .align-items-xxl-start {
     align-items: flex-start !important;
   }

   .align-items-xxl-end {
     align-items: flex-end !important;
   }

   .align-items-xxl-center {
     align-items: center !important;
   }

   .align-items-xxl-baseline {
     align-items: baseline !important;
   }

   .align-items-xxl-stretch {
     align-items: stretch !important;
   }

   .align-content-xxl-start {
     align-content: flex-start !important;
   }

   .align-content-xxl-end {
     align-content: flex-end !important;
   }

   .align-content-xxl-center {
     align-content: center !important;
   }

   .align-content-xxl-between {
     align-content: space-between !important;
   }

   .align-content-xxl-around {
     align-content: space-around !important;
   }

   .align-content-xxl-stretch {
     align-content: stretch !important;
   }

   .align-self-xxl-auto {
     align-self: auto !important;
   }

   .align-self-xxl-start {
     align-self: flex-start !important;
   }

   .align-self-xxl-end {
     align-self: flex-end !important;
   }

   .align-self-xxl-center {
     align-self: center !important;
   }

   .align-self-xxl-baseline {
     align-self: baseline !important;
   }

   .align-self-xxl-stretch {
     align-self: stretch !important;
   }
 }

 .float-left {
   float: left !important;
 }

 .float-right {
   float: right !important;
 }

 .float-none {
   float: none !important;
 }

 @media (min-width: 576px) {
   .float-sm-left {
     float: left !important;
   }

   .float-sm-right {
     float: right !important;
   }

   .float-sm-none {
     float: none !important;
   }
 }

 @media (min-width: 768px) {
   .float-md-left {
     float: left !important;
   }

   .float-md-right {
     float: right !important;
   }

   .float-md-none {
     float: none !important;
   }
 }

 @media (min-width: 992px) {
   .float-lg-left {
     float: left !important;
   }

   .float-lg-right {
     float: right !important;
   }

   .float-lg-none {
     float: none !important;
   }
 }

 @media (min-width: 1200px) {
   .float-xl-left {
     float: left !important;
   }

   .float-xl-right {
     float: right !important;
   }

   .float-xl-none {
     float: none !important;
   }
 }

 @media (min-width: 1440) {
   .float-xxl-left {
     float: left !important;
   }

   .float-xxl-right {
     float: right !important;
   }

   .float-xxl-none {
     float: none !important;
   }
 }

 .user-select-all {
   user-select: all !important;
 }

 .user-select-auto {
   user-select: auto !important;
 }

 .user-select-none {
   user-select: none !important;
 }

 .overflow-auto {
   overflow: auto !important;
 }

 .overflow-hidden {
   overflow: hidden !important;
 }

 .position-static {
   position: static !important;
 }

 .position-relative {
   position: relative !important;
 }

 .position-absolute {
   position: absolute !important;
 }

 .position-fixed {
   position: fixed !important;
 }

 .position-sticky {
   position: sticky !important;
 }

 .fixed-top {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   z-index: 1030;
 }

 .fixed-bottom {
   position: fixed;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1030;
 }

 @supports (position: sticky) {
   .sticky-top {
     position: sticky;
     top: 0;
     z-index: 1020;
   }
 }

 .sr-only {
   position: absolute;
   width: 1px;
   height: 1px;
   padding: 0;
   margin: -1px;
   overflow: hidden;
   clip: rect(0, 0, 0, 0);
   white-space: nowrap;
   border: 0;
 }

 .sr-only-focusable:active,
 .sr-only-focusable:focus {
   position: static;
   width: auto;
   height: auto;
   overflow: visible;
   clip: auto;
   white-space: normal;
 }

 .shadow-sm {
   box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
 }

 .shadow {
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
 }

 .shadow-lg {
   box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
 }

 .shadow-none {
   box-shadow: none !important;
 }

 .w-25 {
   width: 25% !important;
 }

 .w-50 {
   width: 50% !important;
 }

 .w-75 {
   width: 75% !important;
 }

 .w-100 {
   width: 100% !important;
 }

 .w-auto {
   width: auto !important;
 }

 .h-25 {
   height: 25% !important;
 }

 .h-50 {
   height: 50% !important;
 }

 .h-75 {
   height: 75% !important;
 }

 .h-100 {
   height: 100% !important;
 }

 .h-auto {
   height: auto !important;
 }

 .mw-100 {
   max-width: 100% !important;
 }

 .mh-100 {
   max-height: 100% !important;
 }

 .min-vw-100 {
   min-width: 100vw !important;
 }

 .min-vh-100 {
   min-height: 100vh !important;
 }

 .vw-100 {
   width: 100vw !important;
 }

 .vh-100 {
   height: 100vh !important;
 }

 .m-0 {
   margin: 0 !important;
 }

 .mt-0,
 .my-0 {
   margin-top: 0 !important;
 }

 .mr-0,
 .mx-0 {
   margin-right: 0 !important;
 }

 .mb-0,
 .my-0 {
   margin-bottom: 0 !important;
 }

 .ml-0,
 .mx-0 {
   margin-left: 0 !important;
 }

 .m-1 {
   margin: 0.25rem !important;
 }

 .mt-1,
 .my-1 {
   margin-top: 0.25rem !important;
 }

 .mr-1,
 .mx-1 {
   margin-right: 0.25rem !important;
 }

 .mb-1,
 .my-1 {
   margin-bottom: 0.25rem !important;
 }

 .ml-1,
 .mx-1 {
   margin-left: 0.25rem !important;
 }

 .m-2 {
   margin: 0.5rem !important;
 }

 .mt-2,
 .my-2 {
   margin-top: 0.5rem !important;
 }

 .mr-2,
 .mx-2 {
   margin-right: 0.5rem !important;
 }

 .mb-2,
 .my-2 {
   margin-bottom: 0.5rem !important;
 }

 .ml-2,
 .mx-2 {
   margin-left: 0.5rem !important;
 }

 .m-3 {
   margin: 1rem !important;
 }

 .mt-3,
 .my-3 {
   margin-top: 1rem !important;
 }

 .mr-3,
 .mx-3 {
   margin-right: 1rem !important;
 }

 .mb-3,
 .my-3 {
   margin-bottom: 1rem !important;
 }

 .ml-3,
 .mx-3 {
   margin-left: 1rem !important;
 }

 .m-4 {
   margin: 1.5rem !important;
 }

 .mt-4,
 .my-4 {
   margin-top: 1.5rem !important;
 }

 .mr-4,
 .mx-4 {
   margin-right: 1.5rem !important;
 }

 .mb-4,
 .my-4 {
   margin-bottom: 1.5rem !important;
 }

 .ml-4,
 .mx-4 {
   margin-left: 1.5rem !important;
 }

 .m-5 {
   margin: 3rem !important;
 }

 .mt-5,
 .my-5 {
   margin-top: 3rem !important;
 }

 .mr-5,
 .mx-5 {
   margin-right: 3rem !important;
 }

 .mb-5,
 .my-5 {
   margin-bottom: 3rem !important;
 }

 .ml-5,
 .mx-5 {
   margin-left: 3rem !important;
 }

 .p-0 {
   padding: 0 !important;
 }

 .pt-0,
 .py-0 {
   padding-top: 0 !important;
 }

 .pr-0,
 .px-0 {
   padding-right: 0 !important;
 }

 .pb-0,
 .py-0 {
   padding-bottom: 0 !important;
 }

 .pl-0,
 .px-0 {
   padding-left: 0 !important;
 }

 .p-1 {
   padding: 0.25rem !important;
 }

 .pt-1,
 .py-1 {
   padding-top: 0.25rem !important;
 }

 .pr-1,
 .px-1 {
   padding-right: 0.25rem !important;
 }

 .pb-1,
 .py-1 {
   padding-bottom: 0.25rem !important;
 }

 .pl-1,
 .px-1 {
   padding-left: 0.25rem !important;
 }

 .p-2 {
   padding: 0.5rem !important;
 }

 .pt-2,
 .py-2 {
   padding-top: 0.5rem !important;
 }

 .pr-2,
 .px-2 {
   padding-right: 0.5rem !important;
 }

 .pb-2,
 .py-2 {
   padding-bottom: 0.5rem !important;
 }

 .pl-2,
 .px-2 {
   padding-left: 0.5rem !important;
 }

 .p-3 {
   padding: 1rem !important;
 }

 .pt-3,
 .py-3 {
   padding-top: 1rem !important;
 }

 .pr-3,
 .px-3 {
   padding-right: 1rem !important;
 }

 .pb-3,
 .py-3 {
   padding-bottom: 1rem !important;
 }

 .pl-3,
 .px-3 {
   padding-left: 1rem !important;
 }

 .p-4 {
   padding: 1.5rem !important;
 }

 .pt-4,
 .py-4 {
   padding-top: 1.5rem !important;
 }

 .pr-4,
 .px-4 {
   padding-right: 1.5rem !important;
 }

 .pb-4,
 .py-4 {
   padding-bottom: 1.5rem !important;
 }

 .pl-4,
 .px-4 {
   padding-left: 1.5rem !important;
 }

 .p-5 {
   padding: 3rem !important;
 }

 .pt-5,
 .py-5 {
   padding-top: 3rem !important;
 }

 .pr-5,
 .px-5 {
   padding-right: 3rem !important;
 }

 .pb-5,
 .py-5 {
   padding-bottom: 3rem !important;
 }

 .pl-5,
 .px-5 {
   padding-left: 3rem !important;
 }

 .m-n1 {
   margin: -0.25rem !important;
 }

 .mt-n1,
 .my-n1 {
   margin-top: -0.25rem !important;
 }

 .mr-n1,
 .mx-n1 {
   margin-right: -0.25rem !important;
 }

 .mb-n1,
 .my-n1 {
   margin-bottom: -0.25rem !important;
 }

 .ml-n1,
 .mx-n1 {
   margin-left: -0.25rem !important;
 }

 .m-n2 {
   margin: -0.5rem !important;
 }

 .mt-n2,
 .my-n2 {
   margin-top: -0.5rem !important;
 }

 .mr-n2,
 .mx-n2 {
   margin-right: -0.5rem !important;
 }

 .mb-n2,
 .my-n2 {
   margin-bottom: -0.5rem !important;
 }

 .ml-n2,
 .mx-n2 {
   margin-left: -0.5rem !important;
 }

 .m-n3 {
   margin: -1rem !important;
 }

 .mt-n3,
 .my-n3 {
   margin-top: -1rem !important;
 }

 .mr-n3,
 .mx-n3 {
   margin-right: -1rem !important;
 }

 .mb-n3,
 .my-n3 {
   margin-bottom: -1rem !important;
 }

 .ml-n3,
 .mx-n3 {
   margin-left: -1rem !important;
 }

 .m-n4 {
   margin: -1.5rem !important;
 }

 .mt-n4,
 .my-n4 {
   margin-top: -1.5rem !important;
 }

 .mr-n4,
 .mx-n4 {
   margin-right: -1.5rem !important;
 }

 .mb-n4,
 .my-n4 {
   margin-bottom: -1.5rem !important;
 }

 .ml-n4,
 .mx-n4 {
   margin-left: -1.5rem !important;
 }

 .m-n5 {
   margin: -3rem !important;
 }

 .mt-n5,
 .my-n5 {
   margin-top: -3rem !important;
 }

 .mr-n5,
 .mx-n5 {
   margin-right: -3rem !important;
 }

 .mb-n5,
 .my-n5 {
   margin-bottom: -3rem !important;
 }

 .ml-n5,
 .mx-n5 {
   margin-left: -3rem !important;
 }

 .m-auto {
   margin: auto !important;
 }

 .mt-auto,
 .my-auto {
   margin-top: auto !important;
 }

 .mr-auto,
 .mx-auto {
   margin-right: auto !important;
 }

 .mb-auto,
 .my-auto {
   margin-bottom: auto !important;
 }

 .ml-auto,
 .mx-auto {
   margin-left: auto !important;
 }

 @media (min-width: 576px) {
   .m-sm-0 {
     margin: 0 !important;
   }

   .mt-sm-0,
   .my-sm-0 {
     margin-top: 0 !important;
   }

   .mr-sm-0,
   .mx-sm-0 {
     margin-right: 0 !important;
   }

   .mb-sm-0,
   .my-sm-0 {
     margin-bottom: 0 !important;
   }

   .ml-sm-0,
   .mx-sm-0 {
     margin-left: 0 !important;
   }

   .m-sm-1 {
     margin: 0.25rem !important;
   }

   .mt-sm-1,
   .my-sm-1 {
     margin-top: 0.25rem !important;
   }

   .mr-sm-1,
   .mx-sm-1 {
     margin-right: 0.25rem !important;
   }

   .mb-sm-1,
   .my-sm-1 {
     margin-bottom: 0.25rem !important;
   }

   .ml-sm-1,
   .mx-sm-1 {
     margin-left: 0.25rem !important;
   }

   .m-sm-2 {
     margin: 0.5rem !important;
   }

   .mt-sm-2,
   .my-sm-2 {
     margin-top: 0.5rem !important;
   }

   .mr-sm-2,
   .mx-sm-2 {
     margin-right: 0.5rem !important;
   }

   .mb-sm-2,
   .my-sm-2 {
     margin-bottom: 0.5rem !important;
   }

   .ml-sm-2,
   .mx-sm-2 {
     margin-left: 0.5rem !important;
   }

   .m-sm-3 {
     margin: 1rem !important;
   }

   .mt-sm-3,
   .my-sm-3 {
     margin-top: 1rem !important;
   }

   .mr-sm-3,
   .mx-sm-3 {
     margin-right: 1rem !important;
   }

   .mb-sm-3,
   .my-sm-3 {
     margin-bottom: 1rem !important;
   }

   .ml-sm-3,
   .mx-sm-3 {
     margin-left: 1rem !important;
   }

   .m-sm-4 {
     margin: 1.5rem !important;
   }

   .mt-sm-4,
   .my-sm-4 {
     margin-top: 1.5rem !important;
   }

   .mr-sm-4,
   .mx-sm-4 {
     margin-right: 1.5rem !important;
   }

   .mb-sm-4,
   .my-sm-4 {
     margin-bottom: 1.5rem !important;
   }

   .ml-sm-4,
   .mx-sm-4 {
     margin-left: 1.5rem !important;
   }

   .m-sm-5 {
     margin: 3rem !important;
   }

   .mt-sm-5,
   .my-sm-5 {
     margin-top: 3rem !important;
   }

   .mr-sm-5,
   .mx-sm-5 {
     margin-right: 3rem !important;
   }

   .mb-sm-5,
   .my-sm-5 {
     margin-bottom: 3rem !important;
   }

   .ml-sm-5,
   .mx-sm-5 {
     margin-left: 3rem !important;
   }

   .p-sm-0 {
     padding: 0 !important;
   }

   .pt-sm-0,
   .py-sm-0 {
     padding-top: 0 !important;
   }

   .pr-sm-0,
   .px-sm-0 {
     padding-right: 0 !important;
   }

   .pb-sm-0,
   .py-sm-0 {
     padding-bottom: 0 !important;
   }

   .pl-sm-0,
   .px-sm-0 {
     padding-left: 0 !important;
   }

   .p-sm-1 {
     padding: 0.25rem !important;
   }

   .pt-sm-1,
   .py-sm-1 {
     padding-top: 0.25rem !important;
   }

   .pr-sm-1,
   .px-sm-1 {
     padding-right: 0.25rem !important;
   }

   .pb-sm-1,
   .py-sm-1 {
     padding-bottom: 0.25rem !important;
   }

   .pl-sm-1,
   .px-sm-1 {
     padding-left: 0.25rem !important;
   }

   .p-sm-2 {
     padding: 0.5rem !important;
   }

   .pt-sm-2,
   .py-sm-2 {
     padding-top: 0.5rem !important;
   }

   .pr-sm-2,
   .px-sm-2 {
     padding-right: 0.5rem !important;
   }

   .pb-sm-2,
   .py-sm-2 {
     padding-bottom: 0.5rem !important;
   }

   .pl-sm-2,
   .px-sm-2 {
     padding-left: 0.5rem !important;
   }

   .p-sm-3 {
     padding: 1rem !important;
   }

   .pt-sm-3,
   .py-sm-3 {
     padding-top: 1rem !important;
   }

   .pr-sm-3,
   .px-sm-3 {
     padding-right: 1rem !important;
   }

   .pb-sm-3,
   .py-sm-3 {
     padding-bottom: 1rem !important;
   }

   .pl-sm-3,
   .px-sm-3 {
     padding-left: 1rem !important;
   }

   .p-sm-4 {
     padding: 1.5rem !important;
   }

   .pt-sm-4,
   .py-sm-4 {
     padding-top: 1.5rem !important;
   }

   .pr-sm-4,
   .px-sm-4 {
     padding-right: 1.5rem !important;
   }

   .pb-sm-4,
   .py-sm-4 {
     padding-bottom: 1.5rem !important;
   }

   .pl-sm-4,
   .px-sm-4 {
     padding-left: 1.5rem !important;
   }

   .p-sm-5 {
     padding: 3rem !important;
   }

   .pt-sm-5,
   .py-sm-5 {
     padding-top: 3rem !important;
   }

   .pr-sm-5,
   .px-sm-5 {
     padding-right: 3rem !important;
   }

   .pb-sm-5,
   .py-sm-5 {
     padding-bottom: 3rem !important;
   }

   .pl-sm-5,
   .px-sm-5 {
     padding-left: 3rem !important;
   }

   .m-sm-n1 {
     margin: -0.25rem !important;
   }

   .mt-sm-n1,
   .my-sm-n1 {
     margin-top: -0.25rem !important;
   }

   .mr-sm-n1,
   .mx-sm-n1 {
     margin-right: -0.25rem !important;
   }

   .mb-sm-n1,
   .my-sm-n1 {
     margin-bottom: -0.25rem !important;
   }

   .ml-sm-n1,
   .mx-sm-n1 {
     margin-left: -0.25rem !important;
   }

   .m-sm-n2 {
     margin: -0.5rem !important;
   }

   .mt-sm-n2,
   .my-sm-n2 {
     margin-top: -0.5rem !important;
   }

   .mr-sm-n2,
   .mx-sm-n2 {
     margin-right: -0.5rem !important;
   }

   .mb-sm-n2,
   .my-sm-n2 {
     margin-bottom: -0.5rem !important;
   }

   .ml-sm-n2,
   .mx-sm-n2 {
     margin-left: -0.5rem !important;
   }

   .m-sm-n3 {
     margin: -1rem !important;
   }

   .mt-sm-n3,
   .my-sm-n3 {
     margin-top: -1rem !important;
   }

   .mr-sm-n3,
   .mx-sm-n3 {
     margin-right: -1rem !important;
   }

   .mb-sm-n3,
   .my-sm-n3 {
     margin-bottom: -1rem !important;
   }

   .ml-sm-n3,
   .mx-sm-n3 {
     margin-left: -1rem !important;
   }

   .m-sm-n4 {
     margin: -1.5rem !important;
   }

   .mt-sm-n4,
   .my-sm-n4 {
     margin-top: -1.5rem !important;
   }

   .mr-sm-n4,
   .mx-sm-n4 {
     margin-right: -1.5rem !important;
   }

   .mb-sm-n4,
   .my-sm-n4 {
     margin-bottom: -1.5rem !important;
   }

   .ml-sm-n4,
   .mx-sm-n4 {
     margin-left: -1.5rem !important;
   }

   .m-sm-n5 {
     margin: -3rem !important;
   }

   .mt-sm-n5,
   .my-sm-n5 {
     margin-top: -3rem !important;
   }

   .mr-sm-n5,
   .mx-sm-n5 {
     margin-right: -3rem !important;
   }

   .mb-sm-n5,
   .my-sm-n5 {
     margin-bottom: -3rem !important;
   }

   .ml-sm-n5,
   .mx-sm-n5 {
     margin-left: -3rem !important;
   }

   .m-sm-auto {
     margin: auto !important;
   }

   .mt-sm-auto,
   .my-sm-auto {
     margin-top: auto !important;
   }

   .mr-sm-auto,
   .mx-sm-auto {
     margin-right: auto !important;
   }

   .mb-sm-auto,
   .my-sm-auto {
     margin-bottom: auto !important;
   }

   .ml-sm-auto,
   .mx-sm-auto {
     margin-left: auto !important;
   }
 }

 @media (min-width: 768px) {
   .m-md-0 {
     margin: 0 !important;
   }

   .mt-md-0,
   .my-md-0 {
     margin-top: 0 !important;
   }

   .mr-md-0,
   .mx-md-0 {
     margin-right: 0 !important;
   }

   .mb-md-0,
   .my-md-0 {
     margin-bottom: 0 !important;
   }

   .ml-md-0,
   .mx-md-0 {
     margin-left: 0 !important;
   }

   .m-md-1 {
     margin: 0.25rem !important;
   }

   .mt-md-1,
   .my-md-1 {
     margin-top: 0.25rem !important;
   }

   .mr-md-1,
   .mx-md-1 {
     margin-right: 0.25rem !important;
   }

   .mb-md-1,
   .my-md-1 {
     margin-bottom: 0.25rem !important;
   }

   .ml-md-1,
   .mx-md-1 {
     margin-left: 0.25rem !important;
   }

   .m-md-2 {
     margin: 0.5rem !important;
   }

   .mt-md-2,
   .my-md-2 {
     margin-top: 0.5rem !important;
   }

   .mr-md-2,
   .mx-md-2 {
     margin-right: 0.5rem !important;
   }

   .mb-md-2,
   .my-md-2 {
     margin-bottom: 0.5rem !important;
   }

   .ml-md-2,
   .mx-md-2 {
     margin-left: 0.5rem !important;
   }

   .m-md-3 {
     margin: 1rem !important;
   }

   .mt-md-3,
   .my-md-3 {
     margin-top: 1rem !important;
   }

   .mr-md-3,
   .mx-md-3 {
     margin-right: 1rem !important;
   }

   .mb-md-3,
   .my-md-3 {
     margin-bottom: 1rem !important;
   }

   .ml-md-3,
   .mx-md-3 {
     margin-left: 1rem !important;
   }

   .m-md-4 {
     margin: 1.5rem !important;
   }

   .mt-md-4,
   .my-md-4 {
     margin-top: 1.5rem !important;
   }

   .mr-md-4,
   .mx-md-4 {
     margin-right: 1.5rem !important;
   }

   .mb-md-4,
   .my-md-4 {
     margin-bottom: 1.5rem !important;
   }

   .ml-md-4,
   .mx-md-4 {
     margin-left: 1.5rem !important;
   }

   .m-md-5 {
     margin: 3rem !important;
   }

   .mt-md-5,
   .my-md-5 {
     margin-top: 3rem !important;
   }

   .mr-md-5,
   .mx-md-5 {
     margin-right: 3rem !important;
   }

   .mb-md-5,
   .my-md-5 {
     margin-bottom: 3rem !important;
   }

   .ml-md-5,
   .mx-md-5 {
     margin-left: 3rem !important;
   }

   .p-md-0 {
     padding: 0 !important;
   }

   .pt-md-0,
   .py-md-0 {
     padding-top: 0 !important;
   }

   .pr-md-0,
   .px-md-0 {
     padding-right: 0 !important;
   }

   .pb-md-0,
   .py-md-0 {
     padding-bottom: 0 !important;
   }

   .pl-md-0,
   .px-md-0 {
     padding-left: 0 !important;
   }

   .p-md-1 {
     padding: 0.25rem !important;
   }

   .pt-md-1,
   .py-md-1 {
     padding-top: 0.25rem !important;
   }

   .pr-md-1,
   .px-md-1 {
     padding-right: 0.25rem !important;
   }

   .pb-md-1,
   .py-md-1 {
     padding-bottom: 0.25rem !important;
   }

   .pl-md-1,
   .px-md-1 {
     padding-left: 0.25rem !important;
   }

   .p-md-2 {
     padding: 0.5rem !important;
   }

   .pt-md-2,
   .py-md-2 {
     padding-top: 0.5rem !important;
   }

   .pr-md-2,
   .px-md-2 {
     padding-right: 0.5rem !important;
   }

   .pb-md-2,
   .py-md-2 {
     padding-bottom: 0.5rem !important;
   }

   .pl-md-2,
   .px-md-2 {
     padding-left: 0.5rem !important;
   }

   .p-md-3 {
     padding: 1rem !important;
   }

   .pt-md-3,
   .py-md-3 {
     padding-top: 1rem !important;
   }

   .pr-md-3,
   .px-md-3 {
     padding-right: 1rem !important;
   }

   .pb-md-3,
   .py-md-3 {
     padding-bottom: 1rem !important;
   }

   .pl-md-3,
   .px-md-3 {
     padding-left: 1rem !important;
   }

   .p-md-4 {
     padding: 1.5rem !important;
   }

   .pt-md-4,
   .py-md-4 {
     padding-top: 1.5rem !important;
   }

   .pr-md-4,
   .px-md-4 {
     padding-right: 1.5rem !important;
   }

   .pb-md-4,
   .py-md-4 {
     padding-bottom: 1.5rem !important;
   }

   .pl-md-4,
   .px-md-4 {
     padding-left: 1.5rem !important;
   }

   .p-md-5 {
     padding: 3rem !important;
   }

   .pt-md-5,
   .py-md-5 {
     padding-top: 3rem !important;
   }

   .pr-md-5,
   .px-md-5 {
     padding-right: 3rem !important;
   }

   .pb-md-5,
   .py-md-5 {
     padding-bottom: 3rem !important;
   }

   .pl-md-5,
   .px-md-5 {
     padding-left: 3rem !important;
   }

   .m-md-n1 {
     margin: -0.25rem !important;
   }

   .mt-md-n1,
   .my-md-n1 {
     margin-top: -0.25rem !important;
   }

   .mr-md-n1,
   .mx-md-n1 {
     margin-right: -0.25rem !important;
   }

   .mb-md-n1,
   .my-md-n1 {
     margin-bottom: -0.25rem !important;
   }

   .ml-md-n1,
   .mx-md-n1 {
     margin-left: -0.25rem !important;
   }

   .m-md-n2 {
     margin: -0.5rem !important;
   }

   .mt-md-n2,
   .my-md-n2 {
     margin-top: -0.5rem !important;
   }

   .mr-md-n2,
   .mx-md-n2 {
     margin-right: -0.5rem !important;
   }

   .mb-md-n2,
   .my-md-n2 {
     margin-bottom: -0.5rem !important;
   }

   .ml-md-n2,
   .mx-md-n2 {
     margin-left: -0.5rem !important;
   }

   .m-md-n3 {
     margin: -1rem !important;
   }

   .mt-md-n3,
   .my-md-n3 {
     margin-top: -1rem !important;
   }

   .mr-md-n3,
   .mx-md-n3 {
     margin-right: -1rem !important;
   }

   .mb-md-n3,
   .my-md-n3 {
     margin-bottom: -1rem !important;
   }

   .ml-md-n3,
   .mx-md-n3 {
     margin-left: -1rem !important;
   }

   .m-md-n4 {
     margin: -1.5rem !important;
   }

   .mt-md-n4,
   .my-md-n4 {
     margin-top: -1.5rem !important;
   }

   .mr-md-n4,
   .mx-md-n4 {
     margin-right: -1.5rem !important;
   }

   .mb-md-n4,
   .my-md-n4 {
     margin-bottom: -1.5rem !important;
   }

   .ml-md-n4,
   .mx-md-n4 {
     margin-left: -1.5rem !important;
   }

   .m-md-n5 {
     margin: -3rem !important;
   }

   .mt-md-n5,
   .my-md-n5 {
     margin-top: -3rem !important;
   }

   .mr-md-n5,
   .mx-md-n5 {
     margin-right: -3rem !important;
   }

   .mb-md-n5,
   .my-md-n5 {
     margin-bottom: -3rem !important;
   }

   .ml-md-n5,
   .mx-md-n5 {
     margin-left: -3rem !important;
   }

   .m-md-auto {
     margin: auto !important;
   }

   .mt-md-auto,
   .my-md-auto {
     margin-top: auto !important;
   }

   .mr-md-auto,
   .mx-md-auto {
     margin-right: auto !important;
   }

   .mb-md-auto,
   .my-md-auto {
     margin-bottom: auto !important;
   }

   .ml-md-auto,
   .mx-md-auto {
     margin-left: auto !important;
   }
 }

 @media (min-width: 992px) {
   .m-lg-0 {
     margin: 0 !important;
   }

   .mt-lg-0,
   .my-lg-0 {
     margin-top: 0 !important;
   }

   .mr-lg-0,
   .mx-lg-0 {
     margin-right: 0 !important;
   }

   .mb-lg-0,
   .my-lg-0 {
     margin-bottom: 0 !important;
   }

   .ml-lg-0,
   .mx-lg-0 {
     margin-left: 0 !important;
   }

   .m-lg-1 {
     margin: 0.25rem !important;
   }

   .mt-lg-1,
   .my-lg-1 {
     margin-top: 0.25rem !important;
   }

   .mr-lg-1,
   .mx-lg-1 {
     margin-right: 0.25rem !important;
   }

   .mb-lg-1,
   .my-lg-1 {
     margin-bottom: 0.25rem !important;
   }

   .ml-lg-1,
   .mx-lg-1 {
     margin-left: 0.25rem !important;
   }

   .m-lg-2 {
     margin: 0.5rem !important;
   }

   .mt-lg-2,
   .my-lg-2 {
     margin-top: 0.5rem !important;
   }

   .mr-lg-2,
   .mx-lg-2 {
     margin-right: 0.5rem !important;
   }

   .mb-lg-2,
   .my-lg-2 {
     margin-bottom: 0.5rem !important;
   }

   .ml-lg-2,
   .mx-lg-2 {
     margin-left: 0.5rem !important;
   }

   .m-lg-3 {
     margin: 1rem !important;
   }

   .mt-lg-3,
   .my-lg-3 {
     margin-top: 1rem !important;
   }

   .mr-lg-3,
   .mx-lg-3 {
     margin-right: 1rem !important;
   }

   .mb-lg-3,
   .my-lg-3 {
     margin-bottom: 1rem !important;
   }

   .ml-lg-3,
   .mx-lg-3 {
     margin-left: 1rem !important;
   }

   .m-lg-4 {
     margin: 1.5rem !important;
   }

   .mt-lg-4,
   .my-lg-4 {
     margin-top: 1.5rem !important;
   }

   .mr-lg-4,
   .mx-lg-4 {
     margin-right: 1.5rem !important;
   }

   .mb-lg-4,
   .my-lg-4 {
     margin-bottom: 1.5rem !important;
   }

   .ml-lg-4,
   .mx-lg-4 {
     margin-left: 1.5rem !important;
   }

   .m-lg-5 {
     margin: 3rem !important;
   }

   .mt-lg-5,
   .my-lg-5 {
     margin-top: 3rem !important;
   }

   .mr-lg-5,
   .mx-lg-5 {
     margin-right: 3rem !important;
   }

   .mb-lg-5,
   .my-lg-5 {
     margin-bottom: 3rem !important;
   }

   .ml-lg-5,
   .mx-lg-5 {
     margin-left: 3rem !important;
   }

   .p-lg-0 {
     padding: 0 !important;
   }

   .pt-lg-0,
   .py-lg-0 {
     padding-top: 0 !important;
   }

   .pr-lg-0,
   .px-lg-0 {
     padding-right: 0 !important;
   }

   .pb-lg-0,
   .py-lg-0 {
     padding-bottom: 0 !important;
   }

   .pl-lg-0,
   .px-lg-0 {
     padding-left: 0 !important;
   }

   .p-lg-1 {
     padding: 0.25rem !important;
   }

   .pt-lg-1,
   .py-lg-1 {
     padding-top: 0.25rem !important;
   }

   .pr-lg-1,
   .px-lg-1 {
     padding-right: 0.25rem !important;
   }

   .pb-lg-1,
   .py-lg-1 {
     padding-bottom: 0.25rem !important;
   }

   .pl-lg-1,
   .px-lg-1 {
     padding-left: 0.25rem !important;
   }

   .p-lg-2 {
     padding: 0.5rem !important;
   }

   .pt-lg-2,
   .py-lg-2 {
     padding-top: 0.5rem !important;
   }

   .pr-lg-2,
   .px-lg-2 {
     padding-right: 0.5rem !important;
   }

   .pb-lg-2,
   .py-lg-2 {
     padding-bottom: 0.5rem !important;
   }

   .pl-lg-2,
   .px-lg-2 {
     padding-left: 0.5rem !important;
   }

   .p-lg-3 {
     padding: 1rem !important;
   }

   .pt-lg-3,
   .py-lg-3 {
     padding-top: 1rem !important;
   }

   .pr-lg-3,
   .px-lg-3 {
     padding-right: 1rem !important;
   }

   .pb-lg-3,
   .py-lg-3 {
     padding-bottom: 1rem !important;
   }

   .pl-lg-3,
   .px-lg-3 {
     padding-left: 1rem !important;
   }

   .p-lg-4 {
     padding: 1.5rem !important;
   }

   .pt-lg-4,
   .py-lg-4 {
     padding-top: 1.5rem !important;
   }

   .pr-lg-4,
   .px-lg-4 {
     padding-right: 1.5rem !important;
   }

   .pb-lg-4,
   .py-lg-4 {
     padding-bottom: 1.5rem !important;
   }

   .pl-lg-4,
   .px-lg-4 {
     padding-left: 1.5rem !important;
   }

   .p-lg-5 {
     padding: 3rem !important;
   }

   .pt-lg-5,
   .py-lg-5 {
     padding-top: 3rem !important;
   }

   .pr-lg-5,
   .px-lg-5 {
     padding-right: 3rem !important;
   }

   .pb-lg-5,
   .py-lg-5 {
     padding-bottom: 3rem !important;
   }

   .pl-lg-5,
   .px-lg-5 {
     padding-left: 3rem !important;
   }

   .m-lg-n1 {
     margin: -0.25rem !important;
   }

   .mt-lg-n1,
   .my-lg-n1 {
     margin-top: -0.25rem !important;
   }

   .mr-lg-n1,
   .mx-lg-n1 {
     margin-right: -0.25rem !important;
   }

   .mb-lg-n1,
   .my-lg-n1 {
     margin-bottom: -0.25rem !important;
   }

   .ml-lg-n1,
   .mx-lg-n1 {
     margin-left: -0.25rem !important;
   }

   .m-lg-n2 {
     margin: -0.5rem !important;
   }

   .mt-lg-n2,
   .my-lg-n2 {
     margin-top: -0.5rem !important;
   }

   .mr-lg-n2,
   .mx-lg-n2 {
     margin-right: -0.5rem !important;
   }

   .mb-lg-n2,
   .my-lg-n2 {
     margin-bottom: -0.5rem !important;
   }

   .ml-lg-n2,
   .mx-lg-n2 {
     margin-left: -0.5rem !important;
   }

   .m-lg-n3 {
     margin: -1rem !important;
   }

   .mt-lg-n3,
   .my-lg-n3 {
     margin-top: -1rem !important;
   }

   .mr-lg-n3,
   .mx-lg-n3 {
     margin-right: -1rem !important;
   }

   .mb-lg-n3,
   .my-lg-n3 {
     margin-bottom: -1rem !important;
   }

   .ml-lg-n3,
   .mx-lg-n3 {
     margin-left: -1rem !important;
   }

   .m-lg-n4 {
     margin: -1.5rem !important;
   }

   .mt-lg-n4,
   .my-lg-n4 {
     margin-top: -1.5rem !important;
   }

   .mr-lg-n4,
   .mx-lg-n4 {
     margin-right: -1.5rem !important;
   }

   .mb-lg-n4,
   .my-lg-n4 {
     margin-bottom: -1.5rem !important;
   }

   .ml-lg-n4,
   .mx-lg-n4 {
     margin-left: -1.5rem !important;
   }

   .m-lg-n5 {
     margin: -3rem !important;
   }

   .mt-lg-n5,
   .my-lg-n5 {
     margin-top: -3rem !important;
   }

   .mr-lg-n5,
   .mx-lg-n5 {
     margin-right: -3rem !important;
   }

   .mb-lg-n5,
   .my-lg-n5 {
     margin-bottom: -3rem !important;
   }

   .ml-lg-n5,
   .mx-lg-n5 {
     margin-left: -3rem !important;
   }

   .m-lg-auto {
     margin: auto !important;
   }

   .mt-lg-auto,
   .my-lg-auto {
     margin-top: auto !important;
   }

   .mr-lg-auto,
   .mx-lg-auto {
     margin-right: auto !important;
   }

   .mb-lg-auto,
   .my-lg-auto {
     margin-bottom: auto !important;
   }

   .ml-lg-auto,
   .mx-lg-auto {
     margin-left: auto !important;
   }
 }

 @media (min-width: 1200px) {
   .m-xl-0 {
     margin: 0 !important;
   }

   .mt-xl-0,
   .my-xl-0 {
     margin-top: 0 !important;
   }

   .mr-xl-0,
   .mx-xl-0 {
     margin-right: 0 !important;
   }

   .mb-xl-0,
   .my-xl-0 {
     margin-bottom: 0 !important;
   }

   .ml-xl-0,
   .mx-xl-0 {
     margin-left: 0 !important;
   }

   .m-xl-1 {
     margin: 0.25rem !important;
   }

   .mt-xl-1,
   .my-xl-1 {
     margin-top: 0.25rem !important;
   }

   .mr-xl-1,
   .mx-xl-1 {
     margin-right: 0.25rem !important;
   }

   .mb-xl-1,
   .my-xl-1 {
     margin-bottom: 0.25rem !important;
   }

   .ml-xl-1,
   .mx-xl-1 {
     margin-left: 0.25rem !important;
   }

   .m-xl-2 {
     margin: 0.5rem !important;
   }

   .mt-xl-2,
   .my-xl-2 {
     margin-top: 0.5rem !important;
   }

   .mr-xl-2,
   .mx-xl-2 {
     margin-right: 0.5rem !important;
   }

   .mb-xl-2,
   .my-xl-2 {
     margin-bottom: 0.5rem !important;
   }

   .ml-xl-2,
   .mx-xl-2 {
     margin-left: 0.5rem !important;
   }

   .m-xl-3 {
     margin: 1rem !important;
   }

   .mt-xl-3,
   .my-xl-3 {
     margin-top: 1rem !important;
   }

   .mr-xl-3,
   .mx-xl-3 {
     margin-right: 1rem !important;
   }

   .mb-xl-3,
   .my-xl-3 {
     margin-bottom: 1rem !important;
   }

   .ml-xl-3,
   .mx-xl-3 {
     margin-left: 1rem !important;
   }

   .m-xl-4 {
     margin: 1.5rem !important;
   }

   .mt-xl-4,
   .my-xl-4 {
     margin-top: 1.5rem !important;
   }

   .mr-xl-4,
   .mx-xl-4 {
     margin-right: 1.5rem !important;
   }

   .mb-xl-4,
   .my-xl-4 {
     margin-bottom: 1.5rem !important;
   }

   .ml-xl-4,
   .mx-xl-4 {
     margin-left: 1.5rem !important;
   }

   .m-xl-5 {
     margin: 3rem !important;
   }

   .mt-xl-5,
   .my-xl-5 {
     margin-top: 3rem !important;
   }

   .mr-xl-5,
   .mx-xl-5 {
     margin-right: 3rem !important;
   }

   .mb-xl-5,
   .my-xl-5 {
     margin-bottom: 3rem !important;
   }

   .ml-xl-5,
   .mx-xl-5 {
     margin-left: 3rem !important;
   }

   .p-xl-0 {
     padding: 0 !important;
   }

   .pt-xl-0,
   .py-xl-0 {
     padding-top: 0 !important;
   }

   .pr-xl-0,
   .px-xl-0 {
     padding-right: 0 !important;
   }

   .pb-xl-0,
   .py-xl-0 {
     padding-bottom: 0 !important;
   }

   .pl-xl-0,
   .px-xl-0 {
     padding-left: 0 !important;
   }

   .p-xl-1 {
     padding: 0.25rem !important;
   }

   .pt-xl-1,
   .py-xl-1 {
     padding-top: 0.25rem !important;
   }

   .pr-xl-1,
   .px-xl-1 {
     padding-right: 0.25rem !important;
   }

   .pb-xl-1,
   .py-xl-1 {
     padding-bottom: 0.25rem !important;
   }

   .pl-xl-1,
   .px-xl-1 {
     padding-left: 0.25rem !important;
   }

   .p-xl-2 {
     padding: 0.5rem !important;
   }

   .pt-xl-2,
   .py-xl-2 {
     padding-top: 0.5rem !important;
   }

   .pr-xl-2,
   .px-xl-2 {
     padding-right: 0.5rem !important;
   }

   .pb-xl-2,
   .py-xl-2 {
     padding-bottom: 0.5rem !important;
   }

   .pl-xl-2,
   .px-xl-2 {
     padding-left: 0.5rem !important;
   }

   .p-xl-3 {
     padding: 1rem !important;
   }

   .pt-xl-3,
   .py-xl-3 {
     padding-top: 1rem !important;
   }

   .pr-xl-3,
   .px-xl-3 {
     padding-right: 1rem !important;
   }

   .pb-xl-3,
   .py-xl-3 {
     padding-bottom: 1rem !important;
   }

   .pl-xl-3,
   .px-xl-3 {
     padding-left: 1rem !important;
   }

   .p-xl-4 {
     padding: 1.5rem !important;
   }

   .pt-xl-4,
   .py-xl-4 {
     padding-top: 1.5rem !important;
   }

   .pr-xl-4,
   .px-xl-4 {
     padding-right: 1.5rem !important;
   }

   .pb-xl-4,
   .py-xl-4 {
     padding-bottom: 1.5rem !important;
   }

   .pl-xl-4,
   .px-xl-4 {
     padding-left: 1.5rem !important;
   }

   .p-xl-5 {
     padding: 3rem !important;
   }

   .pt-xl-5,
   .py-xl-5 {
     padding-top: 3rem !important;
   }

   .pr-xl-5,
   .px-xl-5 {
     padding-right: 3rem !important;
   }

   .pb-xl-5,
   .py-xl-5 {
     padding-bottom: 3rem !important;
   }

   .pl-xl-5,
   .px-xl-5 {
     padding-left: 3rem !important;
   }

   .m-xl-n1 {
     margin: -0.25rem !important;
   }

   .mt-xl-n1,
   .my-xl-n1 {
     margin-top: -0.25rem !important;
   }

   .mr-xl-n1,
   .mx-xl-n1 {
     margin-right: -0.25rem !important;
   }

   .mb-xl-n1,
   .my-xl-n1 {
     margin-bottom: -0.25rem !important;
   }

   .ml-xl-n1,
   .mx-xl-n1 {
     margin-left: -0.25rem !important;
   }

   .m-xl-n2 {
     margin: -0.5rem !important;
   }

   .mt-xl-n2,
   .my-xl-n2 {
     margin-top: -0.5rem !important;
   }

   .mr-xl-n2,
   .mx-xl-n2 {
     margin-right: -0.5rem !important;
   }

   .mb-xl-n2,
   .my-xl-n2 {
     margin-bottom: -0.5rem !important;
   }

   .ml-xl-n2,
   .mx-xl-n2 {
     margin-left: -0.5rem !important;
   }

   .m-xl-n3 {
     margin: -1rem !important;
   }

   .mt-xl-n3,
   .my-xl-n3 {
     margin-top: -1rem !important;
   }

   .mr-xl-n3,
   .mx-xl-n3 {
     margin-right: -1rem !important;
   }

   .mb-xl-n3,
   .my-xl-n3 {
     margin-bottom: -1rem !important;
   }

   .ml-xl-n3,
   .mx-xl-n3 {
     margin-left: -1rem !important;
   }

   .m-xl-n4 {
     margin: -1.5rem !important;
   }

   .mt-xl-n4,
   .my-xl-n4 {
     margin-top: -1.5rem !important;
   }

   .mr-xl-n4,
   .mx-xl-n4 {
     margin-right: -1.5rem !important;
   }

   .mb-xl-n4,
   .my-xl-n4 {
     margin-bottom: -1.5rem !important;
   }

   .ml-xl-n4,
   .mx-xl-n4 {
     margin-left: -1.5rem !important;
   }

   .m-xl-n5 {
     margin: -3rem !important;
   }

   .mt-xl-n5,
   .my-xl-n5 {
     margin-top: -3rem !important;
   }

   .mr-xl-n5,
   .mx-xl-n5 {
     margin-right: -3rem !important;
   }

   .mb-xl-n5,
   .my-xl-n5 {
     margin-bottom: -3rem !important;
   }

   .ml-xl-n5,
   .mx-xl-n5 {
     margin-left: -3rem !important;
   }

   .m-xl-auto {
     margin: auto !important;
   }

   .mt-xl-auto,
   .my-xl-auto {
     margin-top: auto !important;
   }

   .mr-xl-auto,
   .mx-xl-auto {
     margin-right: auto !important;
   }

   .mb-xl-auto,
   .my-xl-auto {
     margin-bottom: auto !important;
   }

   .ml-xl-auto,
   .mx-xl-auto {
     margin-left: auto !important;
   }
 }

 @media (min-width: 1440) {
   .m-xxl-0 {
     margin: 0 !important;
   }

   .mt-xxl-0,
   .my-xxl-0 {
     margin-top: 0 !important;
   }

   .mr-xxl-0,
   .mx-xxl-0 {
     margin-right: 0 !important;
   }

   .mb-xxl-0,
   .my-xxl-0 {
     margin-bottom: 0 !important;
   }

   .ml-xxl-0,
   .mx-xxl-0 {
     margin-left: 0 !important;
   }

   .m-xxl-1 {
     margin: 0.25rem !important;
   }

   .mt-xxl-1,
   .my-xxl-1 {
     margin-top: 0.25rem !important;
   }

   .mr-xxl-1,
   .mx-xxl-1 {
     margin-right: 0.25rem !important;
   }

   .mb-xxl-1,
   .my-xxl-1 {
     margin-bottom: 0.25rem !important;
   }

   .ml-xxl-1,
   .mx-xxl-1 {
     margin-left: 0.25rem !important;
   }

   .m-xxl-2 {
     margin: 0.5rem !important;
   }

   .mt-xxl-2,
   .my-xxl-2 {
     margin-top: 0.5rem !important;
   }

   .mr-xxl-2,
   .mx-xxl-2 {
     margin-right: 0.5rem !important;
   }

   .mb-xxl-2,
   .my-xxl-2 {
     margin-bottom: 0.5rem !important;
   }

   .ml-xxl-2,
   .mx-xxl-2 {
     margin-left: 0.5rem !important;
   }

   .m-xxl-3 {
     margin: 1rem !important;
   }

   .mt-xxl-3,
   .my-xxl-3 {
     margin-top: 1rem !important;
   }

   .mr-xxl-3,
   .mx-xxl-3 {
     margin-right: 1rem !important;
   }

   .mb-xxl-3,
   .my-xxl-3 {
     margin-bottom: 1rem !important;
   }

   .ml-xxl-3,
   .mx-xxl-3 {
     margin-left: 1rem !important;
   }

   .m-xxl-4 {
     margin: 1.5rem !important;
   }

   .mt-xxl-4,
   .my-xxl-4 {
     margin-top: 1.5rem !important;
   }

   .mr-xxl-4,
   .mx-xxl-4 {
     margin-right: 1.5rem !important;
   }

   .mb-xxl-4,
   .my-xxl-4 {
     margin-bottom: 1.5rem !important;
   }

   .ml-xxl-4,
   .mx-xxl-4 {
     margin-left: 1.5rem !important;
   }

   .m-xxl-5 {
     margin: 3rem !important;
   }

   .mt-xxl-5,
   .my-xxl-5 {
     margin-top: 3rem !important;
   }

   .mr-xxl-5,
   .mx-xxl-5 {
     margin-right: 3rem !important;
   }

   .mb-xxl-5,
   .my-xxl-5 {
     margin-bottom: 3rem !important;
   }

   .ml-xxl-5,
   .mx-xxl-5 {
     margin-left: 3rem !important;
   }

   .p-xxl-0 {
     padding: 0 !important;
   }

   .pt-xxl-0,
   .py-xxl-0 {
     padding-top: 0 !important;
   }

   .pr-xxl-0,
   .px-xxl-0 {
     padding-right: 0 !important;
   }

   .pb-xxl-0,
   .py-xxl-0 {
     padding-bottom: 0 !important;
   }

   .pl-xxl-0,
   .px-xxl-0 {
     padding-left: 0 !important;
   }

   .p-xxl-1 {
     padding: 0.25rem !important;
   }

   .pt-xxl-1,
   .py-xxl-1 {
     padding-top: 0.25rem !important;
   }

   .pr-xxl-1,
   .px-xxl-1 {
     padding-right: 0.25rem !important;
   }

   .pb-xxl-1,
   .py-xxl-1 {
     padding-bottom: 0.25rem !important;
   }

   .pl-xxl-1,
   .px-xxl-1 {
     padding-left: 0.25rem !important;
   }

   .p-xxl-2 {
     padding: 0.5rem !important;
   }

   .pt-xxl-2,
   .py-xxl-2 {
     padding-top: 0.5rem !important;
   }

   .pr-xxl-2,
   .px-xxl-2 {
     padding-right: 0.5rem !important;
   }

   .pb-xxl-2,
   .py-xxl-2 {
     padding-bottom: 0.5rem !important;
   }

   .pl-xxl-2,
   .px-xxl-2 {
     padding-left: 0.5rem !important;
   }

   .p-xxl-3 {
     padding: 1rem !important;
   }

   .pt-xxl-3,
   .py-xxl-3 {
     padding-top: 1rem !important;
   }

   .pr-xxl-3,
   .px-xxl-3 {
     padding-right: 1rem !important;
   }

   .pb-xxl-3,
   .py-xxl-3 {
     padding-bottom: 1rem !important;
   }

   .pl-xxl-3,
   .px-xxl-3 {
     padding-left: 1rem !important;
   }

   .p-xxl-4 {
     padding: 1.5rem !important;
   }

   .pt-xxl-4,
   .py-xxl-4 {
     padding-top: 1.5rem !important;
   }

   .pr-xxl-4,
   .px-xxl-4 {
     padding-right: 1.5rem !important;
   }

   .pb-xxl-4,
   .py-xxl-4 {
     padding-bottom: 1.5rem !important;
   }

   .pl-xxl-4,
   .px-xxl-4 {
     padding-left: 1.5rem !important;
   }

   .p-xxl-5 {
     padding: 3rem !important;
   }

   .pt-xxl-5,
   .py-xxl-5 {
     padding-top: 3rem !important;
   }

   .pr-xxl-5,
   .px-xxl-5 {
     padding-right: 3rem !important;
   }

   .pb-xxl-5,
   .py-xxl-5 {
     padding-bottom: 3rem !important;
   }

   .pl-xxl-5,
   .px-xxl-5 {
     padding-left: 3rem !important;
   }

   .m-xxl-n1 {
     margin: -0.25rem !important;
   }

   .mt-xxl-n1,
   .my-xxl-n1 {
     margin-top: -0.25rem !important;
   }

   .mr-xxl-n1,
   .mx-xxl-n1 {
     margin-right: -0.25rem !important;
   }

   .mb-xxl-n1,
   .my-xxl-n1 {
     margin-bottom: -0.25rem !important;
   }

   .ml-xxl-n1,
   .mx-xxl-n1 {
     margin-left: -0.25rem !important;
   }

   .m-xxl-n2 {
     margin: -0.5rem !important;
   }

   .mt-xxl-n2,
   .my-xxl-n2 {
     margin-top: -0.5rem !important;
   }

   .mr-xxl-n2,
   .mx-xxl-n2 {
     margin-right: -0.5rem !important;
   }

   .mb-xxl-n2,
   .my-xxl-n2 {
     margin-bottom: -0.5rem !important;
   }

   .ml-xxl-n2,
   .mx-xxl-n2 {
     margin-left: -0.5rem !important;
   }

   .m-xxl-n3 {
     margin: -1rem !important;
   }

   .mt-xxl-n3,
   .my-xxl-n3 {
     margin-top: -1rem !important;
   }

   .mr-xxl-n3,
   .mx-xxl-n3 {
     margin-right: -1rem !important;
   }

   .mb-xxl-n3,
   .my-xxl-n3 {
     margin-bottom: -1rem !important;
   }

   .ml-xxl-n3,
   .mx-xxl-n3 {
     margin-left: -1rem !important;
   }

   .m-xxl-n4 {
     margin: -1.5rem !important;
   }

   .mt-xxl-n4,
   .my-xxl-n4 {
     margin-top: -1.5rem !important;
   }

   .mr-xxl-n4,
   .mx-xxl-n4 {
     margin-right: -1.5rem !important;
   }

   .mb-xxl-n4,
   .my-xxl-n4 {
     margin-bottom: -1.5rem !important;
   }

   .ml-xxl-n4,
   .mx-xxl-n4 {
     margin-left: -1.5rem !important;
   }

   .m-xxl-n5 {
     margin: -3rem !important;
   }

   .mt-xxl-n5,
   .my-xxl-n5 {
     margin-top: -3rem !important;
   }

   .mr-xxl-n5,
   .mx-xxl-n5 {
     margin-right: -3rem !important;
   }

   .mb-xxl-n5,
   .my-xxl-n5 {
     margin-bottom: -3rem !important;
   }

   .ml-xxl-n5,
   .mx-xxl-n5 {
     margin-left: -3rem !important;
   }

   .m-xxl-auto {
     margin: auto !important;
   }

   .mt-xxl-auto,
   .my-xxl-auto {
     margin-top: auto !important;
   }

   .mr-xxl-auto,
   .mx-xxl-auto {
     margin-right: auto !important;
   }

   .mb-xxl-auto,
   .my-xxl-auto {
     margin-bottom: auto !important;
   }

   .ml-xxl-auto,
   .mx-xxl-auto {
     margin-left: auto !important;
   }
 }

 .stretched-link::after {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1;
   pointer-events: auto;
   content: "";
   background-color: rgba(0, 0, 0, 0);
 }

 .text-monospace {
   font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
 }

 .text-justify {
   text-align: justify !important;
 }

 .text-wrap {
   white-space: normal !important;
 }

 .text-nowrap {
   white-space: nowrap !important;
 }

 .text-truncate {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
 }

 .text-left {
   text-align: left !important;
 }

 .text-right {
   text-align: right !important;
 }

 .text-center {
   text-align: center !important;
 }

 @media (min-width: 576px) {
   .text-sm-left {
     text-align: left !important;
   }

   .text-sm-right {
     text-align: right !important;
   }

   .text-sm-center {
     text-align: center !important;
   }
 }

 @media (min-width: 768px) {
   .text-md-left {
     text-align: left !important;
   }

   .text-md-right {
     text-align: right !important;
   }

   .text-md-center {
     text-align: center !important;
   }
 }

 @media (min-width: 992px) {
   .text-lg-left {
     text-align: left !important;
   }

   .text-lg-right {
     text-align: right !important;
   }

   .text-lg-center {
     text-align: center !important;
   }
 }

 @media (min-width: 1200px) {
   .text-xl-left {
     text-align: left !important;
   }

   .text-xl-right {
     text-align: right !important;
   }

   .text-xl-center {
     text-align: center !important;
   }
 }

 @media (min-width: 1440) {
   .text-xxl-left {
     text-align: left !important;
   }

   .text-xxl-right {
     text-align: right !important;
   }

   .text-xxl-center {
     text-align: center !important;
   }
 }

 .text-lowercase {
   text-transform: lowercase !important;
 }

 .text-uppercase {
   text-transform: uppercase !important;
 }

 .text-capitalize {
   text-transform: capitalize !important;
 }

 .font-weight-light {
   font-weight: 300 !important;
 }

 .font-weight-lighter {
   font-weight: lighter !important;
 }

 .font-weight-normal {
   font-weight: 400 !important;
 }

 .font-weight-bold {
   font-weight: 700 !important;
 }

 .font-weight-bolder {
   font-weight: bolder !important;
 }

 .font-italic {
   font-style: italic !important;
 }

 .text-white {
   color: #fff !important;
 }

 .text-primary {
   color: #213f99 !important;
 }

 a.text-primary:hover,
 a.text-primary:focus {
   color: #653b11 !important;
 }

 .text-secondary {
   color: #673BB7 !important;
 }

 a.text-secondary:hover,
 a.text-secondary:focus {
   color: #46287d !important;
 }

 .text-success {
   color: #10d876 !important;
 }

 a.text-success:hover,
 a.text-success:focus {
   color: #0b914f !important;
 }

 .text-info {
   color: #2754e6 !important;
 }

 a.text-info:hover,
 a.text-info:focus {
   color: #1438ad !important;
 }

 .text-warning {
   color: #FE9431 !important;
 }

 a.text-warning:hover,
 a.text-warning:focus {
   color: #e16d01 !important;
 }

 .text-danger {
   color: #E50202 !important;
 }

 a.text-danger:hover,
 a.text-danger:focus {
   color: #990101 !important;
 }

 .text-light {
   color: #faf8f2 !important;
 }

 a.text-light:hover,
 a.text-light:focus {
   color: #e5dabb !important;
 }

 .text-dark {
   color: #262F5A !important;
 }

 a.text-dark:hover,
 a.text-dark:focus {
   color: #0f1324 !important;
 }

 .text-body {
   color: #515184 !important;
 }

 .text-muted {
   color: #AEAED5 !important;
 }

 .text-black-50 {
   color: rgba(0, 0, 0, 0.5) !important;
 }

 .text-white-50 {
   color: rgba(255, 255, 255, 0.5) !important;
 }

 .text-hide {
   font: 0/0 a;
   color: transparent;
   text-shadow: none;
   background-color: transparent;
   border: 0;
 }

 .text-decoration-none {
   text-decoration: none !important;
 }

 .text-break {
   word-wrap: break-word !important;
 }

 .text-reset {
   color: inherit !important;
 }

 .visible {
   visibility: visible !important;
 }

 .invisible {
   visibility: hidden !important;
 }

 @media print {

   *,
   *::before,
   *::after {
     text-shadow: none !important;
     box-shadow: none !important;
   }

   a:not(.btn) {
     text-decoration: underline;
   }

   abbr[title]::after {
     content: " (" attr(title) ")";
   }

   pre {
     white-space: pre-wrap !important;
   }

   pre,
   blockquote {
     border: 1px solid #adb5bd;
     page-break-inside: avoid;
   }

   thead {
     display: table-header-group;
   }

   tr,
   img {
     page-break-inside: avoid;
   }

   p,
   h2,
   h3 {
     orphans: 3;
     widows: 3;
   }

   h2,
   h3 {
     page-break-after: avoid;
   }

   @page {
     size: a3;
   }

   body {
     min-width: 992px !important;
   }

   .container {
     min-width: 992px !important;
   }

   .navbar {
     display: none;
   }

   .badge {
     border: 1px solid #000;
   }

   .table {
     border-collapse: collapse !important;
   }

   .table td,
   .table th {
     background-color: #fff !important;
   }

   .table-bordered th,
   .table-bordered td {
     border: 1px solid #dee2e6 !important;
   }

   .table-dark {
     color: inherit;
   }

   .table-dark th,
   .table-dark td,
   .table-dark thead th,
   .table-dark tbody+tbody {
     border-color: #E1E1F0;
   }

   .table .thead-dark th {
     color: inherit;
     border-color: #E1E1F0;
   }
 }

 * {
   outline: none;
   padding: 0;
 }

 *::after {
   margin: 0;
   padding: 0;
 }

 *::before {
   margin: 0;
   padding: 0;
 }

 body {
   line-height: 1.5;
   color: #777777;
 }

 body.fixed {
   overflow: hidden;
   position: fixed;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 .h1,
 .h2,
 .h3,
 .h4,
 .h5,
 .h6 {
   font-family: 'Roboto Condensed', sans-serif;
   font-weight: 600;
   color: #000;
 }

 ul {
   padding: 0;
   margin: 0;
 }

 img {
   border-style: none;
   height: auto;
   max-width: 100%;
   vertical-align: middle;
 }

 li {
   list-style: none;
 }

 a {
   text-decoration: none;
   outline: none;
   color: #213f99;
 }

 a:hover,
 a:focus,
 a.active {
   text-decoration: none;
   outline: none;
 }

 b,
 strong,
 .strong {
   font-weight: 500;
   color: #000;
 }

 .h-0 {
   height: 0;
 }

 #main-wrapper {
   opacity: 0;
   transition: all 0.25s ease-in;
   overflow: hidden;
   position: relative;
   z-index: 1;
   margin-top: 60px;
 }

 #main-wrapper.show {
   opacity: 1;
 }

 .content-body {
   margin-left: 65px;
   margin-top: 18px;
   margin-right: 6px;
   margin-bottom: 45px;
 }

 #loading-area {
   position: fixed;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   background-color: #faf8f2;
   z-index: 999999999;
   background-image: url(../images/loading.svg);
   background-position: center;
   background-repeat: no-repeat;
   background-size: 120px;
 }

 .font-10 {
   font-size: 10px;
 }

 .font-12 {
   font-size: 12px;
 }

 .font-13 {
   font-size: 13px;
 }

 .font-14 {
   font-size: 14px;
 }

 .font-16 {
   font-size: 16px;
 }

 .font-18 {
   font-size: 18px;
 }

 .font-20 {
   font-size: 20px;
 }

 .font-22 {
   font-size: 22px;
 }

 .font-24 {
   font-size: 24px;
 }

 .font-26 {
   font-size: 26px;
 }

 .font-30 {
   font-size: 30px;
 }

 .font-35 {
   font-size: 35px;
 }

 .font-40 {
   font-size: 40px;
 }

 .font-45 {
   font-size: 45px;
 }

 .font-50 {
   font-size: 50px;
 }

 .font-55 {
   font-size: 55px;
 }

 .font-60 {
   font-size: 60px;
 }

 .font-70 {
   font-size: 70px;
 }

 .font-75 {
   font-size: 75px;
 }

 .font-80 {
   font-size: 80px;
 }

 .font-90 {
   font-size: 90px;
 }

 .font-100 {
   font-size: 100px;
 }

 .font-weight-300 {
   font-weight: 300;
 }

 .font-weight-400 {
   font-weight: 400;
 }

 .font-weight-500 {
   font-weight: 500;
 }

 .font-weight-600 {
   font-weight: 600;
 }

 .font-weight-700 {
   font-weight: 700;
 }

 .font-weight-800 {
   font-weight: 800;
 }

 .font-weight-900 {
   font-weight: 900;
 }

 .p-a0 {
   padding: 0;
 }

 .p-a5 {
   padding: 5px;
 }

 .p-a10 {
   padding: 10px;
 }

 .p-a15 {
   padding: 15px;
 }

 .p-a20 {
   padding: 20px;
 }

 .p-a25 {
   padding: 25px;
 }

 .p-a30 {
   padding: 30px;
 }

 .p-a40 {
   padding: 40px;
 }

 .p-a50 {
   padding: 50px;
 }

 .p-a60 {
   padding: 60px;
 }

 .p-a70 {
   padding: 70px;
 }

 .p-a80 {
   padding: 80px;
 }

 .p-a90 {
   padding: 90px;
 }

 .p-a100 {
   padding: 100px;
 }

 .p-t0 {
   padding-top: 0;
 }

 .p-t5 {
   padding-top: 5px;
 }

 .p-t10 {
   padding-top: 10px;
 }

 .p-t15 {
   padding-top: 15px;
 }

 .p-t20 {
   padding-top: 20px;
 }

 .p-t30 {
   padding-top: 30px;
 }

 .p-t40 {
   padding-top: 40px;
 }

 .p-t50 {
   padding-top: 50px;
 }

 .p-t60 {
   padding-top: 60px;
 }

 .p-t70 {
   padding-top: 70px;
 }

 .p-t80 {
   padding-top: 80px;
 }

 .p-t90 {
   padding-top: 90px;
 }

 .p-t100 {
   padding-top: 100px;
 }

 .p-b0 {
   padding-bottom: 0;
 }

 .p-b5 {
   padding-bottom: 5px;
 }

 .p-b10 {
   padding-bottom: 10px;
 }

 .p-b15 {
   padding-bottom: 15px;
 }

 .p-b20 {
   padding-bottom: 20px;
 }

 .p-b30 {
   padding-bottom: 30px;
 }

 .p-b40 {
   padding-bottom: 40px;
 }

 .p-b50 {
   padding-bottom: 50px;
 }

 .p-b60 {
   padding-bottom: 60px;
 }

 .p-b70 {
   padding-bottom: 70px;
 }

 .p-b80 {
   padding-bottom: 80px;
 }

 .p-b90 {
   padding-bottom: 90px;
 }

 .p-b100 {
   padding-bottom: 100px;
 }

 .p-l0 {
   padding-left: 0;
 }

 .p-l5 {
   padding-left: 5px;
 }

 .p-l10 {
   padding-left: 10px;
 }

 .p-l15 {
   padding-left: 15px;
 }

 .p-l20 {
   padding-left: 20px;
 }

 .p-l30 {
   padding-left: 30px;
 }

 .p-l40 {
   padding-left: 40px;
 }

 .p-l50 {
   padding-left: 50px;
 }

 .p-l60 {
   padding-left: 60px;
 }

 .p-l70 {
   padding-left: 70px;
 }

 .p-l80 {
   padding-left: 80px;
 }

 .p-l90 {
   padding-left: 90px;
 }

 .p-l100 {
   padding-left: 100px;
 }

 .p-r0 {
   padding-right: 0;
 }

 .p-r5 {
   padding-right: 5px;
 }

 .p-r10 {
   padding-right: 10px;
 }

 .p-r15 {
   padding-right: 15px;
 }

 .p-r20 {
   padding-right: 20px;
 }

 .p-r30 {
   padding-right: 30px;
 }

 .p-r40 {
   padding-right: 40px;
 }

 .p-r50 {
   padding-right: 50px;
 }

 .p-r60 {
   padding-right: 60px;
 }

 .p-r70 {
   padding-right: 70px;
 }

 .p-r80 {
   padding-right: 80px;
 }

 .p-r90 {
   padding-right: 90px;
 }

 .p-r100 {
   padding-right: 100px;
 }

 .p-lr0 {
   padding-left: 0;
   padding-right: 0;
 }

 .p-lr5 {
   padding-left: 5px;
   padding-right: 5px;
 }

 .p-lr10 {
   padding-left: 10px;
   padding-right: 10px;
 }

 .p-lr15 {
   padding-left: 15px;
   padding-right: 15px;
 }

 .p-lr20 {
   padding-left: 20px;
   padding-right: 20px;
 }

 .p-lr30 {
   padding-left: 30px;
   padding-right: 30px;
 }

 .p-lr40 {
   padding-left: 40px;
   padding-right: 40px;
 }

 .p-lr50 {
   padding-left: 50px;
   padding-right: 50px;
 }

 .p-lr60 {
   padding-left: 60px;
   padding-right: 60px;
 }

 .p-lr70 {
   padding-left: 70px;
   padding-right: 70px;
 }

 .p-lr80 {
   padding-left: 80px;
   padding-right: 80px;
 }

 .p-lr90 {
   padding-left: 90px;
   padding-right: 90px;
 }

 .p-lr100 {
   padding-left: 100px;
   padding-right: 100px;
 }

 .p-tb0 {
   padding-bottom: 0;
   padding-top: 0;
 }

 .p-tb5 {
   padding-bottom: 5px;
   padding-top: 5px;
 }

 .p-tb10 {
   padding-bottom: 10px;
   padding-top: 10px;
 }

 .p-tb15 {
   padding-bottom: 15px;
   padding-top: 15px;
 }

 .p-tb20 {
   padding-bottom: 20px;
   padding-top: 20px;
 }

 .p-tb30 {
   padding-bottom: 30px;
   padding-top: 30px;
 }

 .p-tb40 {
   padding-bottom: 40px;
   padding-top: 40px;
 }

 .p-tb50 {
   padding-bottom: 50px;
   padding-top: 50px;
 }

 .p-tb60 {
   padding-bottom: 60px;
   padding-top: 60px;
 }

 .p-tb70 {
   padding-bottom: 70px;
   padding-top: 70px;
 }

 .p-tb80 {
   padding-bottom: 80px;
   padding-top: 80px;
 }

 .p-tb90 {
   padding-bottom: 90px;
   padding-top: 90px;
 }

 .p-tb100 {
   padding-bottom: 100px;
   padding-top: 100px;
 }

 .m-auto {
   margin: auto;
 }

 .m-a0 {
   margin: 0;
 }

 .m-a5 {
   margin: 5px;
 }

 .m-a10 {
   margin: 10px;
 }

 .m-a15 {
   margin: 15px;
 }

 .m-a20 {
   margin: 20px;
 }

 .m-a30 {
   margin: 30px;
 }

 .m-a40 {
   margin: 40px;
 }

 .m-a50 {
   margin: 50px;
 }

 .m-a60 {
   margin: 60px;
 }

 .m-a70 {
   margin: 70px;
 }

 .m-a80 {
   margin: 80px;
 }

 .m-a90 {
   margin: 90px;
 }

 .m-a100 {
   margin: 100px;
 }

 .m-t0 {
   margin-top: 0;
 }

 .m-t5 {
   margin-top: 5px;
 }

 .m-t10 {
   margin-top: 10px;
 }

 .m-t15 {
   margin-top: 15px;
 }

 .m-t20 {
   margin-top: 20px;
 }

 .m-t30 {
   margin-top: 30px;
 }

 .m-t40 {
   margin-top: 40px;
 }

 .m-t50 {
   margin-top: 50px;
 }

 .m-t60 {
   margin-top: 60px;
 }

 .m-t70 {
   margin-top: 70px;
 }

 .m-t80 {
   margin-top: 80px;
 }

 .m-t90 {
   margin-top: 90px;
 }

 .m-t100 {
   margin-top: 100px;
 }

 .m-b0 {
   margin-bottom: 0;
 }

 .m-b5 {
   margin-bottom: 5px;
 }

 .m-b10 {
   margin-bottom: 10px;
 }

 .m-b15 {
   margin-bottom: 15px;
 }

 .m-b20 {
   margin-bottom: 20px;
 }

 .m-b30 {
   margin-bottom: 30px;
 }

 .m-b40 {
   margin-bottom: 40px;
 }

 .m-b50 {
   margin-bottom: 50px;
 }

 .m-b60 {
   margin-bottom: 60px;
 }

 .m-b70 {
   margin-bottom: 70px;
 }

 .m-b80 {
   margin-bottom: 80px;
 }

 .m-b90 {
   margin-bottom: 90px;
 }

 .m-b100 {
   margin-bottom: 100px;
 }

 .m-l0 {
   margin-left: 0;
 }

 .m-l5 {
   margin-left: 5px;
 }

 .m-l10 {
   margin-left: 10px;
 }

 .m-l15 {
   margin-left: 15px;
 }

 .m-l20 {
   margin-left: 20px;
 }

 .m-l30 {
   margin-left: 30px;
 }

 .m-l40 {
   margin-left: 40px;
 }

 .m-l50 {
   margin-left: 50px;
 }

 .m-l60 {
   margin-left: 60px;
 }

 .m-l70 {
   margin-left: 70px;
 }

 .m-l80 {
   margin-left: 80px;
 }

 .m-l90 {
   margin-left: 90px;
 }

 .m-l100 {
   margin-left: 100px;
 }

 .m-r0 {
   margin-right: 0;
 }

 .m-r5 {
   margin-right: 5px;
 }

 .m-r10 {
   margin-right: 10px;
 }

 .m-r15 {
   margin-right: 15px;
 }

 .m-r20 {
   margin-right: 20px;
 }

 .m-r30 {
   margin-right: 30px;
 }

 .m-r40 {
   margin-right: 40px;
 }

 .m-r50 {
   margin-right: 50px;
 }

 .m-r60 {
   margin-right: 60px;
 }

 .m-r70 {
   margin-right: 70px;
 }

 .m-r80 {
   margin-right: 80px;
 }

 .m-r90 {
   margin-right: 90px;
 }

 .m-r100 {
   margin-right: 100px;
 }

 .m-lr0 {
   margin-left: 0;
   margin-right: 0;
 }

 .m-lr5 {
   margin-left: 5px;
   margin-right: 5px;
 }

 .m-lr10 {
   margin-left: 10px;
   margin-right: 10px;
 }

 .m-lr15 {
   margin-left: 15px;
   margin-right: 15px;
 }

 .m-lr20 {
   margin-left: 20px;
   margin-right: 20px;
 }

 .m-lr30 {
   margin-left: 30px;
   margin-right: 30px;
 }

 .m-lr40 {
   margin-left: 40px;
   margin-right: 40px;
 }

 .m-lr50 {
   margin-left: 50px;
   margin-right: 50px;
 }

 .m-lr60 {
   margin-left: 60px;
   margin-right: 60px;
 }

 .m-lr70 {
   margin-left: 70px;
   margin-right: 70px;
 }

 .m-lr80 {
   margin-left: 80px;
   margin-right: 80px;
 }

 .m-lr90 {
   margin-left: 90px;
   margin-right: 90px;
 }

 .m-lr100 {
   margin-left: 100px;
   margin-right: 100px;
 }

 .m-tb0 {
   margin-bottom: 0;
   margin-top: 0;
 }

 .m-tb5 {
   margin-bottom: 5px;
   margin-top: 5px;
 }

 .m-tb10 {
   margin-bottom: 10px;
   margin-top: 10px;
 }

 .m-tb15 {
   margin-bottom: 15px;
   margin-top: 15px;
 }

 .m-tb20 {
   margin-bottom: 20px;
   margin-top: 20px;
 }

 .m-tb30 {
   margin-bottom: 30px;
   margin-top: 30px;
 }

 .m-tb40 {
   margin-bottom: 40px;
   margin-top: 40px;
 }

 .m-tb50 {
   margin-bottom: 50px;
   margin-top: 50px;
 }

 .m-tb60 {
   margin-bottom: 60px;
   margin-top: 60px;
 }

 .m-tb70 {
   margin-bottom: 70px;
   margin-top: 70px;
 }

 .m-tb80 {
   margin-bottom: 80px;
   margin-top: 80px;
 }

 .m-tb90 {
   margin-bottom: 90px;
   margin-top: 90px;
 }

 .m-tb100 {
   margin-bottom: 100px;
   margin-top: 100px;
 }

 @media only screen and (max-width: 1200px) {
   .m-lg-t0 {
     margin-top: 0;
   }

   .m-lg-t5 {
     margin-top: 5px;
   }

   .m-lg-t10 {
     margin-top: 10px;
   }

   .m-lg-t15 {
     margin-top: 15px;
   }

   .m-lg-t20 {
     margin-top: 20px;
   }

   .m-lg-t30 {
     margin-top: 30px;
   }

   .m-lg-t40 {
     margin-top: 40px;
   }

   .m-lg-t50 {
     margin-top: 50px;
   }

   .m-lg-t60 {
     margin-top: 60px;
   }

   .m-lg-t70 {
     margin-top: 70px;
   }

   .m-lg-t80 {
     margin-top: 80px;
   }

   .m-lg-t90 {
     margin-top: 90px;
   }

   .m-lg-t100 {
     margin-top: 100px;
   }

   .m-lg-b0 {
     margin-bottom: 0;
   }

   .m-lg-b5 {
     margin-bottom: 5px;
   }

   .m-lg-b10 {
     margin-bottom: 10px;
   }

   .m-lg-b15 {
     margin-bottom: 15px;
   }

   .m-lg-b20 {
     margin-bottom: 20px;
   }

   .m-lg-b30 {
     margin-bottom: 30px;
   }

   .m-lg-b40 {
     margin-bottom: 40px;
   }

   .m-lg-b50 {
     margin-bottom: 50px;
   }

   .m-lg-b60 {
     margin-bottom: 60px;
   }

   .m-lg-b70 {
     margin-bottom: 70px;
   }

   .m-lg-b80 {
     margin-bottom: 80px;
   }

   .m-lg-b90 {
     margin-bottom: 90px;
   }

   .m-lg-b100 {
     margin-bottom: 100px;
   }
 }

 @media only screen and (max-width: 991px) {
   .m-md-t0 {
     margin-top: 0;
   }

   .m-md-t5 {
     margin-top: 5px;
   }

   .m-md-t10 {
     margin-top: 10px;
   }

   .m-md-t15 {
     margin-top: 15px;
   }

   .m-md-t20 {
     margin-top: 20px;
   }

   .m-md-t30 {
     margin-top: 30px;
   }

   .m-md-t40 {
     margin-top: 40px;
   }

   .m-md-t50 {
     margin-top: 50px;
   }

   .m-md-t60 {
     margin-top: 60px;
   }

   .m-md-t70 {
     margin-top: 70px;
   }

   .m-md-t80 {
     margin-top: 80px;
   }

   .m-md-t90 {
     margin-top: 90px;
   }

   .m-md-t100 {
     margin-top: 100px;
   }

   .m-md-b0 {
     margin-bottom: 0;
   }

   .m-md-b5 {
     margin-bottom: 5px;
   }

   .m-md-b10 {
     margin-bottom: 10px;
   }

   .m-md-b15 {
     margin-bottom: 15px;
   }

   .m-md-b20 {
     margin-bottom: 20px;
   }

   .m-md-b30 {
     margin-bottom: 30px;
   }

   .m-md-b40 {
     margin-bottom: 40px;
   }

   .m-md-b50 {
     margin-bottom: 50px;
   }

   .m-md-b60 {
     margin-bottom: 60px;
   }

   .m-md-b70 {
     margin-bottom: 70px;
   }

   .m-md-b80 {
     margin-bottom: 80px;
   }

   .m-md-b90 {
     margin-bottom: 90px;
   }

   .m-md-b100 {
     margin-bottom: 100px;
   }
 }

 @media only screen and (max-width: 767px) {
   .m-sm-t0 {
     margin-top: 0;
   }

   .m-sm-t5 {
     margin-top: 5px;
   }

   .m-sm-t10 {
     margin-top: 10px;
   }

   .m-sm-t15 {
     margin-top: 15px;
   }

   .m-sm-t20 {
     margin-top: 20px;
   }

   .m-sm-t30 {
     margin-top: 30px;
   }

   .m-sm-t40 {
     margin-top: 40px;
   }

   .m-sm-t50 {
     margin-top: 50px;
   }

   .m-sm-t60 {
     margin-top: 60px;
   }

   .m-sm-t70 {
     margin-top: 70px;
   }

   .m-sm-t80 {
     margin-top: 80px;
   }

   .m-sm-t90 {
     margin-top: 90px;
   }

   .m-sm-t100 {
     margin-top: 100px;
   }

   .m-sm-b0 {
     margin-bottom: 0;
   }

   .m-sm-b5 {
     margin-bottom: 5px;
   }

   .m-sm-b10 {
     margin-bottom: 10px;
   }

   .m-sm-b15 {
     margin-bottom: 15px;
   }

   .m-sm-b20 {
     margin-bottom: 20px;
   }

   .m-sm-b30 {
     margin-bottom: 30px;
   }

   .m-sm-b40 {
     margin-bottom: 40px;
   }

   .m-sm-b50 {
     margin-bottom: 50px;
   }

   .m-sm-b60 {
     margin-bottom: 60px;
   }

   .m-sm-b70 {
     margin-bottom: 70px;
   }

   .m-sm-b80 {
     margin-bottom: 80px;
   }

   .m-sm-b90 {
     margin-bottom: 90px;
   }

   .m-sm-b100 {
     margin-bottom: 100px;
   }
 }

 @media only screen and (max-width: 576px) {
   .m-xs-t0 {
     margin-top: 0;
   }

   .m-xs-t5 {
     margin-top: 5px;
   }

   .m-xs-t10 {
     margin-top: 10px;
   }

   .m-xs-t15 {
     margin-top: 15px;
   }

   .m-xs-t20 {
     margin-top: 20px;
   }

   .m-xs-t30 {
     margin-top: 30px;
   }

   .m-xs-t40 {
     margin-top: 40px;
   }

   .m-xs-t50 {
     margin-top: 50px;
   }

   .m-xs-t60 {
     margin-top: 60px;
   }

   .m-xs-t70 {
     margin-top: 70px;
   }

   .m-xs-t80 {
     margin-top: 80px;
   }

   .m-xs-t90 {
     margin-top: 90px;
   }

   .m-xs-t100 {
     margin-top: 100px;
   }

   .m-xs-b0 {
     margin-bottom: 0;
   }

   .m-xs-b5 {
     margin-bottom: 5px;
   }

   .m-xs-b10 {
     margin-bottom: 10px;
   }

   .m-xs-b15 {
     margin-bottom: 15px;
   }

   .m-xs-b20 {
     margin-bottom: 20px;
   }

   .m-xs-b30 {
     margin-bottom: 30px;
   }

   .m-xs-b40 {
     margin-bottom: 40px;
   }

   .m-xs-b50 {
     margin-bottom: 50px;
   }

   .m-xs-b60 {
     margin-bottom: 60px;
   }

   .m-xs-b70 {
     margin-bottom: 70px;
   }

   .m-xs-b80 {
     margin-bottom: 80px;
   }

   .m-xs-b90 {
     margin-bottom: 90px;
   }

   .m-xs-b100 {
     margin-bottom: 100px;
   }
 }

 .max-w50 {
   max-width: 50px;
 }

 .max-w60 {
   max-width: 60px;
 }

 .max-w80 {
   max-width: 80px;
 }

 .max-w100 {
   max-width: 100px;
 }

 .max-w200 {
   max-width: 200px;
 }

 .max-w300 {
   max-width: 300px;
 }

 .max-w400 {
   max-width: 400px;
 }

 .max-w500 {
   max-width: 500px;
 }

 .max-w600 {
   max-width: 600px;
 }

 .max-w700 {
   max-width: 700px;
 }

 .max-w800 {
   max-width: 800px;
 }

 .max-w900 {
   max-width: 900px;
 }

 .max-w1000 {
   max-width: 1000px;
 }

 .bg-img-fix {
   background-attachment: unset;
   background-size: cover;
 }

 .full-img {
   width: 100%;
 }

 .sticky-top {
   top: 60px;
 }

 .page-title {
   padding: 65px 0px 45px;
   position: relative;
   background: #faf8f2;
   /* background-image: url("./../images/hero/5.png"); */
   background-size: cover;
   background-position: center;
 }

 .page-title p {
   font-size: 20px;
   font-weight: 500;
   margin-bottom: 5px;
   color: #7272A8;
   text-transform: capitalize;
 }

 @media only screen and (max-width: 575px) {
   .page-title p {
     font-size: 16px;
   }
 }

 .page-title h3 {
   font-size: 40px;
   line-height: 1.2;
   font-weight: 600;
   text-transform: capitalize;
 }

 @media only screen and (max-width: 575px) {
   .page-title h3 {
     font-size: 28px;
   }
 }

 section {
   position: relative;
   z-index: 1;
 }

 section:after {
   content: attr(data-content);
   position: absolute;
   top: -28px;
   font-size: 200px;
   font-weight: 700;
   color: transparent;
   line-height: 1;
   -webkit-text-stroke: 1px #000;
   z-index: -1;
   font-family: "Roboto Condensed", sans-serif;
   opacity: 0.1;
   left: 0;
 }

 @media only screen and (max-width: 1400px) {
   section:after {
     font-size: 140px;
     top: -20px;
   }
 }

 @media only screen and (max-width: 991px) {
   section:after {
     font-size: 80px;
     top: -10px;
   }
 }

 @media only screen and (max-width: 575px) {
   section:after {
     font-size: 50px;
     top: -5px;
   }
 }

 .section-head {
   margin-bottom: 40px;
 }

 @media only screen and (max-width: 1199px) {
   .section-head {
     margin-bottom: 30px;
   }
 }

 .section-head h2.title {
   font-size: 50px;
   line-height: 1.2;
   font-weight: 700;
 }

 @media only screen and (max-width: 1400px) {
   .section-head h2.title {
     font-size: 40px;
   }
 }

 @media only screen and (max-width: 1199px) {
   .section-head h2.title {
     font-size: 35px;
   }
 }

 @media only screen and (max-width: 575px) {
   .section-head h2.title {
     font-size: 28px;
   }
 }

 .section-head h3.title {
   font-size: 40px;
   line-height: 1.2;
   font-weight: 700;
   text-transform: uppercase;
 }

 @media only screen and (max-width: 1400px) {
   .section-head h3.title {
     font-size: 35px;
   }
 }

 @media only screen and (max-width: 1199px) {
   .section-head h3.title {
     font-size: 30px;
   }
 }

 @media only screen and (max-width: 575px) {
   .section-head h3.title {
     font-size: 24px;
   }
 }

 .section-head h4.title {
   font-size: 35px;
   line-height: 1.2;
   font-weight: 700;
   text-transform: uppercase;
 }

 @media only screen and (max-width: 1400px) {
   .section-head h4.title {
     font-size: 30px;
   }
 }

 @media only screen and (max-width: 1199px) {
   .section-head h4.title {
     font-size: 24px;
   }
 }

 @media only screen and (max-width: 575px) {
   .section-head h4.title {
     font-size: 20px;
   }
 }

 .section-head p {
   font-size: 18px;
 }

 @media only screen and (max-width: 1400px) {
   .section-head p {
     font-size: 16px;
   }
 }

 @media only screen and (max-width: 1199px) {
   .section-head p {
     font-size: 14px;
   }
 }

 .section-head.text-center p {
   max-width: 800px;
   margin-left: auto;
   margin-right: auto;
 }

 .section-head.head-col {
   display: flex;
   align-items: center;
 }

 @media only screen and (max-width: 767px) {
   .section-head.head-col {
     display: block;
   }
 }

 .section-head.head-col .title {
   margin-bottom: 0;
 }

 @media only screen and (max-width: 767px) {
   .section-head.head-col .title {
     margin-bottom: 10px;
   }
 }

 .section-head.head-col p {
   padding-left: 15px;
   border-left: 4px solid #213f99;
   margin-left: 15px;
   margin-bottom: 0;
 }

 @media only screen and (max-width: 767px) {
   .section-head.head-col p {
     padding-left: 0;
     border-left: 0;
     margin-left: 0;
   }
 }

 .row.spno,
 .spno {
   margin-left: 0;
   margin-right: 0;
 }

 .row.spno [class*="col"],
 .row.spno [class*="col"],
 .spno [class*="col"],
 .spno [class*="col"] {
   padding-left: 0;
   padding-right: 0;
 }

 .row.sp4,
 .sp4 {
   margin-left: -4px;
   margin-right: -4px;
 }

 .row.sp4 [class*="col"],
 .row.sp4 [class*="col"],
 .sp4 [class*="col"],
 .sp4 [class*="col"] {
   padding-left: 4px;
   padding-right: 4px;
 }

 .dlab-bnr-inr {
   background: #faf8f2;
   background-size: 100%;
   background-position: right bottom;
   background-repeat: no-repeat;
   position: relative;
   overflow: hidden;
 }

 @media only screen and (max-width: 991px) {
   .dlab-bnr-inr {
     height: 300px;
   }
 }

 .dlab-bnr-inr:before {
   content: "";
   width: 200px;
   height: 200px;
   background: #213f99;
   position: absolute;
   bottom: 0;
   left: 0;
   transform: rotate(45deg) translate(0px, 140px);
   -moz-transform: rotate(45deg) translate(0px, 140px);
   -webkit-transform: rotate(45deg) translate(0px, 140px);
   -ms-transform: rotate(45deg) translate(0px, 140px);
   -o-transform: rotate(45deg) translate(0px, 140px);
 }

 @media only screen and (max-width: 991px) {
   .dlab-bnr-inr:before {
     width: 150px;
     height: 150px;
   }
 }

 .dlab-bnr-inr .container {
   display: table;
   height: 100%;
 }

 .dlab-bnr-inr .dlab-bnr-inr-entry {
   height: 450px;
   vertical-align: middle;
   display: table-cell;
   padding-top: 100px;
 }

 @media only screen and (max-width: 991px) {
   .dlab-bnr-inr .dlab-bnr-inr-entry {
     height: 300px;
   }
 }

 @media only screen and (max-width: 767px) {
   .dlab-bnr-inr .dlab-bnr-inr-entry {
     padding-top: 60px;
     text-align: center;
   }

   .dlab-bnr-inr .dlab-bnr-inr-entry .breadcrumb-row {
     display: inline-block;
   }
 }

 .dlab-bnr-inr h1 {
   font-weight: 600;
   font-size: 30px;
   margin-bottom: 5px;
   color: #000;
   text-transform: uppercase;
 }

 @media only screen and (max-width: 991px) {
   .dlab-bnr-inr h1 {
     font-size: 35px;
   }
 }

 .breadcrumb-row ul {
   background: transparent;
   padding: 0;
 }

 .breadcrumb-row ul li {
   padding: 0;
   margin-right: 3px;
   color: #fff;
   display: inline-block;
   font-size: 18px;
   font-weight: 400;
   color: #777;
 }

 .breadcrumb-row ul li a {
   color: #213f99;
 }

 @media only screen and (min-width: 1200px) and (max-width: 1440px) {
   .col-xxl-1 {
     flex: 0 0 8.3333333333%;
     max-width: 8.3333333333%;
   }

   .col-xxl-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }

   .col-xxl-3 {
     flex: 0 0 25%;
     max-width: 25%;
   }

   .col-xxl-4 {
     flex: 0 0 33.3333333333%;
     max-width: 33.3333333333%;
   }

   .col-xxl-5 {
     flex: 0 0 41.6666666667%;
     max-width: 41.6666666667%;
   }

   .col-xxl-6 {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .col-xxl-7 {
     flex: 0 0 58.3333333333%;
     max-width: 58.3333333333%;
   }

   .col-xxl-8 {
     flex: 0 0 66.6666666667%;
     max-width: 66.6666666667%;
   }

   .col-xxl-9 {
     flex: 0 0 75%;
     max-width: 75%;
   }

   .col-xxl-10 {
     flex: 0 0 83.3333333333%;
     max-width: 83.3333333333%;
   }

   .col-xxl-11 {
     flex: 0 0 91.6666666667%;
     max-width: 91.6666666667%;
   }

   .col-xxl-12 {
     flex: 0 0 100%;
     max-width: 100%;
   }
 }

 .card {
   border: 0px solid #E1E1F0;
   margin-bottom: 30px;
   border-radius: 5px;
   box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
   background: #fff;
 }

 .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #E1E1F0;
   background: #fff;
   padding: 20px 30px;
 }

 .card-title {
   font-size: 24px;
   margin-bottom: 0px;
 }

 .card .card-body {
   padding: 20px 30px;
   background: #fff;
   border-radius: 15px;
 }

 .form-group {
   position: relative;
 }

 .form-group label {
   font-size: 14px;
   font-weight: 500;
   color: #515184;
   margin-bottom: 10px;
   background: transparent;
   border-color: #E1E1F0;
 }

 .form-control {
   border-radius: 4px;
   border: 1px solid transparent;
   padding: 0px 15px;
   font-size: 16px;
   font-weight: 400;
   color: #515184;
   transition: all 0.3s ease-in-out;
   background: #fff;
 }

 .form-control .select {
   padding: 12px 0;
 }

 .form-control span {
   margin-top: 0;
 }

 .form-control::-webkit-input-placeholder {
   color: #515184;
 }

 .form-control:-ms-input-placeholder {
   color: #515184;
 }

 .form-control::placeholder {
   color: #515184;
 }

 .form-control:hover {
   box-shadow: none !important;
   outline: none;
   border-color: #E1E1F0;
   color: #515184;
   background: #fff;
 }

 .form-control:focus,
 .form-control:active,
 .form-control.active {
   box-shadow: none !important;
   outline: none;
   border-color: #213f99;
   color: #515184;
   background: #fff;
 }

 input:-internal-autofill-selected {
   background: white !important;
   background-image: none !important;
   color: -internal-light-dark-color(black, white) !important;
 }

 .input-group-text {
   padding: 5px 15px;
   background: #fff;
   margin-bottom: 0px !important;
   color: #515184;
   border-color: transparent;
 }

 .input-group-append .input-group-text {
   border-top-right-radius: 5px;
   border-bottom-right-radius: 5px;
 }

 .input-group-prepend .input-group-text {
   border-top-left-radius: 5px;
   border-bottom-left-radius: 5px;
 }

 /*Styling Selectbox*/
 .toggle {
   cursor: pointer;
   display: block;
 }

 .toggle-switch {
   display: inline-block;
   background: #f7f7f7;
   border-radius: 16px;
   width: 45px;
   height: 20px;
   position: relative;
   vertical-align: middle;
   transition: background 0.25s;
 }

 .toggle-switch:before,
 .toggle-switch:after {
   content: "";
 }

 .toggle-switch:before {
   display: block;
   background: linear-gradient(to bottom, #fff 0%, #eee 100%);
   border-radius: 50%;
   width: 17px;
   height: 17px;
   position: absolute;
   top: 1px;
   left: 3px;
   transition: left 0.25s;
 }

 .toggle:hover .toggle-switch:before {
   background: linear-gradient(to bottom, #fff 0%, #fff 100%);
 }

 .toggle-checkbox:checked+.toggle-switch {
   background: #213f99;
 }

 .toggle-checkbox:checked+.toggle-switch:before {
   left: 26px;
 }

 .toggle-checkbox {
   position: absolute;
   visibility: hidden;
 }

 .toggle-label {
   margin-left: 15px;
   position: relative;
   top: 2px;
   font-size: 16px;
   font-weight: 400;
 }

 .file-upload-wrapper {
   position: relative;
   width: 100%;
   height: calc(1.5em + 1rem + 2px);
   border: 1px solid #E1E1F0;
   border-radius: 5px;
   color: #AEAED5;
 }

 .file-upload-wrapper:after {
   content: attr(data-text);
   position: absolute;
   top: 0;
   left: 0;
   background: #fff;
   padding: 4px 15px;
   display: block;
   width: calc(100% - 40px);
   pointer-events: none;
   z-index: 20;
   height: calc(1.5em + 1rem + 2px);
   line-height: 30px;
   border-radius: 5px 10px 10px 5px;
   font-weight: 400;
   overflow: hidden;
 }

 .file-upload-wrapper:before {
   content: 'Upload';
   position: absolute;
   top: 0;
   right: 0;
   display: inline-block;
   height: calc(1.5em + 1rem + 2px);
   background: #213f99;
   color: #fff;
   font-weight: 400;
   z-index: 25;
   font-size: 14px;
   line-height: 40px;
   padding: 0 15px;
   text-transform: capitalize;
   pointer-events: none;
   border-radius: 0 5px 5px 0;
 }

 .file-upload-wrapper:hover:before {
   background: #7a4815;
 }

 .file-upload-wrapper input {
   opacity: 0;
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 99;
   height: calc(1.5em + 1rem + 2px);
   margin: 0;
   padding: 0;
   display: block;
   cursor: pointer;
   width: 100%;
 }

 #ui-datepicker-div {
   display: none;
   background: #fafafa;
   box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
   margin-top: 0.25rem;
   border-radius: 0.5rem;
   padding: 0.5rem;
 }

 table {
   border-collapse: collapse;
   border-spacing: 0;
 }

 .ui-datepicker-calendar thead th {
   padding: 0.25rem 0;
   text-align: center;
   font-size: 0.75rem;
   font-weight: 400;
   color: #515184;
 }

 .ui-datepicker-calendar tbody td {
   width: 2.5rem;
   text-align: center;
   padding: 0;
 }

 .ui-datepicker-calendar tbody td a {
   display: block;
   border-radius: 0.25rem;
   line-height: 2rem;
   transition: 0.3s all;
   color: #515184;
   font-size: 0.875rem;
   text-decoration: none;
 }

 .ui-datepicker-calendar tbody td a:hover {
   background-color: #213f99;
   color: white;
 }

 .ui-datepicker-calendar tbody td a.ui-state-active {
   background-color: #213f99;
   color: white;
 }

 .ui-datepicker-header a.ui-corner-all {
   cursor: pointer;
   position: absolute;
   top: 0;
   width: 2rem;
   height: 2rem;
   margin: 0.5rem;
   border-radius: 0.25rem;
   transition: 0.3s all;
 }

 .ui-datepicker-header a.ui-corner-all:hover {
   background-color: #e7ae74;
   color: #fff;
 }

 .ui-datepicker-header a.ui-datepicker-prev {
   left: 0;
   background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
   background-repeat: no-repeat;
   background-size: 0.5rem;
   background-position: 50%;
   transform: rotate(180deg);
 }

 .ui-datepicker-header a.ui-datepicker-next {
   right: 0;
   background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
   background-repeat: no-repeat;
   background-size: 10px;
   background-position: 50%;
 }

 .ui-datepicker-header a>span {
   display: none;
 }

 .ui-datepicker-title {
   text-align: center;
   line-height: 2rem;
   margin-bottom: 0.25rem;
   font-size: 0.875rem;
   font-weight: 500;
   padding-bottom: 0.25rem;
 }

 .ui-datepicker-week-col {
   color: #515184;
   font-weight: 400;
   font-size: 0.75rem;
 }

 label.error {
   color: #E50202;
   position: absolute;
   bottom: 0;
   margin-bottom: -22px;
   font-size: 12px;
   font-weight: 400;
 }

 .nice-select {
   display: flex;
   align-items: center;
   border-top-left-radius: 3px !important;
   border-bottom-left-radius: 3px !important;
 }

 .nice-select ul {
   width: 100% !important;
 }

 .nice-select .list {
   background: #262F5A;
 }

 .nice-select .option:hover,
 .nice-select .option.focus,
 .nice-select .option.selected.focus {
   background-color: #262F5A;
   color: #213f99;
 }

 .btn {
   padding: 10px 30px;
   display: inline-block;
   border-radius: 5px;
   font-size: 12px;
   font-weight: 600;
   text-transform: uppercase;
 }

 .btn.btn-lg,
 .btn-group-lg>.btn {
   padding: 10px 35px;
   font-size: 16px;
 }

 .table-responsive-sm {
   min-width: 48rem;
 }

 .table {
   margin-bottom: 0px;
   font-weight: 400;
 }

 .table tr td,
 .table tr th {
   padding: 15px;
 }

 .table tr th {
   color: #000;
   font-weight: 500;
 }

 .table-striped thead tr th {
   padding-bottom: 15px !important;
 }

 .table-striped tbody tr:nth-of-type(odd) {
   background-color: #fff;
 }

 .table-striped tfoot {
   border-top: 2px solid #E1E1F0;
 }

 .table-striped tfoot tr th {
   padding-top: 10px;
 }

 .dlab-separator {
   display: inline-block;
   height: 4px;
   width: 90px;
   margin-bottom: 10px;
   position: relative;
 }

 .dlab-separator.m-b0 {
   margin-bottom: 0;
 }

 .dlab-separator.style-liner {
   width: 20px;
 }

 .dlab-separator.style-icon {
   width: 30px;
   height: auto;
   text-align: center;
   font-size: 20px;
 }

 .dlab-separator[class*="style-"]:after,
 .dlab-separator[class*="style-"]:before {
   content: "";
   position: absolute;
   top: 50%;
   left: 40px;
   width: 70px;
   height: 2px;
   background: #eee;
   -webkit-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
 }

 .dlab-separator[class*="style-"]:before {
   left: auto;
   right: 40px;
 }

 .dlab-separator.style-skew {
   width: 15px;
   height: 10px;
   margin-left: 1px;
   margin-right: 1px;
   -moz-transform: skewX(-10deg);
   -webkit-transform: skewX(-10deg);
   -o-transform: skewX(-10deg);
   -ms-transform: skewX(-10deg);
   transform: skewX(-10deg);
 }

 .dlab-separator.style-skew[class*="style-"]:after,
 .dlab-separator.style-skew[class*="style-"]:before {
   width: 80px;
   height: 4px;
   left: 20px;
   -moz-transform: translateY(-50%) skewX(-10deg);
   -webkit-transform: translateY(-50%) skewX(-10deg);
   -o-transform: translateY(-50%) skewX(-10deg);
   -ms-transform: translateY(-50%) skewX(-10deg);
   transform: translateY(-50%) skewX(-10deg);
 }

 .dlab-separator.style-skew[class*="style-"]:before {
   right: 20px;
   left: auto;
 }

 .dlab-separator.bnr-title {
   height: 1px;
   width: 155px;
   opacity: 0.5;
 }

 .dlab-separator.bnr-title:before,
 .dlab-separator.bnr-title:after {
   position: absolute;
   content: "";
   background-color: inherit;
 }

 .dlab-separator.bnr-title:before {
   height: inherit;
   right: -80px;
   width: 25px;
 }

 .dlab-separator.bnr-title:after {
   height: inherit;
   right: -90px;
   top: 0;
   width: 6px;
 }

 .dlab-separator.bnr-title i {
   background-color: inherit;
   display: block;
   height: inherit;
   position: absolute;
   right: -50px;
   width: 45px;
 }

 .dlab-separator-outer {
   overflow: hidden;
 }

 .icon-bx-xl {
   display: inline-block;
   text-align: center;
   border-radius: 3px;
   width: 150px;
   height: 150px;
   line-height: 150px;
 }

 .icon-bx-xl.radius {
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   border-radius: 100%;
 }

 .icon-bx-xl i {
   font-size: 80px;
   vertical-align: middle;
 }

 .icon-bx-xl img {
   vertical-align: middle;
   max-width: 100%;
   width: auto;
   height: 80px;
 }

 .icon-bx-lg {
   display: inline-block;
   text-align: center;
   border-radius: 3px;
   width: 120px;
   height: 120px;
   line-height: 120px;
 }

 .icon-bx-lg.radius {
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   border-radius: 100%;
 }

 .icon-bx-lg i {
   font-size: 60px;
   vertical-align: middle;
 }

 .icon-bx-lg img {
   vertical-align: middle;
   max-width: 100%;
   width: auto;
   height: 55px;
 }

 .icon-bx-md {
   display: inline-block;
   text-align: center;
   border-radius: 3px;
   width: 100px;
   height: 100px;
   line-height: 100px;
 }

 .icon-bx-md.radius {
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   border-radius: 100%;
 }

 .icon-bx-md i {
   font-size: 45px;
   vertical-align: middle;
 }

 .icon-bx-md img {
   vertical-align: middle;
   max-width: 100%;
   width: auto;
   height: 40px;
 }

 .icon-bx-sm {
   display: inline-block;
   text-align: center;
   border-radius: 3px;
   width: 80px;
   height: 80px;
   line-height: 80px;
 }

 .icon-bx-sm.radius {
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   border-radius: 100%;
 }

 .icon-bx-sm i {
   font-size: 30px;
   vertical-align: middle;
 }

 .icon-bx-sm img {
   vertical-align: middle;
   max-width: 100%;
   width: auto;
   height: 30px;
 }

 .icon-bx-xs {
   display: inline-block;
   text-align: center;
   border-radius: 3px;
   width: 40px;
   height: 40px;
   line-height: 40px;
 }

 .icon-bx-xs.radius {
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   border-radius: 100%;
 }

 .icon-bx-xs i {
   font-size: 20px;
   vertical-align: middle;
 }

 .icon-bx-xs img {
   vertical-align: middle;
   max-width: 100%;
   width: auto;
   height: 20px;
 }

 .icon-xl {
   display: inline-block;
   text-align: center;
   width: 100px;
 }

 .icon-xl i {
   vertical-align: middle;
   font-size: 80px;
 }

 .icon-xl img {
   vertical-align: middle;
   max-width: 100%;
   width: auto;
   height: 80px;
 }

 .icon-lg {
   display: inline-block;
   text-align: center;
   width: 80px;
 }

 .icon-lg i {
   vertical-align: middle;
   font-size: 60px;
 }

 .icon-lg img {
   vertical-align: middle;
   max-width: 100%;
   width: auto;
   height: 70px;
 }

 .icon-md {
   display: inline-block;
   text-align: center;
   width: 60px;
 }

 .icon-md i {
   vertical-align: middle;
   font-size: 45px;
 }

 .icon-md img {
   vertical-align: middle;
   max-width: 100%;
   width: auto;
   height: 50px;
 }

 .icon-sm {
   display: inline-block;
   text-align: center;
   width: 40px;
 }

 .icon-sm i {
   vertical-align: middle;
   font-size: 30px;
 }

 .icon-sm img {
   vertical-align: middle;
   max-width: 100%;
   width: auto;
   height: 30px;
 }

 .icon-xs {
   display: inline-block;
   text-align: center;
   width: 30px;
 }

 .icon-xs i {
   vertical-align: middle;
   font-size: 20px;
 }

 .icon-xs img {
   vertical-align: middle;
   max-width: 100%;
   width: auto;
   height: 20px;
 }

 .icon-bx-wraper {
   position: relative;
 }

 .icon-bx-wraper .dlab-tilte {
   margin-top: 0;
 }

 .icon-bx-wraper .after-titile-line {
   margin-bottom: 10px;
 }

 .icon-bx-wraper p:last-child {
   margin: 0;
 }

 .icon-bx-wraper.center {
   text-align: center;
 }

 .icon-bx-wraper.left .icon-bx-xl,
 .icon-bx-wraper.left .icon-bx-lg,
 .icon-bx-wraper.left .icon-bx-md,
 .icon-bx-wraper.left .icon-bx-sm,
 .icon-bx-wraper.left .icon-bx-xs {
   float: left;
   margin-right: 20px;
 }

 .icon-bx-wraper.left .icon-xl,
 .icon-bx-wraper.left .icon-lg,
 .icon-bx-wraper.left .icon-md,
 .icon-bx-wraper.left .icon-sm,
 .icon-bx-wraper.left .icon-xs {
   float: left;
   margin-right: 10px;
 }

 .icon-bx-wraper.right {
   text-align: right;
 }

 .icon-bx-wraper.right .icon-bx-xl,
 .icon-bx-wraper.right .icon-bx-lg,
 .icon-bx-wraper.right .icon-bx-md,
 .icon-bx-wraper.right .icon-bx-sm,
 .icon-bx-wraper.right .icon-bx-xs {
   float: right;
   margin-left: 20px;
 }

 .icon-bx-wraper.right .icon-xl,
 .icon-bx-wraper.right .icon-lg,
 .icon-bx-wraper.right .icon-md,
 .icon-bx-wraper.right .icon-sm,
 .icon-bx-wraper.right .icon-xs {
   float: right;
   margin-left: 10px;
 }

 .icon-bx-wraper.bx-style-1,
 .icon-bx-wraper.bx-style-2 {
   border-width: 1px;
   border-style: solid;
   border-color: #ebedf2;
 }

 .icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
   position: absolute;
   top: 0;
   left: 50%;
   -webkit-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
 }

 .icon-bx-wraper.bx-style-2.center .icon-bx-xl {
   margin-left: -75px;
 }

 .icon-bx-wraper.bx-style-2.center .icon-bx-lg {
   margin-left: -60px;
 }

 .icon-bx-wraper.bx-style-2.center .icon-bx-md {
   margin-left: -50px;
 }

 .icon-bx-wraper.bx-style-2.center .icon-bx-sm {
   margin-left: -40px;
 }

 .icon-bx-wraper.bx-style-2.center .icon-bx-xs {
   margin-left: -20px;
 }

 .icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
   position: absolute;
   top: auto;
   left: 0;
   -webkit-transform: translateX(-50%);
   -ms-transform: translateX(-50%);
   transform: translateX(-50%);
 }

 .icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
   position: absolute;
   top: auto;
   right: 0;
   -webkit-transform: translateX(50%);
   -ms-transform: translateX(50%);
   transform: translateX(50%);
 }

 .icon-content {
   overflow: hidden;
 }

 [class*="icon-bx-"][class*="bg-"] a {
   color: #fff;
 }

 [class*="icon-bx-"].bg-white a {
   color: inherit;
 }

 [class*="icon-bx-"][class*="border-"] {
   display: table;
   line-height: normal;
   margin-left: auto;
   margin-right: auto;
 }

 [class*="icon-bx-"][class*="border-"] .icon-cell {
   display: table-cell;
   vertical-align: middle;
 }

 .dlab-box,
 .dlab-info,
 .dlab-tilte,
 .dlab-tilte-inner {
   position: relative;
 }

 .dlab-tilte-inner {
   display: inline-block;
 }

 .dlab-box[class*="border-"],
 .dlab-info[class*="border-"] {
   border-color: #eee;
 }

 .dlab-info.border-1,
 .dlab-info.border-2,
 .dlab-info.border-3,
 .dlab-info.border-4,
 .dlab-info.border-5 {
   border-top: none;
 }

 .border-1,
 .border-2,
 .border-3,
 .border-4,
 .border-5 {
   border-style: solid;
 }

 .border-1 {
   border-width: 1px;
 }

 .border-2 {
   border-width: 2px;
 }

 .border-3 {
   border-width: 3px;
 }

 .border-4 {
   border-width: 4px;
 }

 .border-5 {
   border-width: 5px;
 }

 .left-border,
 .right-border {
   position: relative;
 }

 .left-border:before,
 .right-border:before {
   content: "";
   position: absolute;
   top: 5px;
   width: 1px;
   height: 90%;
   background: #CCC;
 }

 .right-border:before {
   right: 0;
 }

 .dlab-media,
 .dlab-post-media {
   position: relative;
   overflow: hidden;
 }

 .dlab-media img,
 .dlab-post-media img {
   width: 100%;
   height: auto;
 }

 .dlab-box-bg {
   overflow: hidden;
   background-size: cover;
 }

 .dlab-box-bg .btn {
   overflow: unset;
 }

 .dlab-box-bg .glyph-icon {
   font-size: 50px;
 }

 .dlab-box-bg .icon-bx-wraper {
   background-color: #f8f8f8;
 }

 .dlab-box-bg .text-primary,
 .dlab-box-bg .icon-content .dlab-tilte,
 .dlab-box-bg .icon-content p,
 .dlab-box-bg .icon-box-btn .btn {
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .dlab-box-bg.active .icon-bx-wraper {
   background-color: rgba(0, 0, 0, 0.7);
 }

 .dlab-box-bg.active .text-primary,
 .dlab-box-bg.active .icon-content .dlab-tilte,
 .dlab-box-bg.active .icon-content p,
 .dlab-box-bg.active .icon-box-btn .btn {
   color: #fff;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .dlab-box-bg .icon-box-btn .btn {
   background-color: #eeeeee;
   padding: 15px 20px;
   text-transform: uppercase;
   font-size: 15px;
   border-radius: 0;
   color: #1abc9c;
   font-weight: 600;
   margin-bottom: 0;
 }

 .site-filters {
   margin-bottom: 30px;
 }

 .site-filters ul {
   margin: 0;
   list-style: none;
 }

 .site-filters li {
   display: inline-block;
   padding: 0;
   margin-bottom: 3px;
 }

 .site-filters li.btn {
   box-shadow: none;
   -webkit-box-shadow: none;
   outline: none !important;
 }

 .site-filters li input {
   display: none;
 }

 .site-filters li a {
   margin: 0 5px 5px 0;
 }

 .site-filters li.active [class*="btn"] {
   color: #fff;
   background-color: #EFBB20;
 }

 .site-filters.center {
   text-align: center;
 }

 .site-filters.center ul {
   display: inline-block;
   margin: auto;
 }

 .site-filters.center [class*="btn"] {
   display: inline-block;
   margin: 0 5px 10px;
   text-transform: uppercase;
 }

 @media only screen and (max-width: 575px) {
   .site-filters.center [class*="btn"] {
     margin: 0 1px 5px;
     padding: 5px 10px;
     font-size: 12px;
   }
 }

 .filter-style1 {
   margin-bottom: 50px;
 }

 @media only screen and (max-width: 575px) {
   .filter-style1 {
     margin-bottom: 30px;
   }
 }

 .filter-style1 ul {
   padding: 0;
 }

 .filter-style1 ul li {
   color: #000;
   font-size: 18px;
   text-transform: capitalize;
   font-weight: 500;
   margin: 0 20px;
   padding: 12px 0;
   position: relative;
   border-radius: 0;
   background: transparent;
   overflow: hidden;
 }

 @media only screen and (max-width: 575px) {
   .filter-style1 ul li {
     padding: 5px 0;
     font-size: 14px;
     margin: 0 5px;
   }
 }

 .filter-style1 ul li:hover {
   background: transparent;
 }

 .filter-style1 ul li a {
   color: #000;
   margin: 0;
   text-transform: uppercase;
 }

 .filter-style1 ul li.active a {
   color: #737373;
 }

 .filter-style1 ul li:before,
 .filter-style1 ul li:after {
   content: "";
   position: absolute;
   width: 100%;
   height: 6px;
   background: #213f99;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 @media only screen and (max-width: 575px) {

   .filter-style1 ul li:before,
   .filter-style1 ul li:after {
     height: 2px;
   }
 }

 .filter-style1 ul li:before {
   top: 0;
   left: -100%;
 }

 .filter-style1 ul li:after {
   bottom: 0;
   right: -100%;
 }

 .filter-style1 ul li:hover:before,
 .filter-style1 ul li.active:before {
   top: 0;
   left: 0;
 }

 .filter-style1 ul li:hover:after,
 .filter-style1 ul li.active:after {
   right: 0;
 }

 .dlab-img-overlay1,
 .dlab-img-overlay2,
 .dlab-img-overlay3,
 .dlab-img-overlay4,
 .dlab-img-overlay5,
 .dlab-img-overlay6,
 .dlab-img-overlay7,
 .dlab-img-overlay8,
 .dlab-img-overlay9,
 .dlab-img-overlay11,
 .dlab-img-overlay12 {
   position: relative;
 }

 .dlab-img-overlay1:before,
 .dlab-img-overlay1:after,
 .dlab-img-overlay2:before,
 .dlab-img-overlay2:after,
 .dlab-img-overlay3:before,
 .dlab-img-overlay3:after,
 .dlab-img-overlay4:before,
 .dlab-img-overlay4:after,
 .dlab-img-overlay5:before,
 .dlab-img-overlay5:after,
 .dlab-img-overlay6:before,
 .dlab-img-overlay6:after,
 .dlab-img-overlay7:before,
 .dlab-img-overlay7:after,
 .dlab-img-overlay8:before,
 .dlab-img-overlay8:after,
 .dlab-img-overlay9:before,
 .dlab-img-overlay9:after,
 .dlab-img-overlay11:before,
 .dlab-img-overlay11:after,
 .dlab-img-overlay12:before,
 .dlab-img-overlay12:after {
   content: "";
   background: #000;
   position: absolute;
   left: 0;
   top: 0;
   width: 0;
   height: 0;
   opacity: 0.4;
   z-index: 1;
   -webkit-transition: all 0.8s linear;
   -moz-transition: all 0.8s linear;
   -ms-transition: all 0.8s linear;
   -o-transition: all 0.8s linear;
   transition: all 0.8s linear;
 }

 .dlab-img-overlay1.overlay-primary,
 .dlab-img-overlay2.overlay-primary,
 .dlab-img-overlay3.overlay-primary,
 .dlab-img-overlay4.overlay-primary,
 .dlab-img-overlay5.overlay-primary,
 .dlab-img-overlay6.overlay-primary,
 .dlab-img-overlay7.overlay-primary,
 .dlab-img-overlay8.overlay-primary,
 .dlab-img-overlay9.overlay-primary,
 .dlab-img-overlay11.overlay-primary,
 .dlab-img-overlay12.overlay-primary {
   background: #213f99;
 }

 .dlab-box:hover .dlab-img-overlay1:before {
   opacity: 0.5;
 }

 .dlab-box:hover .dlab-img-overlay2:before {
   opacity: 0.9;
 }

 .dlab-box:hover .dlab-img-overlay3:before {
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
 }

 .dlab-box:hover .dlab-img-overlay4:before {
   width: 100%;
   left: 0;
 }

 .dlab-box:hover .dlab-img-overlay5:before {
   height: 100%;
   top: 0;
 }

 .dlab-box:hover .dlab-img-overlay6:before {
   height: 100%;
 }

 .dlab-box:hover .dlab-img-overlay7:before,
 .dlab-box:hover .dlab-img-overlay7:after {
   width: 50%;
 }

 .dlab-box:hover .dlab-img-overlay8:before,
 .dlab-box:hover .dlab-img-overlay8:after {
   width: 100%;
   opacity: 0.3;
 }

 .dlab-box:hover .dlab-img-overlay9:before,
 .dlab-box:hover .dlab-img-overlay9:after {
   width: 100%;
   height: 100%;
   opacity: 0.3;
 }

 .dlab-img-overlay1:before {
   width: 100%;
   height: 100%;
   opacity: 0;
 }

 .dlab-img-overlay1:hover:before {
   opacity: 0.5;
 }

 .dlab-img-overlay2:before {
   width: 100%;
   height: 100%;
   opacity: 0;
   background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
   background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
   background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
 }

 .dlab-img-overlay2:before:hover:before {
   opacity: 0.9;
 }

 .dlab-img-overlay3:before {
   left: 50%;
   top: 50%;
 }

 .dlab-img-overlay3:hover:before {
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
 }

 .dlab-img-overlay4:before {
   left: 50%;
   top: 0;
   height: 100%;
 }

 .dlab-img-overlay4:hover:before {
   width: 100%;
   left: 0;
 }

 .dlab-img-overlay5:before {
   left: 0;
   top: 50%;
   width: 100%;
 }

 .dlab-img-overlay5:hover:before {
   height: 100%;
   top: 0;
 }

 .dlab-img-overlay6:before {
   left: 0;
   top: auto;
   bottom: 0;
   width: 100%;
 }

 .dlab-img-overlay6:hover:before {
   height: 100%;
 }

 .dlab-img-overlay7:before,
 .dlab-img-overlay7:after {
   height: 100%;
 }

 .dlab-img-overlay7:after {
   left: auto;
   right: 0;
 }

 .dlab-img-overlay7:hover:before,
 .dlab-img-overlay7:hover:after {
   width: 50%;
 }

 .dlab-img-overlay8:before,
 .dlab-img-overlay8:after {
   height: 100%;
 }

 .dlab-img-overlay8:after {
   left: auto;
   right: 0;
 }

 .dlab-img-overlay8:hover:before,
 .dlab-img-overlay8:hover:after {
   width: 100%;
   opacity: 0.3;
 }

 .dlab-img-overlay9:before:before,
 .dlab-img-overlay9:before:after,
 .dlab-img-overlay9:after:before,
 .dlab-img-overlay9:after:after {
   left: auto;
   right: 0;
   top: auto;
   bottom: 0;
 }

 .dlab-img-overlay9:before:hover:before,
 .dlab-img-overlay9:before:hover:after,
 .dlab-img-overlay9:after:hover:before,
 .dlab-img-overlay9:after:hover:after {
   width: 100%;
   height: 100%;
   opacity: 0.3;
 }

 /*  */
 .overlay-black-light,
 .overlay-black-middle,
 .overlay-black-dark,
 .overlay-gradient-light,
 .overlay-gradient-middle,
 .overlay-gradient-dark,
 .overlay-white-light,
 .overlay-white-middle,
 .overlay-white-dark,
 .overlay-primary-light,
 .overlay-primary-middle,
 .overlay-primary-dark,
 .overlay-primary {
   position: relative;
 }

 .overlay-black-light:before,
 .overlay-black-middle:before,
 .overlay-black-dark:before,
 .overlay-gradient-light:before,
 .overlay-gradient-middle:before,
 .overlay-gradient-dark:before,
 .overlay-white-light:before,
 .overlay-white-middle:before,
 .overlay-white-dark:before,
 .overlay-primary-light:before,
 .overlay-primary-middle:before,
 .overlay-primary-dark:before,
 .overlay-primary:before {
   content: "";
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
 }

 .overlay-black-light .container,
 .overlay-black-light .container-fluid,
 .overlay-black-light .container-sm,
 .overlay-black-light .container-md,
 .overlay-black-light .container-lg,
 .overlay-black-light .container-xl,
 .overlay-black-middle .container,
 .overlay-black-middle .container-fluid,
 .overlay-black-middle .container-sm,
 .overlay-black-middle .container-md,
 .overlay-black-middle .container-lg,
 .overlay-black-middle .container-xl,
 .overlay-black-dark .container,
 .overlay-black-dark .container-fluid,
 .overlay-black-dark .container-sm,
 .overlay-black-dark .container-md,
 .overlay-black-dark .container-lg,
 .overlay-black-dark .container-xl,
 .overlay-gradient-light .container,
 .overlay-gradient-light .container-fluid,
 .overlay-gradient-light .container-sm,
 .overlay-gradient-light .container-md,
 .overlay-gradient-light .container-lg,
 .overlay-gradient-light .container-xl,
 .overlay-gradient-middle .container,
 .overlay-gradient-middle .container-fluid,
 .overlay-gradient-middle .container-sm,
 .overlay-gradient-middle .container-md,
 .overlay-gradient-middle .container-lg,
 .overlay-gradient-middle .container-xl,
 .overlay-gradient-dark .container,
 .overlay-gradient-dark .container-fluid,
 .overlay-gradient-dark .container-sm,
 .overlay-gradient-dark .container-md,
 .overlay-gradient-dark .container-lg,
 .overlay-gradient-dark .container-xl,
 .overlay-white-light .container,
 .overlay-white-light .container-fluid,
 .overlay-white-light .container-sm,
 .overlay-white-light .container-md,
 .overlay-white-light .container-lg,
 .overlay-white-light .container-xl,
 .overlay-white-middle .container,
 .overlay-white-middle .container-fluid,
 .overlay-white-middle .container-sm,
 .overlay-white-middle .container-md,
 .overlay-white-middle .container-lg,
 .overlay-white-middle .container-xl,
 .overlay-white-dark .container,
 .overlay-white-dark .container-fluid,
 .overlay-white-dark .container-sm,
 .overlay-white-dark .container-md,
 .overlay-white-dark .container-lg,
 .overlay-white-dark .container-xl,
 .overlay-primary-light .container,
 .overlay-primary-light .container-fluid,
 .overlay-primary-light .container-sm,
 .overlay-primary-light .container-md,
 .overlay-primary-light .container-lg,
 .overlay-primary-light .container-xl,
 .overlay-primary-middle .container,
 .overlay-primary-middle .container-fluid,
 .overlay-primary-middle .container-sm,
 .overlay-primary-middle .container-md,
 .overlay-primary-middle .container-lg,
 .overlay-primary-middle .container-xl,
 .overlay-primary-dark .container,
 .overlay-primary-dark .container-fluid,
 .overlay-primary-dark .container-sm,
 .overlay-primary-dark .container-md,
 .overlay-primary-dark .container-lg,
 .overlay-primary-dark .container-xl,
 .overlay-primary .container,
 .overlay-primary .container-fluid,
 .overlay-primary .container-sm,
 .overlay-primary .container-md,
 .overlay-primary .container-lg,
 .overlay-primary .container-xl {
   position: relative;
   z-index: 1;
 }

 .overlay-black-light:before,
 .overlay-black-middle:before,
 .overlay-black-dark:before {
   background: #000;
 }

 .overlay-gradient-light:before,
 .overlay-gradient-middle:before,
 .overlay-gradient-dark:before {
   background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
   background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
   background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
 }

 .overlay-white-light:before,
 .overlay-white-middle:before,
 .overlay-white-dark:before {
   background: #FFF;
 }

 .overlay-primary-light:before,
 .overlay-primary-middle:before,
 .overlay-primary-dark:before,
 .overlay-primary:before {
   background: #213f99;
 }

 .overlay-primary-light:before,
 .overlay-white-light:before,
 .overlay-gradient-light:before,
 .overlay-black-light:before {
   opacity: 0.3;
 }

 .overlay-primary-middle:before,
 .overlay-white-middle:before,
 .overlay-gradient-middle:before,
 .overlay-black-middle:before {
   opacity: 0.5;
 }

 .overlay-primary-dark:before,
 .overlay-white-dark:before,
 .overlay-gradient-dark:before,
 .overlay-black-dark:before {
   opacity: 0.8;
 }

 .overlay-bx {
   position: absolute;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 100%;
   text-align: center;
   z-index: 10;
   opacity: 0;
   visibility: hidden;
   -webkit-transition: all 0.8s;
   -ms-transition: all 0.8s;
   transition: all 0.8s;
 }

 .overlay-bx .align-b {
   height: auto;
   left: 0;
   list-style: outside none none;
   margin: 0;
   position: absolute;
   top: auto;
   bottom: 0;
   width: 100%;
   transform: translate(0);
   -o-transform: translate(0);
   -moz-transform: translate(0);
   -webkit-transform: translate(0);
 }

 .overlay-bx .align-m {
   height: auto;
   left: 50%;
   list-style: outside none none;
   margin: 0;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%);
   -o-transform: translate(-50%, -50%);
   -moz-transform: translate(-50%, -50%);
   -webkit-transform: translate(-50%, -50%);
   width: 100%;
   padding: 0 10px;
 }

 .overlay-icon {
   height: auto;
   left: 50%;
   list-style: outside none none;
   margin: 0;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%);
   -o-transform: translate(-50%, -50%);
   -moz-transform: translate(-50%, -50%);
   -webkit-transform: translate(-50%, -50%);
   width: 100%;
   padding: 0 10px;
 }

 .overlay-icon a {
   display: inline-block;
   padding: 0;
   margin: 0 2px;
 }

 .overlay-icon a i {
   background-color: #FFF;
 }

 .overlay-bx:hover a>i,
 .dlab-media:hover .overlay-bx a>i,
 .dlab-box-bx:hover .overlay-bx a>i {
   opacity: 1;
   visibility: visible;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .overlay-bx:hover,
 .dlab-media:hover .overlay-bx,
 .dlab-box:hover .overlay-bx,
 .ow-img:hover .overlay-bx,
 .ow-portfolio-img:hover .overlay-bx {
   opacity: 1;
   visibility: visible;
 }

 .gallery-box-1 .overlay-bx .overlay-icon span i,
 .gallery-box-1 .overlay-bx .overlay-icon a i {
   background-color: #000;
   color: #fff;
   border-radius: 50px;
   font-size: 14px;
   cursor: pointer;
 }

 .gallery-box-1 .overlay-bx .icon-bx-xs {
   width: 60px;
   height: 60px;
   line-height: 60px;
 }

 /* info box show */
 .dlab-info-has {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: auto;
   padding: 30px 15px 40px;
   opacity: 0;
   margin-bottom: -100px;
   z-index: 2;
 }

 .dlab-info-has.p-a15 {
   padding: 15px;
 }

 .dlab-info-has.p-a20 {
   padding: 20px;
 }

 .dlab-info-has h1,
 .dlab-info-has h2,
 .dlab-info-has h3,
 .dlab-info-has h4,
 .dlab-info-has h5,
 .dlab-info-has a,
 .dlab-info-has p,
 .dlab-info-has h6 {
   color: #fff;
 }

 .dlab-info-has .dlab-info-has-text {
   margin-bottom: 20px;
   line-height: 24px;
 }

 .dlab-info-has.bg-white h1,
 .dlab-info-has.bg-white h2,
 .dlab-info-has.bg-white h3,
 .dlab-info-has.bg-white h4,
 .dlab-info-has.bg-white h5,
 .dlab-info-has.bg-white h6 {
   color: #3d474a;
 }

 .dlab-info-has.bg-white h1 a,
 .dlab-info-has.bg-white h2 a,
 .dlab-info-has.bg-white h3 a,
 .dlab-info-has.bg-white h4 a,
 .dlab-info-has.bg-white h5 a,
 .dlab-info-has.bg-white h6 a {
   color: #3d474a;
 }

 .dlab-info-has.bg-white p {
   color: #3d474a;
 }

 .dlab-info-has.bg-primary {
   background-color: rgba(27, 188, 232, 0.9);
 }

 .dlab-info-has.bg-green {
   background-color: rgba(106, 179, 62, 0.9);
 }

 .dlab-info-has.bg-black {
   background-color: rgba(0, 0, 0, 0.6);
 }

 .dlab-info-has.bg-white {
   background-color: rgba(255, 255, 255, 0.8) !important;
 }

 .dlab-media {
   overflow: hidden;
   position: relative;
 }

 .dlab-media:hover .dlab-info-has {
   opacity: 1;
   width: 100%;
   margin-bottom: 0;
 }

 .dlab-box:hover .dlab-info-has {
   opacity: 1;
   width: 100%;
   margin-bottom: 0;
 }

 .overlay-bx.no-hover,
 .dlab-info-has.no-hover {
   opacity: 1;
   width: 100%;
   margin-bottom: 0;
   visibility: visible;
 }

 .testimonial-pic {
   background: #FFF;
   width: 100px;
   height: 100px;
   position: relative;
   display: inline-block;
   border: 5px solid #FFF;
 }

 .testimonial-pic.radius {
   border-radius: 100%;
   -webkit-border-radius: 100%;
 }

 .testimonial-pic.radius img {
   width: 100%;
   height: 100%;
   border-radius: 100%;
   -webkit-border-radius: 100%;
 }

 .testimonial-pic.shadow {
   -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
   -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
   box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
 }

 .quote-left {
   position: relative;
 }

 .quote-left:before {
   font-family: "FontAwesome";
   position: absolute;
   z-index: 10;
   top: 15px;
   content: "\f10d";
   left: 0;
 }

 .quote-right {
   position: relative;
 }

 .quote-right:before {
   font-family: "FontAwesome";
   position: absolute;
   z-index: 10;
   top: 15px;
   content: "\f10e";
   right: 0;
 }

 .testimonial-text {
   padding: 15px;
   position: relative;
   font-size: 15px;
   font-weight: 400;
 }

 .testimonial-text p:last-child {
   margin: 0;
 }

 .testimonial-detail {
   padding: 5px;
 }

 .testimonial-name {
   font-size: 18px;
   font-weight: 500;
 }

 .testimonial-position {
   font-size: 14px;
 }

 .testimonial-name,
 .testimonial-position {
   display: block;
 }

 .testimonial-bg {
   color: #fff;
 }

 .testimonial-1 {
   text-align: center;
 }

 .testimonial-1 .testimonial-position {
   color: #EFBB20;
 }

 .testimonial-1 .testimonial-name,
 .testimonial-1 .testimonial-position {
   display: block;
 }

 .testimonial-1 .quote-left:before {
   width: 36px;
   height: 36px;
   line-height: 36px;
   font-size: 16px;
   background: #EFBB20;
   color: #FFF;
   text-align: center;
   left: 0;
   top: 65px;
   border-radius: 100%;
   -webkit-border-radius: 100%;
 }

 .testimonial-2 .testimonial-text {
   background-color: #F4F5F7;
   padding: 25px;
 }

 .testimonial-2 .testimonial-text:after {
   content: "";
   position: absolute;
   bottom: -25px;
   left: 0;
   width: 0;
   height: 0;
   border-left: 0 solid transparent;
   border-right: 25px solid transparent;
   border-top: 25px solid #F4F5F7;
 }

 .testimonial-2 .testimonial-detail {
   padding: 25px 0 20px 20px;
 }

 .testimonial-2 .testimonial-pic {
   float: left;
   margin-right: 15px;
   width: 80px;
   height: 80px;
 }

 .testimonial-2 .quote-left:before {
   width: 36px;
   height: 36px;
   line-height: 36px;
   font-size: 16px;
   background: #EFBB20;
   color: #FFF;
   text-align: center;
   left: 0;
   top: 50px;
   border-radius: 100%;
   -webkit-border-radius: 100%;
 }

 .testimonial-2 .testimonial-name {
   padding: 15px 0 2px 0;
 }

 .testimonial-2 .testimonial-position {
   padding: 2px 0;
   color: #EFBB20;
 }

 .testimonial-2.testimonial-bg .testimonial-text {
   color: #777;
   background-color: rgba(255, 255, 255, 0.9);
 }

 .testimonial-2.testimonial-bg .testimonial-text:after {
   border-top-color: rgba(255, 255, 255, 0.9);
 }

 .testimonial-3 {
   text-align: center;
 }

 .testimonial-3 .quote-left:before {
   position: static;
   font-size: 40px;
   color: #EFBB20;
 }

 .testimonial-3 .testimonial-position {
   color: #EFBB20;
   display: inline-block;
 }

 .testimonial-3 .testimonial-name {
   display: inline-block;
 }

 .testimonial-4 {
   background-color: #282a3c;
   position: relative;
   padding: 30px 40px 30px 10px;
   margin-left: 140px;
   min-height: 150px;
 }

 @media only screen and (max-width: 575px) {
   .testimonial-4 {
     padding: 25px 10px 25px 0px;
     margin-left: 110px;
   }
 }

 .testimonial-4:after {
   background-color: #282a3c;
   border-left-width: 4px;
   border-left-style: solid;
   border-left-color: #EFBB20;
   content: "";
   position: absolute;
   left: -80px;
   top: 0;
   height: 100%;
   width: 80%;
   z-index: -1;
   -moz-transform: skewX(-25deg);
   -webkit-transform: skewX(-25deg);
   -o-transform: skewX(-25deg);
   -ms-transform: skewX(-25deg);
   transform: skewX(-25deg);
 }

 .testimonial-4 .testimonial-pic {
   position: absolute;
   top: 30px;
   left: 0;
   margin-left: -110px;
   border-color: #EFBB20;
   border-width: 3px;
   width: 90px;
   height: 90px;
 }

 @media only screen and (max-width: 575px) {
   .testimonial-4 .testimonial-pic {
     height: 80px;
     width: 80px;
   }
 }

 .testimonial-4 [class*="quote-"] {
   position: absolute;
   bottom: 0;
   right: 0;
   width: 0;
   height: 0;
   border-style: solid;
   border-width: 30px;
   border-color: #EFBB20;
   border-top-color: transparent !important;
   border-left-color: transparent !important;
 }

 .testimonial-4 [class*="quote-"]:after,
 .testimonial-4 [class*="quote-"]:before {
   top: 0;
   right: -20px;
   font-size: 20px;
   color: #fff;
 }

 .testimonial-4 .testimonial-text {
   padding: 0;
   margin-bottom: 10px;
 }

 .testimonial-4 .testimonial-detail {
   padding: 0;
 }

 .testimonial-4 .testimonial-name {
   display: inline-block;
   text-transform: uppercase;
 }

 .testimonial-4 .testimonial-name:after {
   content: "/";
   color: #EFBB20;
   margin: 0 2px 0 5px;
 }

 @media only screen and (max-width: 575px) {
   .testimonial-4 .testimonial-name:after {
     transform: skewX(-10deg);
     -moz-transform: skewX(-10deg);
     -webkit-transform: skewX(-10deg);
     -o-transform: skewX(-10deg);
   }
 }

 .testimonial-4 .testimonial-position {
   display: inline-block;
   font-style: italic;
   color: #bebebe;
 }

 .testimonial-5 .testimonial-text {
   border: 2px solid #e6e6e6;
   padding: 30px;
 }

 .testimonial-5 .testimonial-text p {
   font-size: 16px;
   line-height: 30px;
 }

 .testimonial-5 .testimonial-detail {
   padding: 0 0 0 20px;
   margin-top: -35px;
 }

 .testimonial-5 .testimonial-pic {
   box-shadow: 0 0 0 2px #e6e6e6;
   width: 70px;
   height: 70px;
 }

 .testimonial-5 .info p {
   display: inline-block;
   position: relative;
   top: -10px;
   padding: 0 10px;
 }

 .testimonial-6 .quote-left:before {
   left: 15px;
   color: #b5b5b5;
   font-size: 18px;
 }

 .testimonial-6 .quote-right:after {
   color: #b5b5b5;
   font-size: 18px;
   right: 15px;
   bottom: 20px;
   top: auto;
 }

 .testimonial-6 .testimonial-text {
   padding: 25px 35px 70px 40px;
 }

 .testimonial-6 .testimonial-detail {
   position: relative;
   padding: 10px 15px;
   top: -30px;
   width: 85%;
 }

 .testimonial-6 .testimonial-detail:after {
   background-color: inherit;
   content: "";
   display: block;
   height: 100%;
   position: absolute;
   right: -23px;
   top: 0;
   transform: skewX(35deg);
   -moz-transform: skewX(35deg);
   -webkit-transform: skewX(35deg);
   -o-transform: skewX(35deg);
   width: 50px;
   z-index: 1;
 }

 .testimonial-6 .testimonial-pic {
   position: absolute;
   right: 20px;
   top: -50px;
   z-index: 2;
 }

 .testimonial-7 .testimonial-text .testimonial-text {
   border: 2px solid #e6e6e6;
   padding: 18px;
 }

 .testimonial-7 .testimonial-text .testimonial-text p {
   font-size: 16px;
   line-height: 30px;
 }

 .testimonial-7 .testimonial-text .testimonial-detail {
   padding: 0 0 0 20px;
   margin-top: -35px;
 }

 .testimonial-7 .testimonial-text .testimonial-pic {
   box-shadow: 0 0 0 2px #e6e6e6;
   width: 70px;
   height: 70px;
 }

 .testimonial-7 .testimonial-text .info p {
   display: inline-block;
   position: relative;
   top: -10px;
   padding: 0 10px;
 }

 .testimonial-8 {
   padding: 15px;
 }

 .testimonial-8 .testimonial-text {
   background: #ffffff none repeat scroll 0 0;
   border-radius: 6px 6px 6px 0px;
   box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
   padding: 25px;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .testimonial-8 .testimonial-text p {
   font-size: 16px;
   line-height: 30px;
 }

 .testimonial-8 .testimonial-text:after {
   content: "";
   position: absolute;
   bottom: -25px;
   left: 0;
   width: 0;
   height: 0;
   border-left: 0 solid transparent;
   border-right: 25px solid transparent;
   border-top: 25px solid #F4F5F7;
 }

 .testimonial-8 .testimonial-text:before {
   content: "\f10e";
   bottom: -10px;
   color: #000000;
   font-family: "FontAwesome";
   font-size: 80px;
   opacity: 0.05;
   position: absolute;
   right: 10px;
   -webkit-transition: all 0.8s;
   -ms-transition: all 0.8s;
   -o-transition: all 0.8s;
   -moz-transition: all 0.8s;
   transition: all 0.8s;
 }

 .testimonial-8 .testimonial-detail {
   padding: 25px 0 20px 20px;
 }

 .testimonial-8 .testimonial-pic {
   float: left;
   margin-right: 15px;
   width: 80px;
   height: 80px;
 }

 .testimonial-8 .testimonial-name {
   padding: 15px 0 2px 0;
 }

 .testimonial-8 .testimonial-position {
   padding: 2px 0;
 }

 .testimonial-box.owl-theme .owl-dots {
   margin-top: 30px;
   position: unset;
   right: auto;
   text-align: center;
   top: auto;
   transform: translateY(-50%);
   width: auto;
 }

 .active.center .testimonial-8 .testimonial-text {
   background-color: #213f99;
   color: #fff;
 }

 .active.center .testimonial-8 .testimonial-text:after {
   border-top: 15px solid #ff3b68;
   -webkit-transition: all 0.8s;
   -ms-transition: all 0.8s;
   -o-transition: all 0.8s;
   -moz-transition: all 0.8s;
   transition: all 0.8s;
 }

 .testimonial-9 {
   background-color: #fff;
   padding: 50px 25px;
   box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
   text-align: center;
 }

 .testimonial-9 .testimonial-text {
   padding: 0;
   color: #505050;
   font-size: 15px;
   font-weight: 400;
   margin-bottom: 15px;
 }

 .testimonial-9 .testimonial-name {
   font-weight: 600;
   font-size: 13px;
   text-transform: uppercase;
   color: #000;
 }

 .testimonial-9 .testimonial-position {
   text-transform: uppercase;
   color: #888 q;
   font-size: 12px;
   font-style: inherit;
 }

 .testimonial-9 .testimonial-position p {
   line-height: 26px;
 }

 .testimonial-9 .testimonial-pic {
   margin-bottom: 15px;
 }

 .testimonial-10 {
   margin: auto;
   max-width: 600px;
   padding-left: 150px;
   position: relative;
 }

 .testimonial-10 .testimonial-name {
   font-weight: 600;
   font-size: 13px;
   text-transform: uppercase;
   color: #000;
 }

 .testimonial-10 .testimonial-position {
   text-transform: uppercase;
   color: #888 q;
   font-size: 12px;
   font-style: inherit;
 }

 .testimonial-10 .testimonial-position p {
   line-height: 26px;
 }

 .testimonial-10 .testimonial-text {
   color: #505050;
   font-size: 15px;
   font-weight: 400;
   margin-bottom: 15px;
   padding: 0;
 }

 .testimonial-10 .testimonial-pic {
   border: 0 none;
   height: 130px;
   left: 0;
   position: absolute;
   width: 130px;
 }

 .client-box .owl-theme .owl-dots {
   text-align: center;
 }

 .client-says {
   padding-left: 180px;
   position: relative;
 }

 @media only screen and (max-width: 767px) {
   .client-says {
     padding-left: 0;
   }
 }

 .client-says .testimonial-detail {
   padding: 0;
 }

 .client-says .testimonial-pic {
   width: 150px;
   height: 150px;
   border: 0;
   position: absolute;
   left: 0;
   top: 0;
 }

 @media only screen and (max-width: 767px) {
   .client-says .testimonial-pic {
     width: 70px;
     height: 70px;
     position: unset;
     margin-bottom: 15px;
     text-align: center;
   }
 }

 .client-says .testimonial-text {
   padding: 0;
 }

 .client-says .testimonial-text p {
   position: relative;
   font-size: 18px;
   line-height: 28px;
   color: #fff;
   font-weight: 400;
   margin-bottom: 20px;
 }

 @media only screen and (max-width: 767px) {
   .client-says .testimonial-text p {
     font-size: 15px;
     line-height: 24px;
   }
 }

 .client-says .testimonial-name {
   color: #213f99;
   font-size: 22px;
   text-transform: capitalize;
   font-weight: 600;
 }

 .client-says .testimonial-position {
   font-size: 14px;
   font-style: inherit;
   text-transform: capitalize;
   color: #999;
 }

 .testimonial-two .client-says {
   padding-left: 130px;
 }

 .testimonial-two .client-says .testimonial-pic {
   width: 90px;
   height: 90px;
 }

 .testimonial-two .client-says .testimonial-text p {
   font-size: 16px;
 }

 .testimonial-two .client-says .testimonial-name {
   font-size: 20px;
 }

 .testimonial-two .client-says .testimonial-position {
   font-size: 13px;
   color: #868686;
 }

 .testimonial-two-dots-bx.owl-theme .owl-dots .owl-dot span {
   height: 13px;
   width: 13px;
   background-color: #fff;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   opacity: 1;
 }

 .testimonial-two-dots-bx.owl-theme .owl-dots .owl-dot.active span {
   transform: scale(1);
   -moz-transform: scale(1);
   -webkit-transform: scale(1);
   -ms-transform: scale(1);
   -o-transform: scale(1);
   background-color: #213f99;
   width: 13px;
 }

 .owl-none .owl-nav {
   display: none;
 }

 .owl-theme .owl-nav,
 .owl-theme .owl-dots {
   margin-top: 20px;
   text-align: center;
 }

 .owl-theme .owl-nav .disabled {
   opacity: .5;
   cursor: default;
 }

 .owl-theme .owl-dots {
   text-align: center;
 }

 .owl-theme .owl-dots .owl-dot {
   display: inline-block;
 }

 .owl-theme .owl-dots .owl-dot span {
   width: 10px;
   height: 10px;
   margin: 5px 7px;
   background: #d6d6d6;
   display: block;
   -webkit-backface-visibility: visible;
   -webkit-transition: opacity 200ms ease;
   -moz-transition: opacity 200ms ease;
   -ms-transition: opacity 200ms ease;
   -o-transition: opacity 200ms ease;
   transition: opacity 200ms ease;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   border-radius: 30px;
 }

 .owl-theme .owl-dots .owl-dot:hover span,
 .owl-theme .owl-dots .owl-dot.active span {
   background: #869791;
 }

 .owl-theme.dots-style-1 .owl-dots .owl-dot span {
   background: rgba(0, 0, 0, 0.2);
   width: 10px;
   height: 10px;
   margin: 5px;
 }

 .owl-theme.dots-style-1 .owl-dots .owl-dot:hover span,
 .owl-theme.dots-style-1 .owl-dots .owl-dot.active span {
   background: #000;
 }

 .owl-theme.dots-style-3 .owl-dots {
   margin-top: 30px;
   position: unset;
   right: auto;
   text-align: center;
   top: auto;
   transform: translateY(-50%);
   -o-transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   -webkit-transform: translateY(-50%);
   width: auto;
 }

 .owl-theme.dots-style-3 .owl-dots .owl-dot {
   border: 1px solid;
   border-radius: 20px;
   display: inline-block;
   margin: 4px;
   padding: 3px;
 }

 .owl-theme.dots-style-3 .owl-dots .owl-dot span {
   height: 8px;
   width: 8px;
   margin: 0;
 }

 .owl-theme.dots-style-3 .owl-dots .owl-dot:hover,
 .owl-theme.dots-style-3 .owl-dots .owl-dot.active {
   border: 1px solid #FF3B68;
 }

 .owl-theme.dots-style-3 .owl-dots .owl-dot:hover span,
 .owl-theme.dots-style-3 .owl-dots .owl-dot.active span {
   background-color: #FF3B68;
 }

 .owl-btn-top-c .owl-nav {
   text-align: center;
 }

 .owl-btn-top-l .owl-nav {
   text-align: left;
 }

 .owl-btn-top-l .owl-nav .owl-prev {
   margin-left: 0;
 }

 .owl-btn-top-r .owl-nav {
   text-align: right;
 }

 .owl-btn-top-r .owl-nav .owl-next {
   margin-right: 0;
 }

 .owl-btn-top-lr .owl-nav {
   text-align: left;
 }

 .owl-btn-top-lr .owl-nav .owl-next {
   margin-right: 0;
   float: right;
 }

 .owl-btn-full .owl-prev,
 .owl-btn-full .owl-next {
   padding: 40px 8px;
 }

 .owl-btn-center-lr .owl-prev,
 .owl-btn-center-lr .owl-next {
   position: absolute;
   left: 0;
   top: 50%;
   margin: 0;
   transform: translateY(-50%);
   -o-transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   -webkit-transform: translateY(-50%);
   cursor: pointer;
 }

 .owl-btn-center-lr .owl-prev {
   left: 0;
 }

 .owl-btn-center-lr .owl-next {
   left: auto;
   right: 0;
 }

 .owl-carousel .owl-item img {
   transform-style: inherit;
 }

 .owl-btn-1 .owl-prev,
 .owl-btn-1 .owl-next {
   border-radius: 3px;
   height: 40px;
   line-height: 40px;
   margin: 0 10px !important;
   padding: 0;
   text-align: center;
   width: 40px;
 }

 .owl-btn-2 .owl-prev,
 .owl-btn-2 .owl-next {
   border-radius: 3px;
   height: 40px;
   line-height: 40px;
   margin: 0 10px !important;
   padding: 0;
   text-align: center;
   width: 40px;
   margin: 0 -70px !important;
 }

 @media only screen and (max-width: 991px) {

   .owl-btn-2 .owl-prev,
   .owl-btn-2 .owl-next {
     margin: 0 10px !important;
   }
 }

 .owl-btn-3 .owl-prev,
 .owl-btn-3 .owl-next {
   border-radius: 3px;
   line-height: 40px;
   margin: 0 10px !important;
   padding: 0;
   text-align: center;
   margin: 0 -70px !important;
   background-color: rgba(0, 0, 0, 0);
   color: #000000;
   font-size: 35px;
   height: auto;
   width: auto;
   opacity: 0.2;
 }

 .owl-btn-3 .owl-prev:hover,
 .owl-btn-3 .owl-next:hover {
   background-color: rgba(0, 0, 0, 0);
   color: #000;
   opacity: 1;
 }

 @media only screen and (max-width: 991px) {

   .owl-btn-3 .owl-prev,
   .owl-btn-3 .owl-next {
     margin: 0 10px !important;
   }
 }

 .owl-btn-3.btn-white .owl-prev,
 .owl-btn-3.btn-white .owl-next {
   color: #fff;
 }

 .owl-btn-3.btn-white .owl-prev:hover,
 .owl-btn-3.btn-white .owl-next:hover {
   color: #fff;
 }

 .owl-btn-4 {
   overflow: hidden;
 }

 .owl-btn-4 .owl-prev,
 .owl-btn-4 .owl-next {
   background: #ffffff none repeat scroll 0 0;
   border-radius: 30px;
   box-shadow: 0 0 20px 7px rgba(0, 0, 0, 0.5);
   color: #000000;
   font-size: 24px;
   height: 50px;
   line-height: 50px;
   margin: 0 -10px !important;
   overflow: hidden;
   padding: 0;
   width: 50px;
   opacity: 0.7;
 }

 .owl-btn-4 .owl-prev:hover,
 .owl-btn-4 .owl-next:hover {
   background: #ffffff none repeat scroll 0 0;
   color: #000;
   opacity: 1;
 }

 .owl-dots-none .owl-dots {
   display: none;
 }

 .owl-dots-white-full .owl-dots .owl-dot span,
 .owl-dots-black-full .owl-dots .owl-dot span,
 .owl-dots-primary-full .owl-dots .owl-dot span {
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   margin: 4px;
 }

 .owl-dots-white-full .owl-dots .owl-dot:active span,
 .owl-dots-black-full .owl-dots .owl-dot:active span,
 .owl-dots-primary-full .owl-dots .owl-dot:active span {
   opacity: 1;
   width: 25px;
 }

 .owl-dots-white-big .owl-dots .owl-dot span,
 .owl-dots-black-big .owl-dots .owl-dot span,
 .owl-dots-primary-big .owl-dots .owl-dot span {
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   margin: 4px;
 }

 .owl-dots-white-big .owl-dots .owl-dot:active span,
 .owl-dots-black-big .owl-dots .owl-dot:active span,
 .owl-dots-primary-big .owl-dots .owl-dot:active span {
   width: 14px;
   height: 14px;
   opacity: 1;
   margin: 0px 4px 2px 4px;
 }

 .owl-dots-primary-full .owl-dots .owl-dot span,
 .owl-dots-primary-big .owl-dots .owl-dot span {
   background-color: #213f99;
 }

 .owl-dots-black-full .owl-dots .owl-dot span,
 .owl-dots-black-big .owl-dots .owl-dot span {
   background-color: #333;
 }

 .owl-dots-white-full .owl-dots .owl-dot span,
 .owl-dots-white-big .owl-dots .owl-dot span {
   background-color: #f5f5f5;
 }

 .service_tables-inner {
   text-align: center;
 }

 .service_tables-price {
   padding: 20px 20px;
   background-color: #fff;
   border: 1px solid #E9E9E9;
 }

 .service_tables-bx {
   color: #282a3c;
   font-family: montserrat;
   font-size: 45px;
   font-weight: 500;
 }

 .service_tables-type {
   font-size: 20px;
   text-transform: uppercase;
   font-size: 16px;
 }

 .service_tables-type :before {
   content: "/";
   margin-right: 3px;
 }

 .service_tables-title {
   background-color: #fff;
   padding: 20px;
   text-transform: uppercase;
 }

 .service_tables-title * {
   margin: 0;
   color: #fff;
   font-weight: 800;
 }

 .service_tables-features {
   margin: 0;
   padding: 0;
   list-style: none;
   border: 1px solid #E9E9E9;
 }

 .service_tables-features li {
   padding: 12px;
   border-bottom: 1px solid #E9E9E9;
 }

 .service_tables-features li i {
   margin: 0 3px;
 }

 .service_tables-features li:nth-child(even) {
   background-color: #fff;
 }

 .service_tables-features li:last-child {
   border-bottom: none;
 }

 .service_tables-footer {
   margin-top: -1px;
   padding: 20px;
   background-color: #fff;
   border: 1px solid #E9E9E9;
 }

 .service_tables-highlight {
   margin: -20px 0;
   -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
   box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
   position: relative;
   z-index: 99;
 }

 .service_tables-highlight .service_tables-price {
   padding: 30px 20px;
 }

 .service_tables-highlight .service_tables-footer {
   padding: 30px 20px;
 }

 .no-col-gap .service_tables-wrapper {
   margin-left: -1px;
 }

 .service_tables-wrapper.style1 {
   border: 2px solid #d3d3d3;
   padding: 10px 10px 40px;
   font-family: montserrat;
   margin-left: -1px;
 }

 .service_tables-wrapper.style1 .service_tables-icon i {
   color: #000000;
   font-size: 48px;
   line-height: 60px;
 }

 .service_tables-wrapper.style1 .service_tables-price {
   background-color: #fff;
   border: 0;
 }

 .service_tables-wrapper.style1 .service_tables-bx {
   font-size: 35px;
   font-weight: 800;
 }

 .service_tables-wrapper.style1 .service_tables-features {
   border: 0;
   width: 80%;
   margin: auto;
 }

 .service_tables-wrapper.style1 .service_tables-features li {
   background: #ffffff none repeat scroll 0 0;
   border: 0 none;
   color: #000;
   font-weight: 500;
   padding: 8px 0;
   text-align: left;
 }

 .service_tables-wrapper.style1 .service_tables-features li i {
   border: 2px solid;
   border-radius: 20px;
   display: block;
   float: right;
   font-size: 11px;
   height: 20px;
   line-height: 16px;
   text-align: center;
   width: 20px;
 }

 .service_tables-wrapper.style1 .button-md {
   font-weight: 400;
   padding: 12px 35px;
 }

 .service_tables-wrapper.style1 .service_tables-type {
   text-transform: capitalize;
 }

 .service_tables-wrapper.style1.active {
   transform: translateY(-25px);
   -ms-transform: translateY(-25px);
   -moz-transform: translateY(-25px);
   -webkit-transform: translateY(-25px);
   -o-transform: translateY(-25px);
   box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
   z-index: 2;
   border: 0;
   padding: 50px 20px;
   margin: 0 -5px;
   position: relative;
   background: #fff;
 }

 .service_tables-wrapper.style2 {
   box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
   padding: 30px;
 }

 .service_tables-wrapper.style2 .service_tables-price {
   padding: 0;
   background-color: rgba(0, 0, 0, 0);
   border: 0;
 }

 .service_tables-wrapper.style2 .service_tables-bx {
   background: rgba(0, 0, 0, 0.02) none repeat scroll 0 0;
   border-radius: 10px;
   color: #a0a0a0;
   display: inline-block;
   font-size: 13px;
   margin-top: 20px;
   padding: 15px 25px;
   margin-bottom: 10px;
 }

 .service_tables-wrapper.style2 .service_tables-bx span {
   font-size: 22px;
 }

 .service_tables-wrapper.style2.active {
   margin: -30px -10px 0;
   padding: 60px 30px;
   position: relative;
   z-index: 1;
 }

 .service_tables-wrapper.style2.active .service_tables-bx {
   color: #fff;
 }

 .table-option {
   border: 2px solid #adadad;
   border-radius: 6px;
   padding: 20px 0px 8px 0;
 }

 .table-option .service_tables-footer {
   border: 0;
   background-color: transparent;
 }

 .table-option .service_tables-features {
   border: 0;
   background-color: transparent;
 }

 .table-option .service_tables-features li {
   border: 0;
   background-color: transparent;
   color: #919191;
   font-size: 18px;
 }

 .table-option .service_tables-title {
   border: 0;
   background-color: transparent;
   padding: 0;
 }

 .table-option .service_tables-title * {
   color: #000;
 }

 .table-option .service_tables-title h2 {
   font-size: 20px;
   line-height: 36px;
 }

 .table-option .service_tables-price {
   padding: 0;
   border: 0;
   background-color: transparent;
 }

 .table-option .service_tables-bx {
   color: #000;
   font-size: 63px;
   font-weight: 800;
 }

 .table-option .service_tables-bx strong,
 .table-option .service_tables-bx sup {
   font-size: 30px;
   font-weight: 700;
 }

 .table-option:hover,
 .table-option.active {
   background-color: #eeeeee;
   border: 2px solid #eeeeee;
 }

 .table-option.dark {
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .table-option.dark:hover,
 .table-option.dark.active {
   background-color: #404040;
   border: 2px solid #404040;
   box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
   transition: all 0.5s;
 }

 .table-option.dark:hover .service_tables-features li,
 .table-option.dark:hover .service_tables-title h2,
 .table-option.dark:hover .service_tables-price .service_tables-bx,
 .table-option.dark.active .service_tables-features li,
 .table-option.dark.active .service_tables-title h2,
 .table-option.dark.active .service_tables-price .service_tables-bx {
   color: #fff;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .table-option.dark:hover .service_tables-footer .btn.black,
 .table-option.dark.active .service_tables-footer .btn.black {
   background-color: #fff;
   border-color: rgba(0, 0, 0, 0);
   color: #000;
 }

 .dlab-img-effect {
   position: relative;
   overflow: hidden;
   display: block;
   vertical-align: middle;
 }

 .dlab-img-effect img {
   display: block;
   margin: 0;
   width: 100%;
   height: auto;
   box-shadow: 0 0 0 rgba(0, 0, 0, 0);
   -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
   -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
   -webkit-transition: all 0.2s;
   -ms-transition: all 0.2s;
   transition: all 0.2s;
 }

 .dlab-img-effect.opacity img:hover {
   opacity: 0.8;
 }

 .dlab-img-effect.zoom-slow img {
   transition: all 10s;
   -moz-transition: all 10s;
   -webkit-transition: all 10s;
   -o-transition: all 10s;
 }

 .dlab-img-effect.zoom-slow:hover img {
   transform: scale(1.2);
   -moz-transform: scale(1.2);
   -webkit-transform: scale(1.2);
   -ms-transform: scale(1.2);
   -o-transform: scale(1.2);
 }

 .dlab-img-effect.zoom:hover img {
   transform: scale(1.5);
   -moz-transform: scale(1.5);
   -webkit-transform: scale(1.5);
   -ms-transform: scale(1.5);
   -o-transform: scale(1.5);
 }

 .dlab-img-effect.shrink:hover img {
   transform: scale(0.8);
   -moz-transform: scale(0.8);
   -webkit-transform: scale(0.8);
   -ms-transform: scale(0.8);
   -o-transform: scale(0.8);
 }

 .dlab-img-effect.side-pan:hover img {
   margin-left: -11%;
   transform: scale(1.2);
   -moz-transform: scale(1.2);
   -webkit-transform: scale(1.2);
   -ms-transform: scale(1.2);
   -o-transform: scale(1.2);
 }

 .dlab-img-effect.vertical-pan:hover img {
   margin-top: -10%;
   transform: scale(1.2);
   -moz-transform: scale(1.2);
   -webkit-transform: scale(1.2);
   -ms-transform: scale(1.2);
   -o-transform: scale(1.2);
 }

 .dlab-img-effect.fade-in {
   background: #000;
 }

 .dlab-img-effect.fade-in img {
   opacity: 0.65;
 }

 .dlab-img-effect.fade-in:hover img {
   opacity: 1;
 }

 .dlab-img-effect.fade-out {
   background: #000;
 }

 .dlab-img-effect.fade-out:hover img {
   opacity: 0.7;
 }

 .dlab-img-effect.rotate:hover img {
   -moz-transform: scale(1.5) rotate(-20deg);
   -webkit-transform: scale(1.5) rotate(-20deg);
   -o-transform: scale(1.5) rotate(-20deg);
   -ms-transform: scale(1.5) rotate(-20deg);
   transform: scale(1.5) rotate(-20deg);
 }

 .dlab-img-effect.image-sepia img {
   -webkit-filter: sepia(100%);
   filter: sepia(100%);
 }

 .dlab-img-effect.blurr img {
   -webkit-transition: all 0.2s;
   -ms-transition: all 0.2s;
   transition: all 0.2s;
 }

 .dlab-img-effect.blurr:hover img {
   filter: blur(3px);
   -webkit-filter: blur(3px);
   -moz-filter: blur(3px);
   -o-filter: blur(3px);
   -ms-filter: blur(3px);
 }

 .dlab-img-effect.blurr-invert img {
   -webkit-transition: all 0.2s;
   -ms-transition: all 0.2s;
   transition: all 0.2s;
   filter: blur(3px);
   -webkit-filter: blur(3px);
   -moz-filter: blur(3px);
   -o-filter: blur(3px);
   -ms-filter: blur(3px);
 }

 .dlab-img-effect.blurr-invert:hover img {
   filter: blur(0px);
   -webkit-filter: blur(0px);
   -moz-filter: blur(0px);
   -o-filter: blur(0px);
   -ms-filter: blur(0px);
 }

 .dlab-img-effect.off-color img {
   filter: grayscale(0);
   -webkit-filter: grayscale(0);
   -moz-filter: grayscale(0);
   -o-filter: grayscale(0);
   -ms-filter: grayscale(0);
 }

 .dlab-img-effect.off-color:hover img {
   filter: grayscale(1);
   -webkit-filter: grayscale(1);
   -moz-filter: grayscale(1);
   -o-filter: grayscale(1);
   -ms-filter: grayscale(1);
 }

 .dlab-img-effect.on-color img {
   filter: grayscale(1);
   -webkit-filter: grayscale(1);
   -moz-filter: grayscale(1);
   -o-filter: grayscale(1);
   -ms-filter: grayscale(1);
 }

 .dlab-img-effect.on-color:hover img {
   filter: grayscale(0);
   -webkit-filter: grayscale(0);
   -moz-filter: grayscale(0);
   -o-filter: grayscale(0);
   -ms-filter: grayscale(0);
 }

 .dlab-box:hover .dlab-img-effect.rotate img {
   -moz-transform: scale(1.5) rotate(-20deg);
   -webkit-transform: scale(1.5) rotate(-20deg);
   -o-transform: scale(1.5) rotate(-20deg);
   -ms-transform: scale(1.5) rotate(-20deg);
   transform: scale(1.5) rotate(-20deg);
 }

 .rev-slider {
   padding: 0;
 }

 .rev-slider .tp-bullets.hermes {
   padding: 0;
   counter-reset: li;
   transform: translateY(-50%) !important;
   left: 50px !important;
   top: 50% !important;
   height: auto !important;
   width: auto !important;
 }

 @media only screen and (max-width: 1199px) {
   .rev-slider .tp-bullets.hermes {
     left: 0 !important;
   }
 }

 @media only screen and (max-width: 575px) {
   .rev-slider .tp-bullets.hermes {
     left: 5px !important;
   }
 }

 .rev-slider .tp-bullets.hermes .tp-bullet {
   width: 50px;
   height: 50px;
   position: unset;
   box-shadow: unset;
   font-size: 40px;
   line-height: 50px;
   margin: 10px 0px;
   position: unset;
   font-weight: 700;
   font-family: 'Roboto Condensed', sans-serif;
   color: transparent;
   overflow: unset;
   border: 0;
   text-align: center;
   -webkit-text-stroke: 1px #000;
   opacity: 0.5;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 @media only screen and (max-width: 575px) {
   .rev-slider .tp-bullets.hermes .tp-bullet {
     font-size: 24px;
     line-height: 30px;
     width: 30px;
     height: 30px;
   }
 }

 .rev-slider .tp-bullets.hermes .tp-bullet:before {
   content: "0";
 }

 .rev-slider .tp-bullets.hermes .tp-bullet:after {
   content: counter(li, decimal);
   counter-increment: li;
   position: unset;
   width: auto;
   height: auto;
   background: transparent;
   box-shadow: unset;
 }

 .rev-slider .tp-bullets.hermes .tp-bullet.selected {
   padding: 0;
   font-size: 55px;
   -webkit-text-stroke: 1px #213f99;
   opacity: 1;
 }

 @media only screen and (max-width: 575px) {
   .rev-slider .tp-bullets.hermes .tp-bullet.selected {
     font-size: 30px;
   }
 }

 .rev-slider .tp-bullets.hermes .tp-bullet:hover {
   background: transparent;
 }

 .slide-play-button {
   height: 60px;
   width: 60px;
   border-radius: 40px;
   background-color: #213f99;
   text-align: center;
   line-height: 60px;
   color: #fff !important;
   font-size: 20px;
   position: relative;
   display: inline-block;
   padding-left: 5px;
 }

 .slide-play-button:after {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: block;
   border: 1px solid #213f99;
   -webkit-border-radius: 100%;
   -khtml-border-radius: 100%;
   -moz-border-radius: 100%;
   -ms-border-radius: 100%;
   -o-border-radius: 100%;
   border-radius: 100%;
   animation: animationSignal2;
   animation-iteration-count: infinite;
   animation-duration: 3s;
   -webkit-animation: animationSignal2;
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-duration: 3s;
   z-index: -1;
   transform: scale(1);
   -moz-transform: scale(1);
   -webkit-transform: scale(1);
   -ms-transform: scale(1);
   -o-transform: scale(1);
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .slide-play-button:before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: block;
   border: 1px solid #213f99;
   -webkit-border-radius: 50%;
   -khtml-border-radius: 50%;
   -moz-border-radius: 50%;
   -ms-border-radius: 50%;
   -o-border-radius: 50%;
   border-radius: 100%;
   animation: animationSignal1;
   animation-iteration-count: infinite;
   animation-duration: 3s;
   -webkit-animation: animationSignal1;
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-duration: 3s;
   z-index: -1;
   transform: scale(1);
   -moz-transform: scale(1);
   -webkit-transform: scale(1);
   -ms-transform: scale(1);
   -o-transform: scale(1);
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 @keyframes animationSignal1 {
   0% {
     opacity: 1;
     transform: scale(0.9);
     -moz-transform: scale(0.9);
     -ms-transform: scale(0.9);
     -o-transform: scale(0.9);
     -webkit-transform: scale(0.9);
   }

   100% {
     transform: scale(1.8);
     -moz-transform: scale(1.8);
     -ms-transform: scale(1.8);
     -o-transform: scale(1.8);
     -webkit-transform: scale(1.8);
     opacity: 0;
   }
 }

 @-webkit-keyframes animationSignal1 {
   0% {
     opacity: 1;
     transform: scale(0.9);
     -moz-transform: scale(0.9);
     -ms-transform: scale(0.9);
     -o-transform: scale(0.9);
     -webkit-transform: scale(0.9);
   }

   100% {
     transform: scale(1);
     -moz-transform: scale(1);
     -ms-transform: scale(1);
     -o-transform: scale(1);
     -webkit-transform: scale(1);
     opacity: 0;
   }
 }

 @keyframes animationSignal2 {
   0% {
     opacity: 1;
     transform: scale(0.9);
     -moz-transform: scale(0.9);
     -webkit-transform: scale(0.9);
     -ms-transform: scale(0.9);
     -o-transform: scale(0.9);
   }

   100% {
     transform: scale(2.4);
     -moz-transform: scale(2.4);
     -webkit-transform: scale(2.4);
     -ms-transform: scale(2.4);
     -o-transform: scale(2.4);
     opacity: 0;
   }
 }

 @-webkit-keyframes animationSignal2 {
   0% {
     opacity: 1;
     transform: scale(0.9);
     -moz-transform: scale(0.9);
     -webkit-transform: scale(0.9);
     -ms-transform: scale(0.9);
     -o-transform: scale(0.9);
   }

   100% {
     transform: scale(2.4);
     -moz-transform: scale(2.4);
     -webkit-transform: scale(2.4);
     -ms-transform: scale(2.4);
     -o-transform: scale(2.4);
     opacity: 0;
   }
 }

 .amenities-carousel .owl-item.active.center .amenit-box {
   transform: scale(1.1);
   z-index: 3;
   opacity: 1;
 }

 .amenities-carousel .owl-prev,
 .amenities-carousel .owl-next {
   width: 65px;
   height: 65px;
   background: #213f99;
   color: #fff;
   text-align: center;
   line-height: 65px;
   margin: 0 15% !important;
   border-radius: 6px;
 }

 @media only screen and (max-width: 1199px) {

   .amenities-carousel .owl-prev,
   .amenities-carousel .owl-next {
     width: 40px;
     height: 40px;
     line-height: 44px;
     border-radius: 4px;
   }
 }

 .amenit-box {
   z-index: 2;
   border: 10px solid #fff;
   box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
   padding: 0;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   margin: 80px 0;
   position: relative;
   opacity: 0.5;
 }

 @media only screen and (max-width: 575px) {
   .amenit-box {
     border: 5px solid #fff;
     margin-bottom: 60px;
   }
 }

 .amenit-box .info {
   padding: 15px 30px;
   display: inline-block;
   background: #fff;
   position: absolute;
   bottom: -20px;
   left: 50%;
   transform: translateX(-50%);
   -moz-transform: translateX(-50%);
   -webkit-transform: translateX(-50%);
   -ms-transform: translateX(-50%);
   -o-transform: translateX(-50%);
   box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.15);
 }

 @media only screen and (max-width: 1199px) {
   .amenit-box .info {
     padding: 10px 20px;
   }
 }

 @media only screen and (max-width: 575px) {
   .amenit-box .info {
     padding: 10px 15px;
   }
 }

 .amenit-box .info .title {
   font-size: 18px;
   margin-bottom: 0;
   display: flex;
   align-items: center;
   vertical-align: middle;
   text-transform: uppercase;
   font-family: Roboto;
   color: #000;
 }

 @media only screen and (max-width: 1199px) {
   .amenit-box .info .title {
     font-size: 14px;
   }
 }

 @media only screen and (max-width: 575px) {
   .amenit-box .info .title {
     font-size: 12px;
   }
 }

 .amenit-box .info .title i {
   color: #213f99;
   font-size: 40px;
   line-height: 1;
   margin-right: 10px;
 }

 @media only screen and (max-width: 1199px) {
   .amenit-box .info .title i {
     font-size: 22px;
   }
 }

 .amenities-area {
   position: relative;
   z-index: 1;
   margin-top: -80px;
 }

 .content-inner {
   padding-top: 10px;
   padding-bottom: 70px;
 }

 @media only screen and (max-width: 991px) {
   .content-inner {
     padding-top: 50px;
     padding-bottom: 20px;
   }
 }

 @media only screen and (max-width: 575px) {
   .content-inner {
     padding-top: 30px;
   }
 }

 .content-inner-1 {
   padding-top: 100px;
   padding-bottom: 100px;
 }

 @media only screen and (max-width: 991px) {
   .content-inner-1 {
     padding-top: 50px;
     padding-bottom: 50px;
   }
 }

 @media only screen and (max-width: 575px) {
   .content-inner-1 {
     padding-top: 30px;
     padding-bottom: 30px;
   }
 }

 .content-inner-2 {
  margin-top: 60px;
  margin-bottom: 10px;
  padding-bottom: 0;
 }

 @media only screen and (max-width: 991px) {
   .content-inner-2 {
     padding-top: 50px;
   }
 }

 @media only screen and (max-width: 575px) {
   .content-inner-2 {
     padding-top: 30px;
   }
 }

 @media only screen and (max-width: 575px) {
   .rs-button .btn {
     padding-left: 15px;
     padding-right: 15px;
   }
 }

 .wow {
   visibility: hidden;
 }

 .faq-media {
   height: 100%;
   padding: 0 0px 80px 80px;
   z-index: 1;
 }

 @media only screen and (max-width: 1400px) {
   .faq-media {
     padding: 0 0px 40px 40px;
   }
 }

 @media only screen and (max-width: 1199px) {
   .faq-media {
     padding: 0 0px 20px 20px;
   }
 }

 @media only screen and (max-width: 767px) {
   .faq-media {
     padding: 0 15px 15px 15px;
     min-height: 200px;
   }
 }

 .faq-media:after {
   content: "";
   position: absolute;
   background: #faf8f2;
   width: 100%;
   height: calc(100% - 80px);
   bottom: 0;
   left: 0;
   z-index: -1;
 }

 @media only screen and (max-width: 1400px) {
   .faq-media:after {
     height: calc(100% - 40px);
   }
 }

 .faq-media img {
   height: calc(100% - 80px);
   width: calc(100% - 80px);
   object-fit: cover;
   position: absolute;
   z-index: 1;
   transition: all 1s;
   -moz-transition: all 1s;
   -webkit-transition: all 1s;
   -ms-transition: all 1s;
   -o-transition: all 1s;
   opacity: 0;
   object-position: left;
 }

 @media only screen and (max-width: 1400px) {
   .faq-media img {
     height: calc(100% - 40px);
     width: calc(100% - 40px);
   }
 }

 @media only screen and (max-width: 1199px) {
   .faq-media img {
     height: calc(100% - 20px);
     width: calc(100% - 20px);
   }
 }

 @media only screen and (max-width: 767px) {
   .faq-media img {
     height: 100%;
     width: calc(100% - 30px);
   }
 }

 .faq-media img.active {
   opacity: 1;
   object-position: center;
 }

 @media only screen and (max-width: 767px) {
   .faq-list {
     display: inline-flex;
     flex-direction: column-reverse;
   }
 }

 @media only screen and (max-width: 1199px) {
   .faq-list .text-right {
     padding-right: 15px;
   }
 }

 @media only screen and (max-width: 767px) {
   .faq-list .text-right {
     padding-right: 15px;
     text-align: left !important;
     margin-top: 15px;
     padding-left: 15px;
   }
 }

 .faq-list .accordion {
   background: #faf8f2;
   padding: 30px;
   min-height: 650px;
   max-height: 650px;
   overflow-y: auto;
 }

 @media only screen and (max-width: 1199px) {
   .faq-list .accordion {
     min-height: 450px;
     max-height: 450px;
     padding: 20px;
   }
 }

 @media only screen and (max-width: 767px) {
   .faq-list .accordion {
     padding: 15px;
     min-height: auto;
     max-height: inherit;
   }
 }

 .faq-list .card {
   padding: 0;
   background: transparent;
   box-shadow: none;
   border-radius: 0;
   margin: 0;
 }

 .faq-list .card .card-header {
   padding: 0;
   background: transparent;
   border: 0;
   margin: 0;
 }

 .faq-list .card .card-header a {
   font-size: 18px;
   color: #213f99;
   text-transform: uppercase;
   font-weight: 500;
   display: block;
   width: 100%;
   padding: 10px 0;
   position: relative;
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
   margin: 0;
 }

 @media only screen and (max-width: 1199px) {
   .faq-list .card .card-header a {
     font-size: 14px;
   }
 }

 .faq-list .card .card-header a.collapsed {
   color: #000;
 }

 .faq-list .card .card-header a:after {
   content: "\f107";
   font-family: FontAwesome;
   position: absolute;
   right: 0;
   top: 10px;
   line-height: 1;
   font-size: 22px;
 }

 .faq-list .card .faq-content {
   font-size: 16px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
   font-weight: 300;
 }

 .faq-list .card .faq-content .card-body {
   padding: 10px 0;
   margin: 0;
   border-radius: 0;
   background: transparent;
 }

 .about-box .about-bg {
   background-image: url(../images/about/about.png);
   background-repeat: no-repeat;
   background-position: right bottom;
   background-size: 62%;
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   animation: wave2 3s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
 }

 @media only screen and (max-width: 1400px) {
   .about-box .about-bg {
     background-size: 65%;
   }
 }

 @media only screen and (max-width: 1199px) {
   .about-box .about-bg {
     background-size: 92%;
     background-position: right -220px bottom;
   }
 }

 @media only screen and (max-width: 575px) {
   .about-box .about-bg {
     background-size: 50%;
     background-position: right 0px bottom;
   }
 }

 .about-box .container {
   position: relative;
   z-index: 1;
 }

 .about-box::before {
   content: "";
   background-image: url(../images/about/about1.png);
   background-repeat: no-repeat;
   background-position: left bottom;
   background-size: 17%;
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   animation: wave1 4s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
 }

 @media only screen and (max-width: 1400px) {
   .about-box::before {
     background-size: 8%;
   }
 }

 @media only screen and (max-width: 575px) {
   .about-box::before {
     background-size: 12%;
   }
 }

 @keyframes wave1 {

   0%,
   100% {
     transform: translate3d(0, -5px, 0);
   }

   50% {
     transform: translate3d(0, -15px, 0);
   }
 }

 @keyframes wave2 {

   0%,
   100% {
     transform: translate3d(-5px, 0, 0);
   }

   50% {
     transform: translate3d(-15px, 0, 0);
   }
 }

 .setResizeMargin {
   padding-left: 15px;
   padding-right: 15px;
 }

 @media only screen and (max-width: 1400px) {
   .master-plan {
     padding-left: 40px;
     padding-right: 15px;
     margin: 0 !important;
   }
 }

 @media only screen and (max-width: 1199px) {
   .master-plan {
     padding-left: 15px;
   }
 }

 .flat-plan {
   display: flex;
   flex-wrap: wrap;
   margin-top: -15px;
   margin-bottom: 30px;
 }

 .flat-plan li {
   position: relative;
   flex: 0 0 50%;
   max-width: 50%;
   padding-left: 65px;
   padding-bottom: 15px;
   border-bottom: 1px solid #eee;
   border-right: 1px solid #eee;
   padding-right: 15px;
   padding-top: 15px;
 }

 @media only screen and (max-width: 1199px) {
   .flat-plan li {
     padding-left: 45px;
   }
 }

 .flat-plan li:nth-child(2n) {
   border-right: 0;
   padding-left: 80px;
 }

 .flat-plan li:nth-child(2n) img {
   left: 15px;
 }

 @media only screen and (max-width: 1199px) {
   .flat-plan li:nth-child(2n) {
     padding-left: 60px;
   }
 }

 .flat-plan li:nth-child(3),
 .flat-plan li:nth-child(4) {
   border-bottom: 0;
 }

 .flat-plan li img {
   width: 50px;
   height: 50px;
   position: absolute;
   left: 0;
   top: 18px;
 }

 @media only screen and (max-width: 1199px) {
   .flat-plan li img {
     width: 35px;
     height: 35px;
   }
 }

 .flat-plan li h3 {
   font-size: 32px;
   line-height: 1;
   margin-bottom: 2px;
 }

 @media only screen and (max-width: 1199px) {
   .flat-plan li h3 {
     font-size: 22px;
   }
 }

 .flat-plan li span {
   font-size: 14px;
   font-weight: 600;
   text-transform: uppercase;
   display: block;
 }

 @media only screen and (max-width: 1199px) {
   .flat-plan li span {
     font-size: 12px;
   }
 }

 .call-planner {
   background: #faf8f2;
   color: #000;
   font-size: 24px;
   height: 55px;
   line-height: 55px;
   display: inline-block;
   position: relative;
   padding-left: 65px;
   border-radius: 55px;
   padding-right: 30px;
 }

 @media only screen and (max-width: 1199px) {
   .call-planner {
     font-size: 16px;
     height: 40px;
     line-height: 40px;
     padding-right: 20px;
     padding-left: 55px;
   }
 }

 .call-planner i {
   background: #213f99;
   color: #fff;
   width: 51px;
   height: 51px;
   position: absolute;
   left: 2px;
   border-radius: 50px;
   text-align: center;
   line-height: 56px;
   font-size: 32px;
   top: 2px;
 }

 @media only screen and (max-width: 1199px) {
   .call-planner i {
     width: 40px;
     height: 40px;
     left: 0;
     top: 0;
     font-size: 24px;
     line-height: 45px;
   }
 }

 .plan-preview {
   background: #faf8f2;
   height: 100%;
   padding: 80px;
 }

 @media only screen and (max-width: 1400px) {
   .plan-preview {
     padding: 40px;
   }
 }

 @media only screen and (max-width: 991px) {
   .plan-preview {
     padding: 20px;
     margin-top: 30px;
     height: auto;
   }

   .plan-preview img {
     width: 100%;
   }
 }

 .plan-tabs {
   background: #faf8f2;
   padding: 15px 0;
 }

 @media only screen and (max-width: 575px) {
   .plan-tabs {
     padding: 15px;
     margin-bottom: 30px;
   }
 }

 .plan-tabs li {
   display: block;
   width: 100%;
   padding: 0 30px;
 }

 .plan-tabs li:last-child a {
   border-bottom: 0;
 }

 @media only screen and (max-width: 1199px) {
   .plan-tabs li {
     padding: 0 15px;
   }
 }

 @media only screen and (max-width: 575px) {
   .plan-tabs li {
     width: 50%;
     text-align: center;
   }
 }

 .plan-tabs a {
   color: #000;
   padding: 10px 0;
   border-bottom: 1px solid rgba(0, 0, 0, 0.2);
   position: relative;
   font-size: 18px;
   font-weight: 500;
 }

 @media only screen and (max-width: 1199px) {
   .plan-tabs a {
     font-size: 14px;
     padding: 8px 0;
   }
 }

 .plan-tabs a.active {
   background: #213f99;
   margin: 0 -30px;
   padding: 10px 30px;
   border-color: transparent;
   color: #fff;
 }

 .plan-tabs a.active:after {
   content: "";
   position: absolute;
   right: -4px;
   top: 18px;
   background: #213f99;
   height: 10px;
   width: 10px;
   transform: rotate(45deg);
   -moz-transform: rotate(45deg);
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   -o-transform: rotate(45deg);
 }

 @media only screen and (max-width: 1199px) {
   .plan-tabs a.active:after {
     top: 14px;
   }
 }

 @media only screen and (max-width: 575px) {
   .plan-tabs a.active:after {
     top: auto;
     bottom: -4px;
     left: 50%;
     margin-left: -5px;
     text-align: center;
   }
 }

 @media only screen and (max-width: 1199px) {
   .plan-tabs a.active {
     margin: 0 -15px;
     padding: 8px 15px;
   }
 }

 /* Service Area */
 .service-area {
   padding: 0 80px 80px 80px;
   position: relative;
   z-index: 1;
 }

 @media only screen and (max-width: 1400px) {
   .service-area {
     padding: 0 40px 40px 40px;
   }
 }

 @media only screen and (max-width: 575px) {
   .service-area {
     padding: 0 15px 15px 15px;
   }
 }

 .service-area:after {
   content: "";
   width: 100%;
   height: 200px;
   top: 0;
   z-index: -1;
   background: #fff;
   left: 0;
   position: absolute;
 }

 @media only screen and (max-width: 1400px) {
   .service-area:after {
     height: 150px;
   }
 }

 .service-row .col {
   max-width: 20%;
   flex: 0 0 20%;
   background: #fff;
 }

 .service-row .col:nth-child(2n) {
   background: #faf8f2;
 }

 .service-row .col:nth-child(2n) .info {
   background: #faf8f2;
 }

 @media only screen and (max-width: 1199px) {
   .service-row .col {
     max-width: 25%;
     flex: 0 0 25%;
   }
 }

 @media only screen and (max-width: 991px) {
   .service-row .col {
     max-width: 50%;
     flex: 0 0 50%;
   }
 }

 @media only screen and (max-width: 575px) {
   .service-row .col {
     max-width: 100%;
     flex: 0 0 100%;
   }
 }

 .service-box .media img {
   height: 200px;
   object-fit: cover;
   width: 100%;
 }

 @media only screen and (max-width: 1400px) {
   .service-box .media img {
     height: 150px;
   }
 }

 .service-box .info {
   background: #fff;
   padding: 25px 30px;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 @media only screen and (max-width: 1400px) {
   .service-box .info {
     padding: 15px;
   }
 }

 .service-box .info .title {
   margin-bottom: 10px;
   font-size: 16px;
   text-transform: uppercase;
   color: rgb(42, 72, 159);
 }

 @media only screen and (max-width: 1400px) {
   .service-box .info .title {
     font-size: 16px;
   }
 }

 .service-box .info p {
   font-size: 16px;
   color: #777777;
   margin-bottom: 0;
 }

 @media only screen and (max-width: 1400px) {
   .service-box .info p {
     font-size: 14px;
   }
 }

 .service-box:hover .info {
   background: #213f99 !important;
 }

 .service-box:hover .info .title,
 .service-box:hover .info p {
   color: #fff;
 }

 .bg-view {
   width: 100%;
 }

 .dlab-title {
   margin-bottom: 10px;
 }

 .dlab-title a {
   font-size: 22px;
   line-height: 30px;
   display: block;
   color: #3c3c3c;
 }

 .owl-btn-out .owl-next,
 .owl-btn-out .owl-prev {
   margin: 0 -80px;
   font-size: 70px;
   opacity: 0.1;
 }

 .owl-btn-out .owl-next:hover,
 .owl-btn-out .owl-prev:hover {
   opacity: 1;
 }

 @media only screen and (max-width: 1199px) {

   .owl-btn-out .owl-next,
   .owl-btn-out .owl-prev {
     margin: 0 -30px;
     font-size: 40px;
   }
 }

 @media only screen and (max-width: 575px) {

   .owl-btn-out .owl-next,
   .owl-btn-out .owl-prev {
     margin: 0 -15px;
   }
 }

 .blog-grid .dlab-media {
   overflow: hidden;
   margin-bottom: 20px;
   box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
 }

 .dlab-meta {
   margin-bottom: 10px;
 }

 .dlab-meta li {
   display: inline-block;
   margin-right: 10px;
 }

 .dlab-meta li a {
   color: #777;
 }

 .dlab-meta li i {
   color: #213f99;
   transform: scale(1.5);
   margin-right: 8px;
 }

 .readmore {
   color: #000;
   font-weight: 600;
   text-transform: uppercase;
   display: inline-flex;
   align-items: center;
 }

 .readmore i {
   width: 40px;
   background: #213f99;
   height: 40px;
   color: #fff;
   font-size: 24px;
   border-radius: 40px;
   text-align: center;
   line-height: 40px;
   margin-right: 10px;
   display: inline-block;
 }

 .map-view img {
   width: 100%;
 }

 .main-gallery {
   position: relative;
   height: 100vh;
   z-index: 1;
 }

 .main-gallery .gallery-img {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   z-index: -1;
 }

 .main-gallery .gallery-img img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   opacity: 0;
   position: absolute;
   top: 0;
   left: 0;
 }

 .main-gallery .gallery-img img.active {
   -webkit-transition: opacity 0.4s cubic-bezier(0.4, 0.24, 0.06, 0.91);
   -moz-transition: opacity 0.4s cubic-bezier(0.4, 0.24, 0.06, 0.91);
   transition: opacity 0.4s cubic-bezier(0.4, 0.24, 0.06, 0.91);
   opacity: 1;
 }

 .gallery-area .media img {
   height: 20vh;
   object-fit: cover;
   width: 100%;
 }

 .gallery-category {
   display: flex;
   box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.2);
 }

 .gallery-category .category-media {
   display: flex;
   width: 83.33%;
   overflow-x: auto;
 }

 @media only screen and (max-width: 991px) {
   .gallery-category .category-media {
     width: 75%;
   }
 }

 @media only screen and (max-width: 575px) {
   .gallery-category .category-media {
     width: 100%;
   }
 }

 .gallery-category .category-media .items {
   flex: 0 0 20%;
   max-width: 20%;
   border-right: 1px solid rgba(255, 255, 255, 0.2);
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .gallery-category .category-media .items:hover {
   background: rgba(0, 0, 0, 0.3);
 }

 .gallery-category .category-media .items .media {
   opacity: 0;
 }

 @media only screen and (max-width: 991px) {
   .gallery-category .category-media .items {
     flex: 0 0 33.33%;
     max-width: 33.33%;
   }
 }

 .category-box {
   position: relative;
   z-index: 1;
   width: 16.66%;
 }

 @media only screen and (max-width: 991px) {
   .category-box {
     width: 25%;
   }
 }

 @media only screen and (max-width: 575px) {
   .category-box {
     display: none;
   }
 }

 .category-box:hover+.category-media .media {
   opacity: 1;
 }

 .category-box:after {
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   background: #000;
   opacity: 0.5;
   left: 0;
   top: 0;
 }

 .category-box .media img {
   width: 100%;
 }

 .category-box .info {
   position: absolute;
   bottom: 0;
   left: 0;
   padding: 20px 30px;
   width: 100%;
   z-index: 1;
 }

 @media only screen and (max-width: 1400px) {
   .category-box .info {
     padding: 15px 15px;
   }
 }

 .category-box .info .title {
   color: #fff;
   font-size: 30px;
   margin-bottom: 5px;
   text-transform: uppercase;
 }

 @media only screen and (max-width: 1400px) {
   .category-box .info .title {
     font-size: 22px;
   }
 }

 .category-box .info span {
   color: #fff;
   display: block;
 }

 @media only screen and (max-width: 1400px) {
   .category-box .info span {
     font-size: 14px;
   }
 }

 .category-box .info span i {
   color: #213f99;
   transform: scale(1.4);
   -moz-transform: scale(1.4);
   -webkit-transform: scale(1.4);
   -ms-transform: scale(1.4);
   -o-transform: scale(1.4);
   margin-right: 10px;
 }

 @media only screen and (min-width: 1800px) {
   .col-xxxl-2 {
     flex: 0 0 16.6666666667%;
     max-width: 16.6666666667%;
   }
 }

 .sidenav-list {
   position: fixed;
   right: 0;
   top: 50%;
   transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   -webkit-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   -o-transform: translateY(-50%);
   z-index: 9999;
   background: #2c1b11;
   border-radius: 6px 0 0 6px;
   width: 45px;
 }

 @media only screen and (max-width: 575px) {
   .sidenav-list {
     width: 35px;
   }
 }

 .sidenav-list ul {
   display: block;
   padding: 0;
 }

 .sidenav-list ul li {
   padding: 0;
   display: block;
 }

 .sidenav-list ul a {
   padding: 0;
   color: #fff;
   height: 45px;
   display: block;
   border-bottom: 1px solid rgba(255, 255, 255, 0.1);
   position: relative;
 }

 @media only screen and (max-width: 575px) {
   .sidenav-list ul a {
     height: 35px;
   }
 }

 .sidenav-list ul a i {
   padding: 0;
   width: 45px;
   height: 45px;
   text-align: center;
   font-size: 20px;
   line-height: 45px;
   position: absolute;
   right: 0;
 }

 @media only screen and (max-width: 575px) {
   .sidenav-list ul a i {
     width: 35px;
     height: 35px;
     line-height: 35px;
     font-size: 16px;
   }
 }

 .sidenav-list ul a span {
   height: 45px;
   line-height: 45px;
   padding: 0 45px 0 20px;
   text-transform: uppercase;
   font-size: 15px;
   font-weight: 600;
   font-family: 'Roboto Condensed', sans-serif;
   display: block;
   position: absolute;
   right: 0;
   background: #213f99;
   border-radius: 6px 0 0 6px;
   white-space: nowrap;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   transform: rotateY(90deg);
   -moz-transform: rotateY(90deg);
   -webkit-transform: rotateY(90deg);
   -ms-transform: rotateY(90deg);
   -o-transform: rotateY(90deg);
   transform-origin: right;
   -moz-transform-origin: right;
   -webkit-transform-origin: right;
   -ms-transform-origin: right;
   -o-transform-origin: right;
   z-index: -1;
 }

 @media only screen and (max-width: 575px) {
   .sidenav-list ul a span {
     padding: 0 35px 0 15px;
     height: 35px;
     line-height: 35px;
     font-size: 13px;
     font-weight: 500;
   }
 }

 .sidenav-list ul a.active span,
 .sidenav-list ul a:hover span {
   transform: rotateY(0);
   -moz-transform: rotateY(0);
   -webkit-transform: rotateY(0);
   -ms-transform: rotateY(0);
   -o-transform: rotateY(0);
 }

 @media only screen and (min-width: 991px) {
   .header-tb .header-nav .nav>li>a {
     color: #fff;
   }
 }

 .portbox1 {
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   transform: scale(1);
   -moz-transform: scale(1);
   -webkit-transform: scale(1);
   -ms-transform: scale(1);
   -o-transform: scale(1);
 }

 .portbox1 img {
   transform: scale(1.05);
   -moz-transform: scale(1.05);
   -webkit-transform: scale(1.05);
   -ms-transform: scale(1.05);
   -o-transform: scale(1.05);
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .portbox1 .portinner {
   transform: scale(0.2);
   -webkit-transform: scale(0.2);
   -moz-transform: scale(0.2);
   -ms-transform: scale(0.2);
   -o-transform: scale(0.2);
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .portbox1 .overlay-bx {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 100%;
   text-align: center;
   z-index: 10;
   opacity: 0;
   visibility: hidden;
   padding: 0 10px;
 }

 .portbox1 .overlay-bx span {
   display: block;
   margin-bottom: 10px;
   color: #fff;
 }

 .portbox1 .overlay-bx .port-title {
   font-size: 35px;
   line-height: 45px;
   margin-bottom: 0;
 }

 @media only screen and (max-width: 1400px) {
   .portbox1 .overlay-bx .port-title {
     font-size: 24px;
     line-height: 30px;
   }
 }

 .portbox1 .overlay-bx .port-title a {
   color: #fff;
 }

 .portbox1 .overlay-bx .lightimg {
   background: #213f99;
   position: absolute;
   bottom: 0;
   right: 0;
   width: 50px;
   height: 50px;
   line-height: 50px;
   margin: 0;
 }

 .portbox1:hover {
   transform: scale(1.05);
   box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
 }

 .portbox1:hover img {
   transform: scale(1);
   -moz-transform: scale(1);
   -webkit-transform: scale(1);
   -ms-transform: scale(1);
   -o-transform: scale(1);
 }

 .portbox1:hover .overlay-bx {
   opacity: 1;
   visibility: visible;
 }

 .portbox1:hover .portinner {
   transform: scale(1);
   -moz-transform: scale(1);
   -webkit-transform: scale(1);
   -ms-transform: scale(1);
   -o-transform: scale(1);
 }

 .videoplay-bx {
   width: 140px;
   height: 140px;
   line-height: 140px;
   font-size: 50px;
   text-align: center;
   color: #2d2d2d;
   background-color: #fdfdfd;
   border-radius: 140px;
   display: inline-block;
   margin-top: 50px;
   margin-bottom: 100px;
   box-shadow: 0 0 150px 0 rgba(0, 0, 0, 0.3);
 }

 .portbox2 .dlab-media:after,
 .portbox2 .title,
 .portbox2 .dlab-info-has,
 .portbox2 p,
 .portbox2 span {
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .portbox2 .dlab-media {
   border-radius: 4px;
   margin-bottom: 30px;
   position: relative;
 }

 .portbox2 .dlab-media:after {
   content: "";
   height: 100%;
   position: absolute;
   width: 100%;
   background: #0f0f0f;
   background: -moz-linear-gradient(top, rgba(15, 15, 15, 0) 0%, #0f0f0f 100%);
   background: linear-gradient(to bottom, rgba(15, 15, 15, 0) 0%, #0f0f0f 100%);
   left: 0;
   top: 0;
 }

 .portbox2 .title {
   margin-bottom: 0;
 }

 .portbox2 span {
   color: #fff;
   font-size: 15px;
 }

 .portbox2 p {
   margin-bottom: 0;
   font-size: 15px;
   visibility: hidden;
   height: 1px;
   opacity: 0;
 }

 .portbox2 .dlab-info-has {
   padding: 30px;
 }

 .portbox2:hover .dlab-info-has {
   bottom: 50%;
   transform: translateY(50%);
   -moz-transform: translateY(50%);
   -webkit-transform: translateY(50%);
   -ms-transform: translateY(50%);
   -o-transform: translateY(50%);
 }

 .portbox2:hover span {
   display: none;
 }

 .portbox2:hover p {
   visibility: visible;
   height: auto;
   opacity: 1;
 }

 .portbox2:hover .title {
   margin-bottom: 10px;
 }

 .portbox2:hover .dlab-media:after {
   background: #0f0f0f;
   opacity: 0.85;
 }

 /* .team-member{
	display:flex;
	align-items: center;
	flex-direction: row-reverse;
	
	.team-info,
	.team-media{
		max-width: 50%;
		flex: 0 0 50%;
	}
	.team-job-title{
		color:$primary;
		font-size:18px;
	}
	.team-title{
		font-size:52px;
		line-height:62px;
		margin-bottom:15px;
	}
	.team-info p{
		color:#898989;
		font-size:18px;
		line-height:30px;
	}
}
.team-perinfo {
    display: flex;
	.team-sign{
		max-width: 130px;
		margin-right: 30px;
	}
	.name{
		padding-left:15px;
		h3{
			font-weight: 600;
			margin-bottom: 5px;
		}
		span{
			color: #939393;
			margin-bottom:10px;
			display:block;
		}
	}
}
.team-profile{
	display:flex;
	align-items: start;
	
	.team-pic{
		width: 45px;
		height: 45px;
		border-radius: 45px;
		overflow: hidden;
		min-width: 45px;
		margin-top: 15px;
	}
	ul{
		margin:0;
		padding:0;
		list-style:none;
		li{
			display:inline-block;
			a{
				font-size:18px;
				color:#222;
				padding-right:20px;
			}
		}
	}
} */
 .team-carousel .owl-nav .owl-prev,
 .team-carousel .owl-nav .owl-next {
   padding: 0;
   background: transparent;
   margin: 0 -15px;
 }

 .team-carousel .owl-nav .owl-prev span,
 .team-carousel .owl-nav .owl-next span {
   padding: 0;
   background: transparent;
   margin: 0 4px;
   font-size: 14px;
   text-transform: uppercase;
   font-weight: 600;
 }

 .team-carousel .owl-nav .owl-prev i,
 .team-carousel .owl-nav .owl-next i {
   height: 45px;
   width: 45px;
   text-align: center;
   border-radius: 45px;
   background: #213f99;
   font-size: 22px;
   line-height: 45px;
   color: #fff;
 }

 .blog-half {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
 }

 @media only screen and (max-width: 991px) {
   .blog-half {
     display: block;
   }
 }

 .blog-half.reverse {
   flex-direction: row-reverse;
   text-align: right;
 }

 @media only screen and (max-width: 991px) {
   .blog-half.reverse {
     flex-direction: column;
     text-align: left;
   }
 }

 .blog-half .dlab-media {
   width: 55%;
   max-width: 55%;
 }

 @media only screen and (max-width: 991px) {
   .blog-half .dlab-media {
     width: 100%;
     max-width: 100%;
   }
 }

 .blog-half .dlab-title {
   font-size: 38px;
 }

 @media only screen and (max-width: 991px) {
   .blog-half .dlab-title {
     font-size: 24px;
   }
 }

 .blog-half p {
   font-size: 18px;
   color: #afafaf;
 }

 @media only screen and (max-width: 991px) {
   .blog-half p {
     font-size: 16px;
   }
 }

 .blog-half .dlab-info {
   width: 45%;
   max-width: 45%;
   padding: 0 40px;
 }

 @media only screen and (max-width: 991px) {
   .blog-half .dlab-info {
     width: 100%;
     max-width: 100%;
     padding: 0 0 0 0;
   }
 }

 @media only screen and (max-width: 991px) {
   .blog-half .dlab-info+.dlab-media {
     margin-top: 20px;
     padding: 0 0 0 0;
   }
 }

 @media only screen and (max-width: 991px) {
   .blog-half .dlab-media+.dlab-info {
     padding: 20px 0 0 0;
   }
 }

 .project-info-box {
   position: relative;
 }

 @media only screen and (max-width: 767px) {
   .project-info-box img {
     height: 400px;
     object-fit: cover;
   }
 }

 .project-info-box .project-content {
   background: #fff;
   position: absolute;
   left: 0;
   top: 50%;
   padding: 40px 50px;
   width: 500px;
   transform: translateY(-100%);
   -moz-transform: translateY(-100%);
   -ms-transform: translateY(100%);
   -o-transform: translateY(-100%);
   -webkit-transform: translateY(-100%);
   opacity: 0;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 @media only screen and (max-width: 991px) {
   .project-info-box .project-content {
     padding: 15px;
     width: 250px;
   }
 }

 .active .project-info-box .project-content {
   opacity: 1;
   transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   -ms-transform: translateY(50%);
   -o-transform: translateY(-50%);
   -webkit-transform: translateY(-50%);
 }

 .project-carousel-1 .owl-nav {
   display: flex;
   align-items: center;
   position: absolute;
   right: 20px;
   bottom: 20px;
 }

 .project-carousel-1 .owl-next,
 .project-carousel-1 .owl-prev {
   padding: 0;
   background: transparent;
   font-family: 'Oswald', sans-serif;
   font-size: 16px;
   text-transform: uppercase;
   color: #fff;
   display: flex;
   align-items: center;
   letter-spacing: 5px;
   margin: 0 15px;
   text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
 }

 @media only screen and (max-width: 991px) {

   .project-carousel-1 .owl-next,
   .project-carousel-1 .owl-prev {
     font-size: 13px;
   }
 }

 .project-carousel-1 .owl-next i,
 .project-carousel-1 .owl-prev i {
   font-size: 22px;
 }

 @media only screen and (max-width: 991px) {

   .project-carousel-1 .owl-next i,
   .project-carousel-1 .owl-prev i {
     font-size: 16px;
   }
 }

 .project-area {
   position: relative;
   z-index: 1;
 }

 .project-area:before {
   content: "";
   z-index: -1;
   background: #fff;
   height: 100px;
   bottom: 0;
   left: 0;
   width: 100%;
   position: absolute;
 }

 .team-box.style1 .dlab-media {
   position: relative;
   overflow: unset;
 }

 .team-box.style1 .team-info {
   margin-top: 30px;
 }

 .team-box.style1 .team-info .team-title {
   font-size: 24px;
   font-weight: 600;
   margin-bottom: 0;
 }

 .team-box.style1 .dlab-media {
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .team-box.style1 .dlab-media img {
   z-index: 99;
   position: relative;
   padding: 15px 15px 0 15px;
 }

 .team-box.style1 .dlab-media:after {
   content: "";
   position: absolute;
   height: 230px;
   width: 230px;
   background-color: #faf8f2;
   left: 50%;
   bottom: 0;
   z-index: 0;
   transform: translate(-50%, 15px);
   -moz-transform: translate(-50%, 15px);
   -ms-transform: translate(-50%, 15px);
   -o-transform: translate(-50%, 15px);
   -webkit-transform: translate(-50%, 15px);
   border-radius: 50%;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .team-box.style1:hover .dlab-media {
   transform: translateY(-15px);
   -moz-transform: translateY(-15px);
   -webkit-transform: translateY(-15px);
   -ms-transform: translateY(-15px);
   -o-transform: translateY(-15px);
 }

 .team-box.style1:hover .dlab-media:after {
   transform: translate(-50%, 15px) scale(1.1);
   -moz-transform: translate(-50%, 15px) scale(1.1);
   -webkit-transform: translate(-50%, 15px) scale(1.1);
   -ms-transform: translate(-50%, 15px) scale(1.1);
   -o-transform: translate(-50%, 15px) scale(1.1);
 }

 .team-box.style1.green .dlab-media:after {
   background: #d5f6f3;
 }

 .team-box.style1.pink .dlab-media:after {
   background: #ffebf9;
 }

 .team-box.style1.yellow .dlab-media:after {
   background: #feede7;
 }

 /* About Us Box ======= */
 .about-year {
   display: flex;
   align-items: center;
   padding-top: 30px;
   padding-bottom: 30px;
   margin-bottom: 15px;
 }

 .about-year span {
   font-size: 85px;
   color: #000;
   font-weight: 600;
   line-height: 85px;
   position: relative;
   padding-left: 50px;
   padding-right: 15px;
   z-index: 1;
 }

 .about-year span:after {
   content: "";
   width: 130px;
   height: 130px;
   display: block;
   position: absolute;
   border: 5px solid #213f99;
   left: 0;
   top: -25px;
   z-index: -1;
 }

 .about-year p {
   font-size: 22px;
   margin-bottom: 0;
 }

 .list-details {
   list-style: none;
   margin: 0;
   padding: 0;
   max-width: 430px;
 }

 @media only screen and (max-width: 991px) {
   .list-details {
     max-width: 100%;
   }
 }

 .list-details li {
   color: #000;
   display: flex;
   justify-content: space-between;
   padding: 10px 0;
   border-top: 1px solid rgba(0, 0, 0, 0.1);
 }

 .list-details li:first-child {
   border-top: 0;
 }

 @media only screen and (max-width: 991px) {
   .list-details li {
     padding: 5px 0;
     font-size: 13px;
   }
 }

 .welcome-screen {
   position: relative;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   width: 100%;
   min-height: 100vh;
   z-index: 1;
   overflow: hidden;
 }

 .welcome-screen #video-background {
   position: fixed;
   right: 0;
   bottom: 0;
   min-width: 100%;
   min-height: 100%;
   width: auto;
   height: auto;
   z-index: -100;
 }

 .welcome-screen .container-fluid,
 .welcome-screen .container-sm,
 .welcome-screen .container-md,
 .welcome-screen .container-lg,
 .welcome-screen .container-xl {
   padding: 30px;
   display: flex;
   flex-direction: column;
   height: 100vh;
   justify-content: space-between;
 }

 @media only screen and (max-width: 991px) {

   .welcome-screen .container-fluid,
   .welcome-screen .container-sm,
   .welcome-screen .container-md,
   .welcome-screen .container-lg,
   .welcome-screen .container-xl {
     padding: 30px;
   }
 }

 @media only screen and (max-width: 575px) {

   .welcome-screen .container-fluid,
   .welcome-screen .container-sm,
   .welcome-screen .container-md,
   .welcome-screen .container-lg,
   .welcome-screen .container-xl {
     padding: 20px;
   }
 }

 .wlcm-logo {
   width: 150px;
 }

 @media only screen and (max-width: 1199px) {
   .wlcm-logo {
     width: 150px;
     padding-right: 15px;
   }
 }

 .welcome-header {
   display: flex;
   justify-content: space-between;
 }

 .wlcm-contact {
   color: #fff;
   padding-left: 70px;
   position: relative;
 }

 @media only screen and (max-width: 1199px) {
   .wlcm-contact {
     padding-left: 60px;
   }
 }

 @media only screen and (max-width: 575px) {
   .wlcm-contact {
     padding-left: 42px;
   }
 }

 .wlcm-contact i {
   top: 0;
   color: #213f99;
   font-size: 60px;
   margin-right: 10px;
   position: absolute;
   left: 0;
 }

 @media only screen and (max-width: 1199px) {
   .wlcm-contact i {
     font-size: 48px;
   }
 }

 @media only screen and (max-width: 575px) {
   .wlcm-contact i {
     font-size: 35px;
     top: 4px;
   }
 }

 .wlcm-contact span {
   color: #fff;
   font-size: 15px;
   text-transform: uppercase;
   font-weight: 600;
   display: block;
 }

 @media only screen and (max-width: 1199px) {
   .wlcm-contact span {
     font-size: 14px;
   }
 }

 @media only screen and (max-width: 575px) {
   .wlcm-contact span {
     font-size: 12px;
     font-weight: 400;
   }
 }

 .wlcm-contact h2 {
   color: #fff;
   font-size: 28px;
   margin: 0;
   white-space: nowrap;
 }

 @media only screen and (max-width: 1199px) {
   .wlcm-contact h2 {
     font-size: 22px;
   }
 }

 @media only screen and (max-width: 575px) {
   .wlcm-contact h2 {
     font-size: 16px;
   }
 }

 .wlcm-form {
   display: flex;
   align-items: flex-end;
   justify-content: space-between;
 }

 @media only screen and (max-width: 575px) {
   .wlcm-form {
     display: block;
     margin-bottom: auto;
     margin-top: auto;
   }
 }

 .enter-button .btn {
   padding-right: 55px;
   position: relative;
 }

 @media only screen and (max-width: 575px) {
   .enter-button {
     text-align: center;
     display: none;
   }
 }

 .enter-button i {
   transform: scale(1.3);
   margin-left: 5px;
   display: inline-block;
 }

 .enter-form {
   background: rgba(0, 0, 0, 0.7);
   padding: 40px;
   width: 400px;
   -webkit-backdrop-filter: blur(3px);
   backdrop-filter: blur(3px);
   position: relative;
 }

 .enter-form.active {
   animation: shake 0.5s;
   animation-iteration-count: infinite;
 }

 .enter-form:before,
 .enter-form:after {
   content: "";
   position: absolute;
   width: 120px;
   height: 120px;
 }

 .enter-form:before {
   top: 0;
   left: 0;
   border-top: 5px solid #213f99;
   border-left: 5px solid #213f99;
 }

 .enter-form:after {
   bottom: 0;
   right: 0;
   border-right: 5px solid #213f99;
   border-bottom: 5px solid #213f99;
 }

 @media only screen and (max-width: 1400px) {
   .enter-form {
     padding: 30px;
     width: 350px;
   }
 }

 @media only screen and (max-width: 575px) {
   .enter-form {
     width: 100%;
     padding: 20px;
     margin-bottom: 20px;
   }
 }

 .enter-form h2.wlcm-form-title {
   position: relative;
   padding-bottom: 10px;
   margin-bottom: 30px;
   font-size: 25px;
   font-weight: 600;
   color: #fff;
 }

 @media only screen and (max-width: 1400px) {
   .enter-form h2.wlcm-form-title {
     font-size: 22px;
     margin-bottom: 20px;
   }
 }

 .enter-form h2.wlcm-form-title:after {
   content: "";
   background: #213f99;
   width: 55px;
   height: 3px;
   position: absolute;
   bottom: 0;
   left: 0;
 }

 .enter-form .input-group-prepend {
   height: 45px;
   border: 1px solid rgba(255, 255, 255, 0.15);
   border-right: 0;
   background: rgba(255, 255, 255, 0.02);
   border-radius: inherit;
 }

 .enter-form .input-group-prepend span {
   background: transparent;
   color: #fff;
   font-size: 22px;
   padding: 0 0 0 15px;
   height: 43px;
   line-height: 43px;
 }

 @media only screen and (max-width: 1400px) {
   .enter-form .input-group-prepend span {
     height: 38px;
     line-height: 38px;
   }
 }

 @media only screen and (max-width: 1400px) {
   .enter-form .input-group-prepend {
     height: 40px;
   }
 }

 .enter-form .form-group {
   margin-bottom: 20px;
 }

 .enter-form .form-control {
   background: rgba(255, 255, 255, 0.03);
   height: 45px;
   border: 1px solid rgba(255, 255, 255, 0.15);
   border-left: 0;
   border-radius: 0;
   font-size: 16px;
   color: #fff;
 }

 @media only screen and (max-width: 1400px) {
   .enter-form .form-control {
     height: 40px;
     font-size: 14px;
   }
 }

 .enter-form .form-control::-moz-placeholder {
   color: #fff;
   opacity: 0.7;
 }

 .enter-form .form-control::-webkit-input-placeholder {
   color: #fff;
   opacity: 0.7;
 }

 .enter-form .custom-checkbox {
   padding-left: 35px;
 }

 @media only screen and (max-width: 1400px) {
   .enter-form .custom-checkbox {
     padding-left: 26px;
   }
 }

 .enter-form .custom-checkbox label {
   font-size: 16px;
   margin-bottom: 0;
   color: #fff;
   font-weight: 400;
   line-height: 24px;
   opacity: 0.8;
 }

 @media only screen and (max-width: 1400px) {
   .enter-form .custom-checkbox label {
     font-size: 15px;
     line-height: 18px;
   }
 }

 .enter-form .custom-checkbox .custom-control-label:after {
   top: 0;
   left: -35px;
   display: block;
   width: 24px;
   height: 24px;
 }

 @media only screen and (max-width: 1400px) {
   .enter-form .custom-checkbox .custom-control-label:after {
     height: 18px;
     width: 18px;
     left: -26px;
   }
 }

 .enter-form .custom-checkbox .custom-control-label:before {
   width: 24px;
   height: 24px;
   border: 2px solid #213f99;
   left: -35px;
   top: 0;
   background: transparent;
 }

 @media only screen and (max-width: 1400px) {
   .enter-form .custom-checkbox .custom-control-label:before {
     height: 18px;
     width: 18px;
     left: -26px;
   }
 }

 @keyframes shake {
   0% {
     transform: translate(1px, 1px) rotate(0deg);
   }

   10% {
     transform: translate(-1px, -2px) rotate(-1deg);
   }

   20% {
     transform: translate(-3px, 0px) rotate(1deg);
   }

   30% {
     transform: translate(3px, 2px) rotate(0deg);
   }

   40% {
     transform: translate(1px, -1px) rotate(1deg);
   }

   50% {
     transform: translate(-1px, 2px) rotate(-1deg);
   }

   60% {
     transform: translate(-3px, 1px) rotate(0deg);
   }

   70% {
     transform: translate(3px, 1px) rotate(-1deg);
   }

   80% {
     transform: translate(-1px, -1px) rotate(1deg);
   }

   90% {
     transform: translate(1px, 2px) rotate(0deg);
   }

   100% {
     transform: translate(1px, -2px) rotate(-1deg);
   }
 }

 .enter-animate {
   text-align: center;
   width: 26px;
   height: 50px;
   border-radius: 40px;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   z-index: 10;
   transform: rotate(-90deg);
   position: absolute;
   right: 30px;
   bottom: -2px;
 }

 .enter-animate .arrow {
   position: absolute;
   width: 12px;
   height: 2px;
   opacity: 0;
   transform: scale3d(0.5, 0.5, 0.5);
   animation: move 3s ease-out infinite;
   left: 6px;
   top: -3px;
   margin: 0px 0px;
 }

 .enter-animate .arrow:first-child {
   animation: move 3s ease-out 1s infinite;
 }

 .enter-animate .arrow:nth-child(2) {
   animation: move 3s ease-out 2s infinite;
 }

 .enter-animate .arrow:after,
 .enter-animate .arrow:before {
   content: ' ';
   position: absolute;
   top: 0;
   height: 100%;
   width: 51%;
   background: #fff;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .enter-animate .arrow:before {
   left: 0;
   transform: skew(0deg, 30deg);
 }

 .enter-animate .arrow:after {
   right: 0;
   width: 50%;
   transform: skew(0deg, -30deg);
 }

 @keyframes move {
   25% {
     opacity: 1;
   }

   33% {
     opacity: 1;
     transform: translateY(30px);
   }

   67% {
     opacity: 1;
     transform: translateY(40px);
   }

   100% {
     opacity: 0;
     transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
   }
 }

 .contact-button {
   position: fixed;
   right: -10px;
   top: 50%;
   padding: 0;
   height: 80px;
   width: 80px;
   transform: translateY(-50%);
 }

 .contact-button li {
   position: absolute;
   right: 0;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   top: 50%;
   margin-top: -17px;
   z-index: -1;
   opacity: 0;
 }

 .contact-button li a {
   padding: 0;
   width: 35px;
   height: 35px;
   background: rgba(0, 0, 0, 0.7);
   top: 0;
   text-align: center;
   line-height: 35px;
   color: #fff;
   border-radius: 35px;
 }

 .contact-button .contact-btn {
   padding: 0;
   top: 0;
   margin: 0;
   z-index: 1;
   opacity: 1;
 }

 .contact-button .contact-btn a {
   padding: 0;
   width: 80px;
   height: 80px;
   background: rgba(0, 0, 0, 0.7);
   border-radius: 80px;
   text-align: center;
   line-height: 80px;
   font-size: 30px;
   color: #fff;
 }

 .contact-button.active li {
   opacity: 1;
   margin: 0;
 }

 .contact-button.active li:nth-child(2) {
   top: -45px;
   right: 60px;
 }

 .contact-button.active li:nth-child(3) {
   top: -90px;
   right: 60px;
 }

 .contact-button.active li:nth-child(4) {
   top: 90px;
   right: 65px;
 }

 .contact-button.active li:nth-child(5) {
   top: 135px;
   right: 65px;
 }

 .contact-button-2 {
   position: fixed;
   right: 30px;
   top: 30px;
   padding: 0;
   height: 60px;
   width: 60px;
 }

 @media only screen and (max-width: 575px) {
   .contact-button-2 {
     right: 20px;
     top: 10px;
   }
 }

 .contact-button-2 li {
   position: absolute;
   right: 0;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   top: 50%;
   margin-top: -17px;
   z-index: -1;
   opacity: 0;
 }

 .contact-button-2 li a {
   padding: 0;
   width: 35px;
   height: 35px;
   top: 0;
   text-align: center;
   line-height: 35px;
   color: #fff;
   border-radius: 35px;
   display: block;
   box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
 }

 .contact-button-2 li a.facebook {
   background: #4267b2;
 }

 .contact-button-2 li a.whatsapp {
   background: #3ebc2a;
 }

 .contact-button-2 li a.phone {
   background: #3e69d5;
 }

 .contact-button-2 li a.enquire {
   background: #213f99;
 }

 .contact-button-2 .contact-btn {
   padding: 0;
   top: 0;
   margin: 0;
   z-index: 1;
   opacity: 1;
 }

 .contact-button-2 .contact-btn a {
   padding: 0;
   width: 60px;
   height: 60px;
   background: rgba(0, 0, 0, 0.7);
   border-radius: 60px;
   text-align: center;
   line-height: 60px;
   font-size: 24px;
   color: #fff;
   position: relative;
 }

 .contact-button-2 .contact-btn a:after {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: block;
   border: 1px solid #213f99;
   -webkit-border-radius: 100%;
   -khtml-border-radius: 100%;
   -moz-border-radius: 100%;
   -ms-border-radius: 100%;
   -o-border-radius: 100%;
   border-radius: 100%;
   animation: animationSignal2;
   animation-iteration-count: infinite;
   animation-duration: 3s;
   -webkit-animation: animationSignal2;
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-duration: 3s;
   z-index: -1;
   transform: scale(1);
   -moz-transform: scale(1);
   -webkit-transform: scale(1);
   -ms-transform: scale(1);
   -o-transform: scale(1);
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .contact-button-2 .contact-btn a:before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: block;
   border: 1px solid #213f99;
   -webkit-border-radius: 50%;
   -khtml-border-radius: 50%;
   -moz-border-radius: 50%;
   -ms-border-radius: 50%;
   -o-border-radius: 50%;
   border-radius: 100%;
   animation: animationSignal1;
   animation-iteration-count: infinite;
   animation-duration: 3s;
   -webkit-animation: animationSignal1;
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-duration: 3s;
   z-index: -1;
   transform: scale(1);
   -moz-transform: scale(1);
   -webkit-transform: scale(1);
   -ms-transform: scale(1);
   -o-transform: scale(1);
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .contact-button-2.active li {
   opacity: 1;
 }

 .contact-button-2.active li:nth-child(2) {
   right: 70px;
 }

 .contact-button-2.active li:nth-child(3) {
   right: 114px;
 }

 .contact-button-2.active li:nth-child(4) {
   right: 159px;
 }

 .contact-button-2.active li:nth-child(5) {
   right: 204px;
 }

 .aminite-icon-bx {
   padding: 25px 10px;
   box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.07);
   text-align: center;
 }

 .aminite-icon-bx i {
   font-size: 70px;
   line-height: 70px;
   color: #000;
   margin-bottom: 10px;
   display: block;
 }

 .aminite-icon-bx .title {
   text-transform: uppercase;
   margin-bottom: 0;
   font-size: 16px;
 }

 .gallery-bottom {
   margin-bottom: -10px;
 }

 .gallery-bottom .owl-prev,
 .gallery-bottom .owl-next {
   width: 35px;
   height: 35px;
   background: #fff;
   color: #000;
   text-align: center;
   line-height: 33px;
   margin: 0 0 !important;
   border-radius: 35px;
   border: 1px solid #eaeaea;
   font-size: 14px;
 }

 .gallery-bottom .gallery-box {
   border: 4px solid #fff;
   border-radius: 12px;
   overflow: hidden;
   box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2);
   margin: 10px 0;
   opacity: 0.5;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .gallery-bottom .current .gallery-box {
   border: 4px solid #000;
   opacity: 1;
 }

 .gallery-top {
   margin: -30px 0 0px 0;
 }

 .gallery-top .gallery-media {
   z-index: 2;
   border: 10px solid #fff;
   box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
   padding: 0;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   margin: 30px 0;
   opacity: 0.5;
   transform: scale(0.8);
   position: relative;
   border-radius: 30px;
   overflow: hidden;
 }

 @media only screen and (max-width: 1199px) {
   .gallery-top .gallery-media {
     border: 5px solid #fff;
     border-radius: 15px;
   }
 }

 .gallery-top .gallery-media .title {
   position: absolute;
   padding: 15px 40px;
   bottom: 0;
   left: 0;
   background: #213f99;
   margin: 0;
   color: #fff;
   font-size: 20px;
   text-transform: uppercase;
   font-weight: 500;
 }

 @media only screen and (max-width: 1199px) {
   .gallery-top .gallery-media .title {
     font-size: 16px;
     padding: 12px 30px;
   }
 }

 .gallery-top .owl-item.active.center .gallery-media {
   transform: scale(1);
   z-index: 3;
   opacity: 1;
 }

 .gallery-top .owl-item.active.center+.owl-item .gallery-media {
   transform: scale(0.8);
 }

 .gallery-top .owl-nav.disabled {
   display: none;
 }

 .aminite-overlay {
   background-attachment: fixed;
   background-size: cover;
   background-position: center;
 }

 .aminite-overlay .aminite-over-area {
   padding-top: 1px;
 }

 .aminite-overlay .overlay-box {
   background: rgba(2, 9, 33, 0.8);
   padding: 40px;
   max-width: 600px;
   margin-left: auto;
   margin-top: 35vh;
 }

 @media only screen and (max-width: 1199px) {
   .aminite-overlay .overlay-box {
     padding: 30px;
   }
 }

 @media only screen and (max-width: 575px) {
   .aminite-overlay .overlay-box {
     margin-top: 150px;
     padding: 20px;
   }
 }

 .aminite-overlay .overlay-box .title {
   font-size: 40px;
   color: #fff;
   margin-bottom: 20px;
 }

 @media only screen and (max-width: 1199px) {
   .aminite-overlay .overlay-box .title {
     font-size: 28px;
   }
 }

 .aminite-overlay .overlay-box p {
   font-size: 18px;
   margin-bottom: 0;
   opacity: 0.7;
   color: #fff;
 }

 @media only screen and (max-width: 1199px) {
   .aminite-overlay .overlay-box p {
     font-size: 16px;
   }
 }

 .post-cobble {
   padding-left: 4px;
   padding-right: 4px;
   padding-top: 4px;
 }

 .post-cobble .dlab-card {
   margin-bottom: 8px;
 }

 .post-cobble .overlay-post img {
   height: 300px;
   object-fit: cover;
 }

 @media only screen and (max-width: 1199px) {
   .post-cobble .overlay-post img {
     height: 250px;
   }
 }

 .post-cobble .overlay-post.large img {
   height: 608px;
 }

 @media only screen and (max-width: 1199px) {
   .post-cobble .overlay-post.large img {
     height: 500px;
   }
 }

 @media only screen and (max-width: 575px) {
   .post-cobble .overlay-post.large img {
     height: 250px;
   }
 }

 .blog-grid.overlay-post {
   position: relative;
   z-index: 1;
   overflow: hidden;
 }

 .blog-grid.overlay-post .dlab-media {
   border-radius: 0;
   margin-bottom: 0;
   box-shadow: unset;
 }

 .blog-grid.overlay-post .dlab-media img {
   width: 100%;
 }

 .blog-grid.overlay-post .dlab-info {
   padding: 20px 30px 20px 30px;
   position: absolute;
   bottom: 0px;
   left: 0;
   z-index: 3;
 }

 @media only screen and (max-width: 991px) {
   .blog-grid.overlay-post .dlab-info {
     padding: 15px 15px 10px 15px;
   }
 }

 .blog-grid.overlay-post .dlab-info .dlab-meta li {
   color: #fff;
 }

 .blog-grid.overlay-post .dlab-info .dlab-meta li i,
 .blog-grid.overlay-post .dlab-info .dlab-meta li a {
   color: #fff;
 }

 .blog-grid.overlay-post .dlab-info .dlab-title a {
   color: #fff;
 }

 .blog-grid.overlay-post:after {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   z-index: 2;
   padding: 30px;
   width: 100%;
   height: 100%;
   background: #000;
   opacity: 0.3;
 }

 /* contact-box */
 .contact-box {
   background: #fff;
   border-color: rgba(0, 0, 0, 0.1);
   overflow: hidden;
   border-radius: 4px;
 }

 @media only screen and (max-width: 575px) {
   .contact-box.p-a30 {
     padding: 15px;
   }
 }

 .contact-box .title-box {
   margin: -30px -30px 30px -30px;
   padding: 20px 30px;
   background: #213f99;
   font-size: 24px;
   font-weight: 600;
   color: #fff;
   line-height: 35px;
 }

 @media only screen and (max-width: 575px) {
   .contact-box .title-box {
     margin: -15px -15px 15px -15px;
     padding: 10px 15px;
   }
 }

 .contact-box .form-control {
   background: #faf8f2;
   font-size: 16px;
   height: 46px;
   padding: 10px 20px;
   border-radius: 3px;
   border: 1px solid rgba(0, 0, 0, 0.05);
 }

 .contact-box .form-group {
   margin-bottom: 30px;
 }

 @media only screen and (max-width: 575px) {
   .contact-box .form-group {
     margin-bottom: 15px;
   }
 }

 .contact-box textarea.form-control {
   height: 150px;
   padding-top: 20px;
 }

 .contact-question {
   margin: 0;
   padding: 0;
   list-style: none;
 }

 .contact-question li {
   position: relative;
   padding: 0 0 30px 50px;
   z-index: 1;
 }

 .contact-question li i {
   width: 35px;
   height: 35px;
   background: #213f99;
   line-height: 37px;
   text-align: center;
   color: #fff;
   font-size: 18px;
   border-radius: 40px;
   position: absolute;
   left: 0;
   top: 5px;
 }

 .contact-question li .title {
   font-size: 16px;
   line-height: 23px;
   margin-bottom: 1px;
 }

 .contact-question li p {
   margin: 0;
   font-size: 16px;
   color: #4a4a4a;
 }

 .contact-question li:after {
   content: "";
   background: #000;
   width: 2px;
   height: 100%;
   position: absolute;
   left: 17px;
   top: 6px;
   z-index: -1;
   bottom: 0;
   opacity: .2;
 }

 .contact-question li:last-child:after {
   display: none;
 }

 /*Blog single */
 .blog-single {
   margin-bottom: 50px;
 }

 .blog-single .dlab-title {
   margin-bottom: 20px;
   text-align: center;
 }

 .blog-single .dlab-post-meta {
   margin-bottom: 20px;
 }

 .blog-single .dlab-post-text {
   margin-top: 20px;
 }

 .blog-single .dlab-post-tags {
   margin-top: 20px;
 }

 .blog-single .dlab-post-media {
   border-radius: 4px;
 }

 .alignnone {
   margin: 5px 0 25px 0;
 }

 .alignnoneimg,
 .alignnone.wp-caption,
 .alignnone.is-resized {
   margin: 5px 0 25px 0;
 }

 .aligncenter {
   display: block;
   margin: 5px auto 15px;
   text-align: center;
 }

 .aligncenterimg,
 .aligncenter.wp-caption,
 .aligncenter.is-resized {
   display: block;
   margin: 5px auto 15px;
   text-align: center;
 }

 .alignright {
   float: right;
   margin: 5px 0 25px 25px;
 }

 .alignrightimg,
 .alignright.wp-caption,
 .alignright.is-resized {
   margin: 5px 0 25px 25px;
   float: right;
 }

 .alignleft {
   float: left;
   margin: 5px 25px 25px 0;
 }

 .alignleftimg,
 .alignleft.wp-caption,
 .alignleft.is-resized {
   margin: 5px 25px 25px 0;
   float: left;
 }

 .wp-caption {
   max-width: 100%;
   text-align: center;
 }

 .wp-caption img[class*="wp-image-"] {
   display: block;
   margin: 0;
 }

 .wp-caption img {
   border: 0 none;
   height: auto;
   margin: 0;
   max-width: 100%;
   padding: 0;
   width: auto;
 }

 .wp-caption p.wp-caption-text {
   color: #9d9d9d;
   font-size: 13px;
   line-height: 18px;
   margin: 0;
   padding: 10px 0;
   text-align: left;
 }

 .wp-block-quote.is-large,
 .wp-block-quote.is-style-large,
 blockquote.wp-block-quote,
 blockquote.wp-block-pullquote,
 blockquote.wp-block-pullquote.alignright,
 blockquote.wp-block-pullquote.alignleft,
 blockquote {
   padding: 30px 30px 25px 30px;
   font-size: 15px;
   color: #fff;
   margin-top: 40px;
   margin-bottom: 40px;
   line-height: 30px;
   position: relative;
   clear: both;
   background: #213f99;
   font-weight: 700;
   overflow: hidden;
   z-index: 1;
   border: 0;
 }

 @media only screen and (max-width: 991px) {

   .wp-block-quote.is-large,
   .wp-block-quote.is-style-large,
   blockquote.wp-block-quote,
   blockquote.wp-block-pullquote,
   blockquote.wp-block-pullquote.alignright,
   blockquote.wp-block-pullquote.alignleft,
   blockquote {
     padding: 20px 20px 15px 20px;
     font-size: 13px;
   }
 }

 .wp-block-quote.is-large a:hover,
 .wp-block-quote.is-large a,
 .wp-block-quote.is-style-large a:hover,
 .wp-block-quote.is-style-large a,
 blockquote.wp-block-quote a:hover,
 blockquote.wp-block-quote a,
 blockquote.wp-block-pullquote a:hover,
 blockquote.wp-block-pullquote a,
 blockquote.wp-block-pullquote.alignright a:hover,
 blockquote.wp-block-pullquote.alignright a,
 blockquote.wp-block-pullquote.alignleft a:hover,
 blockquote.wp-block-pullquote.alignleft a,
 blockquote a:hover,
 blockquote a {
   color: #fff;
 }

 .wp-block-quote.is-large cite,
 .wp-block-quote.is-style-large cite,
 blockquote.wp-block-quote cite,
 blockquote.wp-block-pullquote cite,
 blockquote.wp-block-pullquote.alignright cite,
 blockquote.wp-block-pullquote.alignleft cite,
 blockquote cite {
   font-style: normal;
   position: relative;
   display: block;
   margin-top: -5px;
   font-weight: 600;
   color: #fff;
 }

 .wp-block-quote.is-large cite :before,
 .wp-block-quote.is-style-large cite :before,
 blockquote.wp-block-quote cite :before,
 blockquote.wp-block-pullquote cite :before,
 blockquote.wp-block-pullquote.alignright cite :before,
 blockquote.wp-block-pullquote.alignleft cite :before,
 blockquote cite :before {
   content: "-";
   margin-right: 10px;
 }

 .wp-block-quote.is-large:before,
 .wp-block-quote.is-style-large:before,
 blockquote.wp-block-quote:before,
 blockquote.wp-block-pullquote:before,
 blockquote.wp-block-pullquote.alignright:before,
 blockquote.wp-block-pullquote.alignleft:before,
 blockquote:before {
   content: "";
   z-index: -1;
   background-size: cover;
   left: -2%;
   background-image: url(../images/quote-white.png);
   background-position: center right;
   width: 35%;
   padding: 0;
   opacity: 0.3;
   background-repeat: no-repeat;
   height: 100%;
   top: 0;
   position: absolute;
 }

 .wp-block-quote.is-large.wp-block-pullquote.alignleft,
 .wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
 blockquote.wp-block-quote.wp-block-pullquote.alignleft,
 blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
 blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
 blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
 blockquote.wp-block-pullquote.alignleft {
   margin: 0px 25px 25px 0px;
 }

 .wp-block-quote.is-large.wp-block-pullquote.alignright,
 .wp-block-quote.is-style-large.wp-block-pullquote.alignright,
 blockquote.wp-block-quote.wp-block-pullquote.alignright,
 blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
 blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
 blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
 blockquote.wp-block-pullquote.alignright {
   margin: 0px 0 25px 25px;
 }

 blockquote.wp-block-pullquote:not(.is-style-solid-color) {
   background: #5608e0;
 }

 .wp-caption-text {
   font-size: 14px;
   line-height: 1.3;
 }

 .blog-post.blog-single .dlab-post-text blockquote p,
 .dlab-page-text blockquote p,
 blockquote p {
   font-size: 24px;
   line-height: 1.4;
   color: #fff;
   margin-bottom: 10px;
   font-weight: 500;
   font-style: italic;
 }

 @media only screen and (max-width: 991px) {

   .blog-post.blog-single .dlab-post-text blockquote p,
   .dlab-page-text blockquote p,
   blockquote p {
     font-size: 18px;
   }
 }

 .size-auto,
 .size-full,
 .size-large,
 .size-medium,
 .size-thumbnail {
   max-width: 100%;
   height: auto;
 }

 /* Gutenberg Gallery */
 /* Gutenberg Css */
 .min-container,
 .dlab-page-text,
 .dlab-page-text+#faqs-list,
 .dlab-page-text+#faqs-list+.paginate-links,
 .blog-post.blog-single+#faqs-list,
 .blog-post.blog-single {
   max-width: 720px;
   margin-left: auto;
   margin-right: auto;
 }

 .blog-post.blog-single.sidebar+#faqs-list {
   padding: 0;
   max-width: 100%;
 }

 .max-container {
   max-width: 1500px;
   margin-left: auto;
   margin-right: auto;
   padding-left: 55px;
   padding-right: 55px;
 }

 .dlab-page-text+#faqs-list+.paginate-links,
 .dlab-page-text+#faqs-list,
 .dlab-page-text {
   padding: 0;
 }

 .dlab-page-text.sidebar {
   width: 100%;
   padding: 0;
   max-width: 100%;
 }

 .blog-post.blog-single .dlab-info {
   padding: 0;
 }

 .dlab-page-text>ol,
 .dlab-page-text>ul,
 .dlab-page-text>.wp-block-gallery,
 .blog-post.blog-single .dlab-post-text>.wp-block-gallery,
 .blog-post.blog-single .dlab-post-text>ol,
 .blog-post.blog-single .dlab-post-text>ul {
   list-style: none;
   margin-bottom: 30px;
 }

 .dlab-page-text>ol,
 .dlab-page-text>ul,
 .blog-post.blog-single .dlab-post-text.text>ol,
 .blog-post.blog-single .dlab-post-text.text>ul {
   padding-left: 20px;
 }

 .dlab-page-text>ol li,
 .dlab-page-text>ul li,
 .blog-post.blog-single .dlab-post-text>ol li,
 .blog-post.blog-single .dlab-post-text>ul li,
 .blog-post.blog-single .dlab-post-text.text>ol li,
 .blog-post.blog-single .dlab-post-text.text>ul li {
   padding: 8px 8px;
   position: relative;
 }

 .dlab-page-text>.wp-block-gallery,
 .blog-post.blog-single .dlab-post-text.text>.wp-block-gallery {
   padding: 0;
   list-style: none;
 }

 .dlab-page-text>.wp-block-gallery li,
 .blog-post.blog-single .dlab-post-text.text>.wp-block-gallery li {
   padding: 0;
   list-style: none;
   margin: 4px;
 }

 .dlab-page-text>.wp-block-gallery li:before,
 .blog-post.blog-single .dlab-post-text.text>.wp-block-gallery li:before {
   content: none;
 }

 .wp-block-gallery .blocks-gallery-image,
 .wp-block-gallery .blocks-gallery-item {
   margin: 0 8px 8px 0;
 }

 .wp-block-gallery.columns-4 .blocks-gallery-image,
 .wp-block-gallery.columns-4 .blocks-gallery-item,
 .wp-block-gallery.columns-3 .blocks-gallery-image,
 .wp-block-gallery.columns-3 .blocks-gallery-item {
   margin-right: 8px;
 }

 .content-spacious-full .wp-block-gallery {
   margin-left: calc(-200px - (5px/2));
   margin-right: calc(-200px - (5px/2));
   width: calc(100% + 400px + 5px);
   max-width: initial;
 }

 .alignfull,
 .alignwide {
   margin-left: calc(-100px - (5px/2));
   margin-right: calc(-100px - (5px/2));
   width: calc(100% + 200px + 5px);
   max-width: initial;
 }

 .alignfull,
 .alignfullwide {
   margin-left: calc(50% - 50vw - (var(--content-share-ml, 0px)/2));
   width: 100vw;
   max-width: 100vw;
   box-sizing: border-box;
 }

 .dlab-page-text h1,
 .dlab-post-text h1,
 .thm-unit-test .dlab-post-text h1 {
   margin-bottom: 25px;
   font-weight: 700;
 }

 .dlab-page-text h2,
 .dlab-post-text h2,
 .thm-unit-test .dlab-post-text h2 {
   margin-bottom: 25px;
   font-weight: 700;
 }

 .dlab-page-text h3,
 .dlab-post-text h3,
 .thm-unit-test .dlab-post-text h3 {
   margin-bottom: 20px;
   font-weight: 700;
 }

 .dlab-page-text h4,
 .dlab-post-text h4,
 .thm-unit-test .dlab-post-text h4 {
   margin-bottom: 15px;
   font-weight: 600;
 }

 .dlab-page-text h5,
 .dlab-post-text h5,
 .thm-unit-test .dlab-post-text h5 {
   margin-bottom: 15px;
   font-weight: 600;
 }

 .dlab-page-text h6,
 .dlab-post-text h6,
 .thm-unit-test .dlab-post-text h6 {
   margin-bottom: 10px;
   font-weight: 600;
 }

 .wp-block-code,
 pre.wp-block-code,
 pre {
   margin: 0 0 25px;
   padding: 20px;
   color: #fff;
   background-color: #212326;
   white-space: pre;
   font-size: 15px;
   border-radius: 3px;
 }

 .dlab-page-text,
 .dlab-page-text ul li,
 .dlab-page-text ol li,
 .blog-post.blog-single .dlab-post-text,
 .blog-post.blog-single .dlab-post-text ul li,
 .blog-post.blog-single .dlab-post-text ol li,
 .blog-post.blog-single .blog-post.blog-single .dlab-post-text p:not(.has-text-color):not(.has-text-align-center):not(.has-text-align-left):not(.has-text-align-right) {
   color: #6f6f6f;
   font-size: 18px;
   line-height: 1.7;
 }

 .wp-block-cover,
 .wp-block-cover-image {
   color: #fff;
 }

 .wp-block-cover p:last-child,
 .wp-block-cover-image p:last-child {
   margin-bottom: 0;
 }

 .wp-block-quote.has-text-align-right {
   border-right: 0;
 }

 .dlab-page-text ul li,
 .dlab-page-text ol li,
 .blog-post.blog-single .dlab-post-text ul li,
 .blog-post.blog-single .dlab-post-text ol li {
   position: relative;
 }

 .dlab-page-text ul>li:before,
 .dlab-page-text ol>li:before,
 .blog-post.blog-single .dlab-post-text ul>li:before,
 .blog-post.blog-single .dlab-post-text ol>li:before {
   content: "";
   display: inline-block;
   width: 6px;
   height: 6px;
   background: #213f99;
   left: -15px;
   position: absolute;
   top: 20px;
 }

 .dlab-page-text ul>li li:before,
 .dlab-page-text ol>li li:before,
 .blog-post.blog-single .dlab-post-text ul>li li:before,
 .blog-post.blog-single .dlab-post-text ol>li li:before {
   content: none;
 }

 .dlab-page-text a,
 .blog-post.blog-single .dlab-post-text a {
   box-shadow: inset 0 -1px 0 0 var(--bg-color);
 }

 .dlab-page-text p,
 .blog-post.blog-single .dlab-post-text p {
   margin-bottom: 1.65em;
 }

 .dlab-page-text .wp-block-gallery,
 .blog-post.blog-single .dlab-post-text .wp-block-gallery {
   margin-left: calc(-190px - (10px/2));
   margin-right: calc(-190px - (10px/2));
   width: calc(100% + 380px + 10px);
   max-width: initial;
 }

 .paginate-links {
   display: flex;
   align-items: center;
   margin: 20px 0;
 }

 .paginate-links a,
 .paginate-links>span {
   margin: 0 0 0 10px;
   position: relative;
   border: 0px solid #6cc000;
   color: #000;
   display: inline-block;
   font-size: 16px;
   font-weight: 500;
   line-height: 40px;
   min-width: 40px;
   height: 40px;
   text-align: center;
   text-transform: capitalize;
   transition: all 500ms ease 0s;
   background: #E0E0E0;
   border-radius: 3px;
 }

 .wp-block-columns {
   margin-bottom: 0;
 }

 /* Gutenberg Gallery */
 .wp-block-gallery {
   display: flex;
   flex-wrap: wrap;
   list-style-type: none;
   padding: 0;
 }

 .wp-block-gallery .blocks-gallery-image,
 .wp-block-gallery .blocks-gallery-item {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   justify-content: center;
   margin: 0 6px 6px 0;
   position: relative;
 }

 .wp-block-gallery .blocks-gallery-image figure,
 .wp-block-gallery .blocks-gallery-item figure {
   height: 100%;
   margin: 0;
 }

 @supports (position: -webkit-sticky) or (position: sticky) {

   .wp-block-gallery .blocks-gallery-image figure,
   .wp-block-gallery .blocks-gallery-item figure {
     align-items: flex-end;
     display: flex;
     justify-content: flex-start;
   }
 }

 .wp-block-gallery .blocks-gallery-image img,
 .wp-block-gallery .blocks-gallery-item img {
   display: block;
   height: auto;
   max-width: 100%;
   width: 100%;
 }

 @supports (position: -webkit-sticky) or (position: sticky) {

   .wp-block-gallery .blocks-gallery-image img,
   .wp-block-gallery .blocks-gallery-item img {
     width: auto;
   }
 }

 .wp-block-gallery .blocks-gallery-image figcaption,
 .wp-block-gallery .blocks-gallery-item figcaption {
   background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 60%, transparent);
   bottom: 0;
   color: #fff;
   font-size: 13px;
   max-height: 100%;
   overflow: auto;
   padding: 40px 10px 5px;
   position: absolute;
   text-align: center;
   width: 100%;
 }

 .wp-block-gallery .blocks-gallery-image figcaption img,
 .wp-block-gallery .blocks-gallery-item figcaption img {
   display: inline;
 }

 .wp-block-gallery.is-cropped .blocks-gallery-image a,
 .wp-block-gallery.is-cropped .blocks-gallery-image img,
 .wp-block-gallery.is-cropped .blocks-gallery-item a,
 .wp-block-gallery.is-cropped .blocks-gallery-item img {
   width: 100%;
   border-radius: 10px;
 }

 @supports (position: -webkit-sticky) or (position: sticky) {

   .wp-block-gallery.is-cropped .blocks-gallery-image a,
   .wp-block-gallery.is-cropped .blocks-gallery-image img,
   .wp-block-gallery.is-cropped .blocks-gallery-item a,
   .wp-block-gallery.is-cropped .blocks-gallery-item img {
     -o-object-fit: cover;
     flex: 1;
     height: 100%;
     object-fit: cover;
   }
 }

 .wp-block-gallery .blocks-gallery-image,
 .wp-block-gallery .blocks-gallery-item {
   width: calc(50% - 8px);
 }

 .wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
 .wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
   margin-right: 0;
 }

 .wp-block-gallery.columns-1 .blocks-gallery-image,
 .wp-block-gallery.columns-1 .blocks-gallery-item {
   margin-right: 0;
   width: 100%;
 }

 @media only screen and (max-width: 1024px) {
   .alignwide {
     margin-left: 0;
     margin-right: 0;
     width: 100% !important;
     max-width: 100% !important;
   }

   .alignwide .alignleft img {
     width: 100%;
   }

   .alignwide .alignleft {
     margin: 5px 0px 25px 0;
     float: none;
   }
 }

 @media (min-width: 600px) {

   .wp-block-gallery.columns-3 .blocks-gallery-image,
   .wp-block-gallery.columns-3 .blocks-gallery-item {
     margin-right: 6px;
     width: calc(33.33333% - 10.66667px);
   }

   .wp-block-gallery.columns-4 .blocks-gallery-image,
   .wp-block-gallery.columns-4 .blocks-gallery-item {
     margin-right: 6px;
     width: calc(25% - 12px);
   }

   .wp-block-gallery.columns-5 .blocks-gallery-image,
   .wp-block-gallery.columns-5 .blocks-gallery-item {
     margin-right: 6px;
     width: calc(20% - 12.8px);
   }

   .wp-block-gallery.columns-6 .blocks-gallery-image,
   .wp-block-gallery.columns-6 .blocks-gallery-item {
     margin-right: 6px;
     width: calc(16.66667% - 13.33333px);
   }

   .wp-block-gallery.columns-7 .blocks-gallery-image,
   .wp-block-gallery.columns-7 .blocks-gallery-item {
     margin-right: 6px;
     width: calc(14.28571% - 13.71429px);
   }

   .wp-block-gallery.columns-8 .blocks-gallery-image,
   .wp-block-gallery.columns-8 .blocks-gallery-item {
     margin-right: 6px;
     width: calc(12.5% - 14px);
   }

   .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
   .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
   .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
   .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
   .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
   .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
   .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
   .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
   .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
   .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
   .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
   .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
   .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
   .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
   .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
   .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
     margin-right: 0;
   }
 }

 .wp-block-gallery .blocks-gallery-image:last-child,
 .wp-block-gallery .blocks-gallery-item:last-child {
   margin-right: 0;
 }

 .wp-block-gallery .blocks-gallery-item.has-add-item-button {
   width: 100%;
 }

 .wp-block-image.alignfullwide img {
   border-radius: 0;
 }

 .wp-block-image img {
   border-radius: 10px;
 }

 .wp-block-cover,
 .wp-block-cover-image,
 .wp-block-embed,
 .wp-block-image {
   margin-bottom: 30px;
 }

 .wp-block-gallery.alignleft,
 .wp-block-gallery.alignright {
   max-width: 305px;
   width: 100%;
 }

 .wp-block-gallery.aligncenter,
 .wp-block-gallery.alignleft,
 .wp-block-gallery.alignright {
   display: flex;
 }

 .wp-block-gallery.aligncenter .blocks-gallery-item figure {
   justify-content: center;
 }

 .dlab-page-text.sidebar .alignfull,
 .dlab-page-text.sidebar .alignfullwide,
 .dlab-page-text.sidebar .alignwide,
 .dlab-page-text.sidebar .wp-block-gallery,
 .blog-single.blog-post.sidebar .alignfullwide,
 .blog-single.blog-post.sidebar .alignwide,
 .blog-single.blog-post.sidebar .dlab-post-text .wp-block-gallery {
   margin-left: 0;
   margin-right: 0;
   width: 100%;
   max-width: initial;
 }

 .blog-overlap {
   background: #fff;
   margin-top: -140px;
   padding: 20px 20px 0;
   border-radius: 4px 4px 0px 0px;
 }

 .blog-single.blog-post.sidebar {
   width: 100%;
   max-width: 100%;
   padding-left: 0;
   padding-right: 0;
 }

 .container .min-container {
   padding-left: 0;
   padding-right: 0;
 }

 .dlab-post-text table,
 .dlab-page-text table,
 .wp-block-table {
   width: 100%;
   max-width: 100%;
   margin-bottom: 1rem;
   background-color: transparent;
   font-size: 16px;
   margin-bottom: 30px;
 }

 .wp-block-image figcaption,
 .dlab-post-text figcaption,
 .dlab-page-text figcaption {
   font-size: 14px;
   font-family: 'Poppins', sans-serif;
   font-style: normal;
   color: #777;
   margin-top: 10px;
 }

 .dlab-post-text table tbody tr:nth-of-type(odd),
 .dlab-page-text table tbody tr:nth-of-type(odd),
 .wp-block-table tbody tr:nth-of-type(odd) {
   background-color: #fafafa;
 }

 .dlab-post-text td,
 .dlab-post-text th,
 .dlab-page-text td,
 .dlab-page-text th,
 .wp-block-table td,
 .wp-block-table th {
   padding: 10px 15px;
   border: 1px solid #e4e4e4;
   border-right: 0;
   border-left: 0;
 }

 .wp-block-media-text .wp-block-media-text__content {
   padding: 0 30px;
 }

 .wp-block-column ol li:before,
 .wp-block-column ul li:before {
   content: none !important;
 }

 .wp-block-pullquote {
   padding: 1em 0;
   border-top: 0;
   border-bottom: 0;
 }

 .wp-block-pullquote.alignright,
 .wp-block-pullquote.alignleft {
   padding: 0;
   border-top: 0;
   border-bottom: 0;
 }

 .wp-block-pullquote.alignright blockquote,
 .wp-block-pullquote.alignleft blockquote {
   margin: 0;
 }

 .dlab-post-text .wp-block-cover-image-text,
 .dlab-post-text .wp-block-cover-text,
 .dlab-post-text section.wp-block-cover-image h2,
 .dlab-page-text .wp-block-cover-image-text,
 .dlab-page-text .wp-block-cover-text,
 .dlab-page-text section.wp-block-cover-image h2 {
   color: #fff;
 }

 .dlab-post-text .bootstrap-select .dropdown-menu li a,
 .dlab-page-text .bootstrap-select .dropdown-menu li a {
   box-shadow: none;
   font-size: 14px;
   font-family: 'Poppins', sans-serif;
 }

 .dlab-post-text .wp-block-calendar a {
   box-shadow: none !important;
   text-decoration: unset;
 }

 .wp-block-search,
 .wp-block-archives,
 .wp-block-latest-posts,
 .wp-block-latest-faqss,
 .wp-block-categories,
 .wp-block-calendar {
   margin-bottom: 40px;
 }

 /* Admin Bar */
 .admin-bar .is-fixed .main-bar {
   top: 30px;
 }

 .wp-block-quote.is-style-large cite:before {
   display: none;
 }

 /* Theme Unit Test */
 .post-password-form label {
   font-size: 16px;
 }

 .post-password-form input[type="submit"] {
   padding: 10px 25px;
   background-color: #213f99;
   border: none;
   height: 45px;
   font-weight: 600;
   font-size: 14px;
   outline: none;
 }

 .post-password-form input[type="submit"]:hover {
   background-color: #213f99;
 }

 .post-password-form input[type="password"] {
   height: 45px;
   border: 1px solid #ced4da;
 }

 .wp-block-search .wp-block-search__button {
   background: #213f99;
   border: 0;
   color: #fff;
   font-size: 14px;
   text-transform: uppercase;
   font-weight: 600;
   padding: 10px 25px;
   -webkit-transition: all 0.8s;
   -ms-transition: all 0.8s;
   transition: all 0.8s;
 }

 .wp-block-search .wp-block-search__input {
   border: 1px solid #e1e6eb;
   height: 45px;
   padding: 10px 20px;
   font-size: 14px;
   outline: none;
 }

 .wp-block-tag-cloud a {
   padding: 8px 10px;
   background-color: #5608e0;
   font-size: 12px;
   display: inline-block;
   margin: 0 0 5px;
   color: #fff;
   font-weight: 600;
   transition: all 0.5s;
   -moz-transition: all 0.5s;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   -o-transition: all 0.5s;
   font-family: 'Poppins', sans-serif;
 }

 .wp-block-tag-cloud a:hover {
   background: var(--bg-color-ho);
 }

 .wp-block-latest-faqss {
   padding: 0 !important;
 }

 .wp-block-latest-faqss .avatar {
   width: 55px;
   height: 55px;
   border-radius: 55px;
 }

 .wp-block-latest-faqss .wp-block-latest-faqss__faqs .wp-block-latest-faqss__faqs-excerpt {
   margin-left: 70px;
 }

 .wp-block-latest-faqss .wp-block-latest-faqss__faqs .wp-block-latest-faqss__faqs-meta {
   color: #000;
   font-family: 'Poppins', sans-serif;
   font-size: 17px;
   font-weight: 500;
   line-height: 1.7;
   margin-bottom: 10px;
 }

 .wp-block-latest-faqss .wp-block-latest-faqss__faqs .wp-block-latest-faqss__faqs-meta a {
   color: #000;
   box-shadow: none;
 }

 .wp-block-latest-faqss img+article .wp-block-latest-faqss__faqs .wp-block-latest-faqss__faqs-meta {
   margin-left: 70px;
 }

 .wp-block-latest-faqss .wp-block-latest-faqss__faqs-meta time {
   display: block;
   width: 100%;
   color: #9fa1a4;
   font-size: 13px;
   font-weight: 400;
 }

 .wp-block-latest-faqss .wp-block-latest-faqss__faqs-excerpt p {
   font-family: 'Poppins', sans-serif !important;
   font-size: 16px !important;
   color: #646566 !important;
   line-height: 1.5 !important;
   margin-bottom: 10px !important;
 }

 .wp-block-latest-faqss li {
   padding: 0 !important;
   border-bottom: 1px solid #eee;
   padding-bottom: 10px !important;
 }

 .wp-block-latest-faqss li:before {
   content: none !important;
 }

 .wp-block-latest-faqss__faqs-avatar {
   width: 55px;
   height: 55px;
   border-radius: 55px;
 }

 .wp-block-rss {
   padding: 0 !important;
 }

 .wp-block-rss .wp-block-rss__item {
   padding: 5px 0 !important;
   border-bottom: 1px solid #eee;
 }

 .wp-block-rss .wp-block-rss__item:before {
   content: none !important;
 }

 .wp-block-rss .wp-block-rss__item a {
   font-family: 'Poppins', sans-serif;
   font-size: 18px;
   box-shadow: unset !important;
   font-weight: 600;
   color: #060429;
 }

 @media only screen and (max-width: 1200px) {

   .dlab-page-text .wp-block-gallery,
   .blog-post.blog-single .dlab-post-text .wp-block-gallery {
     margin-left: calc(-100px - (10px/2));
     margin-right: calc(-100px - (10px/2));
     width: calc(100% + 200px + 10px);
   }
 }

 @media only screen and (max-width: 991px) {
   .admin-bar .mo-left .header-nav {
     top: 30px;
     height: calc(100vh - 30px) !important;
   }

   .dlab-page-text .wp-block-gallery,
   .blog-post.blog-single .dlab-post-text .wp-block-gallery {
     margin-left: 0;
     margin-right: 0;
     width: 100%;
   }

   .dlab-page-text,
   .dlab-page-text ul li,
   .dlab-page-text ol li,
   .dlab-page-text p,
   .blog-post.blog-single .dlab-post-text,
   .blog-post.blog-single .dlab-post-text ul li,
   .blog-post.blog-single .dlab-post-text ol li,
   .blog-post.blog-single .dlab-post-text p {
     font-size: 16px;
   }
 }

 @media only screen and (max-width: 782px) {
   .admin-bar .is-fixed .main-bar {
     top: 45px;
   }

   .admin-bar .mo-left .header-nav {
     top: 45px;
     height: calc(100vh - 45px) !important;
   }

   .wp-block-media-text {
     display: block;
   }

   .wp-block-media-text .wp-block-media-text__media {
     margin-bottom: 15px;
   }

   .wp-block-media-text .wp-block-media-text__content {
     padding: 0;
   }
 }

 @media only screen and (max-width: 600px) {
   .admin-bar .is-fixed .main-bar {
     top: 0;
   }

   .admin-bar .mo-left .header-nav {
     top: 45px;
     height: calc(100vh - 45px) !important;
   }

   .admin-bar .mo-left .is-fixed .header-nav {
     top: 0;
     height: 100vh !important;
   }
 }

 .post-footer {
   border-top: 1px solid rgba(0, 0, 0, 0.1);
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 15px 0 0;
 }

 @media only screen and (max-width: 575px) {
   .post-footer {
     display: block;
   }
 }

 .post-footer .dlab-meta .tag-list {
   padding-bottom: 0;
   text-align: left;
   margin: 0;
   padding: 0;
   list-style: none;
   text-transform: capitalize;
   display: inline;
 }

 .post-footer .dlab-meta ul li {
   margin-right: 5px;
   padding: 0;
   display: inline-block;
   color: #333333;
   font-weight: 500;
   font-size: 15px;
   font-style: italic;
 }

 .post-footer .post-tag a {
   text-transform: capitalize;
   font-size: 15px;
   color: #999;
   background: transparent;
   padding: 0;
   border: 0;
   line-height: 14px;
   font-weight: 500;
 }

 .share-post li {
   display: inline-block;
 }

 .share-post a {
   width: 35px;
   height: 35px;
   border-radius: 35px;
   border: 1px solid #eee;
   display: block;
   text-align: center;
   line-height: 33px;
 }

 ol.faqs-list {
   list-style: none;
   margin-bottom: 0;
   padding-left: 0;
 }

 ol.faqs-list li.faqs {
   position: relative;
   padding: 0;
 }

 ol.faqs-list li.faqs .faqs-body {
   position: relative;
   padding: 0px 0px 0px 0;
   padding-bottom: 25px;
   position: relative;
   margin-bottom: 25px;
   border-bottom: 1px solid #e1e1e1;
 }

 ol.faqs-list li.faqs .faqs-author {
   display: block;
   margin-bottom: 20px;
   position: relative;
   padding-left: 1px;
   padding-top: 5px;
 }

 @media only screen and (max-width: 767px) {
   ol.faqs-list li.faqs .faqs-author {
     padding-left: 1px;
   }
 }

 ol.faqs-list li.faqs .faqs-author .avatar {
   position: absolute;
   top: 0;
   left: 0;
   width: 55px;
   height: 55px;
   border-radius: 55px;
   -webkit-border-radius: 55px;
 }

 ol.faqs-list li.faqs .faqs-author .fn {
   display: block;
   color: #000;
   font-size: 17px;
   font-weight: 500;
   font-style: normal;
   margin-bottom: 2px;
 }

 ol.faqs-list li.faqs .faqs-author .says {
   display: none;
   color: #999999;
   font-weight: 600;
 }

 ol.faqs-list li.faqs .faqs-meta {
   text-transform: capitalize;
   display: block;
   font-size: 13px;
   margin-bottom: 5px;
 }

 ol.faqs-list li.faqs .faqs-meta a {
   color: #9fa1a4;
   font-size: 13px;
   font-weight: 400;
 }

 ol.faqs-list li.faqs .reply {
   position: absolute;
   right: 0;
   top: 5px;
 }

 ol.faqs-list li.faqs .reply a {
   font-weight: 600;
   text-transform: capitalize;
   font-size: 14px;
   font-style: italic;
   box-shadow: inset 0px -1px 0 #213f99;
 }

 @media only screen and (max-width: 767px) {
   ol.faqs-list li.faqs .reply a {
     position: static;
   }
 }

 ol.faqs-list li.faqs p {
   line-height: 24px;
   margin: 0 0 10px;
   font-size: 16px;
   font-weight: 400;
 }

 ol.faqs-list li .children {
   list-style: none;
   margin-left: 55px;
   padding-left: 0;
 }

 @media only screen and (max-width: 767px) {
   ol.faqs-list li .children {
     margin-left: 20px;
   }
 }

 ol.faqs-list li .children li {
   padding: 0;
 }

 @media only screen and (max-width: 767px) {
   .faqs-respond {
     padding: 0;
   }
 }

 .faqs-respond .faqs-reply-title {
   text-transform: uppercase;
   font-size: 14px;
   font-weight: 600;
   line-height: 1;
   margin-bottom: 10px;
 }

 .faqs-respond .faqs-reply-title small {
   font-size: inherit;
   font-weight: inherit;
 }

 .faqs-respond .faqs-reply-title a {
   color: red;
 }

 .faqss-area {
   padding: 0;
   margin-bottom: 30px;
 }

 .faqss-area .faqss-title {
   text-transform: uppercase;
   font-size: 14px;
   font-weight: 600;
   text-align: center;
   line-height: 26px;
 }

 .faqss-area .faqs-form {
   background: #f6f6f6;
   padding: 30px 20px;
   border: 1px solid rgba(0, 0, 0, 0.1);
   margin-bottom: 30px;
 }

 @media only screen and (max-width: 767px) {
   .faqss-area .faqs-form {
     padding: 15px 5px;
   }
 }

 .faqss-area .faqs-form p {
   width: 50%;
   float: left;
   padding: 0 15px;
   margin-bottom: 20px;
   position: relative;
 }

 .faqss-area .faqs-form p label {
   display: none;
   line-height: 18px;
   margin-bottom: 10px;
 }

 .faqss-area .faqs-form p input[type="text"],
 .faqss-area .faqs-form p input[type="email"],
 .faqss-area .faqs-form p textarea {
   width: 100%;
   height: 45px;
   padding: 10px 20px 10px 20px;
   border: 0;
   text-transform: capitalize;
   font-size: 15px;
 }

 .faqss-area .faqs-form p input[type="text"]:focus,
 .faqss-area .faqs-form p input[type="email"]:focus,
 .faqss-area .faqs-form p textarea:focus {
   outline: none;
   border-color: #213f99;
 }

 .faqss-area .faqs-form p textarea {
   height: 120px;
 }

 .faqss-area .faqs-form p.faqs-form-faqs {
   width: 100%;
   display: block;
   clear: both;
 }

 .faqss-area .faqs-form p.form-submit {
   clear: both;
   float: none;
   width: 100%;
   margin: 0;
 }

 @media only screen and (max-width: 767px) {
   .faqss-area .faqs-form p {
     width: 100%;
     float: none;
     margin-bottom: 20px;
   }
 }

 .faqss-area .faqs-form p.form-allowed-tags {
   width: 100%;
 }

 .faqss-area .faqs-form .faqs-notes {
   display: none;
 }

 .error-page .dz_error {
   font-size: 260px;
   color: #1c1c1c;
   font-weight: 700;
   line-height: 260px;
 }

 @media only screen and (max-width: 767px) {
   .error-page .dz_error {
     font-size: 120px;
     line-height: 120px;
   }
 }

 .error-page .error-head {
   font-size: 40px;
   line-height: 55px;
   max-width: 760px;
   display: inline-block;
   text-transform: uppercase;
   font-weight: 700;
 }

 @media only screen and (max-width: 767px) {
   .error-page .error-head {
     font-size: 20px;
     line-height: 34px;
   }
 }

 .error-page .input-group {
   border: 2px solid #eceef2;
   border-radius: 40px;
 }

 .error-page .input-group .btn {
   border-radius: 50px;
 }

 .error-page .input-group .btn:hover {
   background-color: transparent;
 }

 .error-page .input-group .btn img {
   width: 21px;
 }

 .error-page .form-control {
   border: 0;
   background-color: transparent;
   padding: 25px 30px;
   font-weight: 500;
 }

 .error-page .form-control:active,
 .error-page .form-control:focus {
   border: 0;
   background-color: transparent;
   padding: 25px 30px;
   font-weight: 500;
 }

 .error-page .subscribe-form {
   max-width: 560px;
   width: 560px;
   display: inline-block;
 }

 @media only screen and (max-width: 767px) {
   .error-page .subscribe-form {
     max-width: 100%;
     width: 100%;
   }
 }

 .project-img-bx img {
   width: 100%;
 }

 .widget_getintuch-pro-details {
   margin-bottom: 0;
 }

 .widget_getintuch-pro-details .pro-details {
   padding-left: 70px;
   margin-bottom: 30px;
   position: relative;
 }

 .widget_getintuch-pro-details .pro-details i {
   background-color: #f5f5f5;
   height: 53px;
   width: 53px;
   color: #000;
   border-radius: 50px;
   line-height: 53px;
   font-size: 20px;
   top: 0;
   position: absolute;
   left: 0;
   text-align: center;
 }

 .widget_getintuch-pro-details .pro-details strong {
   display: block;
 }

 @media only screen and (max-width: 991px) {
   .widget_getintuch-pro-details.pro-details-col-5 .col {
     width: 33.32%;
     flex-basis: auto;
   }
 }

 @media only screen and (max-width: 575px) {
   .widget_getintuch-pro-details.pro-details-col-5 .col {
     width: 100%;
   }
 }

 .widget_getintuch-pro-details .pro-details {
   padding-left: 65px;
   margin-bottom: 30px;
   position: relative;
   padding-top: 3px;
 }

 .video-bx {
   padding: 130px 0;
 }

 @media only screen and (max-width: 767px) {
   .video-bx {
     padding: 50px 0;
   }
 }

 .video-bx .video-title {
   font-size: 42px;
 }

 @media only screen and (max-width: 767px) {
   .video-bx .video-title {
     font-size: 28px;
   }
 }

 .video-bx .video-content {
   font-size: 18px;
   line-height: 30px;
   color: rgba(255, 255, 255, 0.9);
   width: 80%;
   margin: 0 auto 50px;
 }

 @media only screen and (max-width: 767px) {
   .video-bx .video-content {
     font-size: 16px;
     width: 100%;
     margin: 0 auto 20px;
     line-height: 24px;
   }
 }

 .video-bx .video-play {
   margin-bottom: 60px;
 }

 @media only screen and (max-width: 767px) {
   .video-bx .video-play {
     margin-bottom: 30px;
   }
 }

 .video-bx .video-play .popup-youtube {
   height: 135px;
   width: 135px;
   border-radius: 50%;
   line-height: 135px;
   font-size: 36px;
   display: block;
   text-align: center;
   margin: auto;
   color: #213f99;
   background: #fff;
 }

 @media only screen and (max-width: 767px) {
   .video-bx .video-play .popup-youtube {
     height: 100px;
     width: 100px;
     line-height: 100px;
     font-size: 30px;
   }
 }

 .gallery-box-2 .lightimg {
   width: 100px;
   height: 100px;
   display: block;
   font-size: 34px;
   line-height: 100px;
   text-align: center;
   background: #213f99;
   border-radius: 100px;
   position: absolute;
   top: 50%;
   left: 50%;
   margin: -50px 0px 0px -50px;
   transform: scale(0) rotate(360deg);
   -moz-transform: scale(0) rotate(360deg);
   -webkit-transform: scale(0) rotate(360deg);
   -ms-transform: scale(0) rotate(360deg);
   -o-transform: scale(0) rotate(360deg);
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
   color: #fff;
 }

 @media only screen and (max-width: 575px) {
   .gallery-box-2 .lightimg {
     width: 50px;
     height: 50px;
     font-size: 22px;
     line-height: 50px;
     margin: -25px 0px 0px -25px;
   }
 }

 .gallery-box-2 .lightimg i {
   background: transparent;
   vertical-align: middle;
   color: #fff;
 }

 .gallery-box-2:hover .lightimg {
   transform: scale(1) rotate(0deg);
   -moz-transform: scale(1) rotate(0deg);
   -webkit-transform: scale(1) rotate(0deg);
   -ms-transform: scale(1) rotate(0deg);
   -o-transform: scale(1) rotate(0deg);
 }

 .project-carousel .owl-nav .owl-prev {
   left: 10%;
 }

 .project-carousel .owl-nav .owl-next {
   right: 10%;
 }

 .project-carousel .owl-nav .owl-prev i,
 .project-carousel .owl-nav .owl-next i {
   height: 50px;
   width: 50px;
   background: #213f99;
   color: #fff;
   border-radius: 50px;
   font-size: 22px;
   text-align: center;
   line-height: 50px;
 }

 .animation-jet {
   max-width: 90px;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 30px;
 }

 .history-box {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   margin-bottom: 30px;
 }

 .history-box p {
   color: #666666;
   line-height: 26px;
   font-size: 16px;
   margin-bottom: 0;
 }

 @media only screen and (max-width: 991px) {
   .history-box p {
     line-height: 24px;
     font-size: 14px;
   }
 }

 .history-box .history-media {
   flex: 0 0 70%;
   max-width: 70%;
 }

 @media only screen and (max-width: 991px) {
   .history-box .history-media {
     flex: 0 0 50%;
     max-width: 50%;
   }
 }

 @media only screen and (max-width: 575px) {
   .history-box .history-media {
     flex: 0 0 100%;
     max-width: 100%;
   }
 }

 .history-box .history-details {
   flex: 0 0 30%;
   padding-right: 50px;
   max-width: 30%;
 }

 @media only screen and (max-width: 991px) {
   .history-box .history-details {
     flex: 0 0 50%;
     padding-right: 20px;
     max-width: 50%;
   }
 }

 @media only screen and (max-width: 575px) {
   .history-box .history-details {
     flex: 0 0 100%;
     max-width: 100%;
     padding-right: 0;
     margin-bottom: 20px;
   }
 }

 .history-box.right {
   flex-direction: row-reverse;
 }

 .history-box.right .history-details {
   padding-left: 50px;
   padding-right: 0;
 }

 @media only screen and (max-width: 991px) {
   .history-box.right .history-details {
     padding-left: 20px;
   }
 }

 @media only screen and (max-width: 575px) {
   .history-box.right .history-details {
     padding-left: 0;
   }
 }

 .history-head {
   border-left: 4px solid #213f99;
   padding-left: 25px;
   margin-bottom: 15px;
 }

 @media only screen and (max-width: 991px) {
   .history-head {
     padding-left: 15px;
   }
 }

 .history-head h2 {
   font-size: 60px;
 }

 @media only screen and (max-width: 991px) {
   .history-head h2 {
     font-size: 40px;
   }
 }

 .history-head h3 {
   font-size: 28px;
 }

 @media only screen and (max-width: 991px) {
   .history-head h3 {
     font-size: 22px;
   }
 }

 /* Comign Soon ======= */
 .coming-soon {
   height: 100vh;
   position: relative;
 }

 .countdown {
   margin-left: auto;
 }

 @media only screen and (max-width: 991px) {
   .countdown {
     margin-left: 0;
   }
 }

 .countdown .date {
   display: inline-block;
   text-align: center;
   width: 130px;
 }

 @media only screen and (max-width: 991px) {
   .countdown .date {
     text-align: left;
   }
 }

 @media only screen and (max-width: 575px) {
   .countdown .date {
     width: 70px;
   }
 }

 .countdown .date div {
   display: block;
   line-height: 30px;
 }

 .countdown .date div strong {
   font-size: 20px;
   font-weight: 400;
   color: #1b1b1b;
   text-transform: uppercase;
   box-shadow: inset 0px -4px 0 #213f99;
   line-height: 18px;
   display: inline-block;
 }

 @media only screen and (max-width: 575px) {
   .countdown .date div strong {
     font-size: 12px;
     line-height: 12px;
   }
 }

 .countdown .date .time {
   font-size: 70px;
   font-family: Poppins;
   color: #000;
   font-weight: 700;
   display: block;
 }

 @media only screen and (max-width: 575px) {
   .countdown .date .time {
     font-size: 40px;
     line-height: 1;
   }
 }

 .countdown-box {
   display: flex;
   line-height: 70px;
   align-items: start;
   padding: 50px 50px 0;
 }

 @media only screen and (max-width: 991px) {
   .countdown-box {
     display: block;
     padding: 50px 20px 0;
   }
 }

 @media only screen and (max-width: 991px) {
   .countdown-box .button-home {
     position: absolute;
     bottom: 20px;
     left: 20px;
   }
 }

 .coming-head {
   display: block;
   max-width: 1170px;
   text-align: center;
   margin-top: 50px;
 }

 @media only screen and (max-width: 991px) {
   .coming-head {
     margin-right: unset;
     text-align: left;
     padding-left: 20px;
     padding-right: 20px;
   }
 }

 @media only screen and (max-width: 575px) {
   .coming-head {
     margin-top: 20px;
   }
 }

 .coming-head h2 {
   line-height: 162px;
   font-size: 162px;
   display: inline;
   position: relative;
   font-family: "Roboto", sans-serif;
 }

 @media only screen and (max-width: 575px) {
   .coming-head h2 {
     line-height: 1;
     font-size: 100px;
   }
 }

 .coming-head h2 strong {
   color: #1b1b1b;
   font-weight: 900;
 }

 .coming-head h2 span {
   font-size: 35px;
   color: #1b1b1b;
   position: absolute;
   right: 26px;
   line-height: 40px;
 }

 @media only screen and (max-width: 575px) {
   .coming-head h2 span {
     font-size: 20px;
     right: 20px;
     line-height: 30px;
   }
 }

 .coming-head .input-group {
   background: #ffffff;
   border: 1px solid #e3e3e3;
   border-radius: 50px;
 }

 .coming-head .input-group .btn {
   border-radius: 40px;
   height: 40px;
   padding: 0;
   width: 40px;
   margin: 5px;
 }

 .coming-head .subscribe-form {
   max-width: 600px;
   margin-left: auto;
   margin-right: auto;
   margin-top: 50px;
 }

 @media only screen and (max-width: 991px) {
   .coming-head .subscribe-form {
     margin-left: unset;
   }
 }

 .coming-head .subscribe-form input {
   background-color: rgba(0, 0, 0, 0);
   border: 2px solid rgba(255, 255, 255, 0.5);
   border-radius: 30px !important;
   height: 50px;
   padding: 10px 20px;
   margin-right: 5px;
   color: #000;
   font-size: 15px;
 }

 /* 
.coming-head .subscribe-form .form-control:hover,
.coming-head .subscribe-form .form-control:active,
.coming-head .subscribe-form .form-control:focus,
.coming-head .subscribe-form .form-control{
	border:0;
	border-radius: 40px !important;
} */
 .exhibition-bx p {
   color: #676767;
   font-size: 17px;
   line-height: 30px;
 }

 .exhibition-bx .title {
   font-size: 50px;
   line-height: 58px;
 }

 .exhibition-carousel {
   padding: 20px 50px 20px 0px;
   position: relative;
   z-index: 1;
 }

 .exhibition-carousel:after {
   content: "";
   position: absolute;
   width: calc(100% - 70px);
   height: 100%;
   right: 0;
   top: 0;
   background-color: #f9f9f9;
   z-index: -1;
 }

 .exhibition-carousel .owl-dot {
   width: 12px;
   height: 12px;
   background: #dadada;
   border-radius: 20px;
   margin: 10px 0;
 }

 .exhibition-carousel .owl-dot.active {
   background: #213f99;
 }

 .exhibition-carousel .owl-dots {
   position: absolute;
   right: 18px;
   top: 50%;
   transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   -webkit-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   -o-transform: translateY(-50%);
 }

 .project-style-2 .project-info-box .project-content {
   left: auto;
   top: auto;
   right: 0;
   bottom: 55px;
   transform: translateY(0);
   -moz-transform: translateY(0);
   -ms-transform: translateY(0);
   -o-transform: translateY(0);
   -webkit-transform: translateY(0);
 }

 @media only screen and (max-width: 991px) {
   .project-style-2 .project-info-box .project-content {
     bottom: 38px;
   }
 }

 .project-style-2.project-carousel-1 .owl-nav {
   right: 0;
   bottom: 0;
   background: #2e2e2e;
   width: 500px;
 }

 @media only screen and (max-width: 991px) {
   .project-style-2.project-carousel-1 .owl-nav {
     width: 250px;
   }
 }

 .project-style-2.project-carousel-1 .owl-next,
 .project-style-2.project-carousel-1 .owl-prev {
   margin: 0;
   width: 50%;
   text-align: center;
   display: flex;
   justify-content: center;
   padding: 15px 20px;
 }

 @media only screen and (max-width: 991px) {

   .project-style-2.project-carousel-1 .owl-next,
   .project-style-2.project-carousel-1 .owl-prev {
     padding: 10px 20px;
   }
 }

 .project-style-2.project-carousel-1 .owl-next:hover,
 .project-style-2.project-carousel-1 .owl-prev:hover {
   background-color: rgba(0, 0, 0, 0.2);
 }

 .about-bx {
   box-shadow: 0 0 11px 5px rgba(102, 102, 102, 0.1);
   padding: 0 30px 30px 30px;
   border-radius: 5px;
   margin-top: 30px;
 }

 .about-bx .icon-bx-sm {
   position: relative;
   top: -30px;
 }

 .about-bx .icon-content {
   padding: 0 0px 10px 20px;
 }

 @media only screen and (max-width: 767px) {
   .about-bx .icon-content {
     padding: 0;
   }
 }

 .about-bx .icon-content .dlab-tilte {
   color: #252525;
   font-size: 24px;
   font-weight: 700;
   margin-bottom: 15px;
 }

 .about-bx .icon-content .dlab-tilte p {
   color: #696969;
   font-size: 16px;
   line-height: 28px;
 }

 .progress-section .progress-bx {
   margin-bottom: 30px;
 }

 .progress-section .progress-bx .progress {
   margin-right: 50px;
   height: 8px;
   border-radius: 0;
   overflow: unset;
 }

 .progress-section .progress-bx .count-box {
   float: right;
   font-size: 18px;
   font-weight: 500;
   color: #000;
   line-height: 10px;
 }

 .progress-section .progress-bx .title {
   font-size: 15px;
 }

 .progress-section .progress-bx .progress-bar {
   position: relative;
   border-radius: 0;
   overflow: unset;
 }

 .progress-section .progress-bx .progress-bar:after {
   content: "";
   height: 16px;
   width: 4px;
   background-color: #213f99;
   right: -2px;
   position: absolute;
 }

 .archia-counter .counter {
   font-size: 50px;
   font-weight: 700;
   color: #000;
   line-height: 60px;
   margin-bottom: 0;
 }

 @media only screen and (max-width: 575px) {
   .archia-counter .counter {
     font-size: 30px;
     line-height: 40px;
   }
 }

 .archia-counter i {
   color: #213f99;
 }

 @media only screen and (max-width: 575px) {
   .archia-counter i {
     font-size: 40px;
   }
 }

 @media only screen and (max-width: 575px) {
   .archia-counter .icon-lg {
     width: 50px;
   }
 }

 @media only screen and (max-width: 575px) {
   .archia-counter .m-l90 {
     margin-left: 60px;
   }
 }

 .our-team.team-style1 .team-title-bx {
   padding: 30px;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 100%;
   z-index: 99;
   opacity: 0;
 }

 .our-team.team-style1 .team-title-bx .team-title {
   font-size: 26px;
   color: #fff;
   line-height: 36px;
   font-weight: 700;
 }

 .our-team.team-style1 .team-title-bx span {
   font-size: 16px;
   color: #fff;
 }

 .our-team.team-style1 .dlab-media:after {
   content: "";
   background-color: #000;
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   z-index: 9;
   opacity: 0;
   transition: all 0.5s;
 }

 .our-team.team-style1:hover .dlab-media:after {
   opacity: 0.7;
 }

 .our-team.team-style1:hover .team-title-bx {
   opacity: 1;
   transition: all 0.5s;
 }

 .logo-header {
   display: table;
   float: left;
   vertical-align: middle;
   padding: 0;
   color: #EFBB20;
   margin-top: 0;
   margin-bottom: 0;
   margin-left: 0;
   margin-right: 0;
   width: 240px;
   height: 100px;
   position: relative;
   z-index: 9;
 }

 @media only screen and (max-width: 1199px) {
   .logo-header {
     width: 180px;
   }
 }

 @media only screen and (max-width: 575px) {
   .logo-header {
     width: 160px;
   }
 }

 .logo-header a {
   display: table-cell;
   vertical-align: middle;
 }

 .logo-header img {
   height: auto;
   width: 100%;
 }

 .logo-header span {
   font-size: 20px;
   letter-spacing: 20px;
 }

 .site-header {
   position: relative;
   z-index: 999;
   background: #ffffff !important;
   border-top: 2px solid #ed7876 !important;
}

 .site-header .main-bar {
   background: #fff;
   width: 100%;
   position: relative;
 }

 .site-header .container {
   position: relative;
 }

 .site-header .extra-nav {
   float: right;
   height: 80px;
   display: flex;
   align-items: center;
   position: relative;
   z-index: 9;
   padding-left: 15px;
 }

 .site-header .extra-nav #quik-search-btn {
   font-size: 25px;
   line-height: 25px;
   color: #020d26;
   cursor: pointer;
   margin-right: 10px;
   border: 0;
   padding: 5px;
 }

 @media only screen and (max-width: 575px) {
   .site-header .extra-nav .btn {
     padding-left: 15px;
     padding-right: 15px;
   }
 }

 .navbar-toggler {
   border: 0 solid #efbb20;
   font-size: 16px;
   line-height: 24px;
   margin: 31px 0 31px 15px;
   padding: 0;
   float: right;
   outline: none !important;
 }

 .navicon {
   width: 34px;
   height: 33px;
   position: relative;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .5s ease-in-out;
   -moz-transition: .5s ease-in-out;
   -o-transition: .5s ease-in-out;
   transition: .5s ease-in-out;
   cursor: pointer;
 }

 .navicon span {
   display: block;
   position: absolute;
   height: 5px;
   width: 34px;
   border-radius: 3px;
   box-shadow: 2px 2px 2px 1px #ffffff4f;
   opacity: 1;
   left: 0;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .25s ease-in-out;
   -moz-transition: .25s ease-in-out;
   -o-transition: .25s ease-in-out;
   transition: .25s ease-in-out;
   background: #304da1;
 }

 .navicon span:nth-child(1) {
   top: 0px;
 }

 .navicon span:nth-child(2) {
   top: 9px;
 }

 .navicon span:nth-child(3) {
   top: 18px;
 }

 .navicon.open span:nth-child(1) {
   top: 7px;
   -webkit-transform: rotate(135deg);
   -moz-transform: rotate(135deg);
   -o-transform: rotate(135deg);
   transform: rotate(135deg);
 }

 .navicon.open span:nth-child(2) {
   opacity: 0;
   left: -60px;
 }

 .navicon.open span:nth-child(3) {
   top: 7px;
   -webkit-transform: rotate(-135deg);
   -moz-transform: rotate(-135deg);
   -o-transform: rotate(-135deg);
   transform: rotate(-135deg);
 }

 .header-tb .navicon span {
   background: #fff;
 }

 .header-nav {
   padding: 0;
 }

 @media only screen and (max-width: 991px) {
   .header-nav {
     overflow-y: scroll;
     position: fixed;
     width: 60px;
     left: -280px;
     height: 100vh !important;
     -webkit-transition: all 0.5s;
     -ms-transition: all 0.5s;
     transition: all 0.5s;
     top: 0;
     background-color: #fff;
     margin: 0;
     z-index: 99;
   }
 }

 @media only screen and (max-width: 991px) {
   .header-nav.show {
     left: -1px;
     -webkit-transition: all 0.8s;
     -ms-transition: all 0.8s;
     transition: all 0.8s;
     margin: 0;
     width: 280px;
   }
 }

 @media only screen and (max-width: 991px) {
   .header-nav .logo-header {
     padding: 30px 20px;
     float: none;
     height: auto;
     width: 240px;
   }
 }

 .header-nav .nav {
   float: right;
   padding: 0;
 }

 .header-nav .nav>li {
   margin: 0px;
   font-weight: 400;
   position: relative;
 }

 .header-nav .nav>li.has-mega-menu {
   position: inherit;
 }

 @media only screen and (max-width: 991px) {
   .header-nav .nav>li {
     border-bottom: 1px solid #eee;
     width: 100%;
   }
 }

 .header-nav .nav>li>a {
    font-size: 16px;
    padding: 44px 12px 20px 12px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-width: 0 0 0 1px;
    line-height: 1;
 }

 @media only screen and (max-width: 1199px) {
   .header-nav .nav>li>a {
     padding: 32px 6px 32px 6px;
   }
 }

 .header-nav .nav>li>a i {
   font-size: 9px;
   margin-left: 3px;
   margin-top: -3px;
   vertical-align: middle;
 }

 @media only screen and (max-width: 991px) {
   .header-nav .nav>li>a i {
     background-color: #213f99;
     color: #fff;
     height: 30px;
     line-height: 30px;
     right: 0;
     text-align: center;
     width: 30px;
     z-index: 3;
     float: right;
     font-size: 20px;
     margin: 0;
   }
 }

 @media only screen and (max-width: 991px) {
   .header-nav .nav>li>a i:before {
     content: "\f105";
   }
 }

 @media only screen and (max-width: 991px) {
   .header-nav .nav>li>a {
     padding: 8px 0;
     display: block;
     line-height: 30px;
   }
 }

 .header-nav .nav>li .mega-menu,
 .header-nav .nav>li .sub-menu {
   background-color: #ffffff;
   display: block;
   left: 0;
   list-style: none;
   opacity: 0;
   padding: 5px 0;
   position: absolute;
   visibility: hidden;
   width: 220px;
   z-index: 10;
   margin-top: 20px;
   box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);
   -webkit-transition: all 0.8s;
   -ms-transition: all 0.8s;
   transition: all 0.8s;
 }

 @media only screen and (max-width: 991px) {

   .header-nav .nav>li .mega-menu,
   .header-nav .nav>li .sub-menu {
     display: none;
     position: static;
     visibility: visible;
     width: auto;
     background: transparent;
     box-shadow: none;
     margin: 0;
     border-top: 1px solid #eee;
     padding: 10px 0;
   }
 }

 .header-nav .nav>li .mega-menu li,
 .header-nav .nav>li .sub-menu li {
   position: relative;
 }

 .header-nav .nav>li .mega-menu li a,
 .header-nav .nav>li .sub-menu li a {
   color: #000;
   display: block;
   font-size: 15px;
   padding: 10px 25px;
   text-transform: capitalize;
   position: relative;
   transition: all 0.15s linear;
   -webkit-transition: all 0.15s linear;
   -moz-transition: all 0.15s linear;
   -o-transition: all 0.15s linear;
   font-weight: 400;
 }

 @media only screen and (max-width: 991px) {

   .header-nav .nav>li .mega-menu li a,
   .header-nav .nav>li .sub-menu li a {
     padding: 4px 0px 4px 20px;
   }

   .header-nav .nav>li .mega-menu li a:after,
   .header-nav .nav>li .sub-menu li a:after {
     content: "\f105";
     font-family: FontAwesome;
     width: auto;
     height: auto;
     background: 0 0 !important;
     top: 1px;
     color: #000;
     font-size: 16px;
     position: absolute;
     left: 0;
     font-weight: 700;
   }
 }

 .header-nav .nav>li .mega-menu li:hover>a,
 .header-nav .nav>li .sub-menu li:hover>a {
   background-color: #F2F2F2;
 }

 .header-nav .nav>li .mega-menu li>.sub-menu,
 .header-nav .nav>li .sub-menu li>.sub-menu {
   left: 220px;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .header-nav .nav>li .sub-menu li .fa {
   color: inherit;
   display: block;
   float: right;
   font-size: 16px;
   opacity: 1;
   margin-top: 4px;
 }

 .header-nav .nav>li .mega-menu {
   display: flex;
   right: 0px;
   width: 100%;
   z-index: 9;
   padding: 0;
 }

 .header-nav .nav>li .mega-menu>li {
   display: block;
   padding: 30px 0 25px;
   position: relative;
   vertical-align: top;
   width: 25%;
 }

 @media only screen and (max-width: 991px) {
   .header-nav .nav>li .mega-menu>li {
     width: 100%;
     padding: 0;
   }
 }

 .header-nav .nav>li .mega-menu>li>a {
   color: #000;
   display: block;
   padding: 0 20px;
   font-size: 16px;
   font-weight: 600;
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
   padding-bottom: 15px;
 }

 .header-nav .nav>li .mega-menu>li>a>i {
   font-size: 14px;
   margin-right: 5px;
   text-align: center;
   width: 15px;
 }

 @media only screen and (max-width: 991px) {
   .header-nav .nav>li .mega-menu>li>a {
     display: none;
   }
 }

 .header-nav .nav>li .mega-menu>li:after {
   content: "";
   background-color: rgba(0, 0, 0, 0.1);
   position: absolute;
   right: 0px;
   top: 0px;
   display: block;
   width: 1px;
   height: 100%;
 }

 @media only screen and (max-width: 991px) {
   .header-nav .nav>li .mega-menu>li:after {
     content: none;
   }
 }

 .header-nav .nav>li .mega-menu>li:last-child:after {
   display: none;
 }

 .header-nav .nav>li .mega-menu>li:hover>a {
   background-color: transparent;
 }

 .header-nav .nav>li .mega-menu>li li i {
   font-size: 14px;
   margin-right: 5px;
   text-align: center;
   width: 15px;
 }

 @media only screen and (max-width: 991px) {
   .header-nav .nav>li .mega-menu {
     display: none;
     padding: 10px 0;
   }
 }

 .header-nav .nav>li:hover>.mega-menu,
 .header-nav .nav>li:hover>.sub-menu {
   opacity: 1;
   visibility: visible;
   margin-top: 0;
   -webkit-transition: all 0.5s;
   -ms-transition: all 0.5s;
   transition: all 0.5s;
 }

 .header-nav .nav>li:hover>.mega-menu li:hover>.sub-menu,
 .header-nav .nav>li:hover>.sub-menu li:hover>.sub-menu {
   opacity: 1;
   visibility: visible;
   margin-top: 0;
   top: -1px;
 }

 .header-nav .nav>li.open .mega-menu,
 .header-nav .nav>li.open .sub-menu {
   display: block;
   opacity: 1;
 }

 @media only screen and (max-width: 991px) {
   .header-nav .nav {
     float: none;
     padding: 0 20px;
   }
 }

 .header-nav .dlab-social-icon {
   display: none;
   text-align: center;
 }

 .header-nav .dlab-social-icon li {
   display: inline-block;
   margin: 0 2px;
 }

 .header-nav .dlab-social-icon a {
   width: 40px;
   height: 40px;
   border: 1px solid #eee;
   line-height: 38px;
   text-align: center;
   border-radius: 40px;
 }

 @media only screen and (max-width: 991px) {
   .header-nav .dlab-social-icon {
     display: block;
     padding-top: 30px;
     padding-bottom: 20px;
   }
 }

 .top-bar {
   background-color: #191f23;
   color: #fff;
   padding: 8px 0;
 }

 @media only screen and (max-width: 575px) {
   .top-bar {
     display: none;
   }
 }

 .top-bar .dlab-topbar-left {
   float: left;
 }

 .top-bar .dlab-topbar-left li {
   padding-right: 15px;
 }

 .top-bar .dlab-topbar-right {
   float: right;
 }

 .top-bar .dlab-topbar-right li {
   padding-left: 15px;
 }

 .top-bar .dlab-topbar-center,
 .top-bar .dlab-topbar-left,
 .top-bar .dlab-topbar-right {
   padding-left: 15px;
   padding-right: 15px;
 }

 .top-bar .dlab-topbar-center ul,
 .top-bar .dlab-topbar-left ul,
 .top-bar .dlab-topbar-right ul {
   list-style: none;
   margin: 0;
   padding: 0;
 }

 .top-bar .dlab-topbar-center ul li,
 .top-bar .dlab-topbar-left ul li,
 .top-bar .dlab-topbar-right ul li {
   display: inline-block;
   font-size: 15px;
   color: var(--white);
 }

 @media only screen and (max-width: 1199px) {

   .top-bar .dlab-topbar-center ul li,
   .top-bar .dlab-topbar-left ul li,
   .top-bar .dlab-topbar-right ul li {
     font-size: 13px;
   }
 }

 .top-bar .dlab-topbar-center ul i,
 .top-bar .dlab-topbar-left ul i,
 .top-bar .dlab-topbar-right ul i {
   color: #ffffff;
   transform: scale(1.3);
   -moz-transform: scale(1.3);
   -webkit-transform: scale(1.3);
   -ms-transform: scale(1.3);
   -o-transform: scale(1.3);
   margin-right: 5px;
 }

 /* Header Transparent */
 .header-transparent {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
 }

 .header-transparent .top-bar,
 .header-transparent .main-bar {
   background: transparent;
 }

 .header-transparent .top-bar {
   color: #777;
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
 }

 @media only screen and (max-width: 575px) {
   .header-transparent .top-bar {
     display: none;
   }
 }

 .header-transparent .container-fluid,
 .header-transparent .container-sm,
 .header-transparent .container-md,
 .header-transparent .container-lg,
 .header-transparent .container-xl {
   padding-left: 80px;
   padding-right: 80px;
 }

 @media only screen and (max-width: 1199px) {

   .header-transparent .container-fluid,
   .header-transparent .container-sm,
   .header-transparent .container-md,
   .header-transparent .container-lg,
   .header-transparent .container-xl {
     padding-left: 15px;
     padding-right: 15px;
   }
 }

 .page-title {
   padding: 30px 0;
 }

 .page-title.dashboard {
   background: transparent;
   margin-left: 75px;
   padding: 20px 0 0px;
 }

 .page-title .page-title-content p {
   font-size: 16px;
   font-weight: 400;
   margin-bottom: 0px;
 }

 .page-title .page-title-content p span {
   font-weight: 500;
   color: #213f99;
 }

 .breadcrumbs {
   display: flex;
   justify-content: flex-end;
 }

 @media only screen and (max-width: 575px) {
   .breadcrumbs {
     justify-content: flex-start;
   }
 }

 .breadcrumbs li a {
   color: #AEAED5;
   display: inline-block;
   margin-left: 15px;
   font-size: 14px;
 }

 .breadcrumbs li:first-child a {
   margin-left: 0px;
 }

 .breadcrumbs li.active a {
   color: #213f99;
 }

 .bottom {
   background: #1b2a4e;
 }

 @media only screen and (max-width: 575px) {
   .bottom {
     padding-bottom: 0;
   }
 }

 .bottom .bottom-logo p {
   line-height: 30px;
   font-weight: 400;
   color: #8686b5;
 }

 @media only screen and (max-width: 991px) {
   .bottom .bottom-widget {
     margin-bottom: 30px;
   }
 }

 .bottom .bottom-widget .widget-title {
   font-size: 18px;
   font-weight: 600;
   color: #fff;
   margin-bottom: 20px;
   text-transform: uppercase;
 }

 .bottom .bottom-widget ul li a {
   color: #8686b5;
   font-weight: 400;
   margin-bottom: 10px;
   display: inline-block;
 }

 footer {
   color: #fff;
   font-size: 15px;
 }

 footer .widget {
   margin-bottom: 20px;
 }

 footer strong {
   color: #fff;
 }

 footer .footer-logo {
   max-width: 240px;
   margin-bottom: 30px;
 }

 footer .footer-logo img {
   width: 100%;
 }

 footer .widget-logo ul {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
 }

 footer .widget-logo ul li {
   display: inline-block;
   flex: 50%;
   padding: 0 15px 15px 0px;
 }

 footer .dlab-social-icon li {
   display: inline-block;
   margin-right: 20px;
 }

 footer .dlab-social-icon a {
   color: #fff;
   font-size: 17px;
 }

 footer .widget_about p {
   margin-bottom: 20px;
 }

 footer .footer-title {
   margin: 0;
   font-size: 18px;
   font-weight: 600;
   padding-bottom: 10px;
   margin-bottom: 40px;
   position: relative;
   color: #fff;
   line-height: 1.2;
   text-transform: uppercase;
 }

 footer .footer-title:after {
   content: "";
   background: #fff;
   width: 55px;
   height: 3px;
   position: absolute;
   bottom: 0;
   left: 0;
 }

 footer .footer-top {
   background: #213f99;
   padding: 70px 0 30px;
 }

 @media only screen and (max-width: 767px) {
   footer .footer-top {
     padding: 50px 0 20px;
   }
 }

 footer .footer-bottom {
   background-color: #213f99;
   padding: 20px 0;
   font-size: 14px;
   border-top: 1px solid rgba(255, 255, 255, 0.25);
 }

 footer .widget-link li {
   display: inline-block;
   text-transform: uppercase;
   margin-left: 20px;
 }

 @media only screen and (max-width: 767px) {
   footer .widget-link li {
     margin-left: 8px;
     margin-right: 7px;
   }
 }

 footer .widget-link a {
   color: #fff;
 }

 .contact-info-bx li {
   padding-bottom: 10px;
   position: relative;
   padding-left: 30px;
   padding-top: 2px;
 }

 .contact-info-bx li strong {
   font-weight: 400;
 }

 .contact-info-bx i {
   width: auto;
   height: 22px;
   color: #fff;
   line-height: 26px;
   text-align: center;
   border-radius: 30px;
   margin-right: 5px;
   position: absolute;
   top: 0;
   left: 0;
   font-size: 22px;
 }