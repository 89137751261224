.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  width: 60px;
}

.carousel .control-next.control-arrow:before {
  border-left: 17px solid #fff !important;
}
.carousel .control-next.control-arrow:after {
  border-left: 17px solid #fff !important;
}
.carousel.carousel-slider .control-arrow {
  background: #00000040 !important;
}
.carousel .slide .legend {
  opacity: 0.7 !important;
}

.carousel .slide .legend {
  opacity: 100% !important;
  font-weight: bold;
}

.carousel .control-dots .dot {
  width: 18px !important;
  height: 18px !important;
  background: #fe9431 !important;
}

.page-wraper {
  border-top: 2px solid #fe9431;
}

.icon-bx-wraper {
  min-height: 237px;
}

.bg-primaryBlue {
  background-color: #20409af5 !important;
}
.legend2 {
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 85px;
  left: 12%;
  margin-left: -6.4%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  transition: opacity 0.35s ease-in-out;
}

.header-nav .nav > li > a {
  color: #002c4e !important;
  font-family: system-ui;
  /* text-transform: uppercase; */
  font-weight: 500;
}

.section-head h2.title {
  font-size: 21px !important;
  text-transform: uppercase;
  color: #646464;
}
.footer_social_icons li {
  margin: 0;
  margin-right: 10px !important;
}
.footer_social_icons li a {
  padding: 2px 8px;
  font-size: 26px !important;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
}

.pnews img {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
}
.dlab-info p {
  font-size: 14px;
}
.postTitle {
  font-size: 30px !important;
  color: #000 !important;
}
.newsDetail {
  text-align: justify !important;
  line-height: 28px !important;
}
.newsDetail span,
p {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 30px;
  color: #333;
  text-align: justify;
}
.dlab-title a:hover {
  color: #20409a;
}
a img:hover {
  border-radius: 0;
}
.service-box:hover {
  color: white !important;
}
.service-box .info {
  min-height: 230px;
}
.onClick:hover {
  cursor: pointer;
}
.service_tables-inner div h1,
h2,
h3 {
  font-size: 16px !important;
}

.service_tables-inner ul li p {
  font-size: 14px;
  margin: 0;
}

.service_tables-inner ul li {
  list-style: square;
}

.service_tables-inner {
  padding: 32px;
  padding-top: 1px;
}

.service_tables-wrapper {
  min-height: 430px;
  margin-bottom: 10px;
}

.task-list {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.task-list .task {
  background: #ffffff;
  width: 100%;
  border: 1px solid #c7c7c7;
  margin-top: 16px;
  padding: 14px;
}
.task-list .task .ant-checkbox-wrapper {
  width: 100%;
}

.task-list .form-detail .item {
}
.task-list .form-detail .item label{
  font-size: 14px;
  margin-top: 12px;
}
.task-list .form-detail .btn-submit {
  text-align: center;
  margin: 10px;
}
.form-detail{
  margin-top: 20px;
}