* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
	color: #0084b4 !important
}

.tp-caption.Twitter-Content a:hover {
	color: #0084b4 !important;
	text-decoration: underline !important
}

.medium_grey,
.tp-caption.medium_grey {
	background-color: #888;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Arial;
	font-size: 20px;
	font-weight: 700;
	line-height: 20px;
	margin: 0;
	padding: 2px 4px;
	position: absolute;
	text-shadow: 0 2px 5px rgba(0, 0, 0, .5);
	white-space: nowrap
}

.small_text,
.tp-caption.small_text {
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Arial;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	margin: 0;
	position: absolute;
	text-shadow: 0 2px 5px rgba(0, 0, 0, .5);
	white-space: nowrap
}

.medium_text,
.tp-caption.medium_text {
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Arial;
	font-size: 20px;
	font-weight: 700;
	line-height: 20px;
	margin: 0;
	position: absolute;
	text-shadow: 0 2px 5px rgba(0, 0, 0, .5);
	white-space: nowrap
}

.large_text,
.tp-caption.large_text {
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Arial;
	font-size: 40px;
	font-weight: 700;
	line-height: 40px;
	margin: 0;
	position: absolute;
	text-shadow: 0 2px 5px rgba(0, 0, 0, .5);
	white-space: nowrap
}

.tp-caption.very_large_text,
.very_large_text {
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Arial;
	font-size: 60px;
	font-weight: 700;
	letter-spacing: -2px;
	line-height: 60px;
	margin: 0;
	position: absolute;
	text-shadow: 0 2px 5px rgba(0, 0, 0, .5);
	white-space: nowrap
}

.tp-caption.very_big_white,
.very_big_white {
	background-color: #000;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Arial;
	font-size: 60px;
	font-weight: 800;
	line-height: 60px;
	margin: 0;
	padding: 1px 4px 0;
	position: absolute;
	text-shadow: none;
	white-space: nowrap
}

.tp-caption.very_big_black,
.very_big_black {
	background-color: #fff;
	border-style: none;
	border-width: 0;
	color: #000;
	font-family: Arial;
	font-size: 60px;
	font-weight: 700;
	line-height: 60px;
	margin: 0;
	padding: 1px 4px 0;
	position: absolute;
	text-shadow: none;
	white-space: nowrap
}

.modern_medium_fat,
.tp-caption.modern_medium_fat {
	border-style: none;
	border-width: 0;
	color: #000;
	font-family: "Open Sans", sans-serif;
	font-size: 24px;
	font-weight: 800;
	line-height: 20px;
	margin: 0;
	position: absolute;
	text-shadow: none;
	white-space: nowrap
}

.modern_medium_fat_white,
.tp-caption.modern_medium_fat_white {
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans", sans-serif;
	font-size: 24px;
	font-weight: 800;
	line-height: 20px;
	margin: 0;
	position: absolute;
	text-shadow: none;
	white-space: nowrap
}

.modern_medium_light,
.tp-caption.modern_medium_light {
	border-style: none;
	border-width: 0;
	color: #000;
	font-family: "Open Sans", sans-serif;
	font-size: 24px;
	font-weight: 300;
	line-height: 20px;
	margin: 0;
	position: absolute;
	text-shadow: none;
	white-space: nowrap
}

.modern_big_bluebg,
.tp-caption.modern_big_bluebg {
	background-color: #4e5b6c;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans", sans-serif;
	font-size: 30px;
	font-weight: 800;
	letter-spacing: 0;
	line-height: 36px;
	margin: 0;
	padding: 3px 10px;
	position: absolute;
	text-shadow: none
}

.modern_big_redbg,
.tp-caption.modern_big_redbg {
	background-color: #de543e;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans", sans-serif;
	font-size: 30px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 36px;
	margin: 0;
	padding: 1px 10px 3px;
	position: absolute;
	text-shadow: none
}

.modern_small_text_dark,
.tp-caption.modern_small_text_dark {
	border-style: none;
	border-width: 0;
	color: #555;
	font-family: Arial;
	font-size: 14px;
	line-height: 22px;
	margin: 0;
	position: absolute;
	text-shadow: none;
	white-space: nowrap
}

.boxshadow,
.tp-caption.boxshadow {
	box-shadow: 0 0 20px rgba(0, 0, 0, .5)
}

.black,
.tp-caption.black {
	color: #000;
	text-shadow: none
}

.noshadow,
.tp-caption.noshadow {
	text-shadow: none
}

.thinheadline_dark,
.tp-caption.thinheadline_dark {
	background-color: transparent;
	color: rgba(0, 0, 0, .85);
	font-family: "Open Sans";
	font-size: 30px;
	font-weight: 300;
	line-height: 30px;
	position: absolute;
	text-shadow: none
}

.thintext_dark,
.tp-caption.thintext_dark {
	background-color: transparent;
	color: rgba(0, 0, 0, .85);
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 300;
	line-height: 26px;
	position: absolute;
	text-shadow: none
}

.largeblackbg,
.tp-caption.largeblackbg {
	background-color: #000;
	border-radius: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 50px;
	font-weight: 300;
	line-height: 70px;
	padding: 0 20px;
	position: absolute;
	text-shadow: none
}

.largepinkbg,
.tp-caption.largepinkbg {
	background-color: #db4360;
	border-radius: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 50px;
	font-weight: 300;
	line-height: 70px;
	padding: 0 20px;
	position: absolute;
	text-shadow: none
}

.largewhitebg,
.tp-caption.largewhitebg {
	background-color: #fff;
	border-radius: 0;
	color: #000;
	font-family: "Open Sans";
	font-size: 50px;
	font-weight: 300;
	line-height: 70px;
	padding: 0 20px;
	position: absolute;
	text-shadow: none
}

.largegreenbg,
.tp-caption.largegreenbg {
	background-color: #67ae73;
	border-radius: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 50px;
	font-weight: 300;
	line-height: 70px;
	padding: 0 20px;
	position: absolute;
	text-shadow: none
}

.excerpt,
.tp-caption.excerpt {
	background-color: #000;
	border-color: #fff;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Arial;
	font-size: 36px;
	font-weight: 700;
	height: auto;
	letter-spacing: -1.5px;
	line-height: 36px;
	margin: 0;
	padding: 1px 4px 0;
	text-decoration: none;
	text-shadow: none;
	white-space: normal !important;
	width: 150px
}

.large_bold_grey,
.tp-caption.large_bold_grey {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #666;
	font-family: "Open Sans";
	font-size: 60px;
	font-weight: 800;
	line-height: 60px;
	margin: 0;
	padding: 1px 4px 0;
	text-decoration: none;
	text-shadow: none
}

.medium_thin_grey,
.tp-caption.medium_thin_grey {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #666;
	font-family: "Open Sans";
	font-size: 34px;
	font-weight: 300;
	line-height: 30px;
	margin: 0;
	padding: 1px 4px 0;
	text-decoration: none;
	text-shadow: none
}

.small_thin_grey,
.tp-caption.small_thin_grey {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #757575;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 300;
	line-height: 26px;
	margin: 0;
	padding: 1px 4px 0;
	text-decoration: none;
	text-shadow: none
}

.lightgrey_divider,
.tp-caption.lightgrey_divider {
	background-color: #ebebeb;
	background-position: initial;
	background-repeat: initial;
	border-color: #222;
	border-style: none;
	border-width: 0;
	height: 3px;
	text-decoration: none;
	width: 370px
}

.large_bold_darkblue,
.tp-caption.large_bold_darkblue {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #34495e;
	font-family: "Open Sans";
	font-size: 58px;
	font-weight: 800;
	line-height: 60px;
	text-decoration: none
}

.medium_bg_darkblue,
.tp-caption.medium_bg_darkblue {
	background-color: #34495e;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 20px;
	font-weight: 800;
	line-height: 20px;
	padding: 10px;
	text-decoration: none
}

.medium_bold_red,
.tp-caption.medium_bold_red {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #e33a0c;
	font-family: "Open Sans";
	font-size: 24px;
	font-weight: 800;
	line-height: 30px;
	padding: 0;
	text-decoration: none
}

.medium_light_red,
.tp-caption.medium_light_red {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #e33a0c;
	font-family: "Open Sans";
	font-size: 21px;
	font-weight: 300;
	line-height: 26px;
	padding: 0;
	text-decoration: none
}

.medium_bg_red,
.tp-caption.medium_bg_red {
	background-color: #e33a0c;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 20px;
	font-weight: 800;
	line-height: 20px;
	padding: 10px;
	text-decoration: none
}

.medium_bold_orange,
.tp-caption.medium_bold_orange {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #f39c12;
	font-family: "Open Sans";
	font-size: 24px;
	font-weight: 800;
	line-height: 30px;
	text-decoration: none
}

.medium_bg_orange,
.tp-caption.medium_bg_orange {
	background-color: #f39c12;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 20px;
	font-weight: 800;
	line-height: 20px;
	padding: 10px;
	text-decoration: none
}

.grassfloor,
.tp-caption.grassfloor {
	background-color: #a0b397;
	border-color: #222;
	border-style: none;
	border-width: 0;
	height: 150px;
	text-decoration: none;
	width: 4000px
}

.large_bold_white,
.tp-caption.large_bold_white {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 58px;
	font-weight: 800;
	line-height: 60px;
	text-decoration: none
}

.medium_light_white,
.tp-caption.medium_light_white {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 30px;
	font-weight: 300;
	line-height: 36px;
	padding: 0;
	text-decoration: none
}

.mediumlarge_light_white,
.tp-caption.mediumlarge_light_white {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 34px;
	font-weight: 300;
	line-height: 40px;
	padding: 0;
	text-decoration: none
}

.mediumlarge_light_white_center,
.tp-caption.mediumlarge_light_white_center {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 34px;
	font-weight: 300;
	line-height: 40px;
	padding: 0;
	text-align: center;
	text-decoration: none
}

.medium_bg_asbestos,
.tp-caption.medium_bg_asbestos {
	background-color: #7f8c8d;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 20px;
	font-weight: 800;
	line-height: 20px;
	padding: 10px;
	text-decoration: none
}

.medium_light_black,
.tp-caption.medium_light_black {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #000;
	font-family: "Open Sans";
	font-size: 30px;
	font-weight: 300;
	line-height: 36px;
	padding: 0;
	text-decoration: none
}

.large_bold_black,
.tp-caption.large_bold_black {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #000;
	font-family: "Open Sans";
	font-size: 58px;
	font-weight: 800;
	line-height: 60px;
	text-decoration: none
}

.mediumlarge_light_darkblue,
.tp-caption.mediumlarge_light_darkblue {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #34495e;
	font-family: "Open Sans";
	font-size: 34px;
	font-weight: 300;
	line-height: 40px;
	padding: 0;
	text-decoration: none
}

.small_light_white,
.tp-caption.small_light_white {
	background-color: transparent;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 17px;
	font-weight: 300;
	line-height: 28px;
	padding: 0;
	text-decoration: none
}

.roundedimage,
.tp-caption.roundedimage {
	border-color: #222;
	border-style: none;
	border-width: 0
}

.large_bg_black,
.tp-caption.large_bg_black {
	background-color: #000;
	border-color: #ffd658;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Open Sans";
	font-size: 40px;
	font-weight: 800;
	line-height: 40px;
	padding: 10px 20px 15px;
	text-decoration: none
}

.mediumwhitebg,
.tp-caption.mediumwhitebg {
	background-color: #fff;
	border-color: #000;
	border-style: none;
	border-width: 0;
	color: #000;
	font-family: "Open Sans";
	font-size: 30px;
	font-weight: 300;
	line-height: 30px;
	padding: 5px 15px 10px;
	text-decoration: none;
	text-shadow: none
}

.maincaption,
.tp-caption.maincaption {
	background-color: transparent;
	border-color: #000;
	border-style: none;
	border-width: 0;
	color: #212a40;
	font-family: roboto;
	font-size: 33px;
	font-weight: 500;
	line-height: 43px;
	text-decoration: none;
	text-shadow: none
}

.miami_title_60px,
.tp-caption.miami_title_60px {
	background-color: transparent;
	border-color: #000;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Source Sans Pro";
	font-size: 60px;
	font-weight: 700;
	letter-spacing: 1px;
	line-height: 60px;
	text-decoration: none;
	text-shadow: none
}

.miami_subtitle,
.tp-caption.miami_subtitle {
	background-color: transparent;
	border-color: #000;
	border-style: none;
	border-width: 0;
	color: rgba(255, 255, 255, .65);
	font-family: "Source Sans Pro";
	font-size: 17px;
	font-weight: 400;
	letter-spacing: 2px;
	line-height: 24px;
	text-decoration: none;
	text-shadow: none
}

.divideline30px,
.tp-caption.divideline30px {
	background: #fff;
	background-color: #fff;
	border-color: #222;
	border-style: none;
	border-width: 0;
	height: 2px;
	min-width: 30px;
	text-decoration: none
}

.Miami_nostyle,
.tp-caption.Miami_nostyle {
	border-color: #222;
	border-style: none;
	border-width: 0
}

.miami_content_light,
.tp-caption.miami_content_light {
	background-color: transparent;
	border-color: #000;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Source Sans Pro";
	font-size: 22px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 28px;
	text-decoration: none;
	text-shadow: none
}

.miami_title_60px_dark,
.tp-caption.miami_title_60px_dark {
	background-color: transparent;
	border-color: #000;
	border-style: none;
	border-width: 0;
	color: #333;
	font-family: "Source Sans Pro";
	font-size: 60px;
	font-weight: 700;
	letter-spacing: 1px;
	line-height: 60px;
	text-decoration: none;
	text-shadow: none
}

.miami_content_dark,
.tp-caption.miami_content_dark {
	background-color: transparent;
	border-color: #000;
	border-style: none;
	border-width: 0;
	color: #666;
	font-family: "Source Sans Pro";
	font-size: 22px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 28px;
	text-decoration: none;
	text-shadow: none
}

.divideline30px_dark,
.tp-caption.divideline30px_dark {
	background-color: #333;
	border-color: #222;
	border-style: none;
	border-width: 0;
	height: 2px;
	min-width: 30px;
	text-decoration: none
}

.ellipse70px,
.tp-caption.ellipse70px {
	background-color: rgba(0, 0, 0, .14902);
	border-color: #222;
	border-radius: 50px 50px 50px 50px;
	border-style: none;
	border-width: 0;
	cursor: pointer;
	line-height: 1px;
	min-height: 70px;
	min-width: 70px;
	text-decoration: none
}

.arrowicon,
.tp-caption.arrowicon {
	border-color: #222;
	border-style: none;
	border-width: 0;
	line-height: 1px
}

.MarkerDisplay,
.tp-caption.MarkerDisplay {
	background-color: transparent;
	border-color: #000;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	font-family: "Permanent Marker";
	font-style: normal;
	padding: 0;
	text-decoration: none;
	text-shadow: none
}

.Restaurant-Display,
.tp-caption.Restaurant-Display {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Roboto;
	font-size: 120px;
	font-style: normal;
	font-weight: 700;
	line-height: 120px;
	padding: 0;
	text-decoration: none
}

.Restaurant-Cursive,
.tp-caption.Restaurant-Cursive {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Nothing you could do";
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 2px;
	line-height: 30px;
	padding: 0;
	text-decoration: none
}

.Restaurant-ScrollDownText,
.tp-caption.Restaurant-ScrollDownText {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Roboto;
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 2px;
	line-height: 17px;
	padding: 0;
	text-decoration: none
}

.Restaurant-Description,
.tp-caption.Restaurant-Description {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Roboto;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	letter-spacing: 3px;
	line-height: 30px;
	padding: 0;
	text-decoration: none
}

.Restaurant-Price,
.tp-caption.Restaurant-Price {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Roboto;
	font-size: 30px;
	font-style: normal;
	font-weight: 300;
	letter-spacing: 3px;
	line-height: 30px;
	padding: 0;
	text-decoration: none
}

.Restaurant-Menuitem,
.tp-caption.Restaurant-Menuitem {
	background-color: #000;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Roboto;
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 2px;
	line-height: 17px;
	padding: 10px 30px;
	text-align: left;
	text-decoration: none
}

.Furniture-LogoText,
.tp-caption.Furniture-LogoText {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #e6cfa3;
	font-family: Raleway;
	font-size: 160px;
	font-style: normal;
	font-weight: 300;
	line-height: 150px;
	padding: 0;
	text-decoration: none;
	text-shadow: none
}

.Furniture-Plus,
.tp-caption.Furniture-Plus {
	background-color: #fff;
	border-color: transparent;
	border-radius: 30px 30px 30px 30px;
	border-style: none;
	border-width: 0;
	box-shadow: rgba(0, 0, 0, .1) 0 1px 3px;
	color: #e6cfa3;
	font-family: Raleway;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	padding: 6px 7px 4px;
	text-decoration: none;
	text-shadow: none
}

.Furniture-Title,
.tp-caption.Furniture-Title {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #000;
	font-family: Raleway;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 3px;
	line-height: 20px;
	padding: 0;
	text-decoration: none;
	text-shadow: none
}

.Furniture-Subtitle,
.tp-caption.Furniture-Subtitle {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #000;
	font-family: Raleway;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;
	line-height: 20px;
	padding: 0;
	text-decoration: none;
	text-shadow: none
}

.Gym-Display,
.tp-caption.Gym-Display {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 80px;
	font-style: normal;
	font-weight: 900;
	line-height: 70px;
	padding: 0;
	text-decoration: none
}

.Gym-Subline,
.tp-caption.Gym-Subline {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 30px;
	font-style: normal;
	font-weight: 100;
	letter-spacing: 5px;
	line-height: 30px;
	padding: 0;
	text-decoration: none
}

.Gym-SmallText,
.tp-caption.Gym-SmallText {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;
	line-height: 22;
	padding: 0;
	text-decoration: none;
	text-shadow: none
}

.Fashion-SmallText,
.tp-caption.Fashion-SmallText {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 2px;
	line-height: 20px;
	padding: 0;
	text-decoration: none
}

.Fashion-BigDisplay,
.tp-caption.Fashion-BigDisplay {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #000;
	font-family: Raleway;
	font-size: 60px;
	font-style: normal;
	font-weight: 900;
	letter-spacing: 2px;
	line-height: 60px;
	padding: 0;
	text-decoration: none
}

.Fashion-TextBlock,
.tp-caption.Fashion-TextBlock {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #000;
	font-family: Raleway;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 2px;
	line-height: 40px;
	padding: 0;
	text-decoration: none
}

.Sports-Display,
.tp-caption.Sports-Display {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 130px;
	font-style: normal;
	font-weight: 100;
	letter-spacing: 13px;
	line-height: 130px;
	padding: 0;
	text-decoration: none
}

.Sports-DisplayFat,
.tp-caption.Sports-DisplayFat {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 130px;
	font-style: normal;
	font-weight: 900;
	line-height: 130px;
	padding: 0;
	text-decoration: none
}

.Sports-Subline,
.tp-caption.Sports-Subline {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #000;
	font-family: Raleway;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 4px;
	line-height: 32px;
	padding: 0;
	text-decoration: none
}

.Instagram-Caption,
.tp-caption.Instagram-Caption {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Roboto;
	font-size: 20px;
	font-style: normal;
	font-weight: 900;
	line-height: 20px;
	padding: 0;
	text-decoration: none
}

.News-Title,
.tp-caption.News-Title {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Roboto Slab";
	font-size: 70px;
	font-style: normal;
	font-weight: 400;
	line-height: 60px;
	padding: 0;
	text-decoration: none
}

.News-Subtitle,
.tp-caption.News-Subtitle {
	background-color: rgba(255, 255, 255, 0);
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Roboto Slab";
	font-size: 15px;
	font-style: normal;
	font-weight: 300;
	line-height: 24px;
	padding: 0;
	text-decoration: none
}

.News-Subtitle:hover,
.tp-caption.News-Subtitle:hover {
	background-color: rgba(255, 255, 255, 0);
	border-color: transparent;
	border-radius: 0;
	border-style: solid;
	border-width: 0;
	color: rgba(255, 255, 255, .65);
	text-decoration: none
}

.Photography-Display,
.tp-caption.Photography-Display {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 80px;
	font-style: normal;
	font-weight: 100;
	letter-spacing: 5px;
	line-height: 70px;
	padding: 0;
	text-decoration: none
}

.Photography-Subline,
.tp-caption.Photography-Subline {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #777;
	font-family: Raleway;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	letter-spacing: 3px;
	line-height: 30px;
	padding: 0;
	text-decoration: none
}

.Photography-ImageHover,
.tp-caption.Photography-ImageHover {
	background-color: transparent;
	border-color: rgba(255, 255, 255, 0);
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 22;
	padding: 0;
	text-decoration: none
}

.Photography-ImageHover:hover,
.tp-caption.Photography-ImageHover:hover {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	text-decoration: none
}

.Photography-Menuitem,
.tp-caption.Photography-Menuitem {
	background-color: rgba(0, 0, 0, .65);
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	letter-spacing: 2px;
	line-height: 20px;
	padding: 3px 5px 3px 8px;
	text-decoration: none
}

.Photography-Menuitem:hover,
.tp-caption.Photography-Menuitem:hover {
	background-color: rgba(0, 255, 222, .65);
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	text-decoration: none
}

.Photography-Textblock,
.tp-caption.Photography-Textblock {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;
	letter-spacing: 2px;
	line-height: 30px;
	padding: 0;
	text-decoration: none
}

.Photography-Subline-2,
.tp-caption.Photography-Subline-2 {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: rgba(255, 255, 255, .35);
	font-family: Raleway;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	letter-spacing: 3px;
	line-height: 30px;
	padding: 0;
	text-decoration: none
}

.Photography-ImageHover2,
.tp-caption.Photography-ImageHover2 {
	background-color: transparent;
	border-color: rgba(255, 255, 255, 0);
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Arial;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 22;
	padding: 0;
	text-decoration: none
}

.Photography-ImageHover2:hover,
.tp-caption.Photography-ImageHover2:hover {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	text-decoration: none
}

.WebProduct-Title,
.tp-caption.WebProduct-Title {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #333;
	font-family: Raleway;
	font-size: 90px;
	font-style: normal;
	font-weight: 100;
	line-height: 90px;
	padding: 0;
	text-decoration: none
}

.WebProduct-SubTitle,
.tp-caption.WebProduct-SubTitle {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #999;
	font-family: Raleway;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	padding: 0;
	text-decoration: none
}

.WebProduct-Content,
.tp-caption.WebProduct-Content {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #999;
	font-family: Raleway;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	padding: 0;
	text-decoration: none
}

.WebProduct-Menuitem,
.tp-caption.WebProduct-Menuitem {
	background-color: #333;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 2px;
	line-height: 20px;
	padding: 3px 5px 3px 8px;
	text-align: left;
	text-decoration: none
}

.WebProduct-Menuitem:hover,
.tp-caption.WebProduct-Menuitem:hover {
	background-color: #fff;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #999;
	text-decoration: none
}

.WebProduct-Title-Light,
.tp-caption.WebProduct-Title-Light {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 90px;
	font-style: normal;
	font-weight: 100;
	line-height: 90px;
	padding: 0;
	text-align: left;
	text-decoration: none
}

.WebProduct-SubTitle-Light,
.tp-caption.WebProduct-SubTitle-Light {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: rgba(255, 255, 255, .35);
	font-family: Raleway;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	padding: 0;
	text-align: left;
	text-decoration: none
}

.WebProduct-Content-Light,
.tp-caption.WebProduct-Content-Light {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: rgba(255, 255, 255, .65);
	font-family: Raleway;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	padding: 0;
	text-align: left;
	text-decoration: none
}

.FatRounded,
.tp-caption.FatRounded {
	background-color: rgba(0, 0, 0, .5);
	border-color: #d3d3d3;
	border-radius: 50px 50px 50px 50px;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 30px;
	font-style: normal;
	font-weight: 900;
	line-height: 30px;
	padding: 20px 22px 20px 25px;
	text-align: left;
	text-decoration: none;
	text-shadow: none
}

.FatRounded:hover,
.tp-caption.FatRounded:hover {
	background-color: #000;
	border-color: #d3d3d3;
	border-radius: 50px 50px 50px 50px;
	border-style: none;
	border-width: 0;
	color: #fff;
	text-decoration: none
}

.NotGeneric-Title,
.tp-caption.NotGeneric-Title {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 70px;
	font-style: normal;
	font-weight: 800;
	line-height: 70px;
	padding: 10px 0;
	text-decoration: none
}

.NotGeneric-SubTitle,
.tp-caption.NotGeneric-SubTitle {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 4px;
	line-height: 20px;
	padding: 0;
	text-align: left;
	text-decoration: none
}

.NotGeneric-CallToAction,
.tp-caption.NotGeneric-CallToAction {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, .5);
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	font-family: Raleway;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 3px;
	line-height: 14px;
	padding: 10px 30px;
	text-align: left;
	text-decoration: none
}

.NotGeneric-CallToAction:hover,
.tp-caption.NotGeneric-CallToAction:hover {
	background-color: transparent;
	border-color: #fff;
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	text-decoration: none
}

.NotGeneric-Icon,
.tp-caption.NotGeneric-Icon {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, 0);
	border-radius: 0;
	border-style: solid;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 3px;
	line-height: 30px;
	padding: 0;
	text-align: left;
	text-decoration: none
}

.NotGeneric-Menuitem,
.tp-caption.NotGeneric-Menuitem {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, .15);
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	font-family: Raleway;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 3px;
	line-height: 14px;
	padding: 27px 30px;
	text-align: left;
	text-decoration: none
}

.NotGeneric-Menuitem:hover,
.tp-caption.NotGeneric-Menuitem:hover {
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff;
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	text-decoration: none
}

.MarkerStyle,
.tp-caption.MarkerStyle {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Permanent Marker";
	font-size: 17px;
	font-style: normal;
	font-weight: 100;
	line-height: 30px;
	padding: 0;
	text-align: left;
	text-decoration: none
}

.Gym-Menuitem,
.tp-caption.Gym-Menuitem {
	background-color: #000;
	border-color: rgba(255, 255, 255, 0);
	border-radius: 3px 3px 3px 3px;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	font-family: Raleway;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	letter-spacing: 2px;
	line-height: 20px;
	padding: 3px 5px 3px 8px;
	text-align: left;
	text-decoration: none
}

.Gym-Menuitem:hover,
.tp-caption.Gym-Menuitem:hover {
	background-color: #000;
	border-color: rgba(255, 255, 255, .25);
	border-radius: 3px 3px 3px 3px;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	text-decoration: none
}

.Newspaper-Button,
.tp-caption.Newspaper-Button {
	background-color: rgba(255, 255, 255, 0);
	border-color: rgba(255, 255, 255, .25);
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	font-family: Roboto;
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 2px;
	line-height: 17px;
	padding: 12px 35px;
	text-align: left;
	text-decoration: none
}

.Newspaper-Button:hover,
.tp-caption.Newspaper-Button:hover {
	background-color: #fff;
	border-color: #fff;
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #000;
	text-decoration: none
}

.Newspaper-Subtitle,
.tp-caption.Newspaper-Subtitle {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #a8d8ee;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 900;
	line-height: 20px;
	padding: 0;
	text-align: left;
	text-decoration: none
}

.Newspaper-Title,
.tp-caption.Newspaper-Title {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Roboto Slab";
	font-size: 50px;
	font-style: normal;
	font-weight: 400;
	line-height: 55px;
	padding: 0 0 10px;
	text-align: left;
	text-decoration: none
}

.Newspaper-Title-Centered,
.tp-caption.Newspaper-Title-Centered {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: "Roboto Slab";
	font-size: 50px;
	font-style: normal;
	font-weight: 400;
	line-height: 55px;
	padding: 0 0 10px;
	text-align: center;
	text-decoration: none
}

.Hero-Button,
.tp-caption.Hero-Button {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, .5);
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	font-family: Raleway;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 3px;
	line-height: 14px;
	padding: 10px 30px;
	text-align: left;
	text-decoration: none
}

.Hero-Button:hover,
.tp-caption.Hero-Button:hover {
	background-color: #fff;
	border-color: #fff;
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #000;
	text-decoration: none
}

.Video-Title,
.tp-caption.Video-Title {
	background-color: #000;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 30px;
	font-style: normal;
	font-weight: 900;
	line-height: 30px;
	padding: 5px;
	text-align: left;
	text-decoration: none
}

.Video-SubTitle,
.tp-caption.Video-SubTitle {
	background-color: rgba(0, 0, 0, .35);
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 2px;
	line-height: 12px;
	padding: 5px;
	text-align: left;
	text-decoration: none
}

.NotGeneric-Button,
.tp-caption.NotGeneric-Button {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, .5);
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	font-family: Raleway;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 3px;
	line-height: 14px;
	padding: 10px 30px;
	text-align: left;
	text-decoration: none
}

.NotGeneric-Button:hover,
.tp-caption.NotGeneric-Button:hover {
	background-color: transparent;
	border-color: #fff;
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	text-decoration: none
}

.NotGeneric-BigButton,
.tp-caption.NotGeneric-BigButton {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, .15);
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	font-family: Raleway;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 3px;
	line-height: 14px;
	padding: 27px 30px;
	text-align: left;
	text-decoration: none
}

.NotGeneric-BigButton:hover,
.tp-caption.NotGeneric-BigButton:hover {
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff;
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	text-decoration: none
}

.WebProduct-Button,
.tp-caption.WebProduct-Button {
	background-color: #333;
	border-color: #000;
	border-radius: 0;
	border-style: none;
	border-width: 2px;
	color: #fff;
	font-family: Raleway;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 48px;
	padding: 0 40px;
	text-align: left;
	text-decoration: none
}

.WebProduct-Button:hover,
.tp-caption.WebProduct-Button:hover {
	background-color: #fff;
	border-color: #000;
	border-radius: 0;
	border-style: none;
	border-width: 2px;
	color: #333;
	text-decoration: none
}

.Restaurant-Button,
.tp-caption.Restaurant-Button {
	background-color: rgba(10, 10, 10, 0);
	border-color: rgba(255, 255, 255, .5);
	border-radius: 0;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	font-family: Roboto;
	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 3px;
	line-height: 17px;
	padding: 12px 35px;
	text-align: left;
	text-decoration: none
}

.Restaurant-Button:hover,
.tp-caption.Restaurant-Button:hover {
	background-color: rgba(0, 0, 0, 0);
	border-color: #ffe081;
	border-radius: 0;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	text-decoration: none
}

.Gym-Button,
.tp-caption.Gym-Button {
	background-color: #8bc027;
	border-color: transparent;
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 0;
	color: #fff;
	font-family: Raleway;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 15px;
	padding: 13px 35px;
	text-align: left;
	text-decoration: none
}

.Gym-Button:hover,
.tp-caption.Gym-Button:hover {
	background-color: #72a800;
	border-color: transparent;
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 0;
	color: #fff;
	text-decoration: none
}

.Gym-Button-Light,
.tp-caption.Gym-Button-Light {
	background-color: transparent;
	border-color: rgba(255, 255, 255, .25);
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	font-family: Raleway;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: 15px;
	padding: 12px 35px;
	text-align: left;
	text-decoration: none
}

.Gym-Button-Light:hover,
.tp-caption.Gym-Button-Light:hover {
	background-color: rgba(114, 168, 0, 0);
	border-color: #8bc027;
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	text-decoration: none
}

.Sports-Button-Light,
.tp-caption.Sports-Button-Light {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, .5);
	border-radius: 0;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	font-family: Raleway;
	font-size: 17px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 2px;
	line-height: 17px;
	padding: 12px 35px;
	text-align: left;
	text-decoration: none
}

.Sports-Button-Light:hover,
.tp-caption.Sports-Button-Light:hover {
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff;
	border-radius: 0;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	text-decoration: none
}

.Sports-Button-Red,
.tp-caption.Sports-Button-Red {
	background-color: #db1c22;
	border-color: rgba(219, 28, 34, 0);
	border-radius: 0;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	font-family: Raleway;
	font-size: 17px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 2px;
	line-height: 17px;
	padding: 12px 35px;
	text-align: left;
	text-decoration: none
}

.Sports-Button-Red:hover,
.tp-caption.Sports-Button-Red:hover {
	background-color: #000;
	border-color: #000;
	border-radius: 0;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	text-decoration: none
}

.Photography-Button,
.tp-caption.Photography-Button {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, .25);
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	font-family: Raleway;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 15px;
	padding: 13px 35px;
	text-align: left;
	text-decoration: none
}

.Photography-Button:hover,
.tp-caption.Photography-Button:hover {
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff;
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 1px;
	color: #fff;
	text-decoration: none
}

.Newspaper-Button-2,
.tp-caption.Newspaper-Button-2 {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, .5);
	border-radius: 3px 3px 3px 3px;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 900;
	line-height: 15px;
	padding: 10px 30px;
	text-align: left;
	text-decoration: none
}

.Newspaper-Button-2:hover,
.tp-caption.Newspaper-Button-2:hover {
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff;
	border-radius: 3px 3px 3px 3px;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	text-decoration: none
}

.Feature-Tour,
.tp-caption.Feature-Tour {
	background-color: #8bc027;
	border-color: transparent;
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 0;
	color: #fff;
	font-family: Roboto;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 17px;
	padding: 17px 35px;
	text-align: left;
	text-decoration: none
}

.Feature-Tour:hover,
.tp-caption.Feature-Tour:hover {
	background-color: #72a800;
	border-color: transparent;
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 0;
	color: #fff;
	text-decoration: none
}

.Feature-Examples,
.tp-caption.Feature-Examples {
	background-color: transparent;
	border-color: rgba(33, 42, 64, .15);
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 2px;
	color: rgba(33, 42, 64, .5);
	font-family: Roboto;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 17px;
	padding: 15px 35px;
	text-align: left;
	text-decoration: none
}

.Feature-Examples:hover,
.tp-caption.Feature-Examples:hover {
	background-color: transparent;
	border-color: #8bc027;
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 2px;
	color: #8bc027;
	text-decoration: none
}

.subcaption,
.tp-caption.subcaption {
	background-color: transparent;
	border-color: #000;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #6f7c82;
	font-family: roboto;
	font-size: 19px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	padding: 0;
	text-align: left;
	text-decoration: none;
	text-shadow: none
}

.menutab,
.tp-caption.menutab {
	background-color: transparent;
	border-color: #000;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #292e31;
	font-family: roboto;
	font-size: 25px;
	font-style: normal;
	font-weight: 300;
	line-height: 30px;
	padding: 0;
	text-align: left;
	text-decoration: none;
	text-shadow: none
}

.menutab:hover,
.tp-caption.menutab:hover {
	background-color: transparent;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #d50000;
	text-decoration: none
}

.maincontent,
.tp-caption.maincontent {
	background-color: transparent;
	border-color: #000;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #292e31;
	font-family: roboto;
	font-size: 21px;
	font-style: normal;
	font-weight: 300;
	line-height: 26px;
	padding: 0;
	text-align: left;
	text-decoration: none;
	text-shadow: none
}

.minitext,
.tp-caption.minitext {
	background-color: transparent;
	border-color: #000;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #b9babb;
	font-family: roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	padding: 0;
	text-align: left;
	text-decoration: none;
	text-shadow: none
}

.Feature-Buy,
.tp-caption.Feature-Buy {
	background-color: #009aee;
	border-color: transparent;
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 0;
	color: #fff;
	font-family: Roboto;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 17px;
	padding: 17px 35px;
	text-align: left;
	text-decoration: none
}

.Feature-Buy:hover,
.tp-caption.Feature-Buy:hover {
	background-color: #0085d6;
	border-color: transparent;
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 0;
	color: #fff;
	text-decoration: none
}

.Feature-Examples-Light,
.tp-caption.Feature-Examples-Light {
	background-color: transparent;
	border-color: rgba(255, 255, 255, .15);
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	font-family: Roboto;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 17px;
	padding: 15px 35px;
	text-align: left;
	text-decoration: none
}

.Feature-Examples-Light:hover,
.tp-caption.Feature-Examples-Light:hover {
	background-color: transparent;
	border-color: #fff;
	border-radius: 30px 30px 30px 30px;
	border-style: solid;
	border-width: 2px;
	color: #fff;
	text-decoration: none
}

.Facebook-Likes,
.tp-caption.Facebook-Likes {
	background-color: #3b5999;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #fff;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	padding: 5px 15px;
	text-align: left;
	text-decoration: none
}

.Twitter-Favorites,
.tp-caption.Twitter-Favorites {
	background-color: rgba(255, 255, 255, 0);
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #8899a6;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	padding: 0;
	text-align: left;
	text-decoration: none
}

.Twitter-Link,
.tp-caption.Twitter-Link {
	background-color: #fff;
	border-color: transparent;
	border-radius: 30px 30px 30px 30px;
	border-style: none;
	border-width: 0;
	color: #8799a5;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	padding: 11px 11px 9px;
	text-align: left;
	text-decoration: none
}

.Twitter-Link:hover,
.tp-caption.Twitter-Link:hover {
	background-color: #0084b4;
	border-color: transparent;
	border-radius: 30px 30px 30px 30px;
	border-style: none;
	border-width: 0;
	color: #fff;
	text-decoration: none
}

.Twitter-Retweet,
.tp-caption.Twitter-Retweet {
	background-color: rgba(255, 255, 255, 0);
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #8899a6;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	padding: 0;
	text-align: left;
	text-decoration: none
}

.Twitter-Content,
.tp-caption.Twitter-Content {
	background-color: #fff;
	border-color: transparent;
	border-radius: 0;
	border-style: none;
	border-width: 0;
	color: #292f33;
	font-family: Roboto;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	padding: 30px 30px 70px;
	text-align: left;
	text-decoration: none
}

.revtp-form input[type=email],
.revtp-form input[type=text],
.revtp-searchform input[type=email],
.revtp-searchform input[type=text] {
	font-family: Arial, sans-serif;
	font-size: 15px;
	color: #000;
	background-color: #fff;
	line-height: 46px;
	padding: 0 20px;
	cursor: text;
	border: 0;
	width: 400px;
	margin-bottom: 0;
	-webkit-transition: background-color .5s;
	-moz-transition: background-color .5s;
	-o-transition: background-color .5s;
	-ms-transition: background-color .5s;
	transition: background-color .5s;
	border-radius: 0
}

.BigBold-Title,
.tp-caption.BigBold-Title {
	color: #fff;
	font-size: 110px;
	line-height: 100px;
	font-weight: 800;
	font-style: normal;
	font-family: Raleway;
	padding: 10px 0 10px 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.BigBold-SubTitle,
.tp-caption.BigBold-SubTitle {
	color: rgba(255, 255, 255, .5);
	font-size: 15px;
	line-height: 24px;
	font-weight: 500;
	font-style: normal;
	font-family: Raleway;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left;
	letter-spacing: 1px
}

.BigBold-Button,
.tp-caption.BigBold-Button {
	color: #fff;
	font-size: 13px;
	line-height: 13px;
	font-weight: 500;
	font-style: normal;
	font-family: Raleway;
	padding: 15px 50px 15px 50px;
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, .5);
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	text-align: left;
	letter-spacing: 1px
}

.BigBold-Button:hover,
.tp-caption.BigBold-Button:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: #fff;
	border-style: solid;
	border-width: 1px;
	border-radius: 0
}

.FoodCarousel-Content,
.tp-caption.FoodCarousel-Content {
	color: #292e31;
	font-size: 17px;
	line-height: 28px;
	font-weight: 500;
	font-style: normal;
	font-family: Raleway;
	padding: 30px 30px 30px 30px;
	text-decoration: none;
	background-color: #fff;
	border-color: #292e31;
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	text-align: left
}

.FoodCarousel-Button,
.tp-caption.FoodCarousel-Button {
	color: #292e31;
	font-size: 13px;
	line-height: 13px;
	font-weight: 700;
	font-style: normal;
	font-family: Raleway;
	padding: 15px 70px 15px 50px;
	text-decoration: none;
	background-color: #fff;
	border-color: #292e31;
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	text-align: left;
	letter-spacing: 1px
}

.FoodCarousel-Button:hover,
.tp-caption.FoodCarousel-Button:hover {
	color: #fff;
	text-decoration: none;
	background-color: #292e31;
	border-color: #292e31;
	border-style: solid;
	border-width: 1px;
	border-radius: 0
}

.FoodCarousel-CloseButton,
.tp-caption.FoodCarousel-CloseButton {
	color: #292e31;
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	font-style: normal;
	font-family: Raleway;
	padding: 14px 14px 14px 16px;
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(41, 46, 49, 0);
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	text-align: left;
	letter-spacing: 1px
}

.FoodCarousel-CloseButton:hover,
.tp-caption.FoodCarousel-CloseButton:hover {
	color: #fff;
	text-decoration: none;
	background-color: #292e31;
	border-color: rgba(41, 46, 49, 0);
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px
}

.Video-SubTitle,
.tp-caption.Video-SubTitle {
	color: #fff;
	font-size: 12px;
	line-height: 12px;
	font-weight: 600;
	font-style: normal;
	font-family: Raleway;
	padding: 5px 5px 5px 5px;
	text-decoration: none;
	background-color: rgba(0, 0, 0, .35);
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	letter-spacing: 2px;
	text-align: left
}

.Video-Title,
.tp-caption.Video-Title {
	color: #fff;
	font-size: 30px;
	line-height: 30px;
	font-weight: 900;
	font-style: normal;
	font-family: Raleway;
	padding: 5px 5px 5px 5px;
	text-decoration: none;
	background-color: #000;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Travel-BigCaption,
.tp-caption.Travel-BigCaption {
	color: #fff;
	font-size: 50px;
	line-height: 50px;
	font-weight: 400;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Travel-SmallCaption,
.tp-caption.Travel-SmallCaption {
	color: #fff;
	font-size: 25px;
	line-height: 30px;
	font-weight: 300;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Travel-CallToAction,
.tp-caption.Travel-CallToAction {
	color: #fff;
	font-size: 25px;
	line-height: 25px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 12px 20px 12px 20px;
	text-decoration: none;
	background-color: rgba(255, 255, 255, .05);
	border-color: #fff;
	border-style: solid;
	border-width: 2px;
	border-radius: 5px 5px 5px 5px;
	text-align: left;
	letter-spacing: 1px
}

.Travel-CallToAction:hover,
.tp-caption.Travel-CallToAction:hover {
	color: #fff;
	text-decoration: none;
	background-color: rgba(255, 255, 255, .15);
	border-color: #fff;
	border-style: solid;
	border-width: 2px;
	border-radius: 5px 5px 5px 5px
}

.RotatingWords-TitleWhite,
.tp-caption.RotatingWords-TitleWhite {
	color: #fff;
	font-size: 70px;
	line-height: 70px;
	font-weight: 800;
	font-style: normal;
	font-family: Raleway;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.RotatingWords-Button,
.tp-caption.RotatingWords-Button {
	color: #fff;
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	font-style: normal;
	font-family: Raleway;
	padding: 20px 50px 20px 50px;
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, .15);
	border-style: solid;
	border-width: 2px;
	border-radius: 0;
	text-align: left;
	letter-spacing: 3px
}

.RotatingWords-Button:hover,
.tp-caption.RotatingWords-Button:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: #fff;
	border-style: solid;
	border-width: 2px;
	border-radius: 0
}

.RotatingWords-SmallText,
.tp-caption.RotatingWords-SmallText {
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	font-style: normal;
	font-family: Raleway;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left;
	text-shadow: none
}

.ContentZoom-SmallTitle,
.tp-caption.ContentZoom-SmallTitle {
	color: #292e31;
	font-size: 33px;
	line-height: 45px;
	font-weight: 600;
	font-style: normal;
	font-family: Raleway;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.ContentZoom-SmallSubtitle,
.tp-caption.ContentZoom-SmallSubtitle {
	color: #6f7c82;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	font-style: normal;
	font-family: Raleway;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.ContentZoom-SmallIcon,
.tp-caption.ContentZoom-SmallIcon {
	color: #292e31;
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	font-style: normal;
	font-family: Raleway;
	padding: 10px 10px 10px 10px;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.ContentZoom-SmallIcon:hover,
.tp-caption.ContentZoom-SmallIcon:hover {
	color: #6f7c82;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0
}

.ContentZoom-DetailTitle,
.tp-caption.ContentZoom-DetailTitle {
	color: #292e31;
	font-size: 70px;
	line-height: 70px;
	font-weight: 500;
	font-style: normal;
	font-family: Raleway;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.ContentZoom-DetailSubTitle,
.tp-caption.ContentZoom-DetailSubTitle {
	color: #6f7c82;
	font-size: 25px;
	line-height: 25px;
	font-weight: 500;
	font-style: normal;
	font-family: Raleway;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.ContentZoom-DetailContent,
.tp-caption.ContentZoom-DetailContent {
	color: #6f7c82;
	font-size: 17px;
	line-height: 28px;
	font-weight: 500;
	font-style: normal;
	font-family: Raleway;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.ContentZoom-Button,
.tp-caption.ContentZoom-Button {
	color: #292e31;
	font-size: 13px;
	line-height: 13px;
	font-weight: 700;
	font-style: normal;
	font-family: Raleway;
	padding: 15px 50px 15px 50px;
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(41, 46, 49, .5);
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	text-align: left;
	letter-spacing: 1px
}

.ContentZoom-Button:hover,
.tp-caption.ContentZoom-Button:hover {
	color: #fff;
	text-decoration: none;
	background-color: #292e31;
	border-color: #292e31;
	border-style: solid;
	border-width: 1px;
	border-radius: 0
}

.ContentZoom-ButtonClose,
.tp-caption.ContentZoom-ButtonClose {
	color: #292e31;
	font-size: 13px;
	line-height: 13px;
	font-weight: 700;
	font-style: normal;
	font-family: Raleway;
	padding: 14px 14px 14px 16px;
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(41, 46, 49, .5);
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	text-align: left;
	letter-spacing: 1px
}

.ContentZoom-ButtonClose:hover,
.tp-caption.ContentZoom-ButtonClose:hover {
	color: #fff;
	text-decoration: none;
	background-color: #292e31;
	border-color: #292e31;
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px
}

.Newspaper-Title,
.tp-caption.Newspaper-Title {
	color: #fff;
	font-size: 50px;
	line-height: 55px;
	font-weight: 400;
	font-style: normal;
	font-family: "Roboto Slab";
	padding: 0 0 10px 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Newspaper-Subtitle,
.tp-caption.Newspaper-Subtitle {
	color: #a8d8ee;
	font-size: 15px;
	line-height: 20px;
	font-weight: 900;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Newspaper-Button,
.tp-caption.Newspaper-Button {
	color: #fff;
	font-size: 13px;
	line-height: 17px;
	font-weight: 700;
	font-style: normal;
	font-family: Roboto;
	padding: 12px 35px 12px 35px;
	text-decoration: none;
	background-color: rgba(255, 255, 255, 0);
	border-color: rgba(255, 255, 255, .25);
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	letter-spacing: 2px;
	text-align: left
}

.Newspaper-Button:hover,
.tp-caption.Newspaper-Button:hover {
	color: #000;
	text-decoration: none;
	background-color: #fff;
	border-color: #fff;
	border-style: solid;
	border-width: 1px;
	border-radius: 0
}

.rtwhitemedium,
.tp-caption.rtwhitemedium {
	font-size: 22px;
	line-height: 26px;
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-width: 0;
	border-color: #000;
	border-style: none;
	text-shadow: none
}

@media only screen and (max-width:767px) {

	.revtp-form input[type=email],
	.revtp-form input[type=text],
	.revtp-searchform input[type=email],
	.revtp-searchform input[type=text] {
		width: 200px !important
	}
}

.revtp-form input[type=submit],
.revtp-searchform input[type=submit] {
	font-family: Arial, sans-serif;
	line-height: 46px;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 700;
	padding: 0 20px;
	border: 0;
	background: #009aee;
	color: #fff;
	border-radius: 0
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
	color: #0084b4 !important
}

.tp-caption.Twitter-Content a:hover {
	color: #0084b4 !important;
	text-decoration: underline !important
}

.Concept-Title,
.tp-caption.Concept-Title {
	color: #fff;
	font-size: 70px;
	line-height: 70px;
	font-weight: 700;
	font-style: normal;
	font-family: "Roboto Condensed";
	padding: 0 0 10px 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	letter-spacing: 5px
}

.Concept-SubTitle,
.tp-caption.Concept-SubTitle {
	color: rgba(255, 255, 255, .65);
	font-size: 25px;
	line-height: 25px;
	font-weight: 700;
	font-style: italic;
	font-family: "" Playfair Display"";
	padding: 0 0 10px 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0
}

.Concept-Content,
.tp-caption.Concept-Content {
	color: #fff;
	font-size: 20px;
	line-height: 30px;
	font-weight: 400;
	font-style: normal;
	font-family: "Roboto Condensed";
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff;
	border-style: none;
	border-width: 2px;
	border-radius: 0
}

.Concept-MoreBtn,
.tp-caption.Concept-MoreBtn {
	color: #fff;
	font-size: 30px;
	line-height: 30px;
	font-weight: 300;
	font-style: normal;
	font-family: Roboto;
	padding: 10px 8px 7px 10px;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: rgba(255, 255, 255, 0);
	border-style: solid;
	border-width: 0;
	border-radius: 50px 50px 50px 50px;
	letter-spacing: 1px;
	text-align: left
}

.Concept-MoreBtn:hover,
.tp-caption.Concept-MoreBtn:hover {
	color: #fff;
	text-decoration: none;
	background-color: rgba(255, 255, 255, .15);
	border-color: rgba(255, 255, 255, 0);
	border-style: solid;
	border-width: 0;
	border-radius: 50px 50px 50px 50px
}

.Concept-LessBtn,
.tp-caption.Concept-LessBtn {
	color: #fff;
	font-size: 30px;
	line-height: 30px;
	font-weight: 300;
	font-style: normal;
	font-family: Roboto;
	padding: 10px 8px 7px 10px;
	text-decoration: none;
	text-align: left;
	background-color: #000;
	border-color: rgba(255, 255, 255, 0);
	border-style: solid;
	border-width: 0;
	border-radius: 50px 50px 50px 50px;
	letter-spacing: 1px;
	text-align: left
}

.Concept-LessBtn:hover,
.tp-caption.Concept-LessBtn:hover {
	color: #000;
	text-decoration: none;
	background-color: #fff;
	border-color: rgba(255, 255, 255, 0);
	border-style: solid;
	border-width: 0;
	border-radius: 50px 50px 50px 50px
}

.Concept-SubTitle-Dark,
.tp-caption.Concept-SubTitle-Dark {
	color: rgba(0, 0, 0, .65);
	font-size: 25px;
	line-height: 25px;
	font-weight: 700;
	font-style: italic;
	font-family: "Playfair Display";
	padding: 0 0 10px 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0
}

.Concept-Title-Dark,
.tp-caption.Concept-Title-Dark {
	color: #000;
	font-size: 70px;
	line-height: 70px;
	font-weight: 700;
	font-style: normal;
	font-family: "Roboto Condensed";
	padding: 0 0 10px 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	letter-spacing: 5px
}

.Concept-MoreBtn-Dark,
.tp-caption.Concept-MoreBtn-Dark {
	color: #000;
	font-size: 30px;
	line-height: 30px;
	font-weight: 300;
	font-style: normal;
	font-family: Roboto;
	padding: 10px 8px 7px 10px;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: rgba(255, 255, 255, 0);
	border-style: solid;
	border-width: 0;
	border-radius: 50px 50px 50px 50px;
	letter-spacing: 1px;
	text-align: left
}

.Concept-MoreBtn-Dark:hover,
.tp-caption.Concept-MoreBtn-Dark:hover {
	color: #fff;
	text-decoration: none;
	background-color: #000;
	border-color: rgba(255, 255, 255, 0);
	border-style: solid;
	border-width: 0;
	border-radius: 50px 50px 50px 50px
}

.Concept-Content-Dark,
.tp-caption.Concept-Content-Dark {
	color: #000;
	font-size: 20px;
	line-height: 30px;
	font-weight: 400;
	font-style: normal;
	font-family: "Roboto Condensed";
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff;
	border-style: none;
	border-width: 2px;
	border-radius: 0
}

.Concept-Notice,
.tp-caption.Concept-Notice {
	color: #fff;
	font-size: 15px;
	line-height: 15px;
	font-weight: 400;
	font-style: normal;
	font-family: "Roboto Condensed";
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff;
	border-style: none;
	border-width: 2px;
	border-radius: 0;
	letter-spacing: 2px
}

.tp-caption.Concept-Content a,
.tp-caption.Concept-Content a:visited {
	color: #fff !important;
	border-bottom: 1px solid #fff !important;
	font-weight: 700 !important
}

.tp-caption.Concept-Content a:hover {
	border-bottom: 1px solid transparent !important
}

.tp-caption.Concept-Content-Dark a,
.tp-caption.Concept-Content-Dark a:visited {
	color: #000 !important;
	border-bottom: 1px solid #000 !important;
	font-weight: 700 !important
}

.tp-caption.Concept-Content-Dark a:hover {
	border-bottom: 1px solid transparent !important
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
	color: #0084b4 !important
}

.tp-caption.Twitter-Content a:hover {
	color: #0084b4 !important;
	text-decoration: underline !important
}

.Creative-Title,
.tp-caption.Creative-Title {
	color: #fff;
	font-size: 70px;
	line-height: 70px;
	font-weight: 400;
	font-style: normal;
	font-family: "Playfair Display";
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0
}

.Creative-SubTitle,
.tp-caption.Creative-SubTitle {
	color: #cdb083;
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	letter-spacing: 2px
}

.Creative-Button,
.tp-caption.Creative-Button {
	color: #cdb083;
	font-size: 13px;
	line-height: 13px;
	font-weight: 400;
	font-style: normal;
	font-family: Lato;
	padding: 15px 50px 15px 50px;
	text-decoration: none;
	text-align: left;
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(205, 176, 131, .25);
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	letter-spacing: 2px
}

.Creative-Button:hover,
.tp-caption.Creative-Button:hover {
	color: #cdb083;
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0);
	border-color: #cdb083;
	border-style: solid;
	border-width: 1px;
	border-radius: 0
}

.subcaption,
.tp-caption.subcaption {
	color: #6f7c82;
	font-size: 19px;
	line-height: 24px;
	font-weight: 400;
	font-style: normal;
	font-family: roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: #000;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-shadow: none;
	text-align: left
}

.RedDot,
.tp-caption.RedDot {
	color: #000;
	font-weight: 400;
	font-style: normal;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: #d50000;
	border-color: #fff;
	border-style: solid;
	border-width: 5px;
	border-radius: 50px 50px 50px 50px
}

.RedDot:hover,
.tp-caption.RedDot:hover {
	color: #000;
	text-decoration: none;
	background-color: rgba(255, 255, 255, .75);
	border-color: #d50000;
	border-style: solid;
	border-width: 5px;
	border-radius: 50px 50px 50px 50px
}

.SlidingOverlays-Title,
.tp-caption.SlidingOverlays-Title {
	color: #fff;
	font-size: 50px;
	line-height: 50px;
	font-weight: 400;
	font-style: normal;
	font-family: "Playfair Display";
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0
}

.SlidingOverlays-Title,
.tp-caption.SlidingOverlays-Title {
	color: #fff;
	font-size: 50px;
	line-height: 50px;
	font-weight: 400;
	font-style: normal;
	font-family: "Playfair Display";
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0
}

.Woo-TitleLarge,
.tp-caption.Woo-TitleLarge {
	color: #000;
	font-size: 40px;
	line-height: 40px;
	font-weight: 400;
	font-style: normal;
	font-family: "Playfair Display";
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.Woo-Rating,
.tp-caption.Woo-Rating {
	color: #000;
	font-size: 14px;
	line-height: 30px;
	font-weight: 300;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Woo-SubTitle,
.tp-caption.Woo-SubTitle {
	color: #000;
	font-size: 18px;
	line-height: 18px;
	font-weight: 300;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center;
	letter-spacing: 2px
}

.Woo-PriceLarge,
.tp-caption.Woo-PriceLarge {
	color: #000;
	font-size: 60px;
	line-height: 60px;
	font-weight: 700;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.Woo-ProductInfo,
.tp-caption.Woo-ProductInfo {
	color: #000;
	font-size: 15px;
	line-height: 15px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 12px 75px 12px 50px;
	text-decoration: none;
	background-color: #fecf72;
	border-color: #000;
	border-style: solid;
	border-width: 1px;
	border-radius: 4px 4px 4px 4px;
	text-align: left
}

.Woo-ProductInfo:hover,
.tp-caption.Woo-ProductInfo:hover {
	color: #000;
	text-decoration: none;
	background-color: #f3a847;
	border-color: #000;
	border-style: solid;
	border-width: 1px;
	border-radius: 4px 4px 4px 4px
}

.Woo-AddToCart,
.tp-caption.Woo-AddToCart {
	color: #000;
	font-size: 15px;
	line-height: 15px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 12px 35px 12px 35px;
	text-decoration: none;
	background-color: #fecf72;
	border-color: #000;
	border-style: solid;
	border-width: 1px;
	border-radius: 4px 4px 4px 4px;
	text-align: left
}

.Woo-AddToCart:hover,
.tp-caption.Woo-AddToCart:hover {
	color: #000;
	text-decoration: none;
	background-color: #f3a847;
	border-color: #000;
	border-style: solid;
	border-width: 1px;
	border-radius: 4px 4px 4px 4px
}

.Woo-TitleLarge,
.tp-caption.Woo-TitleLarge {
	color: #000;
	font-size: 40px;
	line-height: 40px;
	font-weight: 400;
	font-style: normal;
	font-family: "Playfair Display";
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.Woo-SubTitle,
.tp-caption.Woo-SubTitle {
	color: #000;
	font-size: 18px;
	line-height: 18px;
	font-weight: 300;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center;
	letter-spacing: 2px
}

.Woo-PriceLarge,
.tp-caption.Woo-PriceLarge {
	color: #000;
	font-size: 60px;
	line-height: 60px;
	font-weight: 700;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.Woo-ProductInfo,
.tp-caption.Woo-ProductInfo {
	color: #000;
	font-size: 15px;
	line-height: 15px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 12px 75px 12px 50px;
	text-decoration: none;
	background-color: #fecf72;
	border-color: #000;
	border-style: solid;
	border-width: 1px;
	border-radius: 4px 4px 4px 4px;
	text-align: left
}

.Woo-ProductInfo:hover,
.tp-caption.Woo-ProductInfo:hover {
	color: #000;
	text-decoration: none;
	background-color: #f3a847;
	border-color: #000;
	border-style: solid;
	border-width: 1px;
	border-radius: 4px 4px 4px 4px
}

.Woo-AddToCart,
.tp-caption.Woo-AddToCart {
	color: #000;
	font-size: 15px;
	line-height: 15px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 12px 35px 12px 35px;
	text-decoration: none;
	background-color: #fecf72;
	border-color: #000;
	border-style: solid;
	border-width: 1px;
	border-radius: 4px 4px 4px 4px;
	text-align: left
}

.Woo-AddToCart:hover,
.tp-caption.Woo-AddToCart:hover {
	color: #000;
	text-decoration: none;
	background-color: #f3a847;
	border-color: #000;
	border-style: solid;
	border-width: 1px;
	border-radius: 4px 4px 4px 4px
}

.FullScreen-Toggle,
.tp-caption.FullScreen-Toggle {
	color: #fff;
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	font-style: normal;
	font-family: Raleway;
	padding: 11px 8px 11px 12px;
	text-decoration: none;
	text-align: left;
	background-color: rgba(0, 0, 0, .5);
	border-color: rgba(255, 255, 255, 0);
	border-style: solid;
	border-width: 0;
	border-radius: 0;
	letter-spacing: 3px;
	text-align: left
}

.FullScreen-Toggle:hover,
.tp-caption.FullScreen-Toggle:hover {
	color: #fff;
	text-decoration: none;
	background-color: #000;
	border-color: rgba(255, 255, 255, 0);
	border-style: solid;
	border-width: 0;
	border-radius: 0
}

.Agency-Title,
.tp-caption.Agency-Title {
	color: #fff;
	font-size: 70px;
	line-height: 70px;
	font-weight: 900;
	font-style: normal;
	font-family: lato;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left;
	letter-spacing: 10px
}

.Agency-SubTitle,
.tp-caption.Agency-SubTitle {
	color: #fff;
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	font-style: italic;
	font-family: Georgia, serif;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.Agency-PlayBtn,
.tp-caption.Agency-PlayBtn {
	color: #fff;
	font-size: 30px;
	line-height: 71px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: #fff;
	border-style: solid;
	border-width: 2px;
	border-radius: 100px 100px 100px 100px;
	text-align: center
}

.Agency-PlayBtn:hover,
.tp-caption.Agency-PlayBtn:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: #fff;
	border-style: solid;
	border-width: 2px;
	border-radius: 100px 100px 100px 100px;
	cursor: pointer
}

.Agency-SmallText,
.tp-caption.Agency-SmallText {
	color: #fff;
	font-size: 12px;
	line-height: 12px;
	font-weight: 900;
	font-style: normal;
	font-family: lato;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left;
	letter-spacing: 5px
}

.Agency-Social,
.tp-caption.Agency-Social {
	color: #333;
	font-size: 25px;
	line-height: 50px;
	font-weight: 400;
	font-style: normal;
	font-family: Georgia, serif;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: #333;
	border-style: solid;
	border-width: 2px;
	border-radius: 30px 30px 30px 30px;
	text-align: center
}

.Agency-Social:hover,
.tp-caption.Agency-Social:hover {
	color: #fff;
	text-decoration: none;
	background-color: #333;
	border-color: #333;
	border-style: solid;
	border-width: 2px;
	border-radius: 30px 30px 30px 30px;
	cursor: pointer
}

.Agency-CloseBtn,
.tp-caption.Agency-CloseBtn {
	color: #fff;
	font-size: 50px;
	line-height: 50px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: rgba(255, 255, 255, 0);
	border-style: none;
	border-width: 0;
	border-radius: 100px 100px 100px 100px;
	text-align: center
}

.Agency-CloseBtn:hover,
.tp-caption.Agency-CloseBtn:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: rgba(255, 255, 255, 0);
	border-style: none;
	border-width: 0;
	border-radius: 100px 100px 100px 100px;
	cursor: pointer
}

.Dining-Title,
.tp-caption.Dining-Title {
	color: #fff;
	font-size: 70px;
	line-height: 70px;
	font-weight: 400;
	font-style: normal;
	font-family: Georgia, serif;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left;
	letter-spacing: 10px
}

.Dining-SubTitle,
.tp-caption.Dining-SubTitle {
	color: #fff;
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	font-style: normal;
	font-family: Georgia, serif;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Dining-BtnLight,
.tp-caption.Dining-BtnLight {
	color: rgba(255, 255, 255, .5);
	font-size: 15px;
	line-height: 15px;
	font-weight: 700;
	font-style: normal;
	font-family: Lato;
	padding: 17px 73px 17px 50px;
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, .25);
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	text-align: left;
	letter-spacing: 2px
}

.Dining-BtnLight:hover,
.tp-caption.Dining-BtnLight:hover {
	color: #fff;
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff;
	border-style: solid;
	border-width: 1px;
	border-radius: 0
}

.Dining-Social,
.tp-caption.Dining-Social {
	color: #fff;
	font-size: 25px;
	line-height: 50px;
	font-weight: 400;
	font-style: normal;
	font-family: Georgia, serif;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: rgba(255, 255, 255, .25);
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	text-align: center
}

.Dining-Social:hover,
.tp-caption.Dining-Social:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: #fff;
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	cursor: pointer
}

.Team-Thumb,
tp-caption.Team-Thumb {
	color: #fff;
	font-size: 20px;
	line-height: 22px;
	font-weight: 400;
	font-style: normal;
	font-family: Arial;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Team-Thumb:hover,
.tp-caption.Team-Thumb:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.Team-Name,
.tp-caption.Team-Name {
	color: #fff;
	font-size: 70px;
	line-height: 70px;
	font-weight: 900;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Team-Position,
.tp-caption.Team-Position {
	color: #fff;
	font-size: 30px;
	line-height: 30px;
	font-weight: 400;
	font-style: normal;
	font-family: Georgia, serif;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Team-Description,
.tp-caption.Team-Description {
	color: #fff;
	font-size: 18px;
	line-height: 28px;
	font-weight: 400;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Team-Social,
.tp-caption.Team-Social {
	color: #fff;
	font-size: 50px;
	line-height: 50px;
	font-weight: 400;
	font-style: normal;
	font-family: Arial;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.Team-Social:hover,
.tp-caption.Team-Social:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.VideoControls-Play,
.tp-caption.VideoControls-Play {
	color: #000;
	font-size: 50px;
	line-height: 120px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 0 0 0 7px;
	text-decoration: none;
	background-color: #fff;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 100px 100px 100px 100px;
	text-align: center
}

.VideoControls-Play:hover,
.tp-caption.VideoControls-Play:hover {
	color: #000;
	text-decoration: none;
	background-color: #fff;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 100px 100px 100px 100px;
	cursor: pointer
}

.VideoPlayer-Title,
.tp-caption.VideoPlayer-Title {
	color: #fff;
	font-size: 40px;
	line-height: 40px;
	font-weight: 900;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left;
	letter-spacing: 10px
}

.VideoPlayer-SubTitle,
.tp-caption.VideoPlayer-SubTitle {
	color: #fff;
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	font-style: italic;
	font-family: Georgia, serif;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.VideoPlayer-Social,
.tp-caption.VideoPlayer-Social {
	color: #fff;
	font-size: 50px;
	line-height: 50px;
	font-weight: 400;
	font-style: normal;
	font-family: Arial;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.VideoPlayer-Social:hover,
.tp-caption.VideoPlayer-Social:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.VideoControls-Mute,
.tp-caption.VideoControls-Mute {
	color: #000;
	font-size: 20px;
	line-height: 50px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: #fff;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 100px 100px 100px 100px;
	text-align: center
}

.VideoControls-Mute:hover,
.tp-caption.VideoControls-Mute:hover {
	color: #000;
	text-decoration: none;
	background-color: #fff;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 100px 100px 100px 100px;
	cursor: pointer
}

.VideoControls-Pause,
.tp-caption.VideoControls-Pause {
	color: #000;
	font-size: 20px;
	line-height: 50px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: #fff;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 100px 100px 100px 100px;
	text-align: center
}

.VideoControls-Pause:hover,
.tp-caption.VideoControls-Pause:hover {
	color: #000;
	text-decoration: none;
	background-color: #fff;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 100px 100px 100px 100px;
	cursor: pointer
}

.soundcloudwrapper iframe {
	width: 100% !important
}

.SleekLanding-Title,
.tp-caption.SleekLanding-Title {
	color: #fff;
	font-size: 35px;
	line-height: 40px;
	font-weight: 400;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left;
	letter-spacing: 5px
}

.SleekLanding-ButtonBG,
.tp-caption.SleekLanding-ButtonBG {
	color: #000;
	font-weight: 700;
	font-style: normal;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: rgba(255, 255, 255, .1);
	border-color: transparent;
	border-style: solid;
	border-width: 0;
	border-radius: 5px 5px 5px 5px;
	text-align: left;
	box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, .15)
}

.SleekLanding-SmallTitle,
.tp-caption.SleekLanding-SmallTitle {
	color: #fff;
	font-size: 13px;
	line-height: 50px;
	font-weight: 900;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left;
	letter-spacing: 2px
}

.SleekLanding-BottomText,
.tp-caption.SleekLanding-BottomText {
	color: #fff;
	font-size: 15px;
	line-height: 24px;
	font-weight: 400;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.SleekLanding-Social,
.tp-caption.SleekLanding-Social {
	color: #fff;
	font-size: 22px;
	line-height: 30px;
	font-weight: 400;
	font-style: normal;
	font-family: Arial;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.SleekLanding-Social:hover,
.tp-caption.SleekLanding-Social:hover {
	color: rgba(0, 0, 0, .25);
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

#rev_slider_429_1_wrapper .tp-loader.spinner2 {
	background-color: #555 !important
}

.tp-fat {
	font-weight: 900 !important
}

.PostSlider-Category,
.tp-caption.PostSlider-Category {
	color: #000;
	font-size: 15px;
	line-height: 15px;
	font-weight: 300;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	letter-spacing: 3px;
	text-align: left
}

.PostSlider-Title,
.tp-caption.PostSlider-Title {
	color: #000;
	font-size: 40px;
	line-height: 40px;
	font-weight: 400;
	font-style: normal;
	font-family: "Playfair Display";
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.PostSlider-Content,
.tp-caption.PostSlider-Content {
	color: #777;
	font-size: 15px;
	line-height: 23px;
	font-weight: 400;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.PostSlider-Button,
.tp-caption.PostSlider-Button {
	color: #000;
	font-size: 15px;
	line-height: 40px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 1px 56px 1px 32px;
	text-decoration: none;
	background-color: #fff;
	border-color: #000;
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	text-align: left
}

.PostSlider-Button:hover,
.tp-caption.PostSlider-Button:hover {
	color: #000;
	text-decoration: none;
	background-color: #eee;
	border-color: #000;
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	cursor: pointer
}

.LandingPage-Title,
.tp-caption.LandingPage-Title {
	color: #fff;
	font-size: 70px;
	line-height: 80px;
	font-weight: 900;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left;
	letter-spacing: 10px
}

.LandingPage-SubTitle,
.tp-caption.LandingPage-SubTitle {
	color: #fff;
	font-size: 20px;
	line-height: 30px;
	font-weight: 400;
	font-style: italic;
	font-family: Georgia, serif;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.LandingPage-Button,
.tp-caption.LandingPage-Button {
	color: #000;
	font-size: 15px;
	line-height: 54px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 0 35px 0 35px;
	text-decoration: none;
	background-color: #fff;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 0;
	text-align: left;
	letter-spacing: 3px
}

.LandingPage-Button:hover,
.tp-caption.LandingPage-Button:hover {
	color: #000;
	text-decoration: none;
	background-color: #fff;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.tp-caption.App-Content a,
.tp-caption.App-Content a:visited {
	color: #89124e !important;
	border-bottom: 1px solid transparent !important;
	font-weight: 700 !important
}

.tp-caption.App-Content a:hover {
	border-bottom: 1px solid #89124e !important
}

.RockBand-LogoText,
.tp-caption.RockBand-LogoText {
	color: #fff;
	font-size: 60px;
	line-height: 60px;
	font-weight: 700;
	font-style: normal;
	font-family: Oswald;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
	color: #fff !important;
	text-decoration: underline !important
}

.tp-caption.Twitter-Content a:hover {
	color: #fff !important;
	text-decoration: none !important
}

.soundcloudwrapper iframe {
	width: 100% !important
}

.Agency-LogoText,
.tp-caption.Agency-LogoText {
	color: #fff;
	font-size: 12px;
	line-height: 20px;
	font-weight: 400;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center;
	letter-spacing: 1px
}

.ComingSoon-Highlight,
.tp-caption.ComingSoon-Highlight {
	color: #fff;
	font-size: 20px;
	line-height: 37px;
	font-weight: 400;
	font-style: normal;
	font-family: Lato;
	padding: 0 20px 3px 20px;
	text-decoration: none;
	text-align: left;
	background-color: #009aee;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.ComingSoon-Count,
.tp-caption.ComingSoon-Count {
	color: #fff;
	font-size: 50px;
	line-height: 50px;
	font-weight: 900;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.ComingSoon-CountUnit,
.tp-caption.ComingSoon-CountUnit {
	color: #fff;
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.ComingSoon-NotifyMe,
.tp-caption.ComingSoon-NotifyMe {
	color: #a49d8f;
	font-size: 27px;
	line-height: 35px;
	font-weight: 600;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

#mc_embed_signup input#mce-EMAIL {
	font-family: Lato, sans-serif;
	font-size: 15px;
	color: #000;
	background-color: #fff;
	line-height: 46px;
	padding: 0 20px;
	cursor: text;
	border: 1px solid #fff;
	width: 400px;
	margin-bottom: 0;
	-webkit-transition: background-color .5s;
	-moz-transition: background-color .5s;
	-o-transition: background-color .5s;
	-ms-transition: background-color .5s;
	transition: background-color .5s;
	border-radius: 0
}

#mc_embed_signup input#mce-EMAIL[type=email]:focus {
	background-color: #fff;
	border: 1px solid #666;
	border-right: 0
}

#mc_embed_signup input#mc-embedded-subscribe,
#mc_embed_signup input#mc-embedded-subscribe:focus {
	font-family: Lato, sans-serif;
	line-height: 46px;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 900;
	padding: 0 20px;
	border: 1px solid #009aee;
	background: #009aee;
	color: #fff;
	border-radius: 0
}

#mc_embed_signup input#mc-embedded-subscribe:hover {
	background: #0083d4
}

@media only screen and (max-width:767px) {
	#mc_embed_signup input#mce-EMAIL {
		width: 200px
	}
}

.Agency-SmallTitle,
.tp-caption.Agency-SmallTitle {
	color: #fff;
	font-size: 15px;
	line-height: 22px;
	font-weight: 400;
	font-style: normal;
	font-family: lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center;
	letter-spacing: 6px
}

.Agency-SmallContent,
.tp-caption.Agency-SmallContent {
	color: #fff;
	font-size: 15px;
	line-height: 24px;
	font-weight: 400;
	font-style: normal;
	font-family: lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.Agency-SmallLink,
.tp-caption.Agency-SmallLink {
	color: #f87c09;
	font-size: 12px;
	line-height: 22px;
	font-weight: 700;
	font-style: normal;
	font-family: lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center;
	letter-spacing: 2px;
	border-bottom: 1px solid #f87c09 !important
}

.Agency-SmallLink:hover,
.tp-caption.Agency-SmallLink:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.Agency-NavButton,
.tp-caption.Agency-NavButton {
	color: #333;
	font-size: 17px;
	line-height: 50px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: #fff;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.Agency-NavButton:hover,
.tp-caption.Agency-NavButton:hover {
	color: #fff;
	text-decoration: none;
	background-color: #333;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.Agency-SmallLinkGreen,
.tp-caption.Agency-SmallLinkGreen {
	color: #6db19b;
	font-size: 12px;
	line-height: 22px;
	font-weight: 700;
	font-style: normal;
	font-family: lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center;
	letter-spacing: 2px;
	border-bottom: 1px solid #6db19b !important
}

.Agency-SmallLinkGreen:hover,
.tp-caption.Agency-SmallLinkGreen:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.Agency-SmallLinkBlue,
.tp-caption.Agency-SmallLinkBlue {
	color: #999;
	font-size: 12px;
	line-height: 22px;
	font-weight: 700;
	font-style: normal;
	font-family: lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center;
	letter-spacing: 2px;
	border-bottom: 1px solid #999 !important
}

.Agency-SmallLinkBlue:hover,
.tp-caption.Agency-SmallLinkBlue:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.Agency-LogoText,
.tp-caption.Agency-LogoText {
	color: #fff;
	font-size: 12px;
	line-height: 20px;
	font-weight: 400;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center;
	letter-spacing: 1px
}

.Agency-ArrowTooltip,
.tp-caption.Agency-ArrowTooltip {
	color: #333;
	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
	font-style: normal;
	font-family: "Permanent Marker";
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.Agency-SmallSocial,
.tp-caption.Agency-SmallSocial {
	color: #fff;
	font-size: 30px;
	line-height: 30px;
	font-weight: 400;
	font-style: normal;
	font-family: Arial;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.Agency-SmallSocial:hover,
.tp-caption.Agency-SmallSocial:hover {
	color: #333;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
	color: #0084b4 !important
}

.tp-caption.Twitter-Content a:hover {
	color: #0084b4 !important;
	text-decoration: underline !important
}

.CreativeFrontPage-Btn,
.tp-caption.CreativeFrontPage-Btn {
	color: #fff;
	font-size: 14px;
	line-height: 60px;
	font-weight: 900;
	font-style: normal;
	font-family: Roboto;
	padding: 0 50px 0 50px;
	text-decoration: none;
	text-align: left;
	background-color: #00685c;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 4px 4px 4px 4px;
	letter-spacing: 2px
}

.CreativeFrontPage-Btn:hover,
.tp-caption.CreativeFrontPage-Btn:hover {
	color: #fff;
	text-decoration: none;
	background-color: rgba(0, 0, 0, .25);
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 4px 4px 4px 4px;
	cursor: pointer
}

.CreativeFrontPage-Menu,
.tp-caption.CreativeFrontPage-Menu {
	color: #fff;
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	font-style: normal;
	font-family: roboto;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	letter-spacing: 2px
}

.tp-flip-index {
	z-index: 1000 !important
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
	color: #0084b4 !important
}

.tp-caption.Twitter-Content a:hover {
	color: #0084b4 !important;
	text-decoration: underline !important
}

.FullScreenMenu-Category,
.tp-caption.FullScreenMenu-Category {
	color: #111;
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	font-style: normal;
	font-family: BenchNine;
	padding: 21px 30px 16px 30px;
	text-decoration: none;
	text-align: left;
	background-color: rgba(255, 255, 255, .9);
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	letter-spacing: 3px
}

.FullScreenMenu-Title,
.tp-caption.FullScreenMenu-Title {
	color: #fff;
	font-size: 65px;
	line-height: 70px;
	font-weight: 700;
	font-style: normal;
	font-family: BenchNine;
	padding: 21px 30px 16px 30px;
	text-decoration: none;
	text-align: left;
	background-color: rgba(17, 17, 17, .9);
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
	color: #0084b4 !important
}

.tp-caption.Twitter-Content a:hover {
	color: #0084b4 !important;
	text-decoration: underline !important
}

.TechJournal-Button,
.tp-caption.TechJournal-Button {
	color: #fff;
	font-size: 13px;
	line-height: 40px;
	font-weight: 900;
	font-style: normal;
	font-family: Raleway;
	padding: 1px 30px 1px 30px;
	text-decoration: none;
	text-align: left;
	background-color: #8a00ff;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 0;
	letter-spacing: 3px
}

.TechJournal-Button:hover,
.tp-caption.TechJournal-Button:hover {
	color: #000;
	text-decoration: none;
	background-color: #fff;
	border-color: #000;
	border-style: solid;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.TechJournal-Big,
.tp-caption.TechJournal-Big {
	color: #fff;
	font-size: 120px;
	line-height: 120px;
	font-weight: 900;
	font-style: normal;
	font-family: Raleway;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	letter-spacing: 0
}

.rev_slider {
	overflow: hidden
}

.effect_layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
	color: #0084b4 !important
}

.tp-caption.Twitter-Content a:hover {
	color: #0084b4 !important;
	text-decoration: underline !important
}

#menu_forcefullwidth {
	z-index: 5000;
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100%
}

.FullSiteBlock-Title,
.tp-caption.FullSiteBlock-Title {
	color: #333;
	font-size: 55px;
	line-height: 65px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
	color: #333;
	font-size: 25px;
	line-height: 34px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-Link,
.tp-caption.FullSiteBlock-Link {
	color: #0096ff;
	font-size: 25px;
	line-height: 24px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-Link:hover,
.tp-caption.FullSiteBlock-Link:hover {
	color: #333;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.FullSiteBlock-DownButton,
.tp-caption.FullSiteBlock-DownButton {
	color: #333;
	font-size: 25px;
	line-height: 32px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 1px 1px 1px 1px;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: #333;
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	text-align: center
}

.FullSiteBlock-DownButton:hover,
.tp-caption.FullSiteBlock-DownButton:hover {
	color: #0096ff;
	text-decoration: none;
	background-color: transparent;
	border-color: #0096ff;
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	cursor: pointer
}

.FullSiteBlock-Title,
.tp-caption.FullSiteBlock-Title {
	color: #333;
	font-size: 55px;
	line-height: 65px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
	color: #333;
	font-size: 25px;
	line-height: 34px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-Link,
.tp-caption.FullSiteBlock-Link {
	color: #0096ff;
	font-size: 25px;
	line-height: 24px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-Link:hover,
.tp-caption.FullSiteBlock-Link:hover {
	color: #333;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.FullSiteBlock-DownButton,
.tp-caption.FullSiteBlock-DownButton {
	color: #333;
	font-size: 25px;
	line-height: 32px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 1px 1px 1px 1px;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: #333;
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	text-align: center
}

.FullSiteBlock-DownButton:hover,
.tp-caption.FullSiteBlock-DownButton:hover {
	color: #0096ff;
	text-decoration: none;
	background-color: transparent;
	border-color: #0096ff;
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	cursor: pointer
}

.FullSiteBlock-Title,
.tp-caption.FullSiteBlock-Title {
	color: #333;
	font-size: 55px;
	line-height: 65px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
	color: #333;
	font-size: 25px;
	line-height: 34px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-DownButton,
.tp-caption.FullSiteBlock-DownButton {
	color: #333;
	font-size: 25px;
	line-height: 32px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 1px 1px 1px 1px;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: #333;
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	text-align: center
}

.FullSiteBlock-DownButton:hover,
.tp-caption.FullSiteBlock-DownButton:hover {
	color: #0096ff;
	text-decoration: none;
	background-color: transparent;
	border-color: #0096ff;
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	cursor: pointer
}

.FullSiteBlock-Title,
.tp-caption.FullSiteBlock-Title {
	color: #333;
	font-size: 55px;
	line-height: 65px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
	color: #333;
	font-size: 25px;
	line-height: 34px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-Link,
.tp-caption.FullSiteBlock-Link {
	color: #0096ff;
	font-size: 25px;
	line-height: 24px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-Link:hover,
.tp-caption.FullSiteBlock-Link:hover {
	color: #333;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.FullSiteBlock-DownButton,
.tp-caption.FullSiteBlock-DownButton {
	color: #333;
	font-size: 25px;
	line-height: 32px;
	font-weight: 500;
	font-style: normal;
	font-family: Roboto;
	padding: 1px 1px 1px 1px;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: #333;
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	text-align: center
}

.FullSiteBlock-DownButton:hover,
.tp-caption.FullSiteBlock-DownButton:hover {
	color: #0096ff;
	text-decoration: none;
	background-color: transparent;
	border-color: #0096ff;
	border-style: solid;
	border-width: 1px;
	border-radius: 30px 30px 30px 30px;
	cursor: pointer
}

.rev_slider {
	overflow: hidden
}

.effect_layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.gyges .tp-thumb {
	opacity: 1
}

.gyges .tp-thumb-img-wrap {
	padding: 3px;
	background-color: rgba(0, 0, 0, .25);
	display: inline-block;
	width: 100%;
	height: 100%;
	position: relative;
	margin: 0;
	box-sizing: border-box;
	transition: all .3s;
	-webkit-transition: all .3s
}

.gyges .tp-thumb-image {
	padding: 3px;
	display: block;
	box-sizing: border-box;
	position: relative;
	-webkit-box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	-moz-box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25)
}

.gyges .tp-thumb.selected .tp-thumb-img-wrap,
.gyges .tp-thumb:hover .tp-thumb-img-wrap {
	background: -moz-linear-gradient(top, #fff 0, #fff 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0, #fff), color-stop(100%, #fff));
	background: -webkit-linear-gradient(top, #fff 0, #fff 100%);
	background: -o-linear-gradient(top, #fff 0, #fff 100%);
	background: -ms-linear-gradient(top, #fff 0, #fff 100%);
	background: linear-gradient(to bottom, #fff 0, #fff 100%)
}

.FullSiteBlock-Title,
.tp-caption.FullSiteBlock-Title {
	color: #333;
	font-size: 55px;
	line-height: 65px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
	color: #333;
	font-size: 25px;
	line-height: 34px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-Link,
.tp-caption.FullSiteBlock-Link {
	color: #0096ff;
	font-size: 25px;
	line-height: 24px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-Link:hover,
.tp-caption.FullSiteBlock-Link:hover {
	color: #333;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
	color: #333;
	font-size: 25px;
	line-height: 34px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
	color: #333;
	font-size: 25px;
	line-height: 34px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.FullSiteBlock-FooterLink,
.tp-caption.FullSiteBlock-FooterLink {
	color: #555;
	font-size: 15px;
	line-height: 20px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: left
}

.FullSiteBlock-FooterLink:hover,
.tp-caption.FullSiteBlock-FooterLink:hover {
	color: #0096ff;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.fb-share-button.fb_iframe_widget iframe {
	width: 115px !important
}

#tp-socialwrapper {
	opacity: 0
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
	color: #0084b4 !important
}

.tp-caption.Twitter-Content a:hover {
	color: #0084b4 !important;
	text-decoration: underline !important
}

#menu_forcefullwidth {
	z-index: 5000;
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100%
}

#tp-menubg {
	background: -moz-linear-gradient(top, rgba(0, 0, 0, .75) 0, rgba(0, 0, 0, 0) 100%);
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, .75) 0, rgba(0, 0, 0, 0) 100%);
	background: linear-gradient(to bottom, rgba(0, 0, 0, .75) 0, rgba(0, 0, 0, 0) 100%)
}

#mc_embed_signup input[type=email] {
	font-family: Lato, sans-serif;
	font-size: 16px;
	font-weight: 400;
	background-color: #fff;
	color: #888 !important;
	line-height: 46px;
	padding: 0 20px;
	cursor: text;
	border: 0;
	width: 400px;
	margin-bottom: 0;
	-webkit-transition: background-color .5s;
	-moz-transition: background-color .5s;
	-o-transition: background-color .5s;
	-ms-transition: background-color .5s;
	transition: background-color .5s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px
}

#mc_embed_signup input[type=email]::-webkit-input-placeholder {
	color: #888 !important
}

#mc_embed_signup input[type=email]::-moz-placeholder {
	color: #888 !important
}

#mc_embed_signup input[type=email]:-ms-input-placeholder {
	color: #888 !important
}

#mc_embed_signup input[type=email]:focus {
	background-color: #f5f5f5;
	color: #454545
}

#mc_embed_signup input#mc-embedded-subscribe,
#mc_embed_signup input#mc-embedded-subscribe:focus {
	font-family: Lato, sans-serif;
	line-height: 46px;
	font-size: 16px;
	font-weight: 700;
	padding: 0 30px;
	border: 0;
	background: #f04531;
	text-transform: none;
	color: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px
}

#mc_embed_signup input#mc-embedded-subscribe:hover {
	background: #e03727
}

@media only screen and (max-width:767px) {
	#mc_embed_signup input[type=email] {
		width: 260px
	}
}

@media only screen and (max-width:480px) {
	#mc_embed_signup input[type=email] {
		width: 160px
	}
}

#rev_slider_167_6 .uranus.tparrows {
	width: 50px;
	height: 50px;
	background: rgba(255, 255, 255, 0)
}

#rev_slider_167_6 .uranus.tparrows:before {
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 40px;
	transition: all .3s;
	-webkit-transition: all .3s
}

#rev_slider_167_6 .uranus.tparrows:hover:before {
	opacity: .75
}

.FullSiteBlock-SubTitle,
.tp-caption.FullSiteBlock-SubTitle {
	color: #333;
	font-size: 25px;
	line-height: 34px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

.ParallaxWebsite-FooterItem,
.tp-caption.ParallaxWebsite-FooterItem {
	color: rgba(255, 255, 255, .5);
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0
}

.ParallaxWebsite-FooterItem:hover,
.tp-caption.ParallaxWebsite-FooterItem:hover {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	cursor: pointer
}

.fb-share-button.fb_iframe_widget iframe {
	width: 115px !important
}

iframe.twitter-share-button {
	display: none
}

.fb-share-button.fb_iframe_widget iframe {
	display: none
}

.FullSiteBlock-Link,
.tp-caption.FullSiteBlock-Link {
	color: #0096ff;
	font-size: 25px;
	line-height: 24px;
	font-weight: 300;
	font-style: normal;
	font-family: Lato;
	padding: 0;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	border-width: 0;
	border-radius: 0;
	text-align: center
}

#debungcontrolls,
.debugtimeline {
	width: 100%;
	box-sizing: border-box
}

.rev_column,
.rev_column .tp-parallax-wrap,
.tp-svg-layer svg {
	vertical-align: top
}

#debungcontrolls {
	z-index: 100000;
	position: fixed;
	bottom: 0;
	height: auto;
	background: rgba(0, 0, 0, .6);
	padding: 10px
}

.debugtimeline {
	height: 10px;
	position: relative;
	margin-bottom: 3px;
	display: none;
	white-space: nowrap
}

.debugtimeline:hover {
	height: 15px
}

.the_timeline_tester {
	background: #e74c3c;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0
}

.rs-go-fullscreen {
	position: fixed !important;
	width: 100% !important;
	height: 100% !important;
	top: 0 !important;
	left: 0 !important;
	z-index: 9999999 !important;
	background: #fff !important
}

.debugtimeline.tl_slide .the_timeline_tester {
	background: #f39c12
}

.debugtimeline.tl_frame .the_timeline_tester {
	background: #3498db
}

.debugtimline_txt {
	color: #fff;
	font-weight: 400;
	font-size: 7px;
	position: absolute;
	left: 10px;
	top: 0;
	white-space: nowrap;
	line-height: 10px
}

.rtl {
	direction: rtl
}

@font-face {
	font-family: revicons;
	src: url(../fonts/revicons/revicons.eot?5510888);
	src: url(../fonts/revicons/revicons.eot?5510888#iefix) format('embedded-opentype'), url(../fonts/revicons/revicons.woff?5510888) format('woff'), url(../fonts/revicons/revicons.ttf?5510888) format('truetype'), url(../fonts/revicons/revicons.svg?5510888#revicons) format('svg');
	font-weight: 400;
	font-style: normal
}

[class*=" revicon-"]:before,
[class^=revicon-]:before {
	font-family: revicons;
	font-style: normal;
	font-weight: 400;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: .2em
}

.revicon-search-1:before {
	content: '\e802'
}

.revicon-pencil-1:before {
	content: '\e831'
}

.revicon-picture-1:before {
	content: '\e803'
}

.revicon-cancel:before {
	content: '\e80a'
}

.revicon-info-circled:before {
	content: '\e80f'
}

.revicon-trash:before {
	content: '\e801'
}

.revicon-left-dir:before {
	content: '\e817'
}

.revicon-right-dir:before {
	content: '\e818'
}

.revicon-down-open:before {
	content: '\e83b'
}

.revicon-left-open:before {
	content: '\e819'
}

.revicon-right-open:before {
	content: '\e81a'
}

.revicon-angle-left:before {
	content: '\e820'
}

.revicon-angle-right:before {
	content: '\e81d'
}

.revicon-left-big:before {
	content: '\e81f'
}

.revicon-right-big:before {
	content: '\e81e'
}

.revicon-magic:before {
	content: '\e807'
}

.revicon-picture:before {
	content: '\e800'
}

.revicon-export:before {
	content: '\e80b'
}

.revicon-cog:before {
	content: '\e832'
}

.revicon-login:before {
	content: '\e833'
}

.revicon-logout:before {
	content: '\e834'
}

.revicon-video:before {
	content: '\e805'
}

.revicon-arrow-combo:before {
	content: '\e827'
}

.revicon-left-open-1:before {
	content: '\e82a'
}

.revicon-right-open-1:before {
	content: '\e82b'
}

.revicon-left-open-mini:before {
	content: '\e822'
}

.revicon-right-open-mini:before {
	content: '\e823'
}

.revicon-left-open-big:before {
	content: '\e824'
}

.revicon-right-open-big:before {
	content: '\e825'
}

.revicon-left:before {
	content: '\e836'
}

.revicon-right:before {
	content: '\e826'
}

.revicon-ccw:before {
	content: '\e808'
}

.revicon-arrows-ccw:before {
	content: '\e806'
}

.revicon-palette:before {
	content: '\e829'
}

.revicon-list-add:before {
	content: '\e80c'
}

.revicon-doc:before {
	content: '\e809'
}

.revicon-left-open-outline:before {
	content: '\e82e'
}

.revicon-left-open-2:before {
	content: '\e82c'
}

.revicon-right-open-outline:before {
	content: '\e82f'
}

.revicon-right-open-2:before {
	content: '\e82d'
}

.revicon-equalizer:before {
	content: '\e83a'
}

.revicon-layers-alt:before {
	content: '\e804'
}

.revicon-popup:before {
	content: '\e828'
}

.rev_slider_wrapper {
	position: relative;
	z-index: 0;
	width: 100%
}

.rev_slider {
	position: relative;
	overflow: visible
}

.entry-content .rev_slider a,
.rev_slider a {
	box-shadow: none
}

.tp-overflow-hidden {
	overflow: hidden !important
}

.group_ov_hidden {
	overflow: hidden
}

.rev_slider img,
.tp-simpleresponsive img {
	max-width: none !important;
	transition: none;
	margin: 0;
	padding: 0;
	border: none
}

.rev_slider .no-slides-text {
	font-weight: 700;
	text-align: center;
	padding-top: 80px
}

.rev_slider>ul,
.rev_slider>ul>li,
.rev_slider>ul>li:before,
.rev_slider_wrapper>ul,
.tp-revslider-mainul>li,
.tp-revslider-mainul>li:before,
.tp-simpleresponsive>ul,
.tp-simpleresponsive>ul>li,
.tp-simpleresponsive>ul>li:before {
	list-style: none !important;
	position: absolute;
	margin: 0 !important;
	padding: 0 !important;
	overflow-x: visible;
	overflow-y: visible;
	background-image: none;
	background-position: 0 0;
	text-indent: 0;
	top: 0;
	left: 0
}

.rev_slider>ul>li,
.rev_slider>ul>li:before,
.tp-revslider-mainul>li,
.tp-revslider-mainul>li:before,
.tp-simpleresponsive>ul>li,
.tp-simpleresponsive>ul>li:before {
	visibility: hidden
}

.tp-revslider-mainul,
.tp-revslider-slidesli {
	padding: 0 !important;
	margin: 0 !important;
	list-style: none !important
}

.fullscreen-container,
.fullwidthbanner-container {
	padding: 0;
	position: relative
}

.rev_slider li.tp-revslider-slidesli {
	position: absolute !important
}

.tp-caption .rs-untoggled-content {
	display: block
}

.tp-caption .rs-toggled-content {
	display: none
}

.rs-toggle-content-active.tp-caption .rs-toggled-content {
	display: block
}

.rs-toggle-content-active.tp-caption .rs-untoggled-content {
	display: none
}

.rev_slider .caption,
.rev_slider .tp-caption {
	position: relative;
	visibility: hidden;
	white-space: nowrap;
	display: block;
	-webkit-font-smoothing: antialiased !important;
	z-index: 1
}

.rev_slider .caption,
.rev_slider .tp-caption,
.tp-simpleresponsive img {
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none
}

.rev_slider .tp-mask-wrap .tp-caption,
.rev_slider .tp-mask-wrap :last-child,
.wpb_text_column .rev_slider .tp-mask-wrap .tp-caption,
.wpb_text_column .rev_slider .tp-mask-wrap :last-child {
	margin-bottom: 0
}

.tp-svg-layer svg {
	width: 100%;
	height: 100%;
	position: relative
}

.tp-carousel-wrapper {
	cursor: url(openhand.cur), move
}

.tp-carousel-wrapper.dragged {
	cursor: url(closedhand.cur), move
}

.tp_inner_padding {
	box-sizing: border-box;
	max-height: none !important
}

.tp-caption.tp-layer-selectable {
	-moz-user-select: all;
	-khtml-user-select: all;
	-webkit-user-select: all;
	-o-user-select: all
}

.tp-caption.tp-hidden-caption,
.tp-forcenotvisible,
.tp-hide-revslider,
.tp-parallax-wrap.tp-hidden-caption {
	visibility: hidden !important;
	display: none !important
}

.rev_slider audio,
.rev_slider embed,
.rev_slider iframe,
.rev_slider object,
.rev_slider video {
	max-width: none !important
}

.tp-element-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0
}

.tp-blockmask,
.tp-blockmask_in,
.tp-blockmask_out {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	z-index: 1000;
	transform: scaleX(0) scaleY(0)
}

.tp-parallax-wrap {
	transform-style: preserve-3d
}

.rev_row_zone {
	position: absolute;
	width: 100%;
	left: 0;
	box-sizing: border-box;
	min-height: 50px;
	font-size: 0
}

.rev_column_inner,
.rev_slider .tp-caption.rev_row {
	position: relative;
	width: 100% !important;
	box-sizing: border-box
}

.rev_row_zone_top {
	top: 0
}

.rev_row_zone_middle {
	top: 50%;
	transform: translateY(-50%)
}

.rev_row_zone_bottom {
	bottom: 0
}

.rev_slider .tp-caption.rev_row {
	display: table;
	table-layout: fixed;
	vertical-align: top;
	height: auto !important;
	font-size: 0
}

.rev_column {
	display: table-cell;
	position: relative;
	height: auto;
	box-sizing: border-box;
	font-size: 0
}

.rev_column_inner {
	display: block;
	height: auto !important;
	white-space: normal !important
}

.rev_column_bg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	box-sizing: border-box;
	background-clip: content-box;
	border: 0 solid transparent
}

.tp-caption .backcorner,
.tp-caption .backcornertop,
.tp-caption .frontcorner,
.tp-caption .frontcornertop {
	height: 0;
	top: 0;
	width: 0;
	position: absolute
}

.rev_column_inner .tp-loop-wrap,
.rev_column_inner .tp-mask-wrap,
.rev_column_inner .tp-parallax-wrap {
	text-align: inherit
}

.rev_column_inner .tp-mask-wrap {
	display: inline-block
}

.rev_column_inner .tp-parallax-wrap,
.rev_column_inner .tp-parallax-wrap .tp-loop-wrap,
.rev_column_inner .tp-parallax-wrap .tp-mask-wrap {
	position: relative !important;
	left: auto !important;
	top: auto !important;
	line-height: 0
}

.tp-video-play-button,
.tp-video-play-button i {
	line-height: 50px !important;
	vertical-align: top;
	text-align: center
}

.rev_column_inner .rev_layer_in_column,
.rev_column_inner .tp-parallax-wrap,
.rev_column_inner .tp-parallax-wrap .tp-loop-wrap,
.rev_column_inner .tp-parallax-wrap .tp-mask-wrap {
	vertical-align: top
}

.rev_break_columns {
	display: block !important
}

.rev_break_columns .tp-parallax-wrap.rev_column {
	display: block !important;
	width: 100% !important
}

.fullwidthbanner-container {
	overflow: hidden
}

.fullwidthbanner-container .fullwidthabanner {
	width: 100%;
	position: relative
}

.tp-static-layers {
	position: absolute;
	z-index: 101;
	top: 0;
	left: 0
}

.tp-caption .frontcorner {
	border-left: 40px solid transparent;
	border-right: 0 solid transparent;
	border-top: 40px solid #00A8FF;
	left: -40px
}

.tp-caption .backcorner {
	border-left: 0 solid transparent;
	border-right: 40px solid transparent;
	border-bottom: 40px solid #00A8FF;
	right: 0
}

.tp-caption .frontcornertop {
	border-left: 40px solid transparent;
	border-right: 0 solid transparent;
	border-bottom: 40px solid #00A8FF;
	left: -40px
}

.tp-caption .backcornertop {
	border-left: 0 solid transparent;
	border-right: 40px solid transparent;
	border-top: 40px solid #00A8FF;
	right: 0
}

.tp-layer-inner-rotation {
	position: relative !important
}

img.tp-slider-alternative-image {
	width: 100%;
	height: auto
}

.caption.fullscreenvideo,
.rs-background-video-layer,
.tp-caption.coverscreenvideo,
.tp-caption.fullscreenvideo {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute
}

.noFilterClass {
	filter: none !important
}

.rs-background-video-layer {
	visibility: hidden;
	z-index: 0
}

.caption.fullscreenvideo audio,
.caption.fullscreenvideo iframe,
.caption.fullscreenvideo video,
.tp-caption.fullscreenvideo iframe,
.tp-caption.fullscreenvideo iframe audio,
.tp-caption.fullscreenvideo iframe video {
	width: 100% !important;
	height: 100% !important;
	display: none
}

.fullcoveredvideo audio,
.fullscreenvideo audio .fullcoveredvideo video,
.fullscreenvideo video {
	background: #000
}

.fullcoveredvideo .tp-poster {
	background-position: center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0
}

.videoisplaying .html5vid .tp-poster {
	display: none
}

.tp-video-play-button {
	background: #000;
	background: rgba(0, 0, 0, .3);
	border-radius: 5px;
	position: absolute;
	top: 50%;
	left: 50%;
	color: #FFF;
	margin-top: -25px;
	margin-left: -25px;
	cursor: pointer;
	width: 50px;
	height: 50px;
	box-sizing: border-box;
	display: inline-block;
	z-index: 4;
	opacity: 0;
	transition: opacity .3s ease-out !important
}

.tp-audio-html5 .tp-video-play-button,
.tp-hiddenaudio {
	display: none !important
}

.tp-caption .html5vid {
	width: 100% !important;
	height: 100% !important
}

.tp-video-play-button i {
	width: 50px;
	height: 50px;
	display: inline-block;
	font-size: 40px !important
}

.rs-fullvideo-cover,
.tp-dottedoverlay,
.tp-shadowcover {
	height: 100%;
	top: 0;
	left: 0;
	position: absolute
}

.tp-caption:hover .tp-video-play-button {
	opacity: 1;
	display: block
}

.tp-caption .tp-revstop {
	display: none;
	border-left: 5px solid #fff !important;
	border-right: 5px solid #fff !important;
	margin-top: 15px !important;
	line-height: 20px !important;
	vertical-align: top;
	font-size: 25px !important
}

.tp-seek-bar,
.tp-video-button,
.tp-volume-bar {
	outline: 0;
	line-height: 12px;
	margin: 0;
	cursor: pointer
}

.videoisplaying .revicon-right-dir {
	display: none
}

.videoisplaying .tp-revstop {
	display: inline-block
}

.videoisplaying .tp-video-play-button {
	display: none
}

.fullcoveredvideo .tp-video-play-button {
	display: none !important
}

.fullscreenvideo .fullscreenvideo audio,
.fullscreenvideo .fullscreenvideo video {
	object-fit: contain !important
}

.fullscreenvideo .fullcoveredvideo audio,
.fullscreenvideo .fullcoveredvideo video {
	object-fit: cover !important
}

.tp-video-controls {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 5px;
	opacity: 0;
	transition: opacity .3s;
	background-image: linear-gradient(to bottom, #000 13%, #323232 100%);
	display: table;
	max-width: 100%;
	overflow: hidden;
	box-sizing: border-box
}

.rev-btn.rev-hiddenicon i,
.rev-btn.rev-withicon i {
	transition: all .2s ease-out !important;
	font-size: 15px
}

.tp-caption:hover .tp-video-controls {
	opacity: .9
}

.tp-video-button {
	background: rgba(0, 0, 0, .5);
	border: 0;
	border-radius: 3px;
	font-size: 12px;
	color: #fff;
	padding: 0
}

.tp-video-button:hover {
	cursor: pointer
}

.tp-video-button-wrap,
.tp-video-seek-bar-wrap,
.tp-video-vol-bar-wrap {
	padding: 0 5px;
	display: table-cell;
	vertical-align: middle
}

.tp-video-seek-bar-wrap {
	width: 80%
}

.tp-video-vol-bar-wrap {
	width: 20%
}

.tp-seek-bar,
.tp-volume-bar {
	width: 100%;
	padding: 0
}

.rs-fullvideo-cover {
	width: 100%;
	background: 0 0;
	z-index: 5
}

.disabled_lc .tp-video-play-button,
.rs-background-video-layer audio::-webkit-media-controls,
.rs-background-video-layer video::-webkit-media-controls,
.rs-background-video-layer video::-webkit-media-controls-start-playback-button {
	display: none !important
}

.tp-audio-html5 .tp-video-controls {
	opacity: 1 !important;
	visibility: visible !important
}

.tp-dottedoverlay {
	background-repeat: repeat;
	width: 100%;
	z-index: 3
}

.tp-dottedoverlay.twoxtwo {
	background: url(../assets/gridtile.png)
}

.tp-dottedoverlay.twoxtwowhite {
	background: url(../assets/gridtile_white.png)
}

.tp-dottedoverlay.threexthree {
	background: url(../assets/gridtile_3x3.png)
}

.tp-dottedoverlay.threexthreewhite {
	background: url(../assets/gridtile_3x3_white.png)
}

.tp-shadowcover {
	width: 100%;
	background: #fff;
	z-index: -1
}

.tp-shadow1 {
	box-shadow: 0 10px 6px -6px rgba(0, 0, 0, .8)
}

.tp-shadow2:after,
.tp-shadow2:before,
.tp-shadow3:before,
.tp-shadow4:after {
	z-index: -2;
	position: absolute;
	content: "";
	bottom: 10px;
	left: 10px;
	width: 50%;
	top: 85%;
	max-width: 300px;
	background: 0 0;
	box-shadow: 0 15px 10px rgba(0, 0, 0, .8);
	transform: rotate(-3deg)
}

.tp-shadow2:after,
.tp-shadow4:after {
	transform: rotate(3deg);
	right: 10px;
	left: auto
}

.tp-shadow5 {
	position: relative;
	box-shadow: 0 1px 4px rgba(0, 0, 0, .3), 0 0 40px rgba(0, 0, 0, .1) inset
}

.tp-shadow5:after,
.tp-shadow5:before {
	content: "";
	position: absolute;
	z-index: -2;
	box-shadow: 0 0 25px 0 rgba(0, 0, 0, .6);
	top: 30%;
	bottom: 0;
	left: 20px;
	right: 20px;
	border-radius: 100px/20px
}

.tp-button {
	padding: 6px 13px 5px;
	border-radius: 3px;
	height: 30px;
	cursor: pointer;
	color: #fff !important;
	text-shadow: 0 1px 1px rgba(0, 0, 0, .6) !important;
	font-size: 15px;
	line-height: 45px !important;
	font-family: arial, sans-serif;
	font-weight: 700;
	letter-spacing: -1px;
	text-decoration: none
}

.tp-button.big {
	color: #fff;
	text-shadow: 0 1px 1px rgba(0, 0, 0, .6);
	font-weight: 700;
	padding: 9px 20px;
	font-size: 19px;
	line-height: 57px !important
}

.purchase:hover,
.tp-button.big:hover,
.tp-button:hover {
	background-position: bottom, 15px 11px
}

.purchase.green,
.purchase:hover.green,
.tp-button.green,
.tp-button:hover.green {
	background-color: #21a117;
	box-shadow: 0 3px 0 0 #104d0b
}

.purchase.blue,
.purchase:hover.blue,
.tp-button.blue,
.tp-button:hover.blue {
	background-color: #1d78cb;
	box-shadow: 0 3px 0 0 #0f3e68
}

.purchase.red,
.purchase:hover.red,
.tp-button.red,
.tp-button:hover.red {
	background-color: #cb1d1d;
	box-shadow: 0 3px 0 0 #7c1212
}

.purchase.orange,
.purchase:hover.orange,
.tp-button.orange,
.tp-button:hover.orange {
	background-color: #f70;
	box-shadow: 0 3px 0 0 #a34c00
}

.purchase.darkgrey,
.purchase:hover.darkgrey,
.tp-button.darkgrey,
.tp-button.grey,
.tp-button:hover.darkgrey,
.tp-button:hover.grey {
	background-color: #555;
	box-shadow: 0 3px 0 0 #222
}

.purchase.lightgrey,
.purchase:hover.lightgrey,
.tp-button.lightgrey,
.tp-button:hover.lightgrey {
	background-color: #888;
	box-shadow: 0 3px 0 0 #555
}

.rev-btn,
.rev-btn:visited {
	outline: 0 !important;
	box-shadow: none !important;
	text-decoration: none !important;
	line-height: 44px;
	font-size: 17px;
	font-weight: 500;
	padding: 12px 35px;
	box-sizing: border-box;
	font-family: Roboto, sans-serif;
	cursor: pointer
}

.rev-btn.rev-uppercase,
.rev-btn.rev-uppercase:visited {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 15px;
	font-weight: 900
}

.rev-btn.rev-withicon i {
	font-weight: 400;
	position: relative;
	top: 0;
	margin-left: 10px !important
}

.rev-btn.rev-hiddenicon i {
	font-weight: 400;
	position: relative;
	top: 0;
	opacity: 0;
	margin-left: 0 !important;
	width: 0 !important
}

.rev-btn.rev-hiddenicon:hover i {
	opacity: 1 !important;
	margin-left: 10px !important;
	width: auto !important
}

.rev-btn.rev-medium,
.rev-btn.rev-medium:visited {
	line-height: 36px;
	font-size: 14px;
	padding: 10px 30px
}

.rev-btn.rev-medium.rev-hiddenicon i,
.rev-btn.rev-medium.rev-withicon i {
	font-size: 14px;
	top: 0
}

.rev-btn.rev-small,
.rev-btn.rev-small:visited {
	line-height: 28px;
	font-size: 12px;
	padding: 7px 20px
}

.rev-btn.rev-small.rev-hiddenicon i,
.rev-btn.rev-small.rev-withicon i {
	font-size: 12px;
	top: 0
}

.rev-maxround {
	border-radius: 30px
}

.rev-minround {
	border-radius: 3px
}

.rev-burger {
	position: relative;
	width: 60px;
	height: 60px;
	box-sizing: border-box;
	padding: 22px 0 0 14px;
	border-radius: 50%;
	border: 1px solid rgba(51, 51, 51, .25);
	-webkit-tap-highlight-color: transparent;
	cursor: pointer
}

.rev-burger span {
	display: block;
	width: 30px;
	height: 3px;
	background: #333;
	transition: .7s;
	pointer-events: none;
	transform-style: flat !important
}

.rev-burger.revb-white span,
.rev-burger.revb-whitenoborder span {
	background: #fff
}

.rev-burger span:nth-child(2) {
	margin: 3px 0
}

#dialog_addbutton .rev-burger:hover :first-child,
.open .rev-burger :first-child,
.open.rev-burger :first-child {
	transform: translateY(6px) rotate(-45deg)
}

#dialog_addbutton .rev-burger:hover :nth-child(2),
.open .rev-burger :nth-child(2),
.open.rev-burger :nth-child(2) {
	transform: rotate(-45deg);
	opacity: 0
}

#dialog_addbutton .rev-burger:hover :last-child,
.open .rev-burger :last-child,
.open.rev-burger :last-child {
	transform: translateY(-6px) rotate(-135deg)
}

.rev-burger.revb-white {
	border: 2px solid rgba(255, 255, 255, .2)
}

.rev-burger.revb-darknoborder,
.rev-burger.revb-whitenoborder {
	border: 0
}

.rev-burger.revb-darknoborder span {
	background: #333
}

.rev-burger.revb-whitefull {
	background: #fff;
	border: none
}

.rev-burger.revb-whitefull span {
	background: #333
}

.rev-burger.revb-darkfull {
	background: #333;
	border: none
}

.rev-burger.revb-darkfull span,
.rev-scroll-btn.revs-fullwhite {
	background: #fff
}

@keyframes rev-ani-mouse {
	0% {
		opacity: 1;
		top: 29%
	}

	15% {
		opacity: 1;
		top: 50%
	}

	50% {
		opacity: 0;
		top: 50%
	}

	100% {
		opacity: 0;
		top: 29%
	}
}

.rev-scroll-btn {
	display: inline-block;
	position: relative;
	left: 0;
	right: 0;
	text-align: center;
	cursor: pointer;
	width: 35px;
	height: 55px;
	box-sizing: border-box;
	border: 3px solid #fff;
	border-radius: 23px
}

.rev-scroll-btn>* {
	display: inline-block;
	line-height: 18px;
	font-size: 13px;
	font-weight: 400;
	color: #fff;
	font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
	letter-spacing: 2px
}

.rev-scroll-btn>.active,
.rev-scroll-btn>:focus,
.rev-scroll-btn>:hover {
	color: #fff
}

.rev-scroll-btn>.active,
.rev-scroll-btn>:active,
.rev-scroll-btn>:focus,
.rev-scroll-btn>:hover {
	opacity: .8
}

.rev-scroll-btn.revs-fullwhite span {
	background: #333
}

.rev-scroll-btn.revs-fulldark {
	background: #333;
	border: none
}

.rev-scroll-btn.revs-fulldark span,
.tp-bullet {
	background: #fff
}

.rev-scroll-btn span {
	position: absolute;
	display: block;
	top: 29%;
	left: 50%;
	width: 8px;
	height: 8px;
	margin: -4px 0 0 -4px;
	background: #fff;
	border-radius: 50%;
	animation: rev-ani-mouse 2.5s linear infinite
}

.rev-scroll-btn.revs-dark {
	border-color: #333
}

.rev-scroll-btn.revs-dark span {
	background: #333
}

.rev-control-btn {
	position: relative;
	display: inline-block;
	z-index: 5;
	color: #FFF;
	font-size: 20px;
	line-height: 60px;
	font-weight: 400;
	font-style: normal;
	font-family: Raleway;
	text-decoration: none;
	text-align: center;
	background-color: #000;
	border-radius: 50px;
	text-shadow: none;
	background-color: rgba(0, 0, 0, .5);
	width: 60px;
	height: 60px;
	box-sizing: border-box;
	cursor: pointer
}

.rev-cbutton-dark-sr,
.rev-cbutton-light-sr {
	border-radius: 3px
}

.rev-cbutton-light,
.rev-cbutton-light-sr {
	color: #333;
	background-color: rgba(255, 255, 255, .75)
}

.rev-sbutton {
	line-height: 37px;
	width: 37px;
	height: 37px
}

.rev-sbutton-blue {
	background-color: #3B5998
}

.rev-sbutton-lightblue {
	background-color: #00A0D1
}

.rev-sbutton-red {
	background-color: #DD4B39
}

.tp-bannertimer {
	visibility: hidden;
	width: 100%;
	height: 5px;
	background: #000;
	background: rgba(0, 0, 0, .15);
	position: absolute;
	z-index: 200;
	top: 0
}

.tp-bannertimer.tp-bottom {
	top: auto;
	bottom: 0 !important;
	height: 5px
}

.tp-caption img {
	background: 0 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
	zoom: 1
}

.caption.slidelink a div,
.tp-caption.slidelink a div {
	width: 3000px;
	height: 1500px;
	background: url(../assets/coloredbg.png)
}

.tp-caption.slidelink a span {
	background: url(../assets/coloredbg.png)
}

.tp-loader.spinner0,
.tp-loader.spinner5 {
	background-image: url(../assets/loader.gif);
	background-repeat: no-repeat
}

.tp-shape {
	width: 100%;
	height: 100%
}

.tp-caption .rs-starring {
	display: inline-block
}

.tp-caption .rs-starring .star-rating {
	float: none;
	display: inline-block;
	vertical-align: top;
	color: #FFC321 !important
}

.tp-caption .rs-starring .star-rating,
.tp-caption .rs-starring-page .star-rating {
	position: relative;
	height: 1em;
	width: 5.4em;
	font-family: star;
	font-size: 1em !important
}

.tp-loader.spinner0,
.tp-loader.spinner1 {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	animation: tp-rotateplane 1.2s infinite ease-in-out;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15)
}

.tp-caption .rs-starring .star-rating:before,
.tp-caption .rs-starring-page .star-rating:before {
	content: "\73\73\73\73\73";
	color: #E0DADF;
	float: left;
	top: 0;
	left: 0;
	position: absolute
}

.tp-caption .rs-starring .star-rating span {
	overflow: hidden;
	float: left;
	top: 0;
	left: 0;
	position: absolute;
	padding-top: 1.5em;
	font-size: 1em !important
}

.tp-caption .rs-starring .star-rating span:before {
	content: "\53\53\53\53\53";
	top: 0;
	position: absolute;
	left: 0
}

.tp-loader {
	top: 50%;
	left: 50%;
	z-index: 10000;
	position: absolute
}

.tp-loader.spinner0 {
	background-position: center center
}

.tp-loader.spinner5 {
	background-position: 10px 10px;
	background-color: #fff;
	margin: -22px;
	width: 44px;
	height: 44px;
	border-radius: 3px
}

@keyframes tp-rotateplane {
	0% {
		transform: perspective(120px) rotateX(0) rotateY(0)
	}

	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0)
	}

	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
	}
}

.tp-loader.spinner2 {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	background-color: red;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
	border-radius: 100%;
	animation: tp-scaleout 1s infinite ease-in-out
}

@keyframes tp-scaleout {
	0% {
		transform: scale(0)
	}

	100% {
		transform: scale(1);
		opacity: 0
	}
}

.tp-loader.spinner3 {
	margin: -9px 0 0 -35px;
	width: 70px;
	text-align: center
}

.tp-loader.spinner3 .bounce1,
.tp-loader.spinner3 .bounce2,
.tp-loader.spinner3 .bounce3 {
	width: 18px;
	height: 18px;
	background-color: #fff;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
	border-radius: 100%;
	display: inline-block;
	animation: tp-bouncedelay 1.4s infinite ease-in-out;
	animation-fill-mode: both
}

.tp-loader.spinner3 .bounce1 {
	animation-delay: -.32s
}

.tp-loader.spinner3 .bounce2 {
	animation-delay: -.16s
}

@keyframes tp-bouncedelay {

	0%,
	100%,
	80% {
		transform: scale(0)
	}

	40% {
		transform: scale(1)
	}
}

.tp-loader.spinner4 {
	margin: -20px 0 0 -20px;
	width: 40px;
	height: 40px;
	text-align: center;
	animation: tp-rotate 2s infinite linear
}

.tp-loader.spinner4 .dot1,
.tp-loader.spinner4 .dot2 {
	width: 60%;
	height: 60%;
	display: inline-block;
	position: absolute;
	top: 0;
	background-color: #fff;
	border-radius: 100%;
	animation: tp-bounce 2s infinite ease-in-out;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15)
}

.tp-loader.spinner4 .dot2 {
	top: auto;
	bottom: 0;
	animation-delay: -1s
}

@keyframes tp-rotate {
	100% {
		transform: rotate(360deg)
	}
}

@keyframes tp-bounce {

	0%,
	100% {
		transform: scale(0)
	}

	50% {
		transform: scale(1)
	}
}

.tp-bullets.navbar,
.tp-tabs.navbar,
.tp-thumbs.navbar {
	border: none;
	min-height: 0;
	margin: 0;
	border-radius: 0
}

.tp-bullets,
.tp-tabs,
.tp-thumbs {
	position: absolute;
	display: block;
	z-index: 1000;
	top: 0;
	left: 0
}

.tp-tab,
.tp-thumb {
	cursor: pointer;
	position: absolute;
	opacity: .5;
	box-sizing: border-box
}

.tp-arr-imgholder,
.tp-tab-image,
.tp-thumb-image,
.tp-videoposter {
	background-position: center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0
}

.tp-tab.selected,
.tp-tab:hover,
.tp-thumb.selected,
.tp-thumb:hover {
	opacity: 1
}

.tp-tab-mask,
.tp-thumb-mask {
	box-sizing: border-box !important
}

.tp-tabs,
.tp-thumbs {
	box-sizing: content-box !important
}

.tp-bullet {
	width: 15px;
	height: 15px;
	position: absolute;
	background: rgba(255, 255, 255, .3);
	cursor: pointer
}

.tp-bullet.selected,
.tp-bullet:hover {
	background: #fff
}

.tparrows {
	cursor: pointer;
	background: #000;
	background: rgba(0, 0, 0, .5);
	width: 40px;
	height: 40px;
	position: absolute;
	display: block;
	z-index: 1000
}

.tparrows:hover {
	background: #000
}

.tparrows:before {
	font-family: revicons;
	font-size: 15px;
	color: #fff;
	display: block;
	line-height: 40px;
	text-align: center
}

.hginfo,
.hglayerinfo {
	font-size: 12px;
	font-weight: 600
}

.tparrows.tp-leftarrow:before {
	content: '\e824'
}

.tparrows.tp-rightarrow:before {
	content: '\e825'
}

body.rtl .tp-kbimg {
	left: 0 !important
}

.dddwrappershadow {
	box-shadow: 0 45px 100px rgba(0, 0, 0, .4)
}

.hglayerinfo {
	position: fixed;
	bottom: 0;
	left: 0;
	color: #FFF;
	line-height: 20px;
	background: rgba(0, 0, 0, .75);
	padding: 5px 10px;
	z-index: 2000;
	white-space: normal
}

.helpgrid,
.hginfo {
	position: absolute
}

.hginfo {
	top: -2px;
	left: -2px;
	color: #e74c3c;
	background: #000;
	padding: 2px 5px
}

.indebugmode .tp-caption:hover {
	border: 1px dashed #c0392b !important
}

.helpgrid {
	border: 2px dashed #c0392b;
	top: 0;
	left: 0;
	z-index: 0
}

#revsliderlogloglog {
	padding: 15px;
	color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	width: 200px;
	height: 150px;
	background: rgba(0, 0, 0, .7);
	z-index: 100000;
	font-size: 10px;
	overflow: scroll
}

.aden {
	filter: hue-rotate(-20deg) contrast(.9) saturate(.85) brightness(1.2)
}

.aden::after {
	background: linear-gradient(to right, rgba(66, 10, 14, .2), transparent);
	mix-blend-mode: darken
}

.perpetua::after,
.reyes::after {
	mix-blend-mode: soft-light;
	opacity: .5
}

.inkwell {
	filter: sepia(.3) contrast(1.1) brightness(1.1) grayscale(1)
}

.perpetua::after {
	background: linear-gradient(to bottom, #005b9a, #e6c13d)
}

.reyes {
	filter: sepia(.22) brightness(1.1) contrast(.85) saturate(.75)
}

.reyes::after {
	background: #efcdad
}

.gingham {
	filter: brightness(1.05) hue-rotate(-10deg)
}

.gingham::after {
	background: linear-gradient(to right, rgba(66, 10, 14, .2), transparent);
	mix-blend-mode: darken
}

.toaster {
	filter: contrast(1.5) brightness(.9)
}

.toaster::after {
	background: radial-gradient(circle, #804e0f, #3b003b);
	mix-blend-mode: screen
}

.walden {
	filter: brightness(1.1) hue-rotate(-10deg) sepia(.3) saturate(1.6)
}

.walden::after {
	background: #04c;
	mix-blend-mode: screen;
	opacity: .3
}

.hudson {
	filter: brightness(1.2) contrast(.9) saturate(1.1)
}

.hudson::after {
	background: radial-gradient(circle, #a6b1ff 50%, #342134);
	mix-blend-mode: multiply;
	opacity: .5
}

.earlybird {
	filter: contrast(.9) sepia(.2)
}

.earlybird::after {
	background: radial-gradient(circle, #d0ba8e 20%, #360309 85%, #1d0210 100%);
	mix-blend-mode: overlay
}

.mayfair {
	filter: contrast(1.1) saturate(1.1)
}

.mayfair::after {
	background: radial-gradient(circle at 40% 40%, rgba(255, 255, 255, .8), rgba(255, 200, 200, .6), #111 60%);
	mix-blend-mode: overlay;
	opacity: .4
}

.lofi {
	filter: saturate(1.1) contrast(1.5)
}

.lofi::after {
	background: radial-gradient(circle, transparent 70%, #222 150%);
	mix-blend-mode: multiply
}

._1977 {
	filter: contrast(1.1) brightness(1.1) saturate(1.3)
}

._1977:after {
	background: rgba(243, 106, 188, .3);
	mix-blend-mode: screen
}

.brooklyn {
	filter: contrast(.9) brightness(1.1)
}

.brooklyn::after {
	background: radial-gradient(circle, rgba(168, 223, 193, .4) 70%, #c4b7c8);
	mix-blend-mode: overlay
}

.xpro2 {
	filter: sepia(.3)
}

.xpro2::after {
	background: radial-gradient(circle, #e6e7e0 40%, rgba(43, 42, 161, .6) 110%);
	mix-blend-mode: color-burn
}

.nashville {
	filter: sepia(.2) contrast(1.2) brightness(1.05) saturate(1.2)
}

.nashville::after {
	background: rgba(0, 70, 150, .4);
	mix-blend-mode: lighten
}

.nashville::before {
	background: rgba(247, 176, 153, .56);
	mix-blend-mode: darken
}

.lark {
	filter: contrast(.9)
}

.lark::after {
	background: rgba(242, 242, 242, .8);
	mix-blend-mode: darken
}

.lark::before {
	background: #22253f;
	mix-blend-mode: color-dodge
}

.moon {
	filter: grayscale(1) contrast(1.1) brightness(1.1)
}

.moon::before {
	background: #a0a0a0;
	mix-blend-mode: soft-light
}

.moon::after {
	background: #383838;
	mix-blend-mode: lighten
}

.clarendon {
	filter: contrast(1.2) saturate(1.35)
}

.clarendon:before {
	background: rgba(127, 187, 227, .2);
	mix-blend-mode: overlay
}

.willow {
	filter: grayscale(.5) contrast(.95) brightness(.9)
}

.willow::before {
	background-color: radial-gradient(40%, circle, #d4a9af 55%, #000 150%);
	mix-blend-mode: overlay
}

.willow::after {
	background-color: #d8cdcb;
	mix-blend-mode: color
}

.rise {
	filter: brightness(1.05) sepia(.2) contrast(.9) saturate(.9)
}

.rise::after {
	background: radial-gradient(circle, rgba(232, 197, 152, .8), transparent 90%);
	mix-blend-mode: overlay;
	opacity: .6
}

.rise::before {
	background: radial-gradient(circle, rgba(236, 205, 169, .15) 55%, rgba(50, 30, 7, .4));
	mix-blend-mode: multiply
}

._1977:after,
._1977:before,
.aden:after,
.aden:before,
.brooklyn:after,
.brooklyn:before,
.clarendon:after,
.clarendon:before,
.earlybird:after,
.earlybird:before,
.gingham:after,
.gingham:before,
.hudson:after,
.hudson:before,
.inkwell:after,
.inkwell:before,
.lark:after,
.lark:before,
.lofi:after,
.lofi:before,
.mayfair:after,
.mayfair:before,
.moon:after,
.moon:before,
.nashville:after,
.nashville:before,
.perpetua:after,
.perpetua:before,
.reyes:after,
.reyes:before,
.rise:after,
.rise:before,
.slumber:after,
.slumber:before,
.toaster:after,
.toaster:before,
.walden:after,
.walden:before,
.willow:after,
.willow:before,
.xpro2:after,
.xpro2:before {
	content: '';
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none
}

._1977,
.aden,
.brooklyn,
.clarendon,
.earlybird,
.gingham,
.hudson,
.inkwell,
.lark,
.lofi,
.mayfair,
.moon,
.nashville,
.perpetua,
.reyes,
.rise,
.slumber,
.toaster,
.walden,
.willow,
.xpro2 {
	position: relative
}

._1977 img,
.aden img,
.brooklyn img,
.clarendon img,
.earlybird img,
.gingham img,
.hudson img,
.inkwell img,
.lark img,
.lofi img,
.mayfair img,
.moon img,
.nashville img,
.perpetua img,
.reyes img,
.rise img,
.slumber img,
.toaster img,
.walden img,
.willow img,
.xpro2 img {
	width: 100%;
	z-index: 1
}

._1977:before,
.aden:before,
.brooklyn:before,
.clarendon:before,
.earlybird:before,
.gingham:before,
.hudson:before,
.inkwell:before,
.lark:before,
.lofi:before,
.mayfair:before,
.moon:before,
.nashville:before,
.perpetua:before,
.reyes:before,
.rise:before,
.slumber:before,
.toaster:before,
.walden:before,
.willow:before,
.xpro2:before {
	z-index: 2
}

._1977:after,
.aden:after,
.brooklyn:after,
.clarendon:after,
.earlybird:after,
.gingham:after,
.hudson:after,
.inkwell:after,
.lark:after,
.lofi:after,
.mayfair:after,
.moon:after,
.nashville:after,
.perpetua:after,
.reyes:after,
.rise:after,
.slumber:after,
.toaster:after,
.walden:after,
.willow:after,
.xpro2:after {
	z-index: 3
}

.slumber {
	filter: saturate(.66) brightness(1.05)
}

.slumber::after {
	background: rgba(125, 105, 24, .5);
	mix-blend-mode: soft-light
}

.slumber::before {
	background: rgba(69, 41, 12, .4);
	mix-blend-mode: lighten
}

.tp-kbimg-wrap:after,
.tp-kbimg-wrap:before {
	height: 500%;
	width: 500%
}

.ares.tparrows {
	cursor: pointer;
	background: #fff;
	min-width: 60px;
	min-height: 60px;
	position: absolute;
	display: block;
	z-index: 100;
	border-radius: 50%
}

.ares.tparrows:before {
	font-family: revicons;
	font-size: 25px;
	color: #aaa;
	display: block;
	line-height: 60px;
	text-align: center;
	-webkit-transition: color .3s;
	-moz-transition: color .3s;
	transition: color .3s;
	z-index: 2;
	position: relative
}

.ares.tparrows.tp-leftarrow:before {
	content: "\e81f"
}

.ares.tparrows.tp-rightarrow:before {
	content: "\e81e"
}

.ares.tparrows:hover:before {
	color: #000
}

.ares .tp-title-wrap {
	position: absolute;
	z-index: 1;
	display: inline-block;
	background: #fff;
	min-height: 60px;
	line-height: 60px;
	top: 0;
	margin-left: 30px;
	border-radius: 0 30px 30px 0;
	overflow: hidden;
	-webkit-transition: -webkit-transform .3s;
	transition: transform .3s;
	transform: scaleX(0);
	-webkit-transform: scaleX(0);
	transform-origin: 0 50%;
	-webkit-transform-origin: 0 50%
}

.ares.tp-rightarrow .tp-title-wrap {
	right: 0;
	margin-right: 30px;
	margin-left: 0;
	-webkit-transform-origin: 100% 50%;
	border-radius: 30px 0 0 30px
}

.ares.tparrows:hover .tp-title-wrap {
	transform: scaleX(1) scaleY(1);
	-webkit-transform: scaleX(1) scaleY(1)
}

.ares .tp-arr-titleholder {
	position: relative;
	-webkit-transition: -webkit-transform .3s;
	transition: transform .3s;
	transform: translateX(200px);
	text-transform: uppercase;
	color: #000;
	font-weight: 400;
	font-size: 14px;
	line-height: 60px;
	white-space: nowrap;
	padding: 0 20px;
	margin-left: 10px;
	opacity: 0
}

.ares.tp-rightarrow .tp-arr-titleholder {
	transform: translateX(-200px);
	margin-left: 0;
	margin-right: 10px
}

.ares.tparrows:hover .tp-arr-titleholder {
	transform: translateX(0);
	-webkit-transform: translateX(0);
	transition-delay: .1s;
	opacity: 1
}

.ares.tp-bullets:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background: 0 0;
	padding: 10px;
	margin-left: -10px;
	margin-top: -10px;
	box-sizing: content-box
}

.ares .tp-bullet {
	width: 13px;
	height: 13px;
	position: absolute;
	background: #e5e5e5;
	border-radius: 50%;
	cursor: pointer;
	box-sizing: content-box
}

.ares .tp-bullet.selected,
.ares .tp-bullet:hover {
	background: #fff
}

.ares .tp-bullet-title {
	position: absolute;
	color: #888;
	font-size: 12px;
	padding: 0 10px;
	font-weight: 600;
	right: 27px;
	top: -4px;
	background: #fff;
	background: rgba(255, 255, 255, .75);
	visibility: hidden;
	transform: translateX(-20px);
	-webkit-transform: translateX(-20px);
	transition: transform .3s;
	-webkit-transition: transform .3s;
	line-height: 20px;
	white-space: nowrap
}

.ares .tp-bullet-title:after {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent rgba(255, 255, 255, .75);
	content: " ";
	position: absolute;
	right: -10px;
	top: 0
}

.ares .tp-bullet:hover .tp-bullet-title {
	visibility: visible;
	transform: translateX(0);
	-webkit-transform: translateX(0)
}

.ares .tp-bullet.selected:hover .tp-bullet-title {
	background: #fff
}

.ares .tp-bullet.selected:hover .tp-bullet-title:after {
	border-color: transparent transparent transparent #fff
}

.ares.tp-bullets:hover .tp-bullet-title {
	visibility: hidden
}

.ares.tp-bullets:hover .tp-bullet:hover .tp-bullet-title {
	visibility: visible
}

.ares .tp-tab {
	opacity: 1;
	padding: 10px;
	box-sizing: border-box;
	font-family: Roboto, sans-serif;
	border-bottom: 1px solid #e5e5e5
}

.ares .tp-tab-image {
	width: 60px;
	height: 60px;
	max-height: 100%;
	max-width: 100%;
	position: relative;
	display: inline-block;
	float: left
}

.ares .tp-tab-content {
	background: rgba(0, 0, 0, 0);
	position: relative;
	padding: 15px 15px 15px 85px;
	left: 0;
	overflow: hidden;
	margin-top: -15px;
	box-sizing: border-box;
	color: #333;
	display: inline-block;
	width: 100%;
	height: 100%;
	position: absolute
}

.ares .tp-tab-date {
	display: block;
	color: #aaa;
	font-weight: 500;
	font-size: 12px;
	margin-bottom: 0
}

.ares .tp-tab-title {
	display: block;
	text-align: left;
	color: #333;
	font-size: 14px;
	font-weight: 500;
	text-transform: none;
	line-height: 17px
}

.ares .tp-tab.selected,
.ares .tp-tab:hover {
	background: #eee
}

.custom.tparrows {
	cursor: pointer;
	background: #000;
	background: rgba(0, 0, 0, .5);
	width: 40px;
	height: 40px;
	position: absolute;
	display: block;
	z-index: 10000
}

.custom.tparrows:hover {
	background: #000
}

.custom.tparrows:before {
	font-family: revicons;
	font-size: 15px;
	color: #fff;
	display: block;
	line-height: 40px;
	text-align: center
}

.custom.tparrows.tp-leftarrow:before {
	content: "\e824"
}

.custom.tparrows.tp-rightarrow:before {
	content: "\e825"
}

.custom.tp-bullets:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background: 0 0;
	padding: 10px;
	margin-left: -10px;
	margin-top: -10px;
	box-sizing: content-box
}

.custom .tp-bullet {
	width: 12px;
	height: 12px;
	position: absolute;
	background: #aaa;
	background: rgba(125, 125, 125, .5);
	cursor: pointer;
	box-sizing: content-box
}

.custom .tp-bullet.selected,
.custom .tp-bullet:hover {
	background: #7d7d7d
}

.dione.tparrows {
	height: 100%;
	width: 100px;
	background: 0 0;
	background: rgba(0, 0, 0, 0);
	line-height: 100%;
	transition: all .3s;
	-webkit-transition: all .3s
}

.dione.tparrows:hover {
	background: rgba(0, 0, 0, .45)
}

.dione .tp-arr-imgwrapper {
	width: 100px;
	left: 0;
	position: absolute;
	height: 100%;
	top: 0;
	overflow: hidden
}

.dione.tp-rightarrow .tp-arr-imgwrapper {
	left: auto;
	right: 0
}

.dione .tp-arr-imgholder {
	background-position: center center;
	background-size: cover;
	width: 100px;
	height: 100%;
	top: 0;
	visibility: hidden;
	transform: translateX(-50px);
	-webkit-transform: translateX(-50px);
	transition: all .3s;
	-webkit-transition: all .3s;
	opacity: 0;
	left: 0
}

.dione.tparrows.tp-rightarrow .tp-arr-imgholder {
	right: 0;
	left: auto;
	transform: translateX(50px);
	-webkit-transform: translateX(50px)
}

.dione.tparrows:before {
	position: absolute;
	line-height: 30px;
	margin-left: -22px;
	top: 50%;
	left: 50%;
	font-size: 30px;
	margin-top: -15px;
	transition: all .3s;
	-webkit-transition: all .3s
}

.dione.tparrows.tp-rightarrow:before {
	margin-left: 6px
}

.dione.tparrows:hover:before {
	transform: translateX(-20px);
	-webkit-transform: translateX(-20px);
	opacity: 0
}

.dione.tparrows.tp-rightarrow:hover:before {
	transform: translateX(20px);
	-webkit-transform: translateX(20px)
}

.dione.tparrows:hover .tp-arr-imgholder {
	transform: translateX(0);
	-webkit-transform: translateX(0);
	opacity: 1;
	visibility: visible
}

.dione .tp-bullet {
	opacity: 1;
	width: 50px;
	height: 50px;
	padding: 3px;
	background: #000;
	background-color: rgba(0, 0, 0, .25);
	margin: 0;
	box-sizing: border-box;
	transition: all .3s;
	-webkit-transition: all .3s
}

.dione .tp-bullet-image {
	display: block;
	box-sizing: border-box;
	position: relative;
	-webkit-box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	-moz-box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	width: 44px;
	height: 44px;
	background-size: cover;
	background-position: center center
}

.dione .tp-bullet-title {
	position: absolute;
	bottom: 65px;
	display: inline-block;
	left: 50%;
	background: #000;
	background: rgba(0, 0, 0, .75);
	color: #fff;
	padding: 10px 30px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	opacity: 0;
	transition: all .3s;
	-webkit-transition: all .3s;
	transform: translateZ(.001px) translateX(-50%) translateY(14px);
	transform-origin: 50% 100%;
	-webkit-transform: translateZ(.001px) translateX(-50%) translateY(14px);
	-webkit-transform-origin: 50% 100%;
	opacity: 0;
	white-space: nowrap
}

.dione .tp-bullet:hover .tp-bullet-title {
	transform: rotateX(0) translateX(-50%);
	-webkit-transform: rotateX(0) translateX(-50%);
	opacity: 1
}

.dione .tp-bullet.selected,
.dione .tp-bullet:hover {
	background: #fff;
	background: -moz-linear-gradient(top, #fff 0, #777 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0, #fff), color-stop(100%, #777));
	background: -webkit-linear-gradient(top, #fff 0, #777 100%);
	background: -o-linear-gradient(top, #fff 0, #777 100%);
	background: -ms-linear-gradient(top, #fff 0, #777 100%);
	background: linear-gradient(to bottom, #fff 0, #777 100%)
}

.dione .tp-bullet-title:after {
	content: " ";
	position: absolute;
	left: 50%;
	margin-left: -8px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 0 8px;
	border-color: rgba(0, 0, 0, .75) transparent transparent transparent;
	bottom: -8px
}

.erinyen.tparrows {
	cursor: pointer;
	background: #000;
	background: rgba(0, 0, 0, .5);
	min-width: 70px;
	min-height: 70px;
	position: absolute;
	display: block;
	z-index: 1000;
	border-radius: 35px
}

.erinyen.tparrows:before {
	font-family: revicons;
	font-size: 20px;
	color: #fff;
	display: block;
	line-height: 70px;
	text-align: center;
	z-index: 2;
	position: relative
}

.erinyen.tparrows.tp-leftarrow:before {
	content: "\e824"
}

.erinyen.tparrows.tp-rightarrow:before {
	content: "\e825"
}

.erinyen .tp-title-wrap {
	position: absolute;
	z-index: 1;
	display: inline-block;
	background: #000;
	background: rgba(0, 0, 0, .5);
	min-height: 70px;
	line-height: 70px;
	top: 0;
	margin-left: 0;
	border-radius: 35px;
	overflow: hidden;
	transition: opacity .3s;
	-webkit-transition: opacity .3s;
	-moz-transition: opacity .3s;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	transform: scale(0);
	visibility: hidden;
	opacity: 0
}

.erinyen.tparrows:hover .tp-title-wrap {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	transform: scale(1);
	opacity: 1;
	visibility: visible
}

.erinyen.tp-rightarrow .tp-title-wrap {
	right: 0;
	margin-right: 0;
	margin-left: 0;
	-webkit-transform-origin: 100% 50%;
	border-radius: 35px;
	padding-right: 20px;
	padding-left: 10px
}

.erinyen.tp-leftarrow .tp-title-wrap {
	padding-left: 20px;
	padding-right: 10px
}

.erinyen .tp-arr-titleholder {
	letter-spacing: 3px;
	position: relative;
	-webkit-transition: -webkit-transform .3s;
	transition: transform .3s;
	transform: translateX(200px);
	text-transform: uppercase;
	color: #fff;
	font-weight: 600;
	font-size: 13px;
	line-height: 70px;
	white-space: nowrap;
	padding: 0 20px;
	margin-left: 11px;
	opacity: 0
}

.erinyen .tp-arr-imgholder {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-position: center center;
	background-size: cover
}

.erinyen .tp-arr-img-over {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: #000;
	background: rgba(0, 0, 0, .5)
}

.erinyen.tp-rightarrow .tp-arr-titleholder {
	transform: translateX(-200px);
	margin-left: 0;
	margin-right: 11px
}

.erinyen.tparrows:hover .tp-arr-titleholder {
	transform: translateX(0);
	-webkit-transform: translateX(0);
	transition-delay: .1s;
	opacity: 1
}

.erinyen.tp-bullets:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background: #555;
	background: -moz-linear-gradient(top, #555 0, #222 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #555), color-stop(100%, #222));
	background: -webkit-linear-gradient(top, #555 0, #222 100%);
	background: -o-linear-gradient(top, #555 0, #222 100%);
	background: -ms-linear-gradient(top, #555 0, #222 100%);
	background: linear-gradient(to bottom, #555 0, #222 100%);
	padding: 10px 15px;
	margin-left: -15px;
	margin-top: -10px;
	box-sizing: content-box;
	border-radius: 10px;
	box-shadow: 0 0 2px 1px rgba(33, 33, 33, .3)
}

.erinyen .tp-bullet {
	width: 13px;
	height: 13px;
	position: absolute;
	background: #111;
	border-radius: 50%;
	cursor: pointer;
	box-sizing: content-box
}

.erinyen .tp-bullet.selected,
.erinyen .tp-bullet:hover {
	background: #e5e5e5;
	background: -moz-linear-gradient(top, #e5e5e5 0, #999 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #e5e5e5), color-stop(100%, #999));
	background: -webkit-linear-gradient(top, #e5e5e5 0, #999 100%);
	background: -o-linear-gradient(top, #e5e5e5 0, #999 100%);
	background: -ms-linear-gradient(top, #e5e5e5 0, #999 100%);
	background: linear-gradient(to bottom, #e5e5e5 0, #999 100%);
	border: 1px solid #555;
	width: 12px;
	height: 12px
}

.erinyen .tp-thumb {
	opacity: 1
}

.erinyen .tp-thumb-over {
	background: #000;
	background: rgba(0, 0, 0, .25);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	-webkit-transition: all .3s;
	transition: all .3s
}

.erinyen .tp-thumb-more:before {
	font-family: revicons;
	font-size: 12px;
	color: #aaa;
	color: rgba(255, 255, 255, .75);
	display: block;
	line-height: 12px;
	text-align: left;
	z-index: 2;
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 2
}

.erinyen .tp-thumb-more:before {
	content: "\e825"
}

.erinyen .tp-thumb-title {
	font-family: Raleway;
	letter-spacing: 1px;
	font-size: 12px;
	color: #fff;
	display: block;
	line-height: 15px;
	text-align: left;
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	padding: 20px 35px 20px 20px;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	transition: all .3s;
	-webkit-transition: all .3s;
	font-weight: 500
}

.erinyen .tp-thumb.selected .tp-thumb-more:before,
.erinyen .tp-thumb:hover .tp-thumb-more:before {
	color: #aaa
}

.erinyen .tp-thumb.selected .tp-thumb-over,
.erinyen .tp-thumb:hover .tp-thumb-over {
	background: #fff
}

.erinyen .tp-thumb.selected .tp-thumb-title,
.erinyen .tp-thumb:hover .tp-thumb-title {
	color: #000
}

.erinyen .tp-tab-title {
	color: #a8d8ee;
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
	font-family: "Roboto Slab";
	margin-bottom:5px;
}

.erinyen .tp-tab-desc {
	font-size: 18px;
	font-weight: 400;
	color: #fff;
	line-height: 25px;
	font-family: "Roboto Slab"
}

.gyges.tp-bullets:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background: #777;
	background: -moz-linear-gradient(top, #777 0, #666 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #777), color-stop(100%, #666));
	background: -webkit-linear-gradient(top, #777 0, #666 100%);
	background: -o-linear-gradient(top, #777 0, #666 100%);
	background: -ms-linear-gradient(top, #777 0, #666 100%);
	background: linear-gradient(to bottom, #777 0, #666 100%);
	padding: 10px;
	margin-left: -10px;
	margin-top: -10px;
	box-sizing: content-box;
	border-radius: 10px
}

.gyges .tp-bullet {
	width: 12px;
	height: 12px;
	position: absolute;
	background: #333;
	border: 3px solid #444;
	border-radius: 50%;
	cursor: pointer;
	box-sizing: content-box
}

.gyges .tp-bullet.selected,
.gyges .tp-bullet:hover {
	background: #fff;
	background: -moz-linear-gradient(top, #fff 0, #e1e1e1 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(100%, #e1e1e1));
	background: -webkit-linear-gradient(top, #fff 0, #e1e1e1 100%);
	background: -o-linear-gradient(top, #fff 0, #e1e1e1 100%);
	background: -ms-linear-gradient(top, #fff 0, #e1e1e1 100%);
	background: linear-gradient(to bottom, #fff 0, #e1e1e1 100%)
}

.gyges .tp-thumb {
	opacity: 1
}

.gyges .tp-thumb-img-wrap {
	padding: 3px;
	background: #000;
	background-color: rgba(0, 0, 0, .25);
	display: inline-block;
	width: 100%;
	height: 100%;
	position: relative;
	margin: 0;
	box-sizing: border-box;
	transition: all .3s;
	-webkit-transition: all .3s
}

.gyges .tp-thumb-image {
	padding: 3px;
	display: block;
	box-sizing: border-box;
	position: relative;
	-webkit-box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	-moz-box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25)
}

.gyges .tp-thumb-title {
	position: absolute;
	bottom: 100%;
	display: inline-block;
	left: 50%;
	background: rgba(255, 255, 255, .8);
	padding: 10px 30px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	margin-bottom: 20px;
	opacity: 0;
	transition: all .3s;
	-webkit-transition: all .3s;
	transform: translateZ(.001px) translateX(-50%) translateY(14px);
	transform-origin: 50% 100%;
	-webkit-transform: translateZ(.001px) translateX(-50%) translateY(14px);
	-webkit-transform-origin: 50% 100%;
	white-space: nowrap
}

.gyges .tp-thumb:hover .tp-thumb-title {
	transform: rotateX(0) translateX(-50%);
	-webkit-transform: rotateX(0) translateX(-50%);
	opacity: 1
}

.gyges .tp-thumb.selected .tp-thumb-img-wrap,
.gyges .tp-thumb:hover .tp-thumb-img-wrap {
	background: #fff;
	background: -moz-linear-gradient(top, #fff 0, #777 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0, #fff), color-stop(100%, #777));
	background: -webkit-linear-gradient(top, #fff 0, #777 100%);
	background: -o-linear-gradient(top, #fff 0, #777 100%);
	background: -ms-linear-gradient(top, #fff 0, #777 100%);
	background: linear-gradient(to bottom, #fff 0, #777 100%)
}

.gyges .tp-thumb-title:after {
	content: " ";
	position: absolute;
	left: 50%;
	margin-left: -8px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 0 8px;
	border-color: rgba(255, 255, 255, .8) transparent transparent transparent;
	bottom: -8px
}

.gyges .tp-tab {
	opacity: 1;
	padding: 10px;
	box-sizing: border-box;
	font-family: Roboto, sans-serif;
	border-bottom: 1px solid rgba(255, 255, 255, .15)
}

.gyges .tp-tab-image {
	width: 60px;
	height: 60px;
	max-height: 100%;
	max-width: 100%;
	position: relative;
	display: inline-block;
	float: left
}

.gyges .tp-tab-content {
	background: rgba(0, 0, 0, 0);
	position: relative;
	padding: 15px 15px 15px 85px;
	left: 0;
	overflow: hidden;
	margin-top: -15px;
	box-sizing: border-box;
	color: #333;
	display: inline-block;
	width: 100%;
	height: 100%;
	position: absolute
}

.gyges .tp-tab-date {
	display: block;
	color: rgba(255, 255, 255, .25);
	font-weight: 500;
	font-size: 12px;
	margin-bottom: 0
}

.gyges .tp-tab-title {
	display: block;
	text-align: left;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	text-transform: none;
	line-height: 17px
}

.gyges .tp-tab.selected,
.gyges .tp-tab:hover {
	background: rgba(0, 0, 0, .5)
}

.hades.tparrows {
	cursor: pointer;
	background: #000;
	background: rgba(0, 0, 0, .15);
	width: 100px;
	height: 100px;
	position: absolute;
	display: block;
	z-index: 1000
}

.hades.tparrows:before {
	font-family: revicons;
	font-size: 30px;
	color: #fff;
	display: block;
	line-height: 100px;
	text-align: center;
	transition: background .3s, color .3s
}

.hades.tparrows.tp-leftarrow:before {
	content: "\e824"
}

.hades.tparrows.tp-rightarrow:before {
	content: "\e825"
}

.hades.tparrows:hover:before {
	color: #aaa;
	background: #fff;
	background: #fff
}

.hades .tp-arr-allwrapper {
	position: absolute;
	left: 100%;
	top: 0;
	background: #888;
	width: 100px;
	height: 100px;
	-webkit-transition: all .3s;
	transition: all .3s;
	-moz-opacity: 0;
	-khtml-opacity: 0;
	opacity: 0;
	-webkit-transform: rotatey(-90deg);
	transform: rotatey(-90deg);
	-webkit-transform-origin: 0 50%;
	transform-origin: 0 50%
}

.hades.tp-rightarrow .tp-arr-allwrapper {
	left: auto;
	right: 100%;
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-transform: rotatey(90deg);
	transform: rotatey(90deg)
}

.hades:hover .tp-arr-allwrapper {
	-moz-opacity: 1;
	-khtml-opacity: 1;
	opacity: 1;
	-webkit-transform: rotatey(0);
	transform: rotatey(0)
}

.hades .tp-arr-imgholder {
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.hades.tp-bullets:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background: 0 0;
	padding: 10px;
	margin-left: -10px;
	margin-top: -10px;
	box-sizing: content-box
}

.hades .tp-bullet {
	width: 3px;
	height: 3px;
	position: absolute;
	background: #888;
	cursor: pointer;
	border: 5px solid #fff;
	box-sizing: content-box;
	box-shadow: 0 0 3px 1px rgba(0, 0, 0, .2);
	-webkit-perspective: 400;
	perspective: 400;
	-webkit-transform: translatez(.01px);
	transform: translatez(.01px)
}

.hades .tp-bullet.selected,
.hades .tp-bullet:hover {
	background: #555
}

.hades .tp-bullet-image {
	position: absolute;
	top: -80px;
	left: -60px;
	width: 120px;
	height: 60px;
	background-position: center center;
	background-size: cover;
	visibility: hidden;
	opacity: 0;
	transition: all .3s;
	-webkit-transform-style: flat;
	transform-style: flat;
	perspective: 600;
	-webkit-perspective: 600;
	transform: rotatex(-90deg);
	-webkit-transform: rotatex(-90deg);
	box-shadow: 0 0 3px 1px rgba(0, 0, 0, .2);
	transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%
}

.hades .tp-bullet:hover .tp-bullet-image {
	display: block;
	opacity: 1;
	transform: rotatex(0);
	-webkit-transform: rotatex(0);
	visibility: visible
}

.hades .tp-thumb {
	opacity: 1
}

.hades .tp-thumb-img-wrap {
	border-radius: 50%;
	padding: 3px;
	display: inline-block;
	background: #000;
	background-color: rgba(0, 0, 0, .25);
	width: 100%;
	height: 100%;
	position: relative;
	margin: 0;
	box-sizing: border-box;
	transition: all .3s;
	-webkit-transition: all .3s
}

.hades .tp-thumb-image {
	padding: 3px;
	border-radius: 50%;
	display: block;
	box-sizing: border-box;
	position: relative;
	-webkit-box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	-moz-box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25)
}

.hades .tp-thumb.selected .tp-thumb-img-wrap,
.hades .tp-thumb:hover .tp-thumb-img-wrap {
	background: #fff;
	background: -moz-linear-gradient(top, #fff 0, #777 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0, #fff), color-stop(100%, #777));
	background: -webkit-linear-gradient(top, #fff 0, #777 100%);
	background: -o-linear-gradient(top, #fff 0, #777 100%);
	background: -ms-linear-gradient(top, #fff 0, #777 100%);
	background: linear-gradient(to bottom, #fff 0, #777 100%)
}

.hades .tp-thumb-title:after {
	content: " ";
	position: absolute;
	left: 50%;
	margin-left: -8px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 0 8px;
	border-color: rgba(0, 0, 0, .75) transparent transparent transparent;
	bottom: -8px
}

.hades .tp-tab {
	opacity: 1
}

.hades .tp-tab-title {
	display: block;
	color: #333;
	font-weight: 600;
	font-size: 18px;
	text-align: center;
	line-height: 25px
}

.hades .tp-tab-price {
	display: block;
	text-align: center;
	color: #999;
	font-size: 16px;
	margin-top: 10px;
	line-height: 20px
}

.hades .tp-tab-button {
	display: inline-block;
	margin-top: 15px;
	text-align: center;
	padding: 5px 15px;
	color: #fff;
	font-size: 14px;
	background: #219bd7;
	border-radius: 4px;
	font-weight: 400
}

.hades .tp-tab-inner {
	text-align: center
}

.hebe.tparrows {
	cursor: pointer;
	background: #fff;
	min-width: 70px;
	min-height: 70px;
	position: absolute;
	display: block;
	z-index: 1000
}

.hebe.tparrows:before {
	font-family: revicons;
	font-size: 30px;
	color: #aaa;
	display: block;
	line-height: 70px;
	text-align: center;
	-webkit-transition: color .3s;
	-moz-transition: color .3s;
	transition: color .3s;
	z-index: 2;
	position: relative;
	background: #fff;
	min-width: 70px;
	min-height: 70px
}

.hebe.tparrows.tp-leftarrow:before {
	content: "\e824"
}

.hebe.tparrows.tp-rightarrow:before {
	content: "\e825"
}

.hebe.tparrows:hover:before {
	color: #000
}

.hebe .tp-title-wrap {
	position: absolute;
	z-index: 0;
	display: inline-block;
	background: #000;
	background: rgba(0, 0, 0, .75);
	min-height: 60px;
	line-height: 60px;
	top: -10px;
	margin-left: 0;
	-webkit-transition: -webkit-transform .3s;
	transition: transform .3s;
	transform: scaleX(0);
	-webkit-transform: scaleX(0);
	transform-origin: 0 50%;
	-webkit-transform-origin: 0 50%
}

.hebe.tp-rightarrow .tp-title-wrap {
	right: 0;
	-webkit-transform-origin: 100% 50%
}

.hebe.tparrows:hover .tp-title-wrap {
	transform: scaleX(1);
	-webkit-transform: scaleX(1)
}

.hebe .tp-arr-titleholder {
	position: relative;
	text-transform: uppercase;
	color: #fff;
	font-weight: 600;
	font-size: 12px;
	line-height: 90px;
	white-space: nowrap;
	padding: 0 20px 0 90px
}

.hebe.tp-rightarrow .tp-arr-titleholder {
	margin-left: 0;
	padding: 0 90px 0 20px
}

.hebe.tparrows:hover .tp-arr-titleholder {
	transform: translateX(0);
	-webkit-transform: translateX(0);
	transition-delay: .1s;
	opacity: 1
}

.hebe .tp-arr-imgholder {
	width: 90px;
	height: 90px;
	position: absolute;
	left: 100%;
	display: block;
	background-size: cover;
	background-position: center center;
	top: 0;
	right: -90px
}

.hebe.tp-rightarrow .tp-arr-imgholder {
	right: auto;
	left: -90px
}

.hebe.tp-bullets:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background: 0 0;
	padding: 10px;
	margin-left: -10px;
	margin-top: -10px;
	box-sizing: content-box
}

.hebe .tp-bullet {
	width: 3px;
	height: 3px;
	position: absolute;
	background: #fff;
	cursor: pointer;
	border: 5px solid #222;
	border-radius: 50%;
	box-sizing: content-box;
	-webkit-perspective: 400;
	perspective: 400;
	-webkit-transform: translateZ(.01px);
	transform: translateZ(.01px);
	transition: all .3s
}

.hebe .tp-bullet.selected,
.hebe .tp-bullet:hover {
	background: #222;
	border-color: #fff
}

.hebe .tp-bullet-image {
	position: absolute;
	top: -90px;
	left: -40px;
	width: 70px;
	height: 70px;
	background-position: center center;
	background-size: cover;
	visibility: hidden;
	opacity: 0;
	transition: all .3s;
	-webkit-transform-style: flat;
	transform-style: flat;
	perspective: 600;
	-webkit-perspective: 600;
	transform: scale(0);
	-webkit-transform: scale(0);
	transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%;
	border-radius: 6px
}

.hebe .tp-bullet:hover .tp-bullet-image {
	display: block;
	opacity: 1;
	transform: scale(1);
	-webkit-transform: scale(1);
	visibility: visible
}

.hebe .tp-tab-title {
	color: #a8d8ee;
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
	font-family: "Roboto Slab";
	margin-bottom:5px
}

.hebe .tp-tab-desc {
	font-size: 18px;
	font-weight: 400;
	color: #fff;
	line-height: 25px;
	font-family: "Roboto Slab"
}

.hephaistos.tparrows {
	cursor: pointer;
	background: #000;
	background: rgba(0, 0, 0, .5);
	width: 40px;
	height: 40px;
	position: absolute;
	display: block;
	z-index: 1000;
	border-radius: 50%
}

.hephaistos.tparrows:hover {
	background: #000
}

.hephaistos.tparrows:before {
	font-family: revicons;
	font-size: 18px;
	color: #fff;
	display: block;
	line-height: 40px;
	text-align: center
}

.hephaistos.tparrows.tp-leftarrow:before {
	content: "\e82c";
	margin-left: -2px
}

.hephaistos.tparrows.tp-rightarrow:before {
	content: "\e82d";
	margin-right: -2px
}

.hephaistos.tp-bullets:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background: 0 0;
	padding: 10px;
	margin-left: -10px;
	margin-top: -10px;
	box-sizing: content-box
}

.hephaistos .tp-bullet {
	width: 12px;
	height: 12px;
	position: absolute;
	background: #999;
	border: 3px solid #f5f5f5;
	border-radius: 50%;
	cursor: pointer;
	box-sizing: content-box;
	box-shadow: 0 0 2px 1px rgba(130, 130, 130, .3)
}

.hephaistos .tp-bullet.selected,
.hephaistos .tp-bullet:hover {
	background: #fff;
	border-color: #000
}

.hermes.tparrows {
	cursor: pointer;
	background: #000;
	background: rgba(0, 0, 0, .5);
	width: 30px;
	height: 110px;
	position: absolute;
	display: block;
	z-index: 1000
}

.hermes.tparrows:before {
	font-family: revicons;
	font-size: 15px;
	color: #fff;
	display: block;
	line-height: 110px;
	text-align: center;
	transform: translateX(0);
	-webkit-transform: translateX(0);
	transition: all .3s;
	-webkit-transition: all .3s
}

.hermes.tparrows.tp-leftarrow:before {
	content: "\e824"
}

.hermes.tparrows.tp-rightarrow:before {
	content: "\e825"
}

.hermes.tparrows.tp-leftarrow:hover:before {
	transform: translateX(-20px);
	-webkit-transform: translateX(-20px);
	opacity: 0
}

.hermes.tparrows.tp-rightarrow:hover:before {
	transform: translateX(20px);
	-webkit-transform: translateX(20px);
	opacity: 0
}

.hermes .tp-arr-allwrapper {
	overflow: hidden;
	position: absolute;
	width: 180px;
	height: 140px;
	top: 0;
	left: 0;
	visibility: hidden;
	-webkit-transition: -webkit-transform .3s .3s;
	transition: transform .3s .3s;
	-webkit-perspective: 1000px;
	perspective: 1000px
}

.hermes.tp-rightarrow .tp-arr-allwrapper {
	right: 0;
	left: auto
}

.hermes.tparrows:hover .tp-arr-allwrapper {
	visibility: visible
}

.hermes .tp-arr-imgholder {
	width: 180px;
	position: absolute;
	left: 0;
	top: 0;
	height: 110px;
	transform: translateX(-180px);
	-webkit-transform: translateX(-180px);
	transition: all .3s;
	transition-delay: .3s
}

.hermes.tp-rightarrow .tp-arr-imgholder {
	transform: translateX(180px);
	-webkit-transform: translateX(180px)
}

.hermes.tparrows:hover .tp-arr-imgholder {
	transform: translateX(0);
	-webkit-transform: translateX(0)
}

.hermes .tp-arr-titleholder {
	top: 110px;
	width: 180px;
	text-align: left;
	display: block;
	padding: 0 10px;
	line-height: 30px;
	background: #000;
	background: rgba(0, 0, 0, .75);
	color: #fff;
	font-weight: 600;
	position: absolute;
	font-size: 12px;
	white-space: nowrap;
	letter-spacing: 1px;
	-webkit-transition: all .3s;
	transition: all .3s;
	-webkit-transform: rotateX(-90deg);
	transform: rotateX(-90deg);
	-webkit-transform-origin: 50% 0;
	transform-origin: 50% 0;
	box-sizing: border-box
}

.hermes.tparrows:hover .tp-arr-titleholder {
	-webkit-transition-delay: .6s;
	transition-delay: .6s;
	-webkit-transform: rotateX(0);
	transform: rotateX(0)
}

.hermes .tp-bullet {
	overflow: hidden;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	background-color: rgba(0, 0, 0, 0);
	box-shadow: inset 0 0 0 2px #fff;
	-webkit-transition: background .3s ease;
	transition: background .3s ease;
	position: absolute
}

.hermes .tp-bullet:hover {
	background-color: rgba(0, 0, 0, .2)
}

.hermes .tp-bullet:after {
	content: ' ';
	position: absolute;
	bottom: 0;
	height: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 0 1px #fff;
	-webkit-transition: height .3s ease;
	transition: height .3s ease
}

.hermes .tp-bullet.selected:after {
	height: 100%
}

.hermes .tp-tab {
	opacity: 1;
	padding-right: 10px;
	box-sizing: border-box
}

.hermes .tp-tab-image {
	width: 100%;
	height: 60%;
	position: relative
}

.hermes .tp-tab-content {
	background: #363636;
	position: absolute;
	padding: 20px 20px 20px 30px;
	box-sizing: border-box;
	color: #fff;
	display: block;
	width: 100%;
	min-height: 40%;
	bottom: 0;
	left: -10px
}

.hermes .tp-tab-date {
	display: block;
	color: #888;
	font-weight: 600;
	font-size: 12px;
	margin-bottom: 10px
}

.hermes .tp-tab-title {
	display: block;
	color: #fff;
	font-size: 16px;
	font-weight: 800;
	text-transform: uppercase;
	line-height: 19px
}

.hermes .tp-tab.selected .tp-tab-title:after {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 30px 0 30px 10px;
	border-color: transparent transparent transparent #363636;
	content: " ";
	position: absolute;
	right: -9px;
	bottom: 50%;
	margin-bottom: -30px
}

.hermes .tp-tab-mask {
	padding-right: 10px !important
}

@media only screen and (max-width:960px) {
	.hermes .tp-tab .tp-tab-title {
		font-size: 14px;
		line-height: 16px
	}

	.hermes .tp-tab-date {
		font-size: 11px;
		line-height: 13px;
		margin-bottom: 10px
	}

	.hermes .tp-tab-content {
		padding: 15px 15px 15px 25px
	}
}

@media only screen and (max-width:768px) {
	.hermes .tp-tab .tp-tab-title {
		font-size: 12px;
		line-height: 14px
	}

	.hermes .tp-tab-date {
		font-size: 10px;
		line-height: 12px;
		margin-bottom: 5px
	}

	.hermes .tp-tab-content {
		padding: 10px 10px 10px 20px
	}
}

.hesperiden.tparrows {
	cursor: pointer;
	background: #000;
	background: rgba(0, 0, 0, .5);
	width: 40px;
	height: 40px;
	position: absolute;
	display: block;
	z-index: 1000;
	border-radius: 50%
}

.hesperiden.tparrows:hover {
	background: #000
}

.hesperiden.tparrows:before {
	font-family: revicons;
	font-size: 20px;
	color: #fff;
	display: block;
	line-height: 40px;
	text-align: center
}

.hesperiden.tparrows.tp-leftarrow:before {
	content: "\e82c";
	margin-left: -3px
}

.hesperiden.tparrows.tp-rightarrow:before {
	content: "\e82d";
	margin-right: -3px
}

.hesperiden.tp-bullets:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background: 0 0;
	padding: 10px;
	margin-left: -10px;
	margin-top: -10px;
	box-sizing: content-box;
	border-radius: 8px
}

.hesperiden .tp-bullet {
	width: 12px;
	height: 12px;
	position: absolute;
	background: #999;
	background: -moz-linear-gradient(top, #999 0, #e1e1e1 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #999), color-stop(100%, #e1e1e1));
	background: -webkit-linear-gradient(top, #999 0, #e1e1e1 100%);
	background: -o-linear-gradient(top, #999 0, #e1e1e1 100%);
	background: -ms-linear-gradient(top, #999 0, #e1e1e1 100%);
	background: linear-gradient(to bottom, #999 0, #e1e1e1 100%);
	border: 3px solid #e5e5e5;
	border-radius: 50%;
	cursor: pointer;
	box-sizing: content-box
}

.hesperiden .tp-bullet.selected,
.hesperiden .tp-bullet:hover {
	background: #666
}

.hesperiden .tp-thumb {
	opacity: 1;
	-webkit-perspective: 600px;
	perspective: 600px
}

.hesperiden .tp-thumb .tp-thumb-title {
	font-size: 12px;
	position: absolute;
	margin-top: -10px;
	color: #fff;
	display: block;
	z-index: 10000;
	background-color: #000;
	padding: 5px 10px;
	bottom: 0;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	transition: all .3s;
	-webkit-transition: all .3s;
	transform: rotatex(90deg) translatez(.001px);
	transform-origin: 50% 100%;
	-webkit-transform: rotatex(90deg) translatez(.001px);
	-webkit-transform-origin: 50% 100%;
	opacity: 0
}

.hesperiden .tp-thumb:hover .tp-thumb-title {
	transform: rotatex(0);
	-webkit-transform: rotatex(0);
	opacity: 1
}

.hesperiden .tp-tab {
	opacity: 1;
	padding: 10px;
	box-sizing: border-box;
	font-family: Roboto, sans-serif;
	border-bottom: 1px solid #e5e5e5
}

.hesperiden .tp-tab-image {
	width: 60px;
	height: 60px;
	max-height: 100%;
	max-width: 100%;
	position: relative;
	display: inline-block;
	float: left
}

.hesperiden .tp-tab-content {
	background: rgba(0, 0, 0, 0);
	position: relative;
	padding: 15px 15px 15px 85px;
	left: 0;
	overflow: hidden;
	margin-top: -15px;
	box-sizing: border-box;
	color: #333;
	display: inline-block;
	width: 100%;
	height: 100%;
	position: absolute
}

.hesperiden .tp-tab-date {
	display: block;
	color: #aaa;
	font-weight: 500;
	font-size: 12px;
	margin-bottom: 0
}

.hesperiden .tp-tab-title {
	display: block;
	text-align: left;
	color: #333;
	font-size: 14px;
	font-weight: 500;
	text-transform: none;
	line-height: 17px
}

.hesperiden .tp-tab.selected,
.hesperiden .tp-tab:hover {
	background: #eee
}

.metis.tparrows {
	background: #fff;
	padding: 10px;
	transition: all .3s;
	-webkit-transition: all .3s;
	width: 60px;
	height: 60px;
	box-sizing: border-box
}

.metis.tparrows:hover {
	background: #fff;
	background: rgba(255, 255, 255, .75)
}

.metis.tparrows:before {
	color: #000;
	transition: all .3s;
	-webkit-transition: all .3s
}

.metis.tparrows:hover:before {
	transform: scale(1.5)
}

.metis .tp-bullet {
	opacity: 1;
	width: 50px;
	height: 50px;
	padding: 3px;
	background: #000;
	background-color: rgba(0, 0, 0, .25);
	margin: 0;
	box-sizing: border-box;
	transition: all .3s;
	-webkit-transition: all .3s;
	border-radius: 50%
}

.metis .tp-bullet-image {
	border-radius: 50%;
	display: block;
	box-sizing: border-box;
	position: relative;
	-webkit-box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	-moz-box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .25);
	width: 44px;
	height: 44px;
	background-size: cover;
	background-position: center center
}

.metis .tp-bullet-title {
	position: absolute;
	bottom: 65px;
	display: inline-block;
	left: 50%;
	background: #000;
	background: rgba(0, 0, 0, .75);
	color: #fff;
	padding: 10px 30px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	opacity: 0;
	transition: all .3s;
	-webkit-transition: all .3s;
	transform: translateZ(.001px) translateX(-50%) translateY(14px);
	transform-origin: 50% 100%;
	-webkit-transform: translateZ(.001px) translateX(-50%) translateY(14px);
	-webkit-transform-origin: 50% 100%;
	opacity: 0;
	white-space: nowrap
}

.metis .tp-bullet:hover .tp-bullet-title {
	transform: rotateX(0) translateX(-50%);
	-webkit-transform: rotateX(0) translateX(-50%);
	opacity: 1
}

.metis .tp-bullet.selected,
.metis .tp-bullet:hover {
	background: #fff;
	background: -moz-linear-gradient(top, #fff 0, #777 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0, #fff), color-stop(100%, #777));
	background: -webkit-linear-gradient(top, #fff 0, #777 100%);
	background: -o-linear-gradient(top, #fff 0, #777 100%);
	background: -ms-linear-gradient(top, #fff 0, #777 100%);
	background: linear-gradient(to bottom, #fff 0, #777 100%)
}

.metis .tp-bullet-title:after {
	content: " ";
	position: absolute;
	left: 50%;
	margin-left: -8px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 0 8px;
	border-color: rgba(0, 0, 0, .75) transparent transparent transparent;
	bottom: -8px
}

.metis .tp-tab-number {
	color: #fff;
	font-size: 40px;
	line-height: 30px;
	font-weight: 400;
	font-family: "Playfair Display";
	width: 50px;
	margin-right: 17px;
	display: inline-block;
	float: left
}

.metis .tp-tab-mask {
	padding-left: 20px;
	left: 0;
	max-width: 90px !important;
	transition: .4s padding-left, .4s left, .4s max-width
}

.metis:hover .tp-tab-mask {
	padding-left: 0;
	left: 50px;
	max-width: 500px !important
}

.metis .tp-tab-divider {
	border-right: 1px solid transparent;
	height: 30px;
	width: 1px;
	margin-top: 5px;
	display: inline-block;
	float: left
}

.metis .tp-tab-title {
	color: #fff;
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	font-family: "Playfair Display";
	position: relative;
	padding-top: 10px;
	padding-left: 30px;
	display: inline-block;
	transform: translateX(-100%);
	transition: .4s all
}

.metis .tp-tab-title-mask {
	position: absolute;
	overflow: hidden;
	left: 67px
}

.metis:hover .tp-tab-title {
	transform: translateX(0)
}

.metis .tp-tab {
	opacity: .15;
	transition: .4s all
}

.metis .tp-tab.selected,
.metis .tp-tab:hover {
	opacity: 1
}

.metis .tp-tab.selected .tp-tab-divider {
	border-right: 1px solid #cdb083
}

.metis.tp-tabs {
	max-width: 118px !important;
	padding-left: 50px
}

.metis.tp-tabs:before {
	content: " ";
	height: 100%;
	width: 88px;
	background: rgba(0, 0, 0, .15);
	border-right: 1px solid rgba(255, 255, 255, .1);
	left: 0;
	top: 0;
	position: absolute;
	transition: .4s all
}

.metis.tp-tabs:hover:before {
	width: 118px
}

@media (max-width:499px) {
	.metis.tp-tabs:before {
		background: rgba(0, 0, 0, .75)
	}
}

.persephone.tparrows {
	cursor: pointer;
	background: #aaa;
	background: rgba(200, 200, 200, .5);
	width: 40px;
	height: 40px;
	position: absolute;
	display: block;
	z-index: 100;
	border: 1px solid #f5f5f5
}

.persephone.tparrows:hover {
	background: #333
}

.persephone.tparrows:before {
	font-family: revicons;
	font-size: 15px;
	color: #fff;
	display: block;
	line-height: 40px;
	text-align: center
}

.persephone.tparrows.tp-leftarrow:before {
	content: "\e824"
}

.persephone.tparrows.tp-rightarrow:before {
	content: "\e825"
}

.persephone.tp-bullets:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background: #transparent;
	padding: 10px;
	margin-left: -10px;
	margin-top: -10px;
	box-sizing: content-box
}

.persephone .tp-bullet {
	width: 12px;
	height: 12px;
	position: absolute;
	background: #aaa;
	border: 1px solid #e5e5e5;
	cursor: pointer;
	box-sizing: content-box
}

.persephone .tp-bullet.selected,
.persephone .tp-bullet:hover {
	background: #222
}

.uranus.tparrows {
	width: 50px;
	height: 50px;
	background: 0 0
}

.uranus.tparrows:before {
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 40px;
	transition: all .3s;
	-webkit-transition: all .3s
}

.uranus.tparrows:hover:before {
	opacity: .75
}

.uranus .tp-bullet {
	border-radius: 50%;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
	-webkit-transition: box-shadow .3s ease;
	transition: box-shadow .3s ease;
	background: 0 0
}

.uranus .tp-bullet.selected,
.uranus .tp-bullet:hover {
	box-shadow: 0 0 0 2px #fff;
	border: none;
	border-radius: 50%;
	background: 0 0
}

.uranus .tp-bullet-inner {
	background-color: rgba(255, 255, 255, .7);
	-webkit-transition: background-color .3s ease, -webkit-transform .3s ease;
	transition: background-color .3s ease, transform .3s ease;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	outline: 0;
	border-radius: 50%;
	background-color: #fff;
	background-color: rgba(255, 255, 255, .3);
	text-indent: -999em;
	cursor: pointer;
	position: absolute
}

.uranus .tp-bullet.selected .tp-bullet-inner,
.uranus .tp-bullet:hover .tp-bullet-inner {
	transform: scale(.4);
	-webkit-transform: scale(.4);
	background-color: #fff
}

.zeus.tparrows {
	cursor: pointer;
	min-width: 70px;
	min-height: 70px;
	position: absolute;
	display: block;
	z-index: 100;
	border-radius: 35px;
	overflow: hidden;
	background: rgba(0, 0, 0, .1)
}

.zeus.tparrows:before {
	font-family: revicons;
	font-size: 20px;
	color: #fff;
	display: block;
	line-height: 70px;
	text-align: center;
	z-index: 2;
	position: relative
}

.zeus.tparrows.tp-leftarrow:before {
	content: "\e824"
}

.zeus.tparrows.tp-rightarrow:before {
	content: "\e825"
}

.zeus .tp-title-wrap {
	background: #000;
	background: rgba(0, 0, 0, .5);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	transform: scale(0);
	-webkit-transform: scale(0);
	transition: all .3s;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	border-radius: 50%
}

.zeus .tp-arr-imgholder {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-position: center center;
	background-size: cover;
	border-radius: 50%;
	transform: translateX(-100%);
	-webkit-transform: translateX(-100%);
	transition: all .3s;
	-webkit-transition: all .3s;
	-moz-transition: all .3s
}

.zeus.tp-rightarrow .tp-arr-imgholder {
	transform: translateX(100%);
	-webkit-transform: translateX(100%)
}

.zeus.tparrows:hover .tp-arr-imgholder {
	transform: translateX(0);
	-webkit-transform: translateX(0);
	opacity: 1
}

.zeus.tparrows:hover .tp-title-wrap {
	transform: scale(1);
	-webkit-transform: scale(1);
	opacity: 1
}

.zeus .tp-bullet {
	box-sizing: content-box;
	-webkit-box-sizing: content-box;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0);
	-webkit-transition: opacity .3s ease;
	transition: opacity .3s ease;
	width: 13px;
	height: 13px;
	border: 2px solid #fff
}

.zeus .tp-bullet:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	border-radius: 50%;
	background-color: #fff;
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-transition: -webkit-transform .3s ease;
	transition: transform .3s ease
}

.zeus .tp-bullet.selected:after,
.zeus .tp-bullet:hover:after {
	-webkit-transform: scale(1.2);
	transform: scale(1.2)
}

.zeus .tp-bullet-image,
.zeus .tp-bullet-imageoverlay {
	width: 135px;
	height: 60px;
	position: absolute;
	background: #000;
	background: rgba(0, 0, 0, .5);
	bottom: 25px;
	left: 50%;
	margin-left: -65px;
	box-sizing: border-box;
	background-size: cover;
	background-position: center center;
	visibility: hidden;
	opacity: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	border-radius: 4px
}

.zeus .tp-bullet-imageoverlay,
.zeus .tp-bullet-title {
	z-index: 2;
	-webkit-transition: all .5s ease;
	transition: all .5s ease
}

.zeus .tp-bullet-title {
	color: #fff;
	text-align: center;
	line-height: 15px;
	font-size: 13px;
	font-weight: 600;
	z-index: 3;
	visibility: hidden;
	opacity: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	position: absolute;
	bottom: 45px;
	width: 135px;
	vertical-align: middle;
	left: -57px
}

.zeus .tp-bullet:hover .tp-bullet-image,
.zeus .tp-bullet:hover .tp-bullet-imageoverlay,
.zeus .tp-bullet:hover .tp-bullet-title {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.zeus .tp-thumb {
	opacity: 1
}

.zeus .tp-thumb-over {
	background: #000;
	background: rgba(0, 0, 0, .25);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	-webkit-transition: all .3s;
	transition: all .3s
}

.zeus .tp-thumb-more:before {
	font-family: revicons;
	font-size: 12px;
	color: #aaa;
	color: rgba(255, 255, 255, .75);
	display: block;
	line-height: 12px;
	text-align: left;
	z-index: 2;
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 2
}

.zeus .tp-thumb-more:before {
	content: "\e825"
}

.zeus .tp-thumb-title {
	font-family: Raleway;
	letter-spacing: 1px;
	font-size: 12px;
	color: #fff;
	display: block;
	line-height: 15px;
	text-align: left;
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	padding: 20px 35px 20px 20px;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	transition: all .3s;
	-webkit-transition: all .3s;
	font-weight: 500
}

.zeus .tp-thumb.selected .tp-thumb-more:before,
.zeus .tp-thumb:hover .tp-thumb-more:before {
	color: #aaa
}

.zeus .tp-thumb.selected .tp-thumb-over,
.zeus .tp-thumb:hover .tp-thumb-over {
	background: #000
}

.zeus .tp-thumb.selected .tp-thumb-title,
.zeus .tp-thumb:hover .tp-thumb-title {
	color: #fff
}

.zeus .tp-tab {
	opacity: 1;
	box-sizing: border-box
}

.zeus .tp-tab-title {
	display: block;
	text-align: center;
	background: rgba(0, 0, 0, .25);
	font-family: "Roboto Slab", serif;
	font-weight: 700;
	font-size: 13px;
	line-height: 13px;
	color: #fff;
	padding: 9px 10px
}

.zeus .tp-tab.selected .tp-tab-title,
.zeus .tp-tab:hover .tp-tab-title {
	color: #000;
	background: #fff
}

.post-tabs .tp-thumb {
	opacity: 1
}

.post-tabs .tp-thumb-over {
	background: #252525;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	-webkit-transition: all .3s;
	transition: all .3s
}

.post-tabs .tp-thumb-more:before {
	font-family: revicons;
	font-size: 12px;
	color: #aaa;
	color: rgba(255, 255, 255, .75);
	display: block;
	line-height: 12px;
	text-align: left;
	z-index: 2;
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 2
}

.post-tabs .tp-thumb-more:before {
	content: "\e825"
}

.post-tabs .tp-thumb-title {
	font-family: raleway;
	letter-spacing: 1px;
	font-size: 12px;
	color: #fff;
	display: block;
	line-height: 15px;
	text-align: left;
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	padding: 15px 30px 15px 15px;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	transition: all .3s;
	-webkit-transition: all .3s;
	font-weight: 500
}

.post-tabs .tp-thumb.selected .tp-thumb-more:before,
.post-tabs .tp-thumb:hover .tp-thumb-more:before {
	color: #aaa
}

.post-tabs .tp-thumb.selected .tp-thumb-over,
.post-tabs .tp-thumb:hover .tp-thumb-over {
	background: #fff
}

.post-tabs .tp-thumb.selected .tp-thumb-title,
.post-tabs .tp-thumb:hover .tp-thumb-title {
	color: #000
}